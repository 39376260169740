import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { KisListServable } from '../../interfaces/kis-list-servable.interface';
import { KisRestService } from '../action/kis.rest.service';
import { KisList } from '../../models/kis-list.model';
import { Kis } from '../../models/kis.model';
import { KisMappingService } from '../mapping/kis-mapping.service';
import { ApiKis } from '../../models/api-kis.model';
import { ApiKisList } from '../../models/api-kis-list.model';
import { ApiKisMap } from '../../models/api-kis-map.model';

@Injectable({
  providedIn: 'root',
})
export class KisRestAdapterService implements KisListServable {
  constructor(private kisRestService: KisRestService, private kisMappingService: KisMappingService) {}

  getKis(id: number): Observable<Kis> {
    const resultSubject = new ReplaySubject<Kis>(1);

    this.kisRestService.getKis(id).subscribe((apiKis: ApiKis) => {
      resultSubject.next(this.kisMappingService.mapEntry(apiKis));
    });

    return resultSubject.asObservable();
  }

  getKisByProtocolId(protocolId: string): Observable<Kis> {
    const resultSubject = new ReplaySubject<Kis>(1);

    this.kisRestService.getKisByProtocolId(protocolId).subscribe((apiKis: ApiKis) => {
      resultSubject.next(this.kisMappingService.mapEntry(apiKis));
    });

    return resultSubject.asObservable();
  }

  getKisList(): Observable<KisList> {
    const resultSubject = new ReplaySubject<KisList>(1);

    this.kisRestService.getKisList().subscribe((apiKisList: ApiKisList) => {
      const kisList: KisList = [];

      if (apiKisList === null || apiKisList === undefined || apiKisList.data === undefined) {
        resultSubject.next(kisList);
        return;
      }

      for (const apiKis of apiKisList.data) {
        kisList.push(this.kisMappingService.mapEntry(apiKis));
      }
      resultSubject.next(kisList);
    });

    return resultSubject;
  }

  mapKis(id: number, nidaId: string, protocolId: number, exportAt?: Date): Observable<Kis> {
    const resultSubject = new ReplaySubject<Kis>(1);
    const body: ApiKisMap = {
      nidaId,
      protocolId,
    };

    if (exportAt) {
      body.exportAt = exportAt;
    }

    this.kisRestService.mapKis(id, body).subscribe((apiKis: ApiKis) => {
      resultSubject.next(this.kisMappingService.mapEntry(apiKis));
    });

    return resultSubject;
  }

  unmapKis(id: number): Observable<Kis> {
    const resultSubject = new ReplaySubject<Kis>(1);

    this.kisRestService.unmapKis(id).subscribe((apiKis: ApiKis) => {
      resultSubject.next(this.kisMappingService.mapEntry(apiKis));
    });

    return resultSubject;
  }
}
