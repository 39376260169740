import { Injectable } from '@angular/core';
import { Findings, PatientDetailServable, Picture, PictureList, Video } from '@nida-web/api/generic-interfaces/patient-management';
import { Findings as FindingsRaw } from '../models/findings';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { PatientDetailRESTService } from '../actions/patient-detail.rest.service';
import { FindingMappingService } from '../mapping/finding-mapping.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Finding } from '../models/finding';
import { AudioRecordList } from '../models/audioRecordList';

@Injectable({
  providedIn: 'root',
})
export class PatientDetailRESTAdapterService implements PatientDetailServable {
  constructor(
    private patientDetailRESTService: PatientDetailRESTService,
    private findingMappingService: FindingMappingService,
    private sanitizer: DomSanitizer
  ) {}

  getDetailFinding(revision: string, findingType?: 'voranmeldung' | 'eb_atmung' | 'eb_neuro' | 'eb_kreislauf'): Observable<Findings> {
    const resultSubj = new ReplaySubject<Findings>(1);
    this.patientDetailRESTService.getDetailBefund(revision).subscribe((rawFindings: FindingsRaw) => {
      let cleandUpRawFindings: Finding[] = rawFindings.data;
      if (findingType) {
        cleandUpRawFindings = cleandUpRawFindings.filter((rawFinding: Finding) => rawFinding['befund.befund_art'] === findingType);
      }
      resultSubj.next(this.findingMappingService.mapValues(cleandUpRawFindings));
    });

    return resultSubj.asObservable();
  }

  getDetailVideos(): Observable<Video> {
    // Not supported yet
    return new BehaviorSubject<Video>(new Video()).asObservable();
  }

  getPatientPicture(nidaIdPictures: string, pictureId: string): Observable<Picture> {
    const resultSubj = new ReplaySubject<Picture>(1);

    this.patientDetailRESTService.getDetailSinglePicture(nidaIdPictures, pictureId).subscribe((rawPicture: ArrayBuffer) => {
      if (rawPicture === undefined || rawPicture === null || rawPicture.byteLength <= 0) {
        throw new Error('Picture not loaded!');
      }
      const picture = new Picture();
      picture.isVideo = false;
      picture.srcUrl = this.transform(URL.createObjectURL(new Blob([rawPicture])));
      resultSubj.next(picture);
    });
    return resultSubj.asObservable();
  }

  getPictureList(nidaIdPictures: string): Observable<PictureList> {
    const resultSubj = new ReplaySubject<PictureList>(1);
    this.patientDetailRESTService.getDetailPictures(nidaIdPictures).subscribe((rawList) => {
      const pictureList = new PictureList();

      if (rawList === null || rawList.data === undefined || rawList.data === null) {
        resultSubj.next(pictureList);
        return;
      }

      for (const rawPicture of rawList.data) {
        const checkedPicture = this.checkRawPictureIntegrity(rawPicture);

        this.getPatientPicture(nidaIdPictures, checkedPicture.id).subscribe((picture) => {
          if (checkedPicture.date) {
            picture.date = new Date(checkedPicture.date);
          } else {
            picture.date = undefined;
          }

          pictureList.push(picture);

          if (pictureList.length === rawList.data.length) {
            resultSubj.next(pictureList);
          }
        });
      }
    });
    return resultSubj.asObservable();
  }

  getAudioList(protocolId: number): Observable<AudioRecordList> {
    return this.patientDetailRESTService.getAudioRecords(protocolId);
  }

  getAudioRecordById(protocolId: number, id: number): Observable<Blob> {
    return this.patientDetailRESTService.getAudioRecordById(protocolId, id);
  }

  checkRawPictureIntegrity(rawPicture: any) {
    if (rawPicture['protokoll_bilder.id'] !== undefined) {
      rawPicture.id = rawPicture['protokoll_bilder.id'];
    }
    if (rawPicture['protokoll_bilder.datum_zeit_aufnahme'] !== undefined) {
      rawPicture.date = new Date(rawPicture['protokoll_bilder.datum_zeit_aufnahme']);
      rawPicture['protokoll_bilder.datum_zeit_aufnahme'] = rawPicture.date;
    }

    return rawPicture;
  }

  private transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
