import { EcgLeadData } from './ecg-lead-data.model';
import { EcgLeadMeta } from './ecg-lead-meta.model';
import { EcgLeadDataByType } from './ecg-lead-data-by-type.model';

export class EcgLead {
  metadata: EcgLeadMeta;
  dataByType: EcgLeadDataByType;
  data: Array<EcgLeadData>;

  constructor(metadata?: EcgLeadMeta, data?: Array<EcgLeadData>, dataByType?: EcgLeadDataByType) {
    this.metadata = metadata || new EcgLeadMeta();
    this.data = data || [];
    this.dataByType = dataByType || new EcgLeadDataByType();
  }
}
