export class EcgLeadMeta {
  timePerSample: number;
  valueMin: number;
  valueMax: number;
  valueBaseline: number;
  length: number;

  constructor(timePerSample?: number, valueMin?: number, valueMax?: number, valueBaseline?: number, length?: number) {
    this.timePerSample = timePerSample || 0;
    this.valueMin = valueMin || 0;
    this.valueMax = valueMax || 0;
    this.valueBaseline = valueBaseline || 0;
    this.length = length || 0;
  }
}
