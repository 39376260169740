import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable()
export class CheckForUpdateService {
  constructor(private updates: SwUpdate) {
    this.checkForUpdate();
  }

  checkForUpdate() {
    // when developing service-worker is not loaded, lets check it first before getting errors
    if (this.updates.isEnabled) {
      const pollingMinutes = 10;
      const everyInterval = interval(pollingMinutes * 60 * 1000);
      everyInterval.subscribe(() => {
        this.updates.checkForUpdate().then();
      });
    }
  }
}
