import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { PermissionsHelper, SessionManagerService } from '@nida-web/api/rest/authentication';

@Injectable()
export class AuthGuard {
  private userPermissions: string[];

  constructor(private router: Router, private sessionManagerService: SessionManagerService) {
    this.userPermissions = [];
    this.sessionManagerService.getSessionInformation().subscribe((sessionInfo) => {
      if (sessionInfo.loggedIn) {
        this.userPermissions = sessionInfo.permissions ? sessionInfo.permissions : [];
      } else {
        this.userPermissions = [];
      }
    });
  }

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const expectedRoles: string[] = this.getExpectedRoles(next);
    if (expectedRoles && expectedRoles.length > 0) {
      const hasPermissions: boolean = PermissionsHelper.isAllowedAccess(expectedRoles, this.userPermissions);
      if (hasPermissions) {
        return true;
      } else {
        return this.router.parseUrl('login');
      }
    } else {
      return true;
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(next);
  }

  private getExpectedRoles(next: ActivatedRouteSnapshot) {
    if (next && next.data) {
      return next.data.expectedRoles;
    } else {
      return null;
    }
  }
}
