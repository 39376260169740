<div class="content-block">
  <div class="responsive-paddings">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="pw-change-title">{{ 'Hint' | transloco }}</div>
      </div>
      <div class="row justify-content-center">
        <div class="pw-change-header">{{ 'settings.changeUserPassword.firstLoginOrExpired' | transloco }}</div>
      </div>
      <div class="justify-content-center row">
        <div class="smaller-pw-change">
          <nida-web-password-management
            [headerName]="'settings.changeUserPassword.changePassword' | transloco"
            [buttonName]="'settings.changeUserPassword.changePassword' | transloco"
            [requireCurrentPassword]="true"
            [redirect]="true"
            [nidaMobile]="false"
          ></nida-web-password-management>
        </div>
      </div>
    </div>
  </div>
</div>
