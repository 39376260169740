import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-countdown',
  templateUrl: './countdown.component.html',
  encapsulation: ViewEncapsulation.Emulated,
})
export class CountdownComponent implements OnInit, OnDestroy {
  static readonly refreshRate = 30000;
  static readonly millisecondsOfOneMinute = 60000;
  @Input() end: string | Date;
  private timer?: number;
  displayTime: number;

  constructor() {
    this.displayTime = 0;
    this.end = '';
  }

  private static getTimeDiff(datetime: Date): number {
    const now: number = new Date().getTime();
    const millisecondsDiff = datetime.getTime() - now;
    return Math.round(millisecondsDiff / CountdownComponent.millisecondsOfOneMinute);
  }

  ngOnInit(): void {
    this.displayTime = CountdownComponent.getTimeDiff(new Date(this.end));
    this.timer = window.setInterval(() => {
      this.displayTime = CountdownComponent.getTimeDiff(new Date(this.end));
    }, CountdownComponent.refreshRate);
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  private stopTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = undefined;
  }
}
