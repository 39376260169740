import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { ModuleSettingsService } from '../services/module-settings.service';
import { Subscription } from 'rxjs';
import { BreadcrumbNavItem } from '../models/breadcrumb-nav-item.model';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'nida-web-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  public menuItems: Array<BreadcrumbNavItem>;

  public showBreadcrumbs: boolean;
  public upperLevelExist: boolean;

  public subSettings?: Subscription;
  public subCreated?: Subscription;
  public subShow?: Subscription;

  public mainSubscription?: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private moduleSettingsService: ModuleSettingsService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute
  ) {
    this.menuItems = [];
    this.upperLevelExist = false;
    this.showBreadcrumbs = false;
  }

  ngOnInit(): void {
    this.subscribeServiceToRouter();
    this.subscribeToService();
  }

  ngOnDestroy(): void {
    if (this.subSettings) this.subSettings.unsubscribe();
    if (this.subCreated) this.subCreated.unsubscribe();
    if (this.subShow) this.subShow.unsubscribe();
  }

  subscribeServiceToRouter() {
    this.mainSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.breadcrumbService.triggerBreadcrumbCreation(this.activatedRoute.root);
    });
  }

  subscribeToService() {
    this.subSettings = this.moduleSettingsService.getSettings().subscribe((settings) => {
      if (settings.breadcrumbs) {
        this.breadcrumbService.setBreadcrumbs(settings.breadcrumbs);
      } else {
        this.breadcrumbService.setBreadcrumbs(false);
      }
    });

    this.subCreated = this.breadcrumbService.isCreated.subscribe((bool: boolean) => {
      if (bool) {
        this.manageStore();
      } else {
        // reloading the page != router.event
        // manually triggering 1 breadcrumbs creation
        this.breadcrumbService.triggerBreadcrumbCreation(this.activatedRoute.root);
      }
    });

    this.subShow = this.breadcrumbService.showBreadcrumb.subscribe((bool: boolean) => {
      this.showBreadcrumbs = bool;
    });
  }

  manageStore() {
    this.menuItems = this.breadcrumbService.getMenuItems();
    this.upperLevelExist = this.breadcrumbService.upperLevelExist();
  }

  navigateTo(path: string) {
    this.breadcrumbService.navigateTo(path);
  }

  navigateBack() {
    this.navigateTo(this.breadcrumbService.getUpperLevelPath());
  }

  testFunction() {
    this.breadcrumbService.triggerBreadcrumbCreation(this.activatedRoute);
  }
}
