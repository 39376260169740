import { Component, NgZone, OnInit } from '@angular/core';
import { PatientTableComponent } from '../patient-table/patient-table.component';
import { Router } from '@angular/router';
import { PatientListStoreService } from '../services/store/patient-list.store.service';
import { ColumnProviderService, DateService } from '@nida-web/core';
import { TranslocoService } from '@ngneat/transloco';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { PatientMappingService } from '@nida-web/api/rest/patient-management';
import { ElasticStoreService } from '../services/store/elastic.store.service';
import { ElasticRestService } from '@nida-web/api/rest/nidaserver/elastic';

@Component({
  selector: 'nida-web-patient-table-current',
  templateUrl: '../patient-table/patient-table.component.html',
  styleUrls: ['../patient-table/patient-table.component.scss'],
})
export class PatientTableCurrentComponent extends PatientTableComponent implements OnInit {
  constructor(
    protected router: Router,
    protected columnService: ColumnProviderService,
    protected dateService: DateService,
    protected zone: NgZone,
    protected transloco: TranslocoService,
    protected session: SessionManagerService,
    protected patientListStoreService: PatientListStoreService,
    protected exampleElkRestService: ElasticRestService,
    protected patientMappingService: PatientMappingService,
    protected elasticStoreService: ElasticStoreService
  ) {
    super(router, columnService, dateService, transloco, session, exampleElkRestService, patientMappingService, elasticStoreService, zone);
    this.gridName = 'patientCurrentGrid';
    this.configName = 'CurrentPatientColumns';
    this.columnService.buildTableColumns('CurrentPatientColumns');
  }

  ngOnInit(): void {
    this.dataSource = this.patientListStoreService.getPatientList();
    this.getColumns();
    this.initializeColumnHovered();
  }

  triggerNavigation(input: any) {
    this.router.navigate(['/current-patients/details/' + input]).then();
  }
}
