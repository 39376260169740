<ng-container *ngIf="!hiddenHeader">
  <nida-web-header
    (menuToggle)="burgerClick()"
    [menuToggleEnabled]="userNameExists"
    [title]="title"
    [headerTemplate]="this.headerTemplate"
    class="layout-header"
  >
  </nida-web-header>
</ng-container>

<ng-container *ngIf="navigationItems$ | async as navigationItems">
  <dx-drawer
    [(opened)]="menuOpened"
    [closeOnOutsideClick]="shaderEnabled"
    [minSize]="minMenuSize"
    [openedStateMode]="menuMode"
    [revealMode]="menuRevealMode"
    [shading]="shaderEnabled"
    class="layout-body"
    position="before"
  >
    <nida-web-side-navigation-menu
      *dxTemplate="let dataMenu of 'panel'"
      (openMenu)="navigationClickedOnSmallDevice()"
      [compactMode]="!menuOpened"
      [items]="navigationItems"
      [selectedItem]="selectedRoute"
      class="dx-swatch-additional"
      [replaceIconsWithNumbers]="replaceIconsWithNumbers"
    >
    </nida-web-side-navigation-menu>

    <dx-scroll-view
      class="full-height-scrollable"
      style="background-color: whitesmoke; box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
    >
      <div class="content" style="display: flex">
        <div style="width: 100%">
          <ng-content style="width: 100%"></ng-content>
        </div>
      </div>
      <div class="content-block">
        <ng-content select="nida-web-footer"></ng-content>
      </div>
    </dx-scroll-view>
  </dx-drawer>
</ng-container>
