import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ToasterInterceptorService implements HttpInterceptor {
  constructor(private translocoService: TranslocoService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorCodeHandling(error);
        return throwError(error);
      })
    );
  }

  private errorCodeHandling(error: HttpErrorResponse) {
    switch (error.status) {
      case 0: {
        if (error.url?.includes('serverinfo/health')) {
          // not show message
        }
        break;
      }
      case 200: {
        if (error.url?.includes('serverinfo/health')) {
          // not show message
        }
        break;
      }
      case 401: {
        if (error.url?.includes('auth/validate2factor')) {
          notify({
            message: this.translocoService.translate('validations.interceptorService.login2FAFailed'),
            type: 'error',
            displayTime: 5000,
          });
        } else if (error.url?.includes('auth/validate')) {
          notify({
            message: this.translocoService.translate('validations.interceptorService.loginFailed'),
            type: 'error',
            displayTime: 5000,
          });
        } else if (error.url?.includes('auth/me')) {
          notify({
            message: this.translocoService.translate('validations.interceptorService.sessionExpired'),
            type: 'warning',
            displayTime: 5000,
          });
        } else {
          notify({
            message: this.translocoService.translate('validations.interceptorService.notLoggedIn'),
            type: 'error',
            displayTime: 5000,
          });
        }

        break;
      }
      case 403: {
        if (error.url?.includes('auth/verifysecondfactor')) {
          break;
        }
        notify({
          message: this.translocoService.translate('validations.interceptorService.notEnoughPermissions'),
          type: 'error',
          displayTime: 5000,
        });
        break;
      }
      case 404: {
        //don't show error if user-method is not implemented by server
        if (error.url?.includes('auth/user-method')) {
          break;
        }
        if (error.url?.includes('ecg/pdf')) {
          notify({
            message: this.translocoService.translate('validations.interceptorService.pdfNotAvailable'),
            type: 'warning',
            displayTime: 5000,
          });
        } else {
          notify({
            message: this.translocoService.translate('unknownServerError'),
            type: 'error',
            displayTime: 5000,
          });
        }
        break;
      }
      // Should be handled in the component that made the request
      case 409:
        break;
      case 500: {
        notify({
          message: this.translocoService.translate('unknownServerError'),
          type: 'error',
          displayTime: 5000,
        });
        break;
      }
      default:
        notify({
          message: this.translocoService.translate('validations.interceptorService.unknownErrorCode', { value: error.message }),
          type: 'error',
          displayTime: 5000,
        });
    }
  }
}
