/**
 * detail data type
 * additional data for a patient (e.g. address, insurance number, birth date)
 */
export class DetailData {
  birth_date?: string;
  gender?: string;
  house_nr?: string;
  country?: string;
  name?: string;
  family_name?: string;
  city?: string;
  pat_family_tel?: string;
  pat_nationality?: string;
  pat_citizenship?: string;
  zip?: string;
  street?: string;
  insurance_no?: string;
  insurance?: string;
  insurancePrivate?: string;
  ik?: string;
  insuranceStatus?: string;
  insuranceExempted?: string;

  // TODO: define types!!!
  constructor(
    birth_date?: string,
    gender?: string,
    house_nr?: string,
    country?: string,
    name?: string,
    family_name?: string,
    city?: string,
    pat_family_tel?: string,
    pat_nationality?: string,
    pat_citizenship?: string,
    zip?: string,
    street?: string,
    insurance_no?: string,
    insurance?: string,
    insurancePrivate?: string,
    ik?: string,
    insuranceStatus?: string,
    insuranceExempted?: string
  ) {
    this.birth_date = birth_date;
    this.gender = gender;
    this.house_nr = house_nr;
    this.country = country;
    this.name = name;
    this.family_name = family_name;
    this.city = city;
    this.pat_family_tel = pat_family_tel;
    this.pat_nationality = pat_nationality;
    this.pat_citizenship = pat_citizenship;
    this.zip = zip;
    this.street = street;
    this.insurance_no = insurance_no;
    this.insurance = insurance;
    this.insurancePrivate = insurancePrivate;
    this.ik = ik;
    this.insuranceStatus = insuranceStatus;
    this.insuranceExempted = insuranceExempted;
  }
}
