<div class="pad-10">
  <nida-web-er-title-toolbar
    [title]="'Abschlussbericht'"
    [disabled]="finalReportFormGroup.pristine"
    (saveTrigger)="submitFormGroups()"
    (revertTrigger)="resetFormGroups()"
  ></nida-web-er-title-toolbar>
</div>
<div>
  <form [formGroup]="finalReportFormGroup">
    <div class="basic-card-wrapper pt-0">
      <div class="basic-card-style pad-bot-15">
        <div class="basic-card-title basic-padding mb-2">
          {{ 'Diagnoses' | transloco }}
        </div>

        <div class="row m-0 p-0">
          <div class="col m-0 p-0">
            <div class="row m-0 p-0">
              <div class="col-xl-12">
                <div class="row m-0 p-0"></div>
                <div class="row m-0 p-0">
                  <dx-data-grid
                    class="mt-1"
                    id="gridContainer"
                    [dataSource]="icd10List"
                    [allowColumnReordering]="true"
                    [columnHidingEnabled]="true"
                    [showRowLines]="true"
                    [showBorders]="true"
                    (onToolbarPreparing)="onToolbarPreparing($event)"
                    (onRowInserting)="onRowInserting($event)"
                    (onRowRemoving)="onRowRemoving($event)"
                    (onRowUpdated)="onRowUpdated($event)"
                  >
                    <dxo-paging [enabled]="true"></dxo-paging>
                    <dxo-editing useIcons="true" mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                    </dxo-editing>

                    <dxi-column dataField="codeFirstpart" width="100" dataType="number" [caption]="'First part' | transloco">
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>

                    <dxi-column dataField="codeSecondpart" width="150" dataType="number" [caption]="'Second part' | transloco">
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>

                    <dxi-column dataField="description" dataType="string" [caption]="'Description' | transloco"> </dxi-column>

                    <div *dxTemplate="let data of 'newToolbar'">
                      <div class="basic-card-title-without-border basic-padding">
                        {{ 'ICD10' | transloco }}
                      </div>
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row m-0 p-0">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="row mb-1">
              <div class="col-xl-6 mb-1 mb-xl-0 d-flex align-items-center">
                {{ 'Complete diagnostics before ICU admission' | transloco }}
              </div>
              <div class="col-xl-6 mb-2 mb-xl-0 d-flex align-items-center">
                <dx-switch
                  formControlName="diagnosticsBeforeIcuComplete"
                  [switchedOnText]="'Yes' | transloco"
                  [switchedOffText]="'No' | transloco"
                >
                </dx-switch>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-xl-6 mb-1 mb-xl-0 mb-lg-1 mb-xl-0 d-flex align-items-center">
                {{ 'Emergency room diagnostics ended normally' | transloco }}
              </div>
              <div class="col-xl-6 mb-2 mb-xl-0 d-flex align-items-center">
                <dx-switch formControlName="shockRoomRegularEnd" [switchedOnText]="'Yes' | transloco" [switchedOffText]="'No' | transloco">
                </dx-switch>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-xl-6 mb-1 mb-xl-0 d-flex align-items-center">{{ 'Patient transferred / discharged to' | transloco }}</div>
              <div class="col-xl-6 mb-2 mb-xl-0 d-flex align-items-center justify-content-end">
                <dx-select-box
                  class="m-0 w-100"
                  formControlName="transferredDischargedTo"
                  [dataSource]="selectBoxOptions"
                  displayExpr="name"
                  valueExpr="name"
                  [placeholder]="'Select a value...' | transloco"
                  itemTemplate="item"
                >
                  <div *dxTemplate="let data of 'item'">
                    <div>{{ data.name | transloco }}</div>
                  </div>
                </dx-select-box>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-xl-6 mb-1 mb-xl-0 d-flex align-items-center">{{ 'Time of handover' | transloco }}</div>
              <div class="col-xl-6 mb-2 mb-xl-0 d-flex align-items-center justify-content-end">
                <dx-date-box
                  class="m-0 w-100"
                  (click)="autoFillDateOnFieldClick('handoverTime')"
                  formControlName="handoverTime"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
            <div class="row mb-1 mb-lg-0">
              <div class="col-xl-6 mb-1 mb-xl-0 d-flex">{{ 'Time of relocation' | transloco }}</div>
              <div class="col-xl-6 mb-2 mb-lg-0 d-flex">
                <dx-date-box
                  class="m-0 w-100"
                  (click)="autoFillDateOnFieldClick('transferredDischargedTime')"
                  formControlName="transferredDischargedTime"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
          </div>

          <div class="col-lg-6 justify-content-center basic-flex-container basic-direction-column">
            <p class="mb-1">Abschlussdiagnosen</p>
            <dx-text-area
              formControlName="finalDiagnosis"
              name="description"
              class="percent-100-height"
              placeholder="{{ 'Description' | transloco }}"
              [minHeight]="150"
            >
            </dx-text-area>
          </div>
        </div>
      </div>
    </div>
    <div class="basic-card-wrapper pt-0">
      <div class="basic-card-style pt-2">
        <div class="basic-card-title basic-padding mb-2">
          {{ 'Further procedure' | transloco }}
        </div>
        <div class="row pad-bot-15 mx-0 mb-2 mt-1">
          <div class="col-xl-6 mb-2 mb-xl-0 basic-flex-container basic-direction-column">
            <p class="mb-1">Noch nicht durchgeführte Diagnostik / Noch zu Versorgen / Anweisung für Station</p>
            <dx-text-area
              formControlName="todo"
              class="m-0 percent-100-height"
              name="description"
              placeholder="{{ 'Description' | transloco }}"
              [minHeight]="140"
            >
            </dx-text-area>
          </div>

          <div class="col-xl-6 mt-1 mt-xl-0 basic-flex-container basic-direction-column">
            <p class="mb-1">Therapieempfehlung an den Weiterbehandler</p>
            <dx-text-area
              formControlName="therapyRecommendation"
              class="m-0 percent-100-height"
              name="description"
              placeholder="{{ 'Description' | transloco }}"
              [minHeight]="140"
            >
            </dx-text-area>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
