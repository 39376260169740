import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ERPatient } from '../models/er-patient';
import { ERPreclinic } from '../models/er-preclinic';
import { ERAnamnesis } from '../models/er-anamnesis';
import { ERTherapy } from '../models/er-therapy';
import { ERVitals } from '../models/er-vitals';
import { ERFinalReport } from '../models/er-final-report';
import { ERCouncilList } from '../models/er-council-list';
import { ERCouncil } from '../models/er-council';
import { EROrderList } from '../models/er-order-list';
import { EROrder } from '../models/er-order';
import { ERDiagnostic } from '../models/er-diagnostic';
import { ERMedicalAccess } from '../models/er-medical-access';
import { ERMedicalAccessList } from '../models/er-medical-access-list';
import { ERIcd10List } from '../models/er-icd-10-list';
import { ERIcd10 } from '../models/er-icd-10';
import { ERTreatmentMemberList } from '../models/er-treatment-member-list';
import { ERTreatmentMember } from '../models/er-treatment-member';

@Injectable({
  providedIn: 'root',
})
export abstract class EmergencyRoomServable {
  public abstract getPatient(nidaId: string, protocolId: number): Observable<ERPatient>;

  /**
   *
   * Generates a new Patient entry with a complete create for all other information. Gets the Preclinical Information from the clinic server
   * and sets it in Emergency Room Preclinic, Vitals and MedicalAccess by Preclinic Information.
   * @param nidaId
   * @param protocolId
   */
  public abstract generateNewEntry(nidaId: string, protocolId: number): Observable<ERPatient>;

  public abstract getPreclinic(preclinicId: number): Observable<ERPreclinic>;
  public abstract updatePreclinic(preclinidId: number, preclinic: ERPreclinic): Observable<ERPreclinic>;

  public abstract getAnamnesis(anamnesisId: number): Observable<ERAnamnesis>;
  public abstract updateAnamnesis(anamnesisId: number, anamnesis: ERAnamnesis): Observable<ERAnamnesis>;

  public abstract getTherapy(therapyId: number): Observable<ERTherapy>;
  public abstract updateTherapy(therapyId: number, therapy: ERTherapy): Observable<ERTherapy>;

  public abstract getVitals(vitalsId: number): Observable<ERVitals>;
  public abstract updateVitals(vitalsId: number, vitals: ERVitals): Observable<ERVitals>;

  public abstract getDiagnostic(diagnosticId: number): Observable<ERDiagnostic>;
  public abstract updateDiagnostic(diagnosticId: number, diagnostic: ERDiagnostic): Observable<ERDiagnostic>;

  public abstract getFinalReport(finalReportId: number): Observable<ERFinalReport>;
  public abstract updateFinalReport(finalReportId: number, finalReport: ERFinalReport): Observable<ERFinalReport>;

  public abstract getCouncilList(patientId: number): Observable<ERCouncilList>;
  public abstract createCouncil(council: ERCouncil): Observable<ERCouncil>;
  public abstract updateCouncil(councilId: number, council: ERCouncil): Observable<ERCouncil>;
  public abstract deleteCouncil(councilId: number): Observable<boolean>;

  public abstract getOrderList(councilId: number): Observable<EROrderList>;
  public abstract createOrder(order: EROrder): Observable<EROrder>;
  public abstract updateOrder(orderId: number, order: EROrder): Observable<EROrder>;
  public abstract deleteOrder(orderId: number): Observable<boolean>;

  public abstract getMedicalAccessListByPreclinic(preclinicId: number): Observable<ERMedicalAccessList>;
  public abstract getMedicalAccessListByTherapy(therapyId: number): Observable<ERMedicalAccessList>;
  public abstract createMedicalAccess(medicalAccess: ERMedicalAccess): Observable<ERMedicalAccess>;
  public abstract updateMedicalAccess(medicalAccessId: number, medicalAccess: ERMedicalAccess): Observable<ERMedicalAccess>;
  public abstract deleteMedicalAccess(medicalAccessId: number): Observable<boolean>;

  public abstract getICD10List(finalReportId: number): Observable<ERIcd10List>;
  public abstract createICD10(icd10: ERIcd10): Observable<ERIcd10>;
  public abstract updateICD10(icd10Id: number, icd10: ERIcd10): Observable<ERIcd10>;
  public abstract deleteICD10(icd10Id: number): Observable<boolean>;

  public abstract getTreatmentMemberList(patientId: number): Observable<ERTreatmentMemberList>;
  public abstract createTreatmentMember(treatmentMember: ERTreatmentMember): Observable<ERTreatmentMember>;
  public abstract updateTreatmentMember(treatmentMemberId: number, treatmentMember: ERTreatmentMember): Observable<ERTreatmentMember>;
  public abstract deleteTreatmentMember(treatmentMemberId: number): Observable<boolean>;
}
