import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NavAppInfoService } from '../services/nav-app-info.service';
import { NavigationService } from '../services/navigation.service';
import { Observable } from 'rxjs';
import { NavItem } from '@nida-web/core';

@Component({
  selector: 'nida-web-base-root',
  templateUrl: './base-root.component.html',
  styleUrls: ['./base-root.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class BaseRootComponent {
  @Input()
  public headerTemplate?: TemplateRef<any>;

  public navigationItems$: Observable<NavItem[]>;

  constructor(private router: Router, public navAppInfoService: NavAppInfoService, public navigationService: NavigationService) {
    this.navigationItems$ = this.navigationService.getNavigationItems(this.navigationService.getRawDefaultNavigationItems());
  }

  navigateTo(destiny: string) {
    this.router.navigate([destiny]).then();
  }
}
