import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ERPreclinicDeprecated } from '../models/er-preclinic.model';

@Injectable({
  providedIn: 'root',
})
export abstract class ProtocolServable {
  public abstract archiveProtocol(protocolId: number, archive: boolean): Observable<boolean>;
  public abstract getPreclinicalData(protocolId: number): Observable<ERPreclinicDeprecated>;
}
