export enum DevExpressFilterOperations {
  Contains = 'contains',
  NotContains = 'notcontains',
  StartsWith = 'startswith',
  EndsWith = 'endswith',
  Equal = '=',
  NotEqual = '<>',
  // INFO: additional options - not yet tested in source code!
  // Between      = 'between',
  // Smaller      = '<',
  // Greater      = '>',
  // SmallerEqual = '<=',
  // GreaterEqual = '>=',
}
