export class LoginCredential {
  /**
   * Username
   */
  userName: string;
  /**
   * Password
   */
  password: string;

  constructor(userName: string, password: string) {
    this.userName = userName;
    this.password = password;
  }
}
