<div class="h-100 d-flex flex-column" style="flex: 1">
  <div class="content-block">
    <div class="responsive-paddings">
      <form [formGroup]="loginForm" (submit)="submitForm()">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 login-header">
              <ng-container *ngFor="let logo of logoSettings; let noBorder = last">
                <img *ngIf="noBorder" alt="{{ logo }}" class="{{ logo }}-logo" src="../assets/img/logos/{{ logo }}.png" />
                <img *ngIf="!noBorder" alt="{{ logo }}" class="{{ logo }}-logo logo-border" src="../assets/img/logos/{{ logo }}.png" />
              </ng-container>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="title">{{ appTitle }}</div>
          </div>
          <div class="row justify-content-center">
            <div class="login-subheader">{{ 'Sign in to your account' | transloco }}</div>
          </div>
          <ng-container *ngIf="this.multiTenancy && !this.showTenantList">
            <div class="row justify-content-center pb-2">
              <div class="col-lg-4 col-md-8 col-sm-12 form-max-width">
                <div class="dx-field">
                  <dx-number-box
                    [inputAttr]="{ id: 'loginTenantInputField' }"
                    [value]="lastKnownTenant"
                    placeholder="{{ 'Client identifier' | transloco }}"
                    formControlName="clientid"
                  >
                    <dx-validator>
                      <dxi-validation-rule message="{{ 'validations.login.tenantId' | transloco }}" type="required"></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="this.showTenantList">
            <div class="row justify-content-center pb-2">
              <div class="col-lg-4 col-md-8 col-sm-12 form-max-width">
                <div class="dx-field">
                  <dx-select-box
                    [dataSource]="getTenantService.getTenants()"
                    [value]="lastKnownTenant"
                    [searchEnabled]="true"
                    formControlName="clientid"
                    placeholder="{{ 'Facility' | transloco }}"
                    displayExpr="name"
                    valueExpr="id"
                  >
                    <dx-validator>
                      <dxi-validation-rule message="{{ 'Setup is necessary' | transloco }}" type="required"></dxi-validation-rule>
                    </dx-validator>
                  </dx-select-box>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row justify-content-center pb-2">
            <div class="col-lg-4 col-md-8 col-sm-12 form-max-width">
              <div class="dx-field">
                <dx-text-box
                  [inputAttr]="{ autofocus: 'autofocus', id: 'loginUserNameInputField' }"
                  class="textbox"
                  formControlName="username"
                  placeholder="{{ 'Username' | transloco }}"
                >
                  <dx-validator>
                    <dxi-validation-rule message="{{ 'Username is required' | transloco }}" type="required"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </div>
          </div>
          <div class="row justify-content-center pb-2">
            <div class="col-lg-4 col-md-8 col-sm-12 form-max-width">
              <div class="dx-field">
                <dx-text-box
                  [inputAttr]="{ id: 'loginPasswordInputField' }"
                  class="textbox"
                  formControlName="password"
                  mode="password"
                  placeholder="{{ 'Password' | transloco }}"
                >
                  <dx-validator>
                    <dxi-validation-rule message="{{ 'Password is required' | transloco }}" type="required"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </div>
          </div>
          <div *ngIf="this.resetUserPassword" class="row justify-content-center pb-2">
            <div class="col-lg-4 col-md-8 col-sm-12 form-max-width">
              <a routerLink="/resetPassword">{{ 'forgotten password?' | transloco }}</a>
            </div>
          </div>
          <div class="m-3"></div>
          <div *ngIf="this.multiLang" class="row justify-content-center pb-2">
            <div class="col-lg-4 col-md-8 col-sm-12 form-max-width">
              <nida-web-language-select-box></nida-web-language-select-box>
            </div>
          </div>
          <div class="row justify-content-center pb-2">
            <div class="col-lg-4 col-md-8 col-sm-12 form-max-width">
              <dx-button [useSubmitBehavior]="true" text="{{ 'Login' | transloco }}" type="default" width="100%"></dx-button>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-8 col-sm-12 d-flex justify-content-between flex-wrap form-max-width">
              <ng-container *ngIf="serverStatus$ | async as serverStatus">
                <div class="ml-2 row justify-content-center">
                  <ng-container *ngIf="serverStatus === 'loading'">
                    <div class="circle"></div>
                  </ng-container>
                  <ng-container *ngIf="serverStatus !== 'loading'">
                    <span class="status-icon" [ngClass]="'bg-' + serverStatus"></span>
                  </ng-container>
                  <ng-container *ngIf="serverStatus === 'success'">
                    <p class="status-text ml-2">{{ 'serverAvailable' | transloco }}</p>
                  </ng-container>
                  <ng-container *ngIf="serverStatus === 'loading'">
                    <p class="status-text ml-3">{{ 'serverIsConnecting' | transloco }}</p>
                  </ng-container>
                  <ng-container *ngIf="serverStatus === 'danger'">
                    <p class="status-text ml-2">{{ 'serverIsNotAvailable' | transloco }}</p>
                  </ng-container>
                </div>
              </ng-container>
              <p class="version-text ml-3">Version {{ navAppInfoService.version }}</p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <footer class="footer mt-auto py-3 d-block">
    <div class="container text-center">
      &copy; {{ navAppInfoService.cpYear }} <a href="https://www.meddv.de/">medDV GmbH</a> | <a routerLink="/legal-notice">Impressum</a>
    </div>
  </footer>
</div>
