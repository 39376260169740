<div class="emergency-room-header">
  <div class="row m-0 p-0">
    <div class="col-1 m-0 p-0">
      <div class="row m-0 pad-10 font-size-32 flex-complete-centering hovering-blue hovering-pointer">
        <span class="icon icon-bold-exit" (click)="triggerNavigationBack()"></span>
      </div>
    </div>
    <div class="col-11 m-0 p-0">
      <div class="row m-0 pad-10">
        <!--    outer col left -->
        <div class="col-6 col-lg-2 m-0 p-0 text-center vertical-align-center flex-direction-column">
          <div class="patient">
            {{ patient.name }}
          </div>

          <div style="font-size: 25px">
            <ng-container [ngSwitch]="patient.gender">
              <ng-container *ngSwitchCase="0">
                <span class="icon icon-gender-female"></span>
              </ng-container>
              <ng-container *ngSwitchCase="1">
                <span class="icon icon-gender-male"></span>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <span class="icon icon-gender-diverse"></span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ '' | displayNan }}
              </ng-container>
            </ng-container>
          </div>

          {{ patient.birthday | date: 'dd.MM.yyyy' | displayNan }}

          <ng-container *ngIf="patient.age">({{ patient.age | displayNan }})</ng-container>
        </div>

        <!--    inner col -->
        <div class="test-class col-8">
          <!--   inner row 1   -->
          <div class="row m-0 pad-bot-10">
            <div class="col">
              <div class="row m-0">
                <div class="col-12 m-0 p-0">
                  <p class="header-description m-0">Leitsymptom</p>
                </div>
                <div class="col-12 m-0 p-0">
                  {{ anamnesisData.cedisLeadingSymptom | displayNan | transloco }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row m-0">
                <div class="col-12 m-0 p-0">
                  <p class="header-description m-0">Symptomdauer [hh:mm]</p>
                </div>
                <div class="col-12 m-0 p-0">
                  {{ symptomDuration | displayNan }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row m-0">
                <div class="col-12 m-0 p-0">
                  <p class="header-description m-0">Allergie</p>
                </div>
                <div class="col-12 m-0 p-0" style="display: flex; flex-wrap: wrap">
                  <ng-container
                    *ngIf="!anamnesisData.allergyAntibiotics && !anamnesisData.allergyContrastMedium && !anamnesisData.allergyOthers"
                  >
                    {{ anamnesisData.allergyOthers | displayNan }}
                  </ng-container>
                  <ng-container *ngIf="anamnesisData.allergyAntibiotics">
                    <div class="header-display-button">{{ 'Antibiotics' | transloco }}</div>
                  </ng-container>
                  <ng-container *ngIf="anamnesisData.allergyContrastMedium">
                    <div class="header-display-button">{{ 'Contrast medium' | transloco }}</div>
                  </ng-container>
                  <ng-container *ngIf="anamnesisData.allergyOthers">
                    <div class="header-display-button">{{ anamnesisData.allergyOthers | displayNan }}</div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row m-0">
                <div class="col-12 m-0 p-0">
                  <p class="header-description m-0">Schwangerschaft</p>
                </div>
                <div class="col-12 m-0 p-0">
                  <ng-container *ngIf="anamnesisData.pregnancy">
                    {{ 'Yes' | transloco }}
                  </ng-container>
                  <ng-container *ngIf="!anamnesisData.pregnancy">
                    {{ 'No' | transloco }}
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <!--   inner row 2-->
          <div class="row m-0">
            <div class="col">
              <div class="row m-0">
                <div class="col-12 m-0 p-0">
                  <p class="header-description m-0">Unfallart</p>
                </div>
                <div class="col-12 m-0 p-0">
                  {{ preclinicData.accidentType | displayNan | transloco }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row m-0">
                <div class="col-12 m-0 p-0">
                  <p class="header-description m-0">Symptombeginn</p>
                </div>
                <div class="col-12 m-0 p-0">
                  {{ preclinicData.onsetOfSymptoms | date: 'dd.MM.YYYY HH:mm' | displayNan }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row m-0">
                <div class="col-12 m-0 p-0">
                  <p class="header-description m-0">Tetanusschutz</p>
                </div>
                <div class="col-12 m-0 p-0">
                  {{ anamnesisData.tetanusProtection | displayNan | transloco }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row m-0">
                <div class="col-12 m-0 p-0">
                  <p class="header-description m-0">Isolation</p>
                </div>
                <div class="col-12 m-0 p-0">
                  {{ anamnesisData.isolation | displayNan | transloco }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  inner col end  -->

        <!--  outer col right  -->
        <div class="col-6 col-lg-2 text-center vertical-align-center">
          <div>
            <div class="font-size-21">
              {{ currentTime | date: 'HH:mm:ss' }}
            </div>
            <div class="font-size-14 hr-border-top">
              {{ currentTime | date: 'dd.MM.yyyy' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
