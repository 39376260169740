<div *ngIf="therapyForm">
  <div class="p-2">
    <nida-web-er-title-toolbar
      [disabled]="therapyForm.pristine"
      [title]="'Therapy'"
      (saveTrigger)="saveAll()"
      (revertTrigger)="resetAll()"
    ></nida-web-er-title-toolbar>
  </div>
  <div>
    <nida-web-er-therapy-abcde
      [medicalAccessList]="medicalAccessList"
      [abcdeForm]="therapyForm"
      (saveAccess)="saveAccess($event)"
      (updateAccess)="updateAccess($event)"
      (deleteAccess)="deleteAccess($event)"
    ></nida-web-er-therapy-abcde>
    <form [formGroup]="this.therapyForm">
      <div class="row m-0">
        <div class="col-12 col-xl-4 p-0">
          <div class="basic-card-wrapper percent-100-height">
            <div class="basic-card-style">
              <div class="basic-card-title basic-padding d-flex align-items-center">
                {{ 'Cutting time' | transloco }}
              </div>
              <div class="basic-padding">
                <div class="row basic-padding-bottom">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.therapyForm.getRawValue().cerebralPressureReliefTime"
                      [text]="'Brain pressure relief' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('cerebralPressureReliefTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="cerebralPressureReliefTime"
                      (click)="autoFillDateOnFieldClick('cerebralPressureReliefTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.therapyForm.getRawValue().laminectomyTime"
                      [text]="'Laminectomy' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('laminectomyTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box formControlName="laminectomyTime" (click)="autoFillDateOnFieldClick('laminectomyTime')" type="datetime">
                    </dx-date-box>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.therapyForm.getRawValue().thoracotomyTime"
                      [text]="'Thoracotomy' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('thoracotomyTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box formControlName="thoracotomyTime" (click)="autoFillDateOnFieldClick('thoracotomyTime')" type="datetime">
                    </dx-date-box>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.therapyForm.getRawValue().laparotomyTime"
                      [text]="'Laparotomy' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('laparotomyTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box formControlName="laparotomyTime" (click)="autoFillDateOnFieldClick('laparotomyTime')" type="datetime">
                    </dx-date-box>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.therapyForm.getRawValue().revascularizationTime"
                      [text]="'Revascularization' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('revascularizationTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="revascularizationTime"
                      (click)="autoFillDateOnFieldClick('revascularizationTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.therapyForm.getRawValue().embolisationTime"
                      [text]="'Embolization' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('embolisationTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box formControlName="embolisationTime" (click)="autoFillDateOnFieldClick('embolisationTime')" type="datetime">
                    </dx-date-box>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.therapyForm.getRawValue().pelvisStabilisationTime"
                      [text]="'Pelvic stabilization' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('pelvisStabilisationTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="pelvisStabilisationTime"
                      (click)="autoFillDateOnFieldClick('pelvisStabilisationTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.therapyForm.getRawValue().extremitiesStabilisationTime"
                      [text]="'Extremities stabilization' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('extremitiesStabilisationTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="extremitiesStabilisationTime"
                      (click)="autoFillDateOnFieldClick('extremitiesStabilisationTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-8 p-0">
          <div class="basic-card-wrapper percent-100-height">
            <div class="basic-card-style">
              <div class="basic-card-title basic-padding d-flex align-items-center">
                {{ 'Remarks' | transloco }}
              </div>
              <div class="row basic-padding-bottom">
                <div class="col-12">
                  <div class="p-2">
                    <dx-text-area formControlName="comment" [height]="90" class="percent-100-width"></dx-text-area>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!--
  <div>
    <div class="basic-card-wrapper percent-100-height">
      <div class="basic-card-style">
        <div class="basic-card-title basic-padding d-flex align-items-center">
          {{ 'Cutting time' | transloco }}
        </div>
        <div class="row basic-padding">
          <ng-container *ngFor="let element of cuttingTreatments">
            <div class="col-12 col-sm-6 col-xl-4 basic-padding-bottom">
              <div class="row d-flex align-items-center">
                <div class="col-7">
                  <div class="percent-100-width">
                    <dx-check-box
                      [(value)]="element.value"
                      [text]="element.name | transloco"
                      (onValueChanged)="checkForCheckboxEntry(element)"
                    ></dx-check-box>
                  </div>
                </div>

                <div class="col-5 d-flex justify-content-end">
                  <div (click)="checkForDateEntry(element)">
                    <dx-date-box [(value)]="element.date" type="time"> </dx-date-box>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  -->
</div>
