/**
 * NIDAserver Insurance API
 * REST API to handle Insurance data on/with NIDAserver.
 *
 * OpenAPI spec version: 0.1.0
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { Insurance, InsuranceList } from '@nida-web/api/generic-interfaces/patient-management';

@Injectable({
  providedIn: 'root',
})
export class InsuranceRestService {
  static readonly module = 'insurance';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  /** API Endpoint */
  private basePath: string;
  private subscription: Subscription;

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.basePath = '';
    const configSub = this.configSubject.getRESTURL().subscribe((basePath) => {
      this.basePath = basePath + InsuranceRestService.module;
    });
    this.subscription.add(configSub);
  }

  /**
   * Get one insurance data set
   * Get one listed insurance data set
   * @param insuranceId The ID of the Insurance data set we want to work with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInsurance(insuranceId: number, observe?: 'body', reportProgress?: boolean): Observable<Insurance>;
  public getInsurance(insuranceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Insurance>>;
  public getInsurance(insuranceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Insurance>>;
  public getInsurance(insuranceId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (insuranceId === null || insuranceId === undefined) {
      throw new Error('Required parameter insuranceId was null or undefined when calling getInsurance.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Insurance>('get', `${this.basePath}/${encodeURIComponent(String(insuranceId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get one insurance data set by it&#x27;s protocol_id
   * Get one listed insurance data set by it&#x27;s protocol_id
   * @param protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInsuranceByProtocolId(protocolId: string, observe?: 'body', reportProgress?: boolean): Observable<Insurance>;
  public getInsuranceByProtocolId(protocolId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Insurance>>;
  public getInsuranceByProtocolId(protocolId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Insurance>>;
  public getInsuranceByProtocolId(protocolId: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getInsuranceByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Insurance>('get', `${this.basePath}/byProtocolId/${encodeURIComponent(String(protocolId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get the currently listed insurance data sets
   * Get the currently listed insurance data sets
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInsuranceList(observe?: 'body', reportProgress?: boolean): Observable<InsuranceList>;
  public getInsuranceList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InsuranceList>>;
  public getInsuranceList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InsuranceList>>;
  public getInsuranceList(observe: any = 'body', reportProgress = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<InsuranceList>('get', `${this.basePath}/list`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * SaveInsurance
   *
   * @param body InsuranceModel Object
   * @param id insurance id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveInsurance(body: Insurance, id: string, observe?: 'body', reportProgress?: boolean): Observable<Insurance>;
  public saveInsurance(body: Insurance, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Insurance>>;
  public saveInsurance(body: Insurance, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Insurance>>;
  public saveInsurance(body: Insurance, id: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling saveInsurance.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling saveInsurance.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Insurance>('put', `${this.basePath}/by/${encodeURIComponent(String(id))}`, {
      body,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
}
