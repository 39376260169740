import { Injectable } from '@angular/core';
import { ERPreclinicDeprecated } from '@nida-web/api/generic-interfaces/protocol';

@Injectable({
  providedIn: 'root',
})
export class VitalSignMappingService {
  public mapAFValues(rawData: any, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    if (!rawData || !rawData.data) {
      return refObj;
    }

    // TODO please use find for all maps instead of looping all the stuff -
    //  or at least stop looping by breaking the loop after success
    for (const singleVitalSign of rawData.data) {
      switch (singleVitalSign.description) {
        case 'eb':
          refObj.fistVitalSigns.respiratoryRate = singleVitalSign.value;
          break;
      }
    }
    return refObj;
  }

  public mapHFValues(rawData: any, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    if (!rawData || !rawData.data) {
      return refObj;
    }

    // TODO please use find for all maps instead of looping all the stuff -
    //  or at least stop looping by breaking the loop after success
    for (const singleVitalSign of rawData.data) {
      switch (singleVitalSign.description) {
        case 'eb':
          refObj.fistVitalSigns.heartFrequency = singleVitalSign.value;
          break;
      }
    }
    return refObj;
  }

  public mapSPO2Values(rawData: any, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    if (!rawData || !rawData.data) {
      return refObj;
    }
    for (const singleVitalSign of rawData.data) {
      switch (singleVitalSign.description) {
        case 'eb':
          refObj.fistVitalSigns.breathingSpO2 = singleVitalSign.value;
          break;
      }
    }
    return refObj;
  }
  public mapCO2Values(rawData: any, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    if (!rawData || !rawData.data) {
      return refObj;
    }
    for (const singleVitalSign of rawData.data) {
      switch (singleVitalSign.description) {
        case 'eb':
          refObj.fistVitalSigns.breathingEtCO2 = singleVitalSign.exp;
          break;
      }
    }
    return refObj;
  }

  public mapBDValues(rawData: any, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    if (!rawData || !rawData.data) {
      return refObj;
    }
    for (const singleVitalSign of rawData.data) {
      switch (singleVitalSign.description) {
        case 'eb':
          // TODO ART FEHLT!! if (singleVitalSign.)
          refObj.fistVitalSigns.rrSys = singleVitalSign.sys;
          break;
      }
    }
    return refObj;
  }
}
