import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { APIPersonal } from '../models/api-personal.model';
import { APIPersonalImage } from '../models/api-personal-image.model';
import { APIPersonalListContainer } from '../models/api-personal-list-container.model';

@Injectable({
  providedIn: 'root',
})
export class PersonalRESTService {
  public defaultHeaders: HttpHeaders;
  public configuration: Configuration;
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(private httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'personal';
    });
    this.subscription.add(configSub);
    // this.subscription.unsubscribe();

    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
  }

  /**
   * Add new personal
   *
   * @param body personal Object
   */
  public addNewPersonal(body: APIPersonal): Observable<APIPersonal> {
    if (!body) {
      throw new Error('Required parameter body was null or undefined when calling addNewPersonal.');
    }
    if (body.id_mandanten === undefined || body.id_mandanten < 0) {
      body.id_mandanten = 0;
    }
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<APIPersonal>(`${this.apiPrefix}/person/new`, body, {
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }

  /**
   * Delete personal By Id
   *
   * @param id the person id
   */
  public deletePersonalById(id: string, observe?: 'body', reportProgress?: boolean): Observable<string> {
    if (!id) {
      throw new Error('Required parameter id was null or undefined when calling deletePersonalById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.httpClient.request<string>('delete', `${this.apiPrefix}/person/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all personal
   *
   */
  public getAllPersonal(tenant: number): Observable<APIPersonalListContainer> {
    let params = new HttpParams();
    params = params.set('tenant', String(tenant)); // TODO: DO we need dis?

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header

    return this.httpClient.get<APIPersonalListContainer>(`${this.apiPrefix}/person/list`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      params,
    });
  }

  /**
   * Get all Personal By Group Id
   * For this Operation you need this permission \&quot;administration.personal.rollen:rw\&quot;
   * @param gid the group GroupId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllPersonalByGroupId(gid: string, observe?: 'body', reportProgress?: boolean): Observable<APIPersonalListContainer>;
  public getAllPersonalByGroupId(
    gid: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<APIPersonalListContainer>>;
  public getAllPersonalByGroupId(
    gid: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<APIPersonalListContainer>>;
  public getAllPersonalByGroupId(gid: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (gid === null || gid === undefined) {
      throw new Error('Required parameter gid was null or undefined when calling getAllPersonalByGroupId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<APIPersonalListContainer>(`${this.apiPrefix}/person/list/${encodeURIComponent(String(gid))}`, {
      responseType: responseType as any,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Person By Id
   *
   * @param id the person id
   */
  public getPersonById(id: string): Observable<APIPersonal> {
    if (!id) {
      throw new Error('Required parameter id was null or undefined when calling getPersonById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header

    return this.httpClient.get<APIPersonal>(`${this.apiPrefix}/person/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }

  /**
   * SavePerson
   *
   * @param body personal Object
   * @param id the person id
   */
  public savePerson(body: APIPersonal, id: number): Observable<any> {
    if (!body) {
      throw new Error('Required parameter body was null or undefined when calling savePerson.');
    }

    if (!id) {
      throw new Error('Required parameter id was null or undefined when calling savePerson.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<APIPersonal>(`${this.apiPrefix}/person/${encodeURIComponent(String(id))}`, body, {
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }

  /**
   * savePersonallyData
   * @param id the person id
   * @param personal Personal Object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public savePersonallyData(
    id: number,
    personal: APIPersonal,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<APIPersonal>;
  public savePersonallyData(
    id: number,
    personal: APIPersonal,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<APIPersonal>>;
  public savePersonallyData(
    id: number,
    personal: APIPersonal,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<APIPersonal>>;
  public savePersonallyData(
    id: number,
    personal: APIPersonal,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling savePersonallyData.');
    }
    if (personal === null || personal === undefined) {
      throw new Error('Required parameter personal was null or undefined when calling savePersonallyData.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.put<APIPersonal>(`${this.apiPrefix}/person/${encodeURIComponent(String(id))}/personally`, personal, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * GetImageFromePerson
   *
   * @param idPersonal the person id
   */
  public getImageFromePerson(idPersonal: string): Observable<APIPersonalImage> {
    if (!idPersonal) {
      throw new Error('Required parameter idPersonal was null or undefined when calling getImageFromePerson.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header

    return this.httpClient.get<APIPersonalImage>(`${this.apiPrefix}/image/${encodeURIComponent(String(idPersonal))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }

  /**
   * InsertNewPersonalImage
   *
   * @param body personal Image Object
   */
  public insertNewPersonalImage(body: APIPersonalImage): Observable<APIPersonalImage> {
    if (!body) {
      throw new Error('Required parameter body was null or undefined when calling insertNewPersonalImage.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<APIPersonalImage>(`${this.apiPrefix}/image`, {
      body,
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }

  /**
   * UpdatePersonalImage
   *
   * @param body personal Image Object
   * @param idPersonal the person id
   */
  public updatePersonalImage(body: APIPersonalImage, idPersonal: string): Observable<APIPersonalImage> {
    if (!body) {
      throw new Error('Required parameter body was null or undefined when calling updatePersonalImage.');
    }

    if (!idPersonal) {
      throw new Error('Required parameter idPersonal was null or undefined when calling updatePersonalImage.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<APIPersonalImage>(`${this.apiPrefix}/image/${encodeURIComponent(String(idPersonal))}`, {
      body,
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }
}
