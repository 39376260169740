import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-feature-client-support-page',
  templateUrl: './feature-client-support-page.component.html',
  styleUrls: ['./feature-client-support-page.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FeatureClientSupportPageComponent {
  public pathToHelpPdf: string;

  constructor() {
    this.pathToHelpPdf = '/assets/pdfs/NIDAclient_manual.pdf';
  }
}
