<dx-data-grid
  #userGrid
  id="userGrid"
  (onInitialized)="onInitialized()"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onRowClick)="this.tableManagementService.expandRow($event)"
  [allowColumnReordering]="true"
  [columnHidingEnabled]="true"
  [dataSource]="tableData"
  [masterDetail]="{ enabled: true, template: 'detail' }"
  [rowAlternationEnabled]="true"
  [showBorders]="true"
>
  <dxo-paging [pageSize]="10"> </dxo-paging>
  <dxo-pager [allowedPageSizes]="[5, 10, 30]" [showPageSizeSelector]="true" [showNavigationButtons]="true"> ></dxo-pager>

  <dxo-editing mode="row" [allowDeleting]="allowDeleting" [useIcons]="true"> </dxo-editing>

  <dxo-search-panel [placeholder]="'Search' | transloco" [visible]="true" [width]="178" id="searchPanel"></dxo-search-panel>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxi-column [caption]="'Username' | transloco" dataField="userName" sortOrder="asc"></dxi-column>
  <dxi-column [caption]="'Last name' | transloco" dataField="lastName"></dxi-column>
  <dxi-column [caption]="'First name' | transloco" dataField="firstName"></dxi-column>

  <div *dxTemplate="let personal of 'detail'" class="template-masterview">
    <div class="master-detail-caption">
      <nida-web-user-row-detail
        (collapse)="onCollapse()"
        (updatedUser)="userUpdated()"
        [currentUser]="currentUser"
        [personal]="personal.data"
      ></nida-web-user-row-detail>
    </div>
  </div>
</dx-data-grid>
