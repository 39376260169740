import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import { DisplayNanConfig } from '../models/display-nan-config.model';

export const DISPLAY_NAN_CONFIG = new InjectionToken<DisplayNanConfig>('display-nan-config');

@Pipe({
  name: 'displayNan',
})
export class DisplayNanPipe implements PipeTransform {
  private readonly displayOnNan: string;

  constructor(@Inject(DISPLAY_NAN_CONFIG) displayNanConfig: DisplayNanConfig) {
    this.displayOnNan = displayNanConfig.nanPlaceholder;

    if (this.displayOnNan === undefined || this.displayOnNan === null) {
      this.displayOnNan = '';
    }
  }

  transform(value: string | number | undefined | null, defaultString?: string): string {
    let returnValue: string = this.displayOnNan;

    if (defaultString !== undefined) {
      returnValue = defaultString;
    }

    if (value !== '' && value !== undefined && value !== null) {
      returnValue = String(value);
    }

    return returnValue;
  }
}
