/**
 * NIDA Stammdaten REST-API
 * API Beschreibung für die NIDA Stammdaten API.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantList } from '../models/tenantList';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  // protected basePath = 'https://{host}/{basePath}';
  // public defaultHeaders = new HttpHeaders();
  // public configuration = new Configuration();
  //
  // constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
  //     if (basePath) {
  //         this.basePath = basePath;
  //     }
  //     if (configuration) {
  //         this.configuration = configuration;
  //         this.basePath = basePath || configuration.basePath || this.basePath;
  //     }
  // }

  protected basePath: string;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}master-data`;
    });
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTenants(observe?: 'body', reportProgress?: boolean): Observable<TenantList>;
  public getTenants(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantList>>;
  public getTenants(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantList>>;
  public getTenants(observe: any = 'body', reportProgress = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<TenantList>('get', `${this.basePath}/tenants`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
