import { Injectable } from '@angular/core';
import { Countries } from './countries';
import { getBrowserLang, Translation, TranslocoService } from '@ngneat/transloco';
import Globalize from 'globalize';
import supplementalCldrData from 'devextreme-cldr-data/supplemental.json';
import enMessages from 'devextreme/localization/messages/en.json';
import enCldrData from 'devextreme-cldr-data/en.json';
import deMessages from 'devextreme/localization/messages/de.json';
import deCldrData from 'devextreme-cldr-data/de.json';

import 'devextreme/localization/globalize/number';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/message';
import notify from 'devextreme/ui/notify';
import { availableCountries, Country } from '../models/country';
import { ModuleSettingsService } from '@nida-web/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalizeLanguageService {
  private translatedLangs: string[];
  private countries: Countries;

  constructor(private moduleSettingsService: ModuleSettingsService, private translocoService: TranslocoService) {
    this.translatedLangs = [];
    this.countries = new Countries('./assets/img/language-transloco-icons/');
  }

  loadLanguageForAppInitialization(): Observable<Translation> {
    return this.moduleSettingsService.getSettings().pipe(
      switchMap((settings) => {
        if (!settings.multiLang)
          return this.translocoService.load('de').pipe(
            tap(() => {
              this.initGlobalize('de');
              this.setLanguage('de');
              this.translocoService.setActiveLang('de');
            })
          );

        const localstorageLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : '';
        const browserLanguage = getBrowserLang();
        const availableLanguages: availableCountries[] = this.countries.getCountryCodes();

        // if language is in local storage and a valid language, take it
        if (localstorageLanguage && availableLanguages.includes(localstorageLanguage as 'de' | 'en'))
          return this.translocoService.load(localstorageLanguage).pipe(
            tap(() => {
              this.initGlobalize(localstorageLanguage);
              this.setLanguage(localstorageLanguage);
              this.translocoService.setActiveLang(localstorageLanguage);
            })
          );

        // if language is in browser set and a valid language, take it
        if (browserLanguage && availableLanguages.includes(browserLanguage as 'de' | 'en'))
          return this.translocoService.load(browserLanguage).pipe(
            tap(() => {
              this.initGlobalize(browserLanguage);
              this.setLanguage(browserLanguage);
              this.translocoService.setActiveLang(browserLanguage);
            })
          );

        // Fallback language if no language is found in localstorage or browser
        return this.translocoService.load('de').pipe(
          tap(() => {
            this.initGlobalize('de');
            this.setLanguage('de');
            this.translocoService.setActiveLang('de');
          })
        );
      })
    );
  }

  public setLanguage(lang: string) {
    if (this.translatedLangs.indexOf(lang) > -1) {
      Globalize.locale(lang);
    }
  }

  public getCountries(): Country[] {
    return this.countries.getCountries();
  }

  private initGlobalize(languageCode: string): void {
    const translatedLanguages = this.countries.getCountryCodes();

    const messages = {
      enMessages,
      deMessages,
    };

    Globalize.load(enCldrData);
    Globalize.load(deCldrData);
    Globalize.load(supplementalCldrData);

    for (const translatedLanguage of translatedLanguages) {
      try {
        this.translatedLangs.push(translatedLanguage);
        Globalize.loadMessages(messages[translatedLanguage + 'Messages']);
      } catch (error) {
        notify({
          message: 'Please also add Globalize Messages in language.service for Language: ' + translatedLanguage,
          type: 'Error',
          displayTime: 10000,
        });
      }
    }
    Globalize.locale(languageCode);
  }
}
