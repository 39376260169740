<div (click)="preventDetailOpening()" (mouseleave)="triggerClosing()" class="functionality-wrapper">
  <div class="settings-popup displayFlex">
    <div (click)="changeVisibility()" [ngClass]="{ chosen: this.rawRowData.data.settings.visible !== true }" class="button-visibility">
      <div class="icon-flex-wrapper">
        <i class="icon icon-view-off"></i>
      </div>
    </div>
    <div (click)="changeVisibility()" [ngClass]="{ chosen: this.rawRowData.data.settings.visible === true }" class="button-visibility">
      <div class="icon-flex-wrapper">
        <i class="icon icon-view-1"></i>
      </div>
    </div>
  </div>
</div>
