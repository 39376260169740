import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ERAnamnesis, ERPreclinic, ERVitals } from '@nida-web/api/generic-interfaces/emergencyroom';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import cedisPresentingComplaintListJSON from './cedisPresentingComplaintList.json';
import notify from 'devextreme/ui/notify';

export interface SelectOption {
  value: number;
  name: string;
}

export interface ComplaintCodeItem {
  name: string;
  code: string;
}

export interface ComplaintCodeGroup {
  key: string;
  items: ComplaintCodeItem[];
}

@Component({
  selector: 'nida-web-anamnesis',
  templateUrl: './anamnesis.component.html',
  styleUrls: ['./anamnesis.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AnamnesisComponent implements OnInit {
  public vitalsAnamnesisData: ERVitals;
  public anamnesisData: ERAnamnesis;
  public preclinicData: ERPreclinic; // no changes possible here;

  public vitalsAnamnesisFormGroup: UntypedFormGroup;
  public anamnesisFormGroup: UntypedFormGroup;

  public selectBoxOptions: Array<SelectOption>;
  public isolationBoxOptions: Array<SelectOption>;
  public multiResistantGermBoxOptions: Array<SelectOption>;
  public anticoagulantsBoxOptions: Array<SelectOption>;
  public cedisBoxOptions: Array<ComplaintCodeGroup>;

  private vitalsDisabled: boolean;

  constructor(private emergencyRoomStore: EmergencyRoomStoreService) {}

  ngOnInit(): void {
    this.vitalsDisabled = false;

    this.getData();

    this.selectBoxOptions = [
      { value: 2, name: 'Yes' },
      { value: 1, name: 'No' },
      { value: 0, name: 'Unknown' },
    ];
    this.isolationBoxOptions = [
      { name: 'Multi-resistant germ', value: 1 },
      { name: 'Gastroenteritis', value: 2 },
      { name: 'Tuberculosis', value: 3 },
      { name: 'Meningitis', value: 4 },
      { name: 'Reversed isolation', value: 5 },
      { name: 'Other', value: 6 },
      { name: 'No', value: 7 },
    ];
    this.multiResistantGermBoxOptions = [
      { name: 'MRSA', value: 1 },
      { name: '3-MRGN', value: 2 },
      { name: '4-MRGN', value: 3 },
      { name: '4-VRE', value: 4 },
      { name: '4-Other', value: 5 },
    ];
    this.anticoagulantsBoxOptions = [
      { name: 'Platelet aggregation inhibitor', value: 1 },
      { name: 'NOAK', value: 2 },
      { name: 'Vitamin K antigonists', value: 3 },
      { name: 'Heparins and others', value: 4 },
      { name: 'No', value: 5 },
      { name: 'Unknown', value: 0 },
      { name: 'Not collected', value: -9 },
    ];
    this.cedisBoxOptions = cedisPresentingComplaintListJSON;
  }

  getData() {
    this.emergencyRoomStore.anamnesisData.subscribe((response: ERAnamnesis) => {
      this.anamnesisData = response;
      this.initAnamnesisFormGroup();
    });

    this.emergencyRoomStore.vitalsAnamnesisData.subscribe((response: ERVitals) => {
      this.vitalsAnamnesisData = response;
      this.initVitalsAnamnesisFormGroup();
    });
  }

  initFormGroups() {
    this.initAnamnesisFormGroup();
    this.initVitalsAnamnesisFormGroup();
  }

  initAnamnesisFormGroup() {
    this.anamnesisFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(this.anamnesisData.id),
      allergyAntibiotics: new UntypedFormControl(this.anamnesisData.allergyAntibiotics),
      anticoagulation: new UntypedFormControl(this.anamnesisData.anticoagulation),
      cedisLeadingSymptom: new UntypedFormControl(this.anamnesisData.cedisLeadingSymptom),
      firstMedicalContact: new UntypedFormControl(this.anamnesisData.firstMedicalContact),
      isolation: new UntypedFormControl(this.anamnesisData.isolation),
      allergyContrastMedium: new UntypedFormControl(this.anamnesisData.allergyContrastMedium),
      multiresistantGerm: new UntypedFormControl(this.anamnesisData.multiresistantGerm),
      emergencyAnamnesis: new UntypedFormControl(this.anamnesisData.emergencyAnamnesis),
      pregnancy: new UntypedFormControl(this.anamnesisData.pregnancy),
      allergyOthers: new UntypedFormControl(this.anamnesisData.allergyOthers),
      tetanusProtection: new UntypedFormControl(this.anamnesisData.tetanusProtection),
      responsibleDoctor: new UntypedFormControl(this.anamnesisData.responsibleDoctor),
      vitalsId: new UntypedFormControl(this.anamnesisData.vitalsId),
      symptomEnd: new UntypedFormControl(this.anamnesisData.symptomEnd),
    });
  }

  initVitalsAnamnesisFormGroup() {
    this.vitalsAnamnesisFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl({ value: this.vitalsAnamnesisData.id, disabled: this.vitalsDisabled }),
      respiratoryFrequency: new UntypedFormControl({ value: this.vitalsAnamnesisData.respiratoryFrequency, disabled: this.vitalsDisabled }),
      alcohol: new UntypedFormControl({ value: this.vitalsAnamnesisData.alcohol, disabled: this.vitalsDisabled }),
      respiratoryTractClear: new UntypedFormControl({
        value: this.vitalsAnamnesisData.respiratoryTractClear,
        disabled: this.vitalsDisabled,
      }),
      drugs: new UntypedFormControl({ value: this.vitalsAnamnesisData.drugs, disabled: this.vitalsDisabled }),
      etco2: new UntypedFormControl({ value: this.vitalsAnamnesisData.etco2, disabled: this.vitalsDisabled }),
      fio2: new UntypedFormControl({ value: this.vitalsAnamnesisData.fio2, disabled: this.vitalsDisabled }),
      gcs: new UntypedFormControl({ value: this.vitalsAnamnesisData.gcs, disabled: this.vitalsDisabled }),
      heartRate: new UntypedFormControl({ value: this.vitalsAnamnesisData.heartRate, disabled: this.vitalsDisabled }),
      intoxication: new UntypedFormControl({ value: this.vitalsAnamnesisData.intoxication, disabled: this.vitalsDisabled }),
      bodyTemperature: new UntypedFormControl({ value: this.vitalsAnamnesisData.bodyTemperature, disabled: this.vitalsDisabled }),
      pupilsLightLeft: new UntypedFormControl({ value: this.vitalsAnamnesisData.pupilsLightLeft, disabled: this.vitalsDisabled }),
      pupilsLightRight: new UntypedFormControl({ value: this.vitalsAnamnesisData.pupilsLightRight, disabled: this.vitalsDisabled }),
      pupilsWidthLeft: new UntypedFormControl({ value: this.vitalsAnamnesisData.pupilsWidthLeft, disabled: this.vitalsDisabled }),
      pupilsWidthRight: new UntypedFormControl({ value: this.vitalsAnamnesisData.pupilsWidthRight, disabled: this.vitalsDisabled }),
      rrSys: new UntypedFormControl({ value: this.vitalsAnamnesisData.rrSys, disabled: this.vitalsDisabled }),
      pain: new UntypedFormControl({ value: this.vitalsAnamnesisData.pain, disabled: this.vitalsDisabled }),
      spo2: new UntypedFormControl({ value: this.vitalsAnamnesisData.spo2, disabled: this.vitalsDisabled }),
      gcsEyes: new UntypedFormControl({ value: this.vitalsAnamnesisData.gcsEyes, disabled: this.vitalsDisabled }),
      gcsMotoric: new UntypedFormControl({ value: this.vitalsAnamnesisData.gcsMotoric, disabled: this.vitalsDisabled }),
      gcsVerbal: new UntypedFormControl({ value: this.vitalsAnamnesisData.gcsVerbal, disabled: this.vitalsDisabled }),
    });
  }

  resetFormGroups() {
    this.initAnamnesisFormGroup();
    this.initVitalsAnamnesisFormGroup();
  }

  submitFormGroups() {
    if (!this.vitalsAnamnesisFormGroup.pristine && this.vitalsAnamnesisData.id) {
      const subVitals = this.emergencyRoomStore
        .updateVitals(this.vitalsAnamnesisData.id, this.vitalsAnamnesisFormGroup.getRawValue())
        .subscribe((response) => {
          if (response) {
            this.vitalsAnamnesisData = response;
            this.initFormGroups();
            notify({
              message: 'Änderungen erfolgreich übernommen!',
              type: 'Success',
              displayTime: 5000,
            });
          } else {
            notify({
              message: 'Vorgang nicht erfolgreich beendet!',
              type: 'Error',
              displayTime: 5000,
            });
          }
          subVitals.unsubscribe();
        });
    }
    if (!this.anamnesisFormGroup.pristine && this.anamnesisData.id) {
      const subAnamnesis = this.emergencyRoomStore
        .updateAnamnesis(this.anamnesisData.id, this.anamnesisFormGroup.getRawValue())
        .subscribe((response) => {
          if (response) {
            this.anamnesisData = response;
            this.initFormGroups();
            notify({
              message: 'Änderungen erfolgreich übernommen!',
              type: 'Success',
              displayTime: 5000,
            });
            this.emergencyRoomStore.anamnesisData.next(response);
          } else {
            notify({
              message: 'Vorgang nicht erfolgreich beendet!',
              type: 'Error',
              displayTime: 5000,
            });
          }
          subAnamnesis.unsubscribe();
        });
    }
  }

  checkDateExistence(key: string) {
    if (!this.anamnesisFormGroup.value[key]) {
      this.anamnesisFormGroup.patchValue({ [key]: new Date() });
      this.anamnesisFormGroup.markAsDirty();
    }
  }
}
