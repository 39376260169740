<form [formGroup]="preclinicFormGroup">
  <div class="row mx-0 p-0 percent-100-height">
    <div class="col-sm-5 col-md-4 col-xl-3 basic-card-wrapper">
      <div class="basic-card-style diagnosis-tile">
        <div class="percent-100-height">
          <div class="basic-card-title basic-padding">
            {{ 'Timestamps' | transloco }}
          </div>
          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-lg-12 mb-1">{{ 'Accident' | transloco }}</div>
              <div class="col-lg-12">
                <dx-date-box
                  (click)="autoFillDateOnFieldClick('accidentTime')"
                  [readOnly]="disabled"
                  formControlName="accidentTime"
                  type="datetime"
                  style="width: 100%"
                >
                </dx-date-box>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-lg-4 mb-1">{{ 'Alarm' | transloco }}</div>
              <div class="col-lg-12">
                <dx-date-box
                  (click)="autoFillDateOnFieldClick('alarmTime')"
                  style="width: 100%"
                  formControlName="alarmTime"
                  [readOnly]="disabled"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-lg-4 mb-1">{{ 'Arrival' | transloco }}</div>
              <div class="col-lg-12">
                <dx-date-box
                  (click)="autoFillDateOnFieldClick('arrivalTimeRescueTeam')"
                  style="width: 100%"
                  [readOnly]="disabled"
                  formControlName="arrivalTimeRescueTeam"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-lg-4 mb-1">{{ 'Transportation' | transloco }}</div>
              <div class="col-lg-12">
                <dx-date-box
                  (click)="autoFillDateOnFieldClick('startOfTransport')"
                  [readOnly]="disabled"
                  formControlName="startOfTransport"
                  type="datetime"
                  style="width: 100%"
                >
                </dx-date-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-7 col-md-7 col-lg-6 col-xl-9 basic-card-wrapper">
      <div class="basic-card-style diagnosis-tile">
        <div class="percent-100-height">
          <div class="basic-card-title basic-padding">
            {{ 'Incident Description' | transloco }}
          </div>
          <div class="basic-padding">
            <div class="row">
              <div class="col-xl-6">
                <div class="row basic-padding-bottom">
                  <div class="col-md-3 mb-1 justify-content-center align-self-center">{{ 'ASA' | transloco }}</div>
                  <div class="col-md-9 text-md-right">
                    <dx-button-group
                      [disabled]="disabled"
                      [items]="this.asaItems"
                      [selectedItems]="
                        this.preclinicFormGroup.getRawValue().asa !== undefined ? [asaItems[this.preclinicFormGroup.getRawValue().asa]] : []
                      "
                      (onItemClick)="onBtnGroupChange($event)"
                    >
                    </dx-button-group>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-md-6 mb-1 justify-content-center align-self-center">{{ 'Cause of accident' | transloco }}</div>
                  <div class="col-md-6 text-md-right">
                    <dx-select-box
                      [readOnly]="disabled"
                      [placeholder]="'Select a value...' | transloco"
                      style="width: 100%"
                      [dataSource]="causeOfAccidents"
                      displayExpr="name"
                      valueExpr="name"
                      formControlName="accidentCause"
                    ></dx-select-box>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row basic-padding-bottom">
                  <div class="col-md-6 mb-1 justify-content-center align-self-center">{{ 'Accident mechanism' | transloco }}</div>
                  <div class="col-md-6 text-md-right">
                    <dx-select-box
                      [readOnly]="disabled"
                      [placeholder]="'Select a value...' | transloco"
                      style="width: 100%"
                      [dataSource]="accidentMechanisms"
                      displayExpr="name"
                      valueExpr="name"
                      formControlName="accidentMechanism"
                    ></dx-select-box>
                  </div>
                </div>
                <div class="row basic-padding-bottom">
                  <div class="col-md-6 mb-1 justify-content-center align-self-center">{{ 'Accident type' | transloco }}</div>
                  <div class="col-md-6 text-md-right">
                    <dx-select-box
                      [readOnly]="disabled"
                      [placeholder]="'Select a value...' | transloco"
                      style="width: 100%"
                      [dataSource]="accidentTypes"
                      displayExpr="name"
                      valueExpr="name"
                      formControlName="accidentType"
                    ></dx-select-box>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row basic-padding-bottom">
                  <div class="col-12 mb-1">
                    {{ 'Description' | transloco }}
                  </div>
                  <div class="col-12">
                    <dx-text-area [readOnly]="disabled" [height]="120" formControlName="accidentDescription"></dx-text-area>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
