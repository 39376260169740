import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { CorpulsBindingService } from '@nida-web/shared/data-access';
import { WindowManagementService } from '@nida-web/window-management';

@Component({
  selector: 'nida-web-corpuls-view',
  templateUrl: './corpuls-view.component.html',
  styleUrls: ['./corpuls-view.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CorpulsViewComponent implements OnInit {
  public corpulsLink: string;
  public corpulsComplete: any;
  public URL = URL;

  constructor(
    private route: ActivatedRoute,
    private corpulsBindingService: CorpulsBindingService,
    private sanitizer: DomSanitizer,
    private windowManagementService: WindowManagementService,
    private location: Location
  ) {}

  // TODO: Umstellung auf QueryParameters!
  ngOnInit() {
    for (const key of this.route.snapshot.queryParamMap.keys) {
      if (key === null) {
        continue;
      }
      this.corpulsBindingService.setParameter(key, '' + this.route.snapshot.queryParamMap.get(key));
    }
    this.corpulsLink = this.corpulsBindingService.generateLink('livedata');
    this.corpulsComplete = this.transform(this.corpulsLink);
  }

  transform(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openEKGPopUp(): void {
    if (this.corpulsComplete !== null && this.corpulsComplete !== '') {
      this.windowManagementService.switchOrCreateWindow('corpulsLiveEKG', this.corpulsLink);
    }
  }

  triggerNavigation() {
    this.location.back();
  }
}
