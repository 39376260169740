export class User {
  id: number;
  idMandanten: number;
  lastName?: string;
  firstName?: string;
  userName: string;
  userPassword?: string;
  email?: string;
  loginType?: number;

  constructor(id?: number, idMandanten?: number, userName?: string) {
    this.id = id || 0;
    this.idMandanten = idMandanten || 0;
    this.userName = userName || '';
  }
}
