import { Injectable } from '@angular/core';
import { ModuleConfiguration } from '../models/module-configuration.model';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Lazy Loading issues? Read this:
 * https://angular.io/guide/singleton-services#the-forroot-pattern
 */
export class ModuleSettingsService {
  private moduleConfigSubj: ReplaySubject<ModuleConfiguration>;

  constructor() {
    this.moduleConfigSubj = new ReplaySubject<ModuleConfiguration>(1);
  }

  public updateSettings(settings: ModuleConfiguration): void {
    this.moduleConfigSubj.next(settings);
  }

  public getSettings(): Observable<ModuleConfiguration> {
    return this.moduleConfigSubj.asObservable();
  }
}
