import { DevExpressFilterOperations } from './dev-express-filter-operations.enum';

export type devExpressFilterArray = Array<devExpressFilter>;

export type devExpressFilter = devExpressFilterElement | devExpressFilterLogic | Array<devExpressFilterElement | devExpressFilterLogic>;

type devExpressFilterLogic = 'or' | 'and';

type devExpressFilterElement = [string, DevExpressFilterOperations, string] | devExpressDateFilter;

type devExpressDateFilter = [devExpressDateFilterElement, 'and', devExpressDateFilterElement];

type devExpressDateFilterElement = [string, DevExpressDateCompareOperations, Date];
export enum DevExpressDateCompareOperations {
  Smaller = '<',
  Greater = '>',
  SmallerEqual = '<=',
  GreaterEqual = '>=',
}
