import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { GroupList, User } from '@nida-web/api/generic-interfaces/user-management';
import { UserDetailComponent } from '../../user-detail/user-detail.component';
import { SessionInformation } from '@nida-web/api/rest/authentication';

@Component({
  selector: 'nida-web-user-row-detail',
  templateUrl: './user-row-detail.component.html',
  styleUrls: ['./user-row-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UserRowDetailComponent {
  @ViewChild('userDetail', { static: false }) userDetail: UserDetailComponent | undefined;
  @Input() personal: User | undefined;
  @Input() currentUser: SessionInformation | undefined;
  @Output() collapse: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedUser: EventEmitter<User> = new EventEmitter<any>();

  cancelButtonPressed() {
    this.collapse.emit();
  }

  saveChanges() {
    // this.savePersonalData(this.personalForm);

    if (this.userDetail === undefined) {
      Error('UserDetail Component has not been initalized.');
      return;
    }

    this.userDetail.manageSaving().subscribe();
  }

  getValidGroupIds(groups: GroupList): number[] {
    const validGroupIds: number[] = [];

    for (const group of groups) {
      if (group === undefined || group.id === undefined) {
        Error('Couldnt add Group ID! Missing!');
        continue;
      }
      validGroupIds.push(group.id);
    }

    return validGroupIds;
  }

  userUpdated(updatedUser: User) {
    this.updatedUser.emit(updatedUser);
  }
}
