import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { PlainLogin } from '../models/plainLogin';

@Injectable({
  providedIn: 'root',
})
export class PlainLoginRESTService {
  public defaultHeaders: HttpHeaders;
  public configuration: Configuration;
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(private httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'auth';
    });
    this.subscription.add(configSub);
    // this.subscription.unsubscribe();

    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
  }

  /**
   * Authenticate the user and return an access token
   * authenticate using the (less secure) plain password.
   * @param plainLogin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param options add optional http options
   */
  public plainLogin(
    plainLogin?: PlainLogin,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<string>;
  public plainLogin(
    plainLogin?: PlainLogin,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<HttpResponse<string>>;
  public plainLogin(
    plainLogin?: PlainLogin,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<HttpEvent<string>>;
  public plainLogin(
    plainLogin?: PlainLogin,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<string>(`${this.apiPrefix}/plain-login`, plainLogin, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
