import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Patient, PatientServable, PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
// import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
// import { PatientListStoreService, PatientArchiveStoreService } from '@nida-web/patient-management';
// import { SubNavigationHandlerService } from '@nida-web/navigation';

@Component({
  selector: 'nida-web-er-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PatientComponent implements OnInit {
  public id: string;
  public protocolId: number;
  public patientData: PatientView;
  public patientDetails: Patient | null;
  public loaded: boolean;

  constructor(
    // private emergencyRoomStoreService: EmergencyRoomStoreService,
    // private patientListData: PatientListStoreService,
    // private patientArchiveData: PatientArchiveStoreService,
    // private subNavHandlerService: SubNavigationHandlerService,
    protected patientServable: PatientServable,
    private emergencyRoomStoreService: EmergencyRoomStoreService
  ) {
    this.loaded = false;
  }

  ngOnInit(): void {
    this.getId();
    this.getData();
  }

  private getId(): void {
    this.id = this.emergencyRoomStoreService.nidaId;
    this.protocolId = this.emergencyRoomStoreService.protocolId;
  }

  private getData() {
    this.emergencyRoomStoreService.patientData.subscribe((value) => {
      if (value) {
        this.patientData = value;
      }
    });

    this.emergencyRoomStoreService.patientDetails.subscribe((response) => {
      this.patientDetails = response;
    });
  }
}
