import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { AfList } from '../models/afList';
import { BdList } from '../models/bdList';
import { BzList } from '../models/bzList';
import { Co2List } from '../models/co2List';
import { CoList } from '../models/coList';
import { HbList } from '../models/hbList';
import { HfList } from '../models/hfList';
import { PulsList } from '../models/pulsList';
import { Spo2List } from '../models/spo2List';
import { TempList } from '../models/tempList';
import { PulseRegularList } from '../models/pulseRegularList';

@Injectable({
  providedIn: 'root',
})
export class VitalSignsRestService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = `${apiUrl}vitalSigns/protocols`;
    });

    this.subscription.add(configSub);
    this.subscription.unsubscribe();
  }

  /**
   * Get af entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAfByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<AfList>;
  public getAfByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AfList>>;
  public getAfByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AfList>>;
  public getAfByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getAfByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<AfList>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/af`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get bd entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBdByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<BdList>;
  public getBdByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BdList>>;
  public getBdByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BdList>>;
  public getBdByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getBdByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<BdList>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/bd`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get bz entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBzByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<BzList>;
  public getBzByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BzList>>;
  public getBzByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BzList>>;
  public getBzByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getBzByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<BzList>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/bz`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get co2 entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCo2ByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<Co2List>;
  public getCo2ByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Co2List>>;
  public getCo2ByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Co2List>>;
  public getCo2ByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getCo2ByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Co2List>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/co2`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get co entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCoByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<CoList>;
  public getCoByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CoList>>;
  public getCoByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CoList>>;
  public getCoByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getCoByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<CoList>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/co`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get hb entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getHbByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<HbList>;
  public getHbByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HbList>>;
  public getHbByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HbList>>;
  public getHbByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getHbByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<HbList>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/hb`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get hf entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getHfByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<HfList>;
  public getHfByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HfList>>;
  public getHfByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HfList>>;
  public getHfByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getHfByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<HfList>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/hf`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get puls entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPulsByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<PulsList>;
  public getPulsByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PulsList>>;
  public getPulsByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PulsList>>;
  public getPulsByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getPulsByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<PulsList>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/puls`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get spo2 entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSpo2ByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<Spo2List>;
  public getSpo2ByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Spo2List>>;
  public getSpo2ByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Spo2List>>;
  public getSpo2ByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getSpo2ByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Spo2List>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/spo2`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get temp entries for specific ID
   *
   * @param protocolId the protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTempByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<TempList>;
  public getTempByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TempList>>;
  public getTempByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TempList>>;
  public getTempByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getTempByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<TempList>('get', `${this.apiPrefix}/${encodeURIComponent(String(protocolId))}/temp`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get one PulseRegular data set by it&#x27;s id
   * Get one pulse-regular data set by it&#x27;s Id
   * @param id The ID of the data set we want to work with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPulseRegularById(id: number, observe?: 'body', reportProgress?: boolean): Observable<PulseRegularList>;
  public getPulseRegularById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PulseRegularList>>;
  public getPulseRegularById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PulseRegularList>>;
  public getPulseRegularById(id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getPulseRegularById.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<PulseRegularList>('get', `${this.apiPrefix}/${encodeURIComponent(String(id))}/pulse-regular`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
