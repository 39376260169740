import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BreadcrumbNavItem } from '../models/breadcrumb-nav-item.model';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public subRouter?: Subscription;
  public isCreated: BehaviorSubject<boolean>;
  public showBreadcrumb: BehaviorSubject<boolean>;
  public menuItems: Array<BreadcrumbNavItem>;

  constructor(protected activatedRoute: ActivatedRoute, protected router: Router) {
    this.isCreated = new BehaviorSubject<boolean>(false);
    this.showBreadcrumb = new BehaviorSubject<boolean>(true);
    this.menuItems = [];
  }

  // subscribeToRouter(): void {
  //   this.subRouter = this.router.events
  //     .pipe(filter((event) => event instanceof NavigationEnd))
  //     .subscribe(() => this.createBreadcrumbs(this.activatedRoute));
  // }

  unsubscribeToRouter(): void {
    if (this.subRouter) this.subRouter.unsubscribe();
  }

  public triggerBreadcrumbCreation(route: ActivatedRoute): void {
    this.createBreadcrumbs(route);
  }

  getMenuItems() {
    return this.menuItems;
  }

  private createBreadcrumbs(route: ActivatedRoute) {
    this.menuItems = [];

    route.children.forEach((preChild) => {
      /*
      First level, mother.
      Normally the base route, containing all children
      Example: To access the emergency room environment, the base (level 1) is defined as:
      "current-patients/details/:id/emergency-room/:protocolId", therefore the children (level 2) are defined as:
      "preclinic", "councils" etc.
      There should not be more than 2 levels existing in the application. Else, make this recursive;
       */
      this.processChild(preChild);

      preChild.children.forEach((child) => {
        // second level, children.

        this.processChild(child);
      });
    });

    this.isCreated.next(true);
  }

  processChild(child: ActivatedRoute) {
    const params = child.snapshot.params;

    if (child.snapshot.routeConfig) {
      const path = child.snapshot.routeConfig.path;

      if (path) {
        const menu: any[] = [];
        const crumbs = path.split('/');

        let currentPath = '';
        let index = 0;

        crumbs.forEach((crumb) => {
          // crumb = piece of navigation (management, :id)
          let isDynamic = false;
          let fragment = '';
          let caption = '';

          if (crumb.startsWith(':')) {
            isDynamic = true;
            fragment = crumb.split(':')[1]; // fragment: cleaned up crumb (:id => id)
            /*
             caption: string to show;
             pop(): returns last Array element + deletes it (!)
             caption for a dynamic fragment === fragment of element before
             ":id" caption in "management/capacities/:id" is "capacities"
             */
            currentPath = currentPath + '/' + params[fragment];
          } else {
            fragment = crumb;
            caption = crumb;
            currentPath = currentPath + '/' + crumb;
          }

          menu.push({
            isDynamic,
            fragment,
            caption,
            path: currentPath,
            level: index,
            dynamicValue: params[fragment], // the current value of dynamic fragment
          });

          if (!isDynamic) {
            index = index + 1;
          }
          // only add 1 if not dynamic (.pop() deletes 1 element, see above)
        });

        menu.forEach((entry) => {
          this.menuItems.push(entry);
        });
      }
    }
  }

  upperLevelExist(): boolean {
    let returnValue = false;

    if (this.getUpperLevel()) {
      returnValue = true;
    }

    return returnValue;
  }

  getUpperLevel(): null | BreadcrumbNavItem {
    let upperLevel: null | BreadcrumbNavItem;
    upperLevel = null;

    // TODO: please add types!
    if (this.menuItems && this.menuItems[this.menuItems.length - 2]) {
      upperLevel = this.menuItems[this.menuItems.length - 2];
    }

    return upperLevel;
  }

  getUpperLevelPath(): string {
    let upperLevelPath = '';

    // TODO: please add types!
    if (this.menuItems && this.menuItems[this.menuItems.length - 2]) {
      upperLevelPath = this.menuItems[this.menuItems.length - 2].path;
    }

    return upperLevelPath;
  }

  getRootLevel(): null | BreadcrumbNavItem {
    let rootLevel: null | BreadcrumbNavItem;
    rootLevel = null;

    // TODO: please add types!
    if (this.menuItems && this.menuItems[0]) {
      rootLevel = this.menuItems[0];
    }

    return rootLevel;
  }

  isCurrentPathDynamic(): boolean {
    return this.menuItems[this.menuItems.length - 1].isDynamic;
  }

  getCurrentDynamicValue(): string | number | undefined {
    return this.menuItems[this.menuItems.length - 1].dynamicValue;
  }

  getCurrentFragment(): string {
    return this.menuItems[this.menuItems.length - 1].fragment;
  }

  isFragmentInPath(fragment: string): boolean {
    let returnValue = false;

    this.menuItems.forEach((item) => {
      if (item.fragment === fragment) {
        returnValue = true;
      }
    });

    return returnValue;
  }

  hideBreadcrumbs(): void {
    this.showBreadcrumb.next(false);
  }

  showBreadcrumbs(): void {
    this.showBreadcrumb.next(true);
  }

  setBreadcrumbs(bool: boolean): void {
    this.showBreadcrumb.next(bool);
  }

  navigateTo(path: string): void {
    this.router.navigate([path]).then();
  }
}
