import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ApiEcgList } from '../models/api-ecg-list.model';
import { ApiEcgMeta } from '../models/api-ecg-meta.model';
import { ApiEcg413 } from '../models/api-ecg-4-13.model';
import { ConfigRESTServable, Configuration, CustomHttpUrlEncodingCodec } from '@nida-web/api/generic-interfaces/config';

@Injectable({
  providedIn: 'root',
})
export class EcgRestService {
  public defaultHeaders: HttpHeaders;
  public configuration: Configuration;

  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(private httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';

    const configSub = this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = `${apiUrl}ecg_module`;
    });
    this.subscription.add(configSub);
    this.subscription.unsubscribe();

    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
  }

  /**
   * Get the ECG data of the given mission (nida_id) and ECG id.
   * Returns a JSON object with the data of one ecg. Hertz can be defined/chosen by adding one of the fragments available
   * @param ecgId the ecg record's id
   * @param nidaId the mission's nida id
   * @param fragment the fragment
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEcgJson(ecgId: number, nidaId: string, fragment: string, observe?: 'body', reportProgress?: boolean): Observable<ApiEcg413>;
  public getEcgJson(
    ecgId: number,
    nidaId: string,
    fragment: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ApiEcg413>>;
  public getEcgJson(
    ecgId: number,
    nidaId: string,
    fragment: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ApiEcg413>>;
  public getEcgJson(ecgId: number, nidaId: string, fragment: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (ecgId === null || ecgId === undefined) {
      throw new Error('Required parameter ecgId was null or undefined when calling getEcgJson.');
    }

    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getEcgJson.');
    }

    if (fragment === null || fragment === undefined) {
      throw new Error('Required parameter fragment was null or undefined when calling getEcgJson.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/vnd.meddv.ecg+json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<ApiEcg413>(
      'get',
      `${this.apiPrefix}/${encodeURIComponent(String(nidaId))}/ecg/${encodeURIComponent(String(ecgId))}/${encodeURIComponent(
        String(fragment)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Get all the ECGs of the given mission (nida_id) as a list.
   * Returns a JSON list with all of ecg performed in this mission - belonging to this nida_id
   * @param nidaId the nida id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEcgList(nidaId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiEcgList>;
  public getEcgList(nidaId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiEcgList>>;
  public getEcgList(nidaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiEcgList>>;
  public getEcgList(nidaId: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getEcgList.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<ApiEcgList>(`${this.apiPrefix}/${encodeURIComponent(String(nidaId))}/ecg/list`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get the ECG meta data of the given mission (nida_id) and ECG id.
   * Returns a JSON object with the meta data of one ecg
   * @param ecgId the ecg record's id
   * @param nidaId the mission's nida id
   * @param fragment the fragment
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEcgMetaJson(
    ecgId: number,
    nidaId: string,
    fragment: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ApiEcgMeta>;
  public getEcgMetaJson(
    ecgId: number,
    nidaId: string,
    fragment: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ApiEcgMeta>>;
  public getEcgMetaJson(
    ecgId: number,
    nidaId: string,
    fragment: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ApiEcgMeta>>;
  public getEcgMetaJson(ecgId: number, nidaId: string, fragment: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (ecgId === null || ecgId === undefined) {
      throw new Error('Required parameter ecgId was null or undefined when calling getEcgMetaJson.');
    }

    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getEcgMetaJson.');
    }

    if (fragment === null || fragment === undefined) {
      throw new Error('Required parameter fragment was null or undefined when calling getEcgMetaJson.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/vnd.meddv.ecg+json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<ApiEcgMeta>(
      'get',
      `${this.apiPrefix}/${encodeURIComponent(String(nidaId))}/ecg/meta/${encodeURIComponent(String(ecgId))}/${encodeURIComponent(
        String(fragment)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   *
   * @param nidaId
   * @param timestamp
   */
  public getEcgPdf(nidaId: string, timestamp: Date) {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getEcgPdf.');
    }
    if (timestamp === null || timestamp === undefined) {
      throw new Error('Required parameter timestamp was null or undefined when calling getEcgPdf.');
    }

    let queryParameters: HttpParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('timestamp', timestamp.toISOString());

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [' application/pdf '];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [' application/pdf '];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.get<any>(`${this.apiPrefix}/${encodeURIComponent(String(nidaId))}/ecg/pdf`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      // @ts-ignore
      responseType: 'blob',
    });
  }
}
