import { Injectable } from '@angular/core';
import packageInfo from '../../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class NavAppInfoService {
  private _title: string;
  private _version: string;
  private _cpYear: number;
  private _countryCode: string;

  constructor() {
    this._title = '';
    this._version = packageInfo.version;
    this._cpYear = new Date().getFullYear();
    this._countryCode = '';
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get countryCode(): string {
    return this._countryCode;
  }

  set countryCode(value: string) {
    this._countryCode = value;
  }

  get version(): string {
    return this._version;
  }

  set version(value: string) {
    this._version = value;
  }
  get cpYear(): number {
    return this._cpYear;
  }

  set cpYear(value: number) {
    this._cpYear = value;
  }
}
