import { NgModule } from '@angular/core';
import { EcgViewerComponent } from './ecg-viewer.component';
import {
  DxButtonGroupModule,
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxRangeSelectorModule,
} from 'devextreme-angular';
import { TranslocoTranslationModule } from '@nida-web/transloco-translation';
import { EcgDetailComponent } from './ecg-detail/ecg-detail.component';
import { EcgPdfDownloadComponent } from './ecg-pdf-download/ecg-pdf-download.component';
import { EcgPdfViewerComponent } from './ecg-pdf-viewer/ecg-pdf-viewer.component';
import { EcgOverviewChannelsComponent } from './ecg-overview-channels/ecg-overview-channels.component';
import { EcgOverviewDetailComponent } from './ecg-overview-detail/ecg-overview-detail.component';
import { EcgListComponent } from './ecg-list/ecg-list.component';
import { EcgOverviewHeadComponent } from './ecg-overview-head/ecg-overview-head.component';
import { EcgLeadDetailComponent } from './ecg-lead-detail/ecg-lead-detail.component';
import { CoreModule } from '@nida-web/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { EcgViewerRoutingModule } from './ecg-viewer-routing.module';

@NgModule({
  imports: [
    CommonModule,
    DxRangeSelectorModule,
    DxChartModule,
    DxDropDownButtonModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxDataGridModule,
    TranslocoTranslationModule,
    DxButtonModule,
    CoreModule,
    TranslocoModule,
    EcgViewerRoutingModule,
  ],
  declarations: [
    EcgViewerComponent,
    EcgDetailComponent,
    EcgPdfDownloadComponent,
    EcgPdfViewerComponent,
    EcgOverviewChannelsComponent,
    EcgOverviewDetailComponent,
    EcgListComponent,
    EcgOverviewHeadComponent,
    EcgLeadDetailComponent,
  ],
  exports: [
    EcgViewerComponent,
    EcgDetailComponent,
    EcgListComponent,
    EcgOverviewChannelsComponent,
    EcgOverviewDetailComponent,
    EcgPdfDownloadComponent,
    EcgPdfViewerComponent,
  ],
})
export class EcgViewerModule {}
