import { NgModule } from '@angular/core';
import { ImageLayoutViewerComponent } from './image-layout-viewer.component';
import { ImageCarouselViewerComponent } from './image-carousel-viewer/image-carousel-viewer.component';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxDataGridModule, DxPopupModule } from 'devextreme-angular';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [ImageLayoutViewerComponent, ImageCarouselViewerComponent],
  imports: [CommonModule, TranslocoModule, DxDataGridModule, DxButtonModule, DxPopupModule],
  exports: [ImageLayoutViewerComponent, ImageCarouselViewerComponent],
})
export class ImageLayoutViewerModule {}
