import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@nida-web/shared/data-access';
import { BaseRootComponent } from '@nida-web/navigation';
import { FeatureSupportPageComponent } from './feature-support-page/feature-support-page.component';
import { FeatureClientSupportPageComponent } from './feature-client-support-page/feature-client-support-page.component';

const routes: Routes = [
  {
    path: '',
    component: BaseRootComponent,
    children: [
      {
        path: 'support',
        component: FeatureSupportPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'client-support',
        component: FeatureClientSupportPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedSupportRoutingModule {}
