import { NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxListModule,
  DxTextBoxModule,
  DxValidationGroupModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { UserManagementComponent } from './user-management.component';
import { UserTableComponent } from './user-table/user-table.component';
import { CommonModule } from '@angular/common';
import { NewUserEntryComponent } from './new-user-entry/new-user-entry.component';
import { UserRowDetailComponent } from './user-table/user-row-detail/user-row-detail.component';
import { TranslocoTranslationModule } from '@nida-web/transloco-translation';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { CancelSaveButtonsComponent } from './cancel-save-buttons/cancel-save-buttons.component';
import { UntypedFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { CoreModule, TableManagementService } from '@nida-web/core';
import { SharedUiModule } from '@nida-web/shared/ui';
import { PasswordManagementComponent } from './password-management/password-management.component';
import { SetInitialNidamobilePasswordComponent } from './set-initial-nidamobile-password/set-initial-nidamobile-password.component';
import { UserManagementRoutingModule } from './user-management-routing.module';

@NgModule({
  declarations: [
    UserManagementComponent,
    UserTableComponent,
    NewUserEntryComponent,
    UserRowDetailComponent,
    UserDetailComponent,
    CancelSaveButtonsComponent,
    PasswordManagementComponent,
    SetInitialNidamobilePasswordComponent,
  ],
  imports: [
    DxButtonModule,
    DxDataGridModule,
    DxFormModule,
    DxListModule,
    CommonModule,
    TranslocoTranslationModule,
    CoreModule,
    SharedUiModule,
    DxValidationGroupModule,
    DxValidatorModule,
    DxTextBoxModule,
    ReactiveFormsModule,
    UserManagementRoutingModule,
  ],
  exports: [UserManagementComponent, NewUserEntryComponent, PasswordManagementComponent, SetInitialNidamobilePasswordComponent],
  providers: [UntypedFormBuilder, TableManagementService],
})
export class UserManagementModule {}
