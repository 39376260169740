<dx-data-grid
  #userGrid
  (onContentReady)="onContentReady($event)"
  (onRowClick)="onRowClick($event)"
  [allowColumnReordering]="false"
  [columnAutoWidth]="true"
  [dataSource]="this.mediaList"
  [showBorders]="!responsive"
  [repaintChangesOnly]="false"
  [width]="responsive ? '100%' : 'auto'"
  [height]="responsive ? 'calc(100% -  ' + topOffset + 'px)' : 'auto'"
  id="userGrid2ncGen"
  noDataText="{{ 'No data' | transloco }}"
  dataRowTemplate="mediaListTemplate"
>
  <dxi-column [allowFiltering]="false" [allowSorting]="false" [caption]="'Preview' | transloco" dataField="string"></dxi-column>
  <dxi-column [caption]="'Date' | transloco" dataField="date" dataType="date"></dxi-column>

  <ng-container *dxTemplate="let mediaentry of 'mediaListTemplate'">
    <tr class="dx-row" [ngClass]="{ 'dx-row-alt': mediaentry.rowIndex % 2 }">
      <td><img [src]="mediaentry.data.srcUrl" height="{{ thumbnailHeight }}" /></td>
      <td>
        <div *ngIf="mediaentry.data.date">{{ mediaentry.data.date | date: 'dd.MM.yyyy, HH:mm' }}</div>
      </td>
    </tr>
  </ng-container>
</dx-data-grid>

<dx-popup [(visible)]="popupVisible" [hideOnOutsideClick]="true" [dragEnabled]="false" [showTitle]="true" title="Video">
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <!-- <video [className]="'m-auto rotate-' + videoRotation + ' zoom-level-' + videoZoomLevel" height="300" controls src="{{carouselData[popupMediaIndex]}}"></video>-->
      <video
        [ngStyle]="{
          '-moz-transform':
            'scale(' + videoZoomLevel + ') rotate(' + videoRotation + 'deg) translate(' + videoTranslateX + 'em,' + videoTranslateY + 'em)',
          '-webkit-transform':
            'scale(' + videoZoomLevel + ') rotate(' + videoRotation + 'deg) translate(' + videoTranslateX + 'em,' + videoTranslateY + 'em)',
          '-o-transform':
            'scale(' + videoZoomLevel + ') rotate(' + videoRotation + 'deg) translate(' + videoTranslateX + 'em,' + videoTranslateY + 'em)',
          '-ms-transform':
            'scale(' + videoZoomLevel + ') rotate(' + videoRotation + 'deg) translate(' + videoTranslateX + 'em,' + videoTranslateY + 'em)',
          transform:
            'scale(' + videoZoomLevel + ') rotate(' + videoRotation + 'deg) translate(' + videoTranslateX + 'em,' + videoTranslateY + 'em)'
        }"
        class="col-12 m-auto"
        controls
        height="300"
        src="{{ carouselData[popupMediaIndex] }}"
      ></video>
    </div>
    <div class="row">
      <div class="col-3 mr-auto" style="z-index: 999; position: relative">
        <div class="row">
          <div class="col-1"></div>
          <div class="col-11">
            <dx-button (onClick)="moveVideo('up')" icon="arrowup"></dx-button>
          </div>
        </div>
        <div class="row">
          <dx-button (onClick)="moveVideo('right')" icon="arrowleft"></dx-button>
          <dx-button (onClick)="moveVideo('left')" icon="arrowright"></dx-button>
        </div>
        <div class="row">
          <div class="col-1"></div>
          <div class="col-11">
            <dx-button (onClick)="moveVideo('down')" icon="arrowdown"></dx-button>
          </div>
        </div>
      </div>
      <div class="col-6"></div>
      <div class="col-3" style="z-index: 999; position: relative">
        <div class="row">
          <dx-button (onClick)="rotateVideo(true)" icon="undo"></dx-button>
          <dx-button (onClick)="rotateVideo(false)" icon="redo"></dx-button>
        </div>
        <div class="row">
          <dx-button (onClick)="zoomVideo(true)" icon="plus"></dx-button>
          <dx-button (onClick)="zoomVideo(false)" icon="minus"></dx-button>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<div id="printBtn" (click)="printImage()"><i class="dx-icon-print" *ngIf="showPrintBtn"></i></div>

<nida-web-image-carousel-viewer
  #carouselViewerComponent
  [carouselData]="carouselData"
  (showPrintBtnEvent)="handleShowPrintBtnEvent($event)"
></nida-web-image-carousel-viewer>
