import { Component, OnInit, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
import { ERDiagnostic, ErDiagnosticTile } from '@nida-web/api/generic-interfaces/emergencyroom';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import DiagnosisTilesConfig from './diagnosis.config.json';
import notify from 'devextreme/ui/notify';

@Pipe({ name: 'keys' })
export class KeysPipeComponent implements PipeTransform {
  transform(value) {
    return Object.keys(value);
  }
}

@Component({
  selector: 'nida-web-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DiagnosisComponent implements OnInit {
  diagnosisData: ERDiagnostic;
  diagnosisFormGroup: UntypedFormGroup;
  globalDisabled: boolean;
  tiles: Array<ErDiagnosticTile>;

  constructor(private emergencyRoomStore: EmergencyRoomStoreService) {}

  ngOnInit(): void {
    this.setTiles();
    this.getData();
  }

  setTiles() {
    this.tiles = DiagnosisTilesConfig.data;
  }

  private getData() {
    this.emergencyRoomStore.diagnosticData.subscribe((response) => {
      this.diagnosisData = response;
      this.initFormGroups();
    });
  }

  initFormGroups() {
    this.diagnosisFormGroup = new UntypedFormGroup({});
    this.tiles.forEach((tile: ErDiagnosticTile) => {
      this.diagnosisFormGroup.addControl(
        tile.dateKey,
        new UntypedFormControl({ value: this.diagnosisData[tile.dateKey], disabled: this.globalDisabled })
      );
      this.diagnosisFormGroup.addControl(
        tile.pathoKey,
        new UntypedFormControl({ value: this.diagnosisData[tile.pathoKey], disabled: this.globalDisabled })
      );
    });

    this.diagnosisFormGroup.addControl(
      'findings',
      new UntypedFormControl({ value: this.diagnosisData.findings, disabled: this.globalDisabled })
    );
  }

  checkDateEntryExistence(key: string) {
    const date = this.diagnosisFormGroup.value[key];
    if (!date) {
      this.diagnosisFormGroup.patchValue({ [key]: new Date() });
      this.diagnosisFormGroup.markAsDirty();
    }
  }

  submitForms() {
    if (this.diagnosisData.id) {
      const diagnosisObject = this.diagnosisFormGroup.getRawValue();
      diagnosisObject.id = this.diagnosisData.id;
      this.emergencyRoomStore.updateDiagnostic(this.diagnosisData.id, diagnosisObject).subscribe((response) => {
        if (response) {
          this.diagnosisData = response;
          this.initFormGroups();
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
      });
    } else {
      console.log('ERROR: diagnosisData.id is undefined, null or in a different way not a number');
    }
  }

  // EVENT TRIGGERED:

  saveAll() {
    this.submitForms();
  }

  resetAll() {
    /*
    FormGroup.reset() sets back any data (also pre existing in the db)
    FormGroup.reset() acts more like "clear"
     */

    // this.diagnosisFormGroup.reset()
    this.initFormGroups();
  }
}
