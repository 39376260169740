import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Insurance, InsuranceList, InsuranceServable } from '@nida-web/api/generic-interfaces/patient-management';
import { InsuranceRestService } from '../actions/insurance.rest.service';

@Injectable({
  providedIn: 'root',
})
export class InsuranceRestAdapterService implements InsuranceServable {
  constructor(private insuranceRestService: InsuranceRestService) {}

  getInsurance(protocolId: number): Observable<Insurance> {
    const resultSubject = new ReplaySubject<Insurance>(1);

    this.insuranceRestService.getInsurance(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject.asObservable();
  }

  getInsuranceByProtocolId(protocolId: number): Observable<Insurance> {
    const resultSubject = new ReplaySubject<Insurance>(1);

    this.insuranceRestService.getInsuranceByProtocolId(protocolId.toString()).subscribe((insurance) => {
      resultSubject.next(insurance);
    });

    return resultSubject.asObservable();
  }

  getInsuranceList(): Observable<InsuranceList> {
    const resultSubject = new ReplaySubject<InsuranceList>(1);

    this.insuranceRestService.getInsuranceList().subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject.asObservable();
  }

  putInsurance(insurance: Insurance, id: number): Observable<Insurance> {
    const resultSubject = new ReplaySubject<Insurance>(1);

    this.insuranceRestService.saveInsurance(insurance, id.toString()).subscribe((updatedInsurance) => {
      resultSubject.next(updatedInsurance);
    });

    return resultSubject.asObservable();
  }
}
