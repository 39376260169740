<div class="basic-card-style">
  <div class="basic-card-title basic-padding">
    {{ 'Linked his record' | transloco }}
  </div>
  <div *ngIf="loaded && kis" class="ngIf-loaded">
    <div class="basic-flex-container">
      <div class="basic-padding">
        <div class="basic-subject-title">
          {{ 'Last name' | transloco }}
        </div>
        <div class="basic-font-medium">
          {{ kis.patientLastName | displayNan }}
        </div>
      </div>
      <div class="basic-padding">
        <div class="basic-subject-title">
          {{ 'First name' | transloco }}
        </div>
        <div class="basic-font-medium">
          {{ kis.patientFirstName | displayNan }}
        </div>
      </div>
      <div class="basic-padding">
        <div class="basic-subject-title">
          {{ 'Birthday' | transloco }}
        </div>
        <div class="basic-font-medium">
          {{ formatMoment(kis.patientBirthdate, 'date') | displayNan }}
        </div>
      </div>
      <div class="basic-padding">
        <div class="basic-subject-title">
          {{ 'Insurance membership number' | transloco }}
        </div>
        <div class="basic-font-medium">
          {{ kis.insuredNumber | displayNan }}
        </div>
      </div>
      <div class="basic-padding">
        <div class="basic-subject-title">
          {{ 'Case number' | transloco }}
        </div>
        <div class="basic-font-medium">
          {{ kis.CaseNumber | displayNan }}
        </div>
      </div>
      <div class="basic-padding">
        <div class="basic-subject-title">
          {{ 'His update time' | transloco }}
        </div>
        <div class="basic-font-medium">
          {{ formatMoment(kis.receivedAt, 'medium') | displayNan }}
        </div>
      </div>
      <div class="basic-padding">
        <div class="basic-subject-title">
          {{ 'Message type' | transloco }}
        </div>
        <div class="basic-font-medium">
          {{ kis.messageType | displayNan | adtMessageConverter }}
        </div>
      </div>
    </div>
    <div class="basic-flex-container">
      <div class="basic-padding">
        <div class="basic-font-medium">
          <div class="alert alert-success" role="alert">
            {{ 'Successful transferred:' | transloco }}
            {{ formatMoment(kis.exportAt, 'medium') | displayNan }}
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="basic-flex-container kis-mapping">
      <div class="basic-padding">
        <div class="basic-font-title">
          {{ 'Remove the his link?' | transloco }}
        </div>
        <div class="basic-font-medium">
          {{ 'The data is not removed from the his.' | transloco }}
        </div>
      </div>
      <div class="basic-padding">
        <dx-button
          (onClick)="unlinkKisMapping()"
          [useSubmitBehavior]="true"
          stylingMode="outlined"
          text="{{ 'Remove link' | transloco }}"
          type="default"
          width="100%"
        ></dx-button>
      </div>
    </div>
  </div>
  <div *ngIf="noData" class="ngIf-loaded">
    <div class="basic-flex-container">
      <div class="basic-padding">
        <div class="basic-font-medium">
          {{ 'No his data found' | transloco }}
        </div>
      </div>
    </div>
  </div>
</div>
