<div class="p-3 hint-text-box">
  <p class="m-0">{{ 'settings.changeUserPassword.needsToFulfillRequirements' | transloco }}</p>
  <ul class="mb-0">
    <li>{{ 'validations.password.minLength' | transloco: { value: 8 } }}</li>
    <li>{{ 'validations.password.capitalLetter' | transloco }}</li>
    <li>{{ 'validations.password.lowerCaseLetter' | transloco }}</li>
    <li>{{ 'validations.password.digit' | transloco }}</li>
    <li>
      {{ 'validations.password.containSpecialCharacter' | transloco }}: <br />
      #$%^&*()_+-=;':/,.<>?!
    </li>
  </ul>
</div>
