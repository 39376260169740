/**
 * REST API to provide a forgot password feature.
 * A client can call the REST API to force a password reset for a specific user account.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Account } from '../models/account';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  protected basePath: string;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}password3`;
    });
  }

  /**
   * Reset an account\&#39;s password.
   * @param account
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resetAccountPassword(
    account: Account,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any>;
  public resetAccountPassword(
    account: Account,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public resetAccountPassword(
    account: Account,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public resetAccountPassword(
    account: Account,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' }
  ): Observable<any> {
    if (account === null || account === undefined) {
      throw new Error('Required parameter account was null or undefined when calling resetAccountPassword.');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put(`${this.basePath}/resetpassword`, account, {
      responseType: 'text',
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
