import { EcgLead } from './ecg-lead.model';

export class Ecg {
  I: EcgLead;
  II: EcgLead;
  III: EcgLead;
  aVR: EcgLead;
  aVL: EcgLead;
  aVF: EcgLead;
  naVR?: EcgLead;
  v1?: EcgLead;
  v2?: EcgLead;
  v3?: EcgLead;
  v4?: EcgLead;
  v5?: EcgLead;
  v6?: EcgLead;

  constructor(I: EcgLead, II: EcgLead, III: EcgLead, aVR: EcgLead, aVL: EcgLead, aVF: EcgLead) {
    this.I = I;
    this.II = II;
    this.III = III;
    this.aVR = aVR;
    this.aVL = aVL;
    this.aVF = aVF;
  }
}
