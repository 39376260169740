import { Injectable } from '@angular/core';
import { ERPreclinicDeprecated, ERSeverityEnum } from '@nida-web/api/generic-interfaces/protocol';
import { InjuryList } from '../models/injury-list.model';

@Injectable({
  providedIn: 'root',
})
export class InjuryMappingService {
  public mapValues(rawData: InjuryList, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    for (const singleInjury of rawData.data) {
      switch (singleInjury.type) {
        case 'Unfallmechanismus':
          refObj.incidentDescription.accidentMechanism = singleInjury.kind;
          break;
        case 'Unfallhergang':
          refObj.incidentDescription.accidentType = singleInjury.kind;
          break;
        case 'Verletzung':
          if (!singleInjury.kind?.includes('Verbrennung')) {
            refObj.diagnostic.aisLocalisation.push({
              name: singleInjury.place,
              severity: this.getSeverity(singleInjury.severity),
            });
          }
          break;
      }
    }
    return refObj;
  }

  private getSeverity(severityString: string | undefined): ERSeverityEnum {
    switch (severityString) {
      case 'leicht':
        return ERSeverityEnum.LIGHT;
      case 'mittel':
        return ERSeverityEnum.MIDDLE;
      case 'schwer':
      case 'tödlich':
        return ERSeverityEnum.HEAVY;
      default:
        return ERSeverityEnum.NONE;
    }
  }
}
