<div>
  <div style="padding: 10px">
    <nida-web-er-title-toolbar [title]="'Patient'" [showOnlyTitle]="true"></nida-web-er-title-toolbar>
  </div>

  <div class="row mx-0 p-0">
    <div class="col mx-0 p-10 basic-direction-column basic-padding">
      <nida-web-patient-personal-data [detailData]="patientDetails" [patientData]="patientData"></nida-web-patient-personal-data>
    </div>
    <div class="col mx-0 p-10 basic-direction-column basic-padding">
      <nida-web-patient-insurance [protocolId]="protocolId"></nida-web-patient-insurance>
    </div>
  </div>
</div>
