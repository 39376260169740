import { Injectable } from '@angular/core';
import { ERMedicalAccess, ERPreclinic, ERVitals } from '@nida-web/api/generic-interfaces/emergencyroom';
import { ERCirculationAccess, ERPreclinicDeprecated } from '@nida-web/api/generic-interfaces/protocol';

@Injectable({
  providedIn: 'root',
})
export class ProtocolToPreclinicMappingService {
  public mapPreclinicVital(rawValue: ERPreclinicDeprecated): ERVitals {
    return {
      alcohol: rawValue.fistVitalSigns.intoxicationAlcohol,
      bodyTemperature: rawValue.fistVitalSigns.bodyTemperature,
      drugs: rawValue.fistVitalSigns.intoxicationDrugs,
      etco2: rawValue.fistVitalSigns.breathingEtCO2,
      fio2: rawValue.fistVitalSigns.breathingFiO2,
      gcs: rawValue.fistVitalSigns.scoreGCS,
      gcsEyes: rawValue.fistVitalSigns.gcsEyes,
      gcsMotoric: rawValue.fistVitalSigns.gcsMotoric,
      gcsVerbal: rawValue.fistVitalSigns.gcsVerbal,
      heartRate: rawValue.fistVitalSigns.heartFrequency,
      intoxication: rawValue.fistVitalSigns.intoxicationOther,
      pain: rawValue.fistVitalSigns.painLevel,
      pupilsLightLeft: rawValue.fistVitalSigns.pupilsLightReactionLeft,
      pupilsLightRight: rawValue.fistVitalSigns.pupilsLightReactionRight,
      pupilsWidthLeft: rawValue.fistVitalSigns.pupilsExpanseLeft,
      pupilsWidthRight: rawValue.fistVitalSigns.pupilsExpanseRight,
      respiratoryFrequency: rawValue.fistVitalSigns.respiratoryRate,
      respiratoryTractClear: rawValue.fistVitalSigns.airwayFree,
      rrSys: rawValue.fistVitalSigns.rrSys,
      spo2: rawValue.fistVitalSigns.breathingSpO2,
    };
  }

  public mapPreclinic(rawValue: ERPreclinicDeprecated): ERPreclinic {
    const mappedPreclinic: ERPreclinic = {
      alarmTime: rawValue.dispatchTimestamps.alarmDateTime,
      accidentCause: rawValue.incidentDescription.accidentCause,
      accidentDescription: rawValue.incidentDescription.accidentDescription,
      accidentMechanism: rawValue.incidentDescription.accidentMechanism,
      accidentTime: rawValue.dispatchTimestamps.accidentDateTime,
      accidentType: rawValue.incidentDescription.accidentType,
      analgosedationTime: rawValue.therapy.analgesicSedation,
      arrivalTimeRescueTeam: rawValue.dispatchTimestamps.arrivalDateTime,
      asa: rawValue.incidentDescription.traumaASA ? parseInt(rawValue.incidentDescription.traumaASA, 10) : undefined,
      cardiacMassageTime: rawValue.therapy.cardiacMassage,
      catecholaminesTime: rawValue.therapy.catecholamines,
      cervicalSpineImmobilizationTime: rawValue.therapy.immobilisationHWS,
      // colloidsMl:
      // colloidsTime
      comment: rawValue.diagnostic.comments,
      // crystalloidsTime
      // crystalloidsMl
      defibrillationTime: rawValue.therapy.defibrillation,
      fracture: rawValue.therapy.fractureStart,
      hemostasis: rawValue.therapy.hemostasisStart,
      hemostyptics: rawValue.therapy.hemostasisHemostyptics,
      immobilised: rawValue.therapy.fractureImmobilized,
      intubationTime: rawValue.therapy.intubation,
      mountedTrueToAxis: rawValue.therapy.fractureAxiallyAligned,
      nacaScore: rawValue.diagnostic.scoreNACA ? parseInt(rawValue.diagnostic.scoreNACA, 10) : undefined,
      onsetOfSymptoms: rawValue.dispatchTimestamps.startOfSymptoms,
      pelvicBeltTime: rawValue.therapy.lapBelt,
      pressureBandage: rawValue.therapy.hemostasisBandage,
      reduced: rawValue.therapy.fractureRepositioned,
      startOfTransport: rawValue.dispatchTimestamps.startTransportDateTime,
      supraglotticRespiratoryAidTime: rawValue.therapy.supraglotticAirwayDevice,
      surgicalRespiratoryAidTime: rawValue.therapy.surgicalAirway,
      thermoprotectionTime: rawValue.therapy.thermalProtection,
      thoracicDrainageLeftTime: rawValue.therapy.thoraxDrainage,
      thoracicDrainageRightTime: rawValue.therapy.thoraxDrainage,
      tourniquet: rawValue.therapy.hemostasisTourniquet,
      tranexamicAcidTime: rawValue.therapy.tranexamicAcid,
      // ultrasonic: new Date(rawValue.diagnostic.ultrasound)
    };

    for (const aisEntry of rawValue.diagnostic.aisLocalisation) {
      switch (aisEntry.name) {
        case 'Schädelhirn':
          mappedPreclinic.aisCranialBrain = aisEntry.severity;
          break;
        case 'Gesicht':
          mappedPreclinic.aisFace = aisEntry.severity;
          break;
        case 'Thorax':
          mappedPreclinic.aisThorax = aisEntry.severity;
          break;
        case 'Abdomen':
          mappedPreclinic.aisAbdomen = aisEntry.severity;
          break;
        case 'HWS':
        case 'BWS / LWS':
          if (mappedPreclinic.aisSpine === undefined || mappedPreclinic.aisSpine < (aisEntry.severity ? aisEntry.severity : 0)) {
            mappedPreclinic.aisSpine = aisEntry.severity;
          }
          break;
        case 'Becken':
          mappedPreclinic.aisPelvic = aisEntry.severity;
          break;
        case 'Arme':
          mappedPreclinic.aisUpperExtremities = aisEntry.severity;
          break;
        case 'Beine':
          mappedPreclinic.aisLowerExtremities = aisEntry.severity;
          break;
        case 'Weichteil':
          mappedPreclinic.aisSoftParts = aisEntry.severity;
          break;
      }
    }

    return mappedPreclinic;
  }

  public mapPreclinicMedicalAccess(rawValue: ERCirculationAccess): ERMedicalAccess[] {
    const medicalAccess: ERMedicalAccess[] = [];

    for (const ma of rawValue) {
      medicalAccess.push({
        location: ma.place,
        side: ma.side,
        type: ma.type,
        time: ma.time,
      });
    }
    return medicalAccess;
  }
}
