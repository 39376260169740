<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-6 col-min-height" *ngFor="let link of this.pictureLinks">
      <img class="auto-size-image" [src]="link" />
    </div>
  </div>
  <div class="row col-12">
    <b>{{ this.translatedHeadtext }}</b>
  </div>
  <div class="row col-12">
    <p>{{ this.translatedText }}</p>
  </div>
  <div class="row col-12">
    <dx-button stylingMode="outlined" [text]="this.buttonText" type="default" (onClick)="navigateToPage(this.buttonLink)"></dx-button>
  </div>
</div>
