import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { TranslocoTranslationConfig } from '../models/transloco-translation-config';
import { GlobalizeLanguageService } from './globalize-language.service';

export const TRANSLOCO_TRANSLATION_CONFIG = new InjectionToken<TranslocoTranslationConfig>('transloco-translation-config');

@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoaderService implements TranslocoLoader {
  private langUrlPrefix: string;

  constructor(
    private http: HttpClient,
    @Inject(TRANSLOCO_TRANSLATION_CONFIG) translocoTranslationConfig: TranslocoTranslationConfig,
    private globalizeLanguageService: GlobalizeLanguageService
  ) {
    this.globalizeLanguageService.setLanguage(translocoTranslationConfig.defaultLang);
    this.langUrlPrefix = translocoTranslationConfig.loaderRootPath;
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(this.langUrlPrefix + `${lang}.json`); // TODO Pfad Konfigurierbar machen
  }

  //    return this.http.get<Translation>(`./assets/i18n/${lang}.json`); //
}
