import { Injectable } from '@angular/core';
import { EmergencyRoomIcd10Model } from '../models/emergency-room-icd10.model';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import icd10List from './icd10short.json';

@Injectable({
  providedIn: 'root',
})
export class ICD10StoreService {
  private loaded: BehaviorSubject<boolean>;
  icd10List: {
    icd10List: Array<EmergencyRoomIcd10Model>;
  };

  constructor() {
    this.icd10List = icd10List;
    this.loaded = new BehaviorSubject<boolean>(false);
  }

  getICD10sLoaded() {
    return this.loaded.asObservable();
  }

  getICD10s() {
    return this.icd10List;
  }

  searchICD10(term: string): Observable<EmergencyRoomIcd10Model[]> {
    const results: Array<EmergencyRoomIcd10Model> = this.icd10List.icd10List.filter((icd) => icd.code.includes(term));
    return timer(1000).pipe(map(() => results));
    // return this.http.get<icdType[]>(`${this.api}/icd10/search/${term}`);
  }

  searchText(term: string): Observable<EmergencyRoomIcd10Model[]> {
    const results: Array<EmergencyRoomIcd10Model> = this.icd10List.icd10List.filter((icd) => icd.desc.includes(term));
    return timer(1000).pipe(map(() => results));
    // return this.http.get<icdType[]>(`${this.api}/icd10desc/search/${term}`);
  }
}
