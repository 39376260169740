import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class LoginRESTService {
  public defaultHeaders: HttpHeaders;
  public configuration: Configuration;
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(private httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'auth';
    });
    this.subscription.add(configSub);
    // this.subscription.unsubscribe();

    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
  }

  public logout(): Observable<string> {
    return this.httpClient.get(`${this.apiPrefix}/logout`, {
      responseType: 'text',
    });
  }

  /**
   * Get the user currently logged in
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCurrentUser(observe?: 'body', reportProgress?: boolean): Observable<User>;
  public getCurrentUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
  public getCurrentUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
  public getCurrentUser(observe: any = 'body', reportProgress = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<User>('get', `${this.apiPrefix}/me`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public checkLoginToken(): Observable<any> {
    let headers = this.defaultHeaders;

    const httpHeaderAccepts: string[] = ['text/plain'];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);

    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<string>(`${this.apiPrefix}/checkAuthToken`, {
      headers,
    });
  }

  /**
   * Returns a new access token
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public authRenew(observe?: 'body', reportProgress?: boolean): Observable<string>;
  public authRenew(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public authRenew(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public authRenew(observe: any = 'body', reportProgress = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<string>('post', `${this.apiPrefix}/renew`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
      reportProgress: reportProgress,
    });
  }
}
