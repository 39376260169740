import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { MemberGroupList } from '../models/memberGroupList';
import { RescueServiceMemberGroupList } from '../models/rescueServiceMemberGroupList';
import { RescueServiceGroupWithInboxList } from '../models/rescueServiceGroupWithInboxList';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGroupRESTService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';

    const configSub = this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'permissions';
    });
    this.subscription.add(configSub);
  }

  /**
   * Get all Groups
   * For this Operation you need this permission \&quot;administration.rechterollen:ro\&quot;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllGroups(observe?: 'body', reportProgress?: boolean): Observable<RescueServiceGroupWithInboxList>;
  public getAllGroups(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RescueServiceGroupWithInboxList>>;
  public getAllGroups(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RescueServiceGroupWithInboxList>>;
  public getAllGroups(observe: any = 'body', reportProgress = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<RescueServiceGroupWithInboxList>('get', `${this.apiPrefix}/group/list`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get all Groups By Personal Id
   * For this Operation you need this permission \&quot;administration.personal.rollen:rw\&quot;
   * @param uid the group PersonalId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllGroupsByPersonalId(uid: string, observe?: 'body', reportProgress?: boolean): Observable<MemberGroupList>;
  public getAllGroupsByPersonalId(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MemberGroupList>>;
  public getAllGroupsByPersonalId(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MemberGroupList>>;
  public getAllGroupsByPersonalId(uid: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (uid === null || uid === undefined) {
      throw new Error('Required parameter uid was null or undefined when calling getAllGroupsByPersonalId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<MemberGroupList>('get', `${this.apiPrefix}/group/list/${encodeURIComponent(String(uid))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get all Groups By Personal Id when using a rescue service server
   * For this Operation you need this permission \&quot;administration.personal.rollen:rw\&quot;
   * @param uid the group PersonalId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllRescueServiceGroupsByPersonalId(
    uid: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RescueServiceMemberGroupList>;
  public getAllRescueServiceGroupsByPersonalId(
    uid: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RescueServiceMemberGroupList>>;
  public getAllRescueServiceGroupsByPersonalId(
    uid: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RescueServiceMemberGroupList>>;
  public getAllRescueServiceGroupsByPersonalId(uid: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (uid === null || uid === undefined) {
      throw new Error('Required parameter uid was null or undefined when calling getAllRescueServiceGroupsByPersonalId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<RescueServiceMemberGroupList>(
      'get',
      `${this.apiPrefix}/group/rescue-service-list/${encodeURIComponent(String(uid))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
