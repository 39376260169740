<!--
<nida-web-card headline="HIS Import">
  <ng-container *ngIf="loaded">
    <div class="text-center">
      <ng-container *ngIf="patientData.hl7ExportAt !== undefined && patientData.hl7ExportAt !== null; else toKisPage">
        <a class="d-inline-block" [routerLink]="'kisPage'" [state]="{ state: { patientData: patientData, detailData: detailData } }">
          <i class="icon icon-light-medical-folder active-icon basic-icon-size"></i>
          <img alt="Linked" class="kis-link-icon" src="/assets/resources/linked_green_circle.svg" />
        </a>
      </ng-container>
      <ng-template #toKisPage>
        <a class="d-inline-block" [routerLink]="'kisPage'" [state]="{ state: { patientData: patientData, detailData: detailData } }">
          <i class="icon icon-light-medical-folder inactive-icon basic-icon-size"></i>
        </a>
      </ng-template>
    </div>
  </ng-container>
</nida-web-card>
-->

<ng-container *ngIf="patientData && patientData.hl7ExportAt; else toKisPage">
  <a
    id="kisImport"
    class="d-inline-block hover-disabled"
    [routerLink]="'kisPage'"
    [state]="{ state: { patientData: patientData, detailData: detailData } }"
  >
    <i class="icon icon-light-medical-folder active-icon basic-icon-size"></i>
    <img alt="Linked" class="kis-link-icon" src="/assets/resources/linked_green_circle.svg" />
  </a>
</ng-container>
<ng-template #toKisPage>
  <a
    id="kisImport"
    class="d-inline-block hover-disabled"
    [routerLink]="'kisPage'"
    [state]="{ state: { patientData: patientData, detailData: detailData } }"
  >
    <i class="icon icon-light-medical-folder inactive-icon basic-icon-size"></i>
  </a>
</ng-template>
