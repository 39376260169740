import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decamelize',
})
@Injectable({
  providedIn: 'root',
})
export class DecamelizePipe implements PipeTransform {
  transform(value: string, separator?: string): string {
    const separatorAsString = typeof separator === 'undefined' ? '_' : separator;

    return value
      .replace(/([a-z\d])([A-Z])/g, '$1' + separatorAsString + '$2')
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separatorAsString + '$2')
      .toLowerCase();
  }
}
