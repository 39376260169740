import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { StartPageConfiguration } from '../models/startPage-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class StartPageSettingsService {
  private startPageConfigSubj: Subject<StartPageConfiguration>;

  constructor() {
    this.startPageConfigSubj = new ReplaySubject<StartPageConfiguration>(1);
  }

  public updateSettings(settings: StartPageConfiguration): void {
    this.startPageConfigSubj.next(settings);
  }

  public getSettings(): Observable<StartPageConfiguration> {
    return this.startPageConfigSubj.asObservable();
  }
}
