import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MappingConfig } from '../models/mapping-config.model';

@Injectable({
  providedIn: 'root',
})
export abstract class MappingConfigServable {
  public abstract getMappingConfig(): Observable<MappingConfig>;
}
