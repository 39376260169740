import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AccidentType } from '../../models/emergency-room-accident-types.model';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'nida-web-start-of-symptoms',
  templateUrl: './start-of-symptoms.component.html',
  styleUrls: ['./start-of-symptoms.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class StartOfSymptomsComponent implements OnInit {
  public accidentTypes: Array<AccidentType>;
  public accidentMechanisms: Array<AccidentType>;
  public causeOfAccidents: Array<AccidentType>;
  public asaItems = [{ text: 'I' }, { text: 'II' }, { text: 'III' }, { text: 'IV' }, { text: 'V' }];

  @Input()
  preclinicFormGroup: UntypedFormGroup;

  @Input()
  disabled: boolean;

  constructor() {
    this.accidentTypes = [
      { value: 1, name: 'PKW/LKW Insasse' },
      { value: 2, name: 'Motorradfahrer' },
      { value: 3, name: 'Fahrradfahrer' },
      { value: 4, name: 'Fußgänger' },
      { value: 5, name: 'Sonstiger Verkehrsunfall' },
      { value: 6, name: 'Sturz mit Fallhöhe >= 3m' },
      { value: 7, name: 'Sturz mit Fallhöhe < 3m' },
      { value: 8, name: 'Schlagverletzung' },
      { value: 9, name: 'Schußverletzung' },
      { value: 10, name: 'Stichverletzung' },
      { value: 11, name: 'andere Unfallarten' },
    ];

    this.accidentMechanisms = [
      { value: 1, name: 'stumpf' },
      { value: 2, name: 'penetrierend' },
    ];

    this.causeOfAccidents = [
      { value: 1, name: 'Unfall' },
      { value: 1, name: 'V.a. Gewaltverbrechen' },
      { value: 1, name: 'V.a. Suizid' },
      { value: 1, name: 'andere' },
    ];
  }

  ngOnInit(): void {}

  autoFillDateOnFieldClick(date: string) {
    if (this.preclinicFormGroup.getRawValue()[date] === null || this.preclinicFormGroup.getRawValue()[date] === undefined) {
      this.preclinicFormGroup.patchValue({ [date]: new Date() });
      this.preclinicFormGroup.markAsDirty();
    }
  }

  onBtnGroupChange(event) {
    if (this.preclinicFormGroup.getRawValue().asa !== event.itemIndex) {
      this.preclinicFormGroup.patchValue({ asa: event.itemIndex });
      this.preclinicFormGroup.markAsDirty();
    }
  }
}
