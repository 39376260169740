export class EcgLeadData {
  id: number;
  value: number;
  valid: boolean;
  synd: boolean;
  pacer: boolean;
  gainControl: number;
  highpass: string;
  lowpass: string;
  gap: number;

  constructor(
    id?: number,
    value?: number,
    valid?: boolean,
    synd?: boolean,
    pacer?: boolean,
    gainControl?: number,
    highpass?: string,
    lowpass?: string,
    gap?: number
  ) {
    this.id = id || 0;
    this.value = value || 0;
    this.valid = valid || false;
    this.synd = synd || false;
    this.pacer = pacer || false;
    this.gainControl = gainControl || 0;
    this.highpass = highpass || '';
    this.lowpass = lowpass || '';
    this.gap = gap || 0;
  }
}
