import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // We need to change responseType from "'json'" to "'text' as 'json'" because
        // the rest endpoint answers as text only with the token itself when 2FA is not active
        // when 2FA is active we get a JSON object with token and boolean if 2FA is active for the user
        if (error.status === 200 && error.url?.includes('auth/validate') && req.responseType === 'json') {
          const textReq = req.clone({
            responseType: 'text' as 'json',
          });
          return next.handle(textReq);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
