import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EcgList } from '../models/ecg-list.model';
import { EcgMeta } from '../models/ecg-meta.model';
import { Ecg } from '../models/ecg.model';

@Injectable()
export abstract class EcgServable {
  abstract getEcgList(nidaId: string): Observable<EcgList>;

  abstract getEcg(nidaId: string, ecgId: number, fragment: string): Observable<Ecg>;

  abstract getEcgMeta(nidaId: string, ecgId: number): Observable<EcgMeta>;

  abstract getEcgPdf(nidaId: string, timestamp: Date): Observable<ArrayBuffer>;
}
