import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { StartPageRoutingService } from '@nida-web/core';

@Injectable({
  providedIn: 'root',
})
export class StartPageGuard {
  constructor(protected sessionManagerService: SessionManagerService, private startPageRoutingService: StartPageRoutingService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.startPageRoutingService.calculateRoute();
  }
}
