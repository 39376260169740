import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CardComponent {
  @Input() headline: string;
  @Input() translatedHeadline: string;
  @Input() disableContentPadding: boolean;
  @Input() disableHeadline: boolean;
  @Input() buttonTemplate: TemplateRef<any>;
  @Input() height100: boolean;
  @Input() enableGrayStyling: boolean;

  constructor() {
    if (this.disableContentPadding === undefined) {
      this.disableContentPadding = false;
    }
    if (this.disableHeadline === undefined) {
      this.disableHeadline = false;
    }
    if (this.height100 === undefined) {
      this.height100 = false;
    }
  }

  //ngOnInit(): void {}
}
