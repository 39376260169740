<header>
  <dx-toolbar *ngIf="menuToggleEnabled" class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"
      cssClass="menu-button"
      location="before"
      widget="dxButton"
    >
    </dxi-item>
    <dxi-item *ngIf="title" [text]="title" cssClass="header-title app-title" location="before"></dxi-item>
    <ng-container *ngFor="let logo of logoSettings">
      <dxi-item *ngIf="getScreen() !== 'screen-x-small'" cssClass="menu-logo" location="after">
        <a class="img-link" target="_blank" href="https://www.{{ logo }}.de">
          <img alt="{{ logo }}" class="{{ logo }}-logo img-link" src="./assets/img/logos/{{ logo }}.png" />
        </a>
      </dxi-item>
    </ng-container>
    <!-- Makes user panel only visible on devices bigger than the smallest possible device -->
    <dxi-item *ngIf="getScreen() !== 'screen-x-small'" locateInMenu="auto" location="after" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button class="user-button authorization" height="100%" stylingMode="text">
          <div *dxTemplate="let data of 'content'">
            <nida-web-user-panel [menuItems]="userMenuItems" menuMode="context"></nida-web-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>
    <dxi-item *ngIf="headerTemplate" location="after">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </dxi-item>
    <div *dxTemplate="let data of 'menuItem'">
      <nida-web-user-panel [menuItems]="userMenuItems" menuMode="list"></nida-web-user-panel>
    </div>
  </dx-toolbar>
</header>
