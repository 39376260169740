<ng-container>
  <nida-web-side-nav-outer-toolbar
    [title]="navAppInfoService.title"
    [navigationItems$]="navigationItems$"
    [headerTemplate]="this.headerTemplate"
  >
    <nida-web-breadcrumbs></nida-web-breadcrumbs>
    <router-outlet></router-outlet>
    <nida-web-footer>
      &copy; {{ navAppInfoService.cpYear }} <a href="https://www.meddv.de/">medDV GmbH</a> | Version {{ navAppInfoService.version }} |
      {{ navAppInfoService.countryCode }} | <a routerLink="/support/legal-notice">Impressum</a>
    </nida-web-footer>
  </nida-web-side-nav-outer-toolbar>
</ng-container>
