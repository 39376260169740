import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@nida-web/shared/data-access';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';
import { ChangePasswordPageComponent } from './views/changePassword/change-password-page.component';
import { ResetPasswordComponent } from './views/resetPassword/resetPassword.component';
import { TwoFactorAuthPromptComponent } from './views/two-factor-auth-prompt/two-factor-auth-prompt.component';
import { SecureCodeAuthComponent } from './views/secure-code-auth/secure-code-auth.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'changePassword',
    component: ChangePasswordPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'activate-second-factor',
    component: TwoFactorAuthPromptComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'second-factor-code',
    component: SecureCodeAuthComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
