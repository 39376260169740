import { Component, Input, OnInit } from '@angular/core';
import { EcgList, EcgServable } from '@nida-web/api/generic-interfaces/ecg-viewer';
import { Router } from '@angular/router';

@Component({
  selector: 'nida-web-ecg-list',
  templateUrl: './ecg-list.component.html',
  styleUrls: ['./ecg-list.component.scss'],
})
export class EcgListComponent implements OnInit {
  @Input() nidaId: string;
  @Input() pageSize: number;
  @Input() public topOffset = 0;
  @Input() public responsive = false;
  public ecgList: EcgList = new EcgList();

  constructor(private ecgViewerService: EcgServable, private router: Router) {
    this.nidaId = '';
    this.pageSize = 4;
  }

  ngOnInit(nidaId?: string): void {
    if (nidaId) {
      this.nidaId = nidaId;
    }
    this.getEcgListFromRest();
  }

  onRowClick($event: any): void {
    this.triggerNavigation($event.data.ecg_id, this.nidaId, $event.data.timestamp);
  }

  triggerNavigation(ecgId: number, nidaId: string, timestamp: Date): void {
    this.router.navigate([`/ecg/details/${ecgId}/${nidaId}/${timestamp}`]).then();
  }

  getEcgListFromRest(): void {
    if (this.nidaId.length > 0) {
      this.ecgViewerService.getEcgList(this.nidaId).subscribe((ecgList) => {
        this.ecgList = ecgList;
      });
    }
  }
}
