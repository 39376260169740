<div *ngIf="loaded">
  <div class="inner-detail-wrapper">
    <div>
      <div class="spacer-35"></div>
      <div class="lead-title-holder">
        <strong>{{ channel }}</strong>
      </div>
    </div>

    <div class="display-grid">
      <div class="overflow-scroll">
        <div class="devextreme-grid-width">
          <ng-container *ngIf="checkDetailMode(0)">
            <div>
              <div>
                <div class="spacer-35"></div>

                <dx-chart
                  (click)="triggerPointEmission()"
                  (onPointHoverChanged)="setHoveredPoint($event)"
                  [animation]="{ enabled: false }"
                  [dataSource]="chartData"
                  [resizePanesOnZoom]="true"
                  id="zoomed-chart"
                  pointSelectionMode="multiple"
                >
                  <dxo-size [height]="217"></dxo-size>
                  <dxo-margin [left]="9" [right]="9"></dxo-margin>

                  <dxo-common-axis-settings
                    [maxValueMargin]="0"
                    [minValueMargin]="0"
                    [minorTick]="{ visible: false }"
                    [tick]="{ visible: false }"
                    [valueMarginsEnabled]="false"
                  >
                    <dxo-grid [visible]="true" color="lightgrey"></dxo-grid>
                    <dxo-minor-grid [visible]="true" color="lightgrey"></dxo-minor-grid>
                    <dxo-minor-grid [visible]="true" color="lightgrey"></dxo-minor-grid>
                  </dxo-common-axis-settings>

                  <dxo-argument-axis
                    [minorTickInterval]="intervals.minorTickInterval"
                    [tickInterval]="intervals.tickInterval"
                    [visualRange]="visualRange"
                  >
                    <ng-container *ngIf="detailPointStorage.point0 !== null">
                      <dxi-constant-line
                        [color]="crosshairPointColor"
                        [label]="{ horizontalAlignment: 'right', font: { color: crosshairPointColor } }"
                        [value]="detailPointStorage.point0['argument']"
                      >
                      </dxi-constant-line>
                    </ng-container>
                    <ng-container *ngIf="detailPointStorage.point1 !== null">
                      <dxi-constant-line
                        [color]="crosshairPointColor"
                        [label]="{ horizontalAlignment: 'left', font: { color: crosshairPointColor } }"
                        [value]="detailPointStorage.point1['argument']"
                      >
                      </dxi-constant-line>
                    </ng-container>

                    <dxo-label [customizeText]="customizeTextArgumentAxisLabel" [font]="{ size: 11 }" [indentFromAxis]="6"></dxo-label>
                  </dxo-argument-axis>

                  <dxo-value-axis
                    [label]="{ visible: false }"
                    [minorTickCount]="4"
                    [tickInterval]="this.zoomRange.tick"
                    [visualRange]="{
                      startValue: zoomRange.bottom,
                      endValue: zoomRange.top
                    }"
                  >
                    <dxi-constant-line [color]="'grey'" [displayBehindSeries]="true" [label]="{ visible: false }" [value]="3">
                    </dxi-constant-line>
                    <dxi-constant-line [color]="'grey'" [displayBehindSeries]="true" [label]="{ visible: false }" [value]="4">
                    </dxi-constant-line>

                    <ng-container *ngIf="detailPointStorage.point0 !== null">
                      <dxi-constant-line
                        [color]="crosshairPointColor"
                        [label]="{ verticalAlignment: 'top', font: { color: crosshairPointColor } }"
                        [value]="detailPointStorage.point0['value']"
                      ></dxi-constant-line>
                    </ng-container>
                    <ng-container *ngIf="detailPointStorage.point1 !== null">
                      <dxi-constant-line
                        [color]="crosshairPointColor"
                        [label]="{ verticalAlignment: 'bottom', font: { color: crosshairPointColor } }"
                        [value]="detailPointStorage.point1['value']"
                      ></dxi-constant-line>
                    </ng-container>

                    <dxo-label [enabled]="false" [visible]="false"></dxo-label>
                  </dxo-value-axis>

                  <dxo-legend [visible]="false"></dxo-legend>
                  <dxo-crosshair [enabled]="true">
                    <dxo-horizontal-line>
                      <dxo-label [customizeText]="customizeHorizontalCrosshairLabel"></dxo-label>
                    </dxo-horizontal-line>
                    <dxo-label [visible]="true">
                      <dxo-format [precision]="2" type="fixedPoint"></dxo-format>
                    </dxo-label>
                  </dxo-crosshair>

                  <!--series-->

                  <dxo-common-series-settings type="line">
                    <dxo-point [size]="0"></dxo-point>
                    <dxo-hover-style [width]="1"></dxo-hover-style>
                  </dxo-common-series-settings>

                  <dxi-series [color]="chartColor" [width]="1" argumentField="id" valueField="value"></dxi-series>

                  <dxi-series argumentField="id" color="black" valueField="qrs">
                    <dxo-point
                      [hoverStyle]="{ size: 2 }"
                      [selectionStyle]="{ size: 1 }"
                      [size]="10"
                      [symbol]="'triangleUp'"
                      [visible]="true"
                    >
                    </dxo-point>
                  </dxi-series>
                </dx-chart>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="checkDetailMode(1)">
            <div>
              <div class="devextreme-grid-height">
                <dx-range-selector
                  #testSelector
                  (onValueChanged)="triggerRangeEmission(); setRangeValuesByArguments()"
                  [(value)]="selectedRangeArguments"
                  [dataSource]="chartData"
                  [scale]="scaleOptions"
                  [shutter]="shutterOptions"
                  id="testSelector"
                >
                  <dxo-margin [left]="0" [right]="0"></dxo-margin>

                  <dxo-scale
                    [endValue]="visualRange.endValue"
                    [startValue]="visualRange.startValue"
                    [minorTick]="minorTicks"
                    [tick]="majorTicks"
                    [minorTickInterval]="intervals.minorTickInterval"
                    [tickInterval]="intervals.tickInterval"
                  >
                    <dxo-label [customizeText]="customizeTextArgumentAxisLabel"></dxo-label>
                  </dxo-scale>

                  <dxo-slider-marker [customizeText]="customizeTextSliderMarker">
                    <dxo-format [precision]="4" type="fixedPoint"></dxo-format>
                  </dxo-slider-marker>

                  <dxo-background>
                    <dxo-image location="full" url="./assets/resources/RangeImage.png"></dxo-image>
                  </dxo-background>

                  <dxo-size [height]="250"></dxo-size>
                  <dxo-behavior [animationEnabled]="false" [snapToTicks]="false" valueChangeMode="onHandleMove"></dxo-behavior>

                  <dxo-chart>
                    <dxo-value-axis [max]="refineMaxValue(zoomRange.top)" [min]="zoomRange.bottom"> </dxo-value-axis>
                    <dxo-argument-axis>
                      <dxo-label [customizeText]="customizeTextArgumentAxisLabel"></dxo-label>
                    </dxo-argument-axis>

                    <dxo-common-series-settings type="line">
                      <dxo-point [visible]="false"></dxo-point>
                    </dxo-common-series-settings>

                    <dxo-legend [visible]="false"></dxo-legend>

                    <dxi-series [color]="chartColor" argumentField="id" valueField="value" width="1px"></dxi-series>
                    <dxi-series argumentField="id" color="black" valueField="qrs">
                      <dxo-point [size]="10" [symbol]="'triangleUp'" [visible]="true"></dxo-point>
                    </dxi-series>

                    <dxi-series [width]="1" argumentField="id" color="darkgrey" valueField="bottomLine"></dxi-series>
                    <dxi-series [width]="1" argumentField="id" color="darkgrey" valueField="topLine"></dxi-series>
                  </dxo-chart>
                </dx-range-selector>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="measurement-area-wrapper">
      <ng-container *ngIf="checkDetailMode(0); else elseBlock">
        <div class="height-100-percent">
          <div class="measurement-table-wrapper">
            <table>
              <tr>
                <td class="measurement-table-cell"></td>
                <td class="measurement-table-cell">{{ 'Seconds' | transloco }}</td>
                <td class="measurement-table-cell">{{ 'mV' | transloco }}</td>
                <td></td>
              </tr>
              <ng-container *ngIf="detailPointStorage.point0.argument === null; else elseRowPointZero">
                <tr class="measurement-row-height">
                  <td></td>
                  <td class="measurement-table-cell" colspan="2">
                    {{ 'Choose point' | transloco }}
                  </td>
                </tr>
              </ng-container>
              <ng-template #elseRowPointZero>
                <tr class="measurement-row-height">
                  <td class="measurement-table-cell">1.</td>
                  <td class="measurement-table-cell">
                    <div *ngIf="detailPointStorage.point0 !== null && detailPointStorage.point0.argument !== null">
                      {{ detailPointStorage.point0.argument / 10000 }}
                    </div>
                  </td>
                  <td class="measurement-table-cell">
                    <div *ngIf="detailPointStorage.point0 !== null && detailPointStorage.point0.value">
                      {{ detailPointStorage.point0.value }}
                    </div>
                  </td>
                  <td>
                    <div *ngIf="detailPointStorage.point0.argument !== null">
                      <dx-button (click)="triggerPointEmission(detailPointStorage.point0.argument)" icon="trash"></dx-button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-container *ngIf="detailPointStorage.point0.argument !== null || detailPointStorage.point1.argument !== null">
                <ng-container *ngIf="detailPointStorage.point1.argument === null; else elseRowPointOne">
                  <tr class="measurement-row-height">
                    <td></td>
                    <td class="measurement-table-cell" colspan="2">
                      {{ 'Choose point' | transloco }}
                    </td>
                  </tr>
                </ng-container>
                <ng-template #elseRowPointOne>
                  <tr class="measurement-row-height">
                    <td class="measurement-table-cell">2.</td>
                    <td class="measurement-table-cell">
                      <div *ngIf="detailPointStorage.point1 !== null && detailPointStorage.point1.argument">
                        {{ detailPointStorage.point1.argument / 10000 }}
                      </div>
                    </td>
                    <td class="measurement-table-cell">
                      <div *ngIf="detailPointStorage.point1 !== null && detailPointStorage.point1.value">
                        {{ detailPointStorage.point1.value }}
                      </div>
                    </td>
                    <td>
                      <div *ngIf="detailPointStorage.point1.argument !== null">
                        <dx-button (click)="triggerPointEmission(detailPointStorage.point1.argument)" icon="trash"></dx-button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="detailPointStorage.point0.argument !== null && detailPointStorage.point1.argument">
                <tr>
                  <td class="measurement-table-cell"><em></em></td>
                  <td class="measurement-table-cell measurement-difference-cell measurement-background-color-grey">
                    <div
                      *ngIf="
                        detailPointStorage.point0 !== null &&
                        detailPointStorage.point1 !== null &&
                        detailPointStorage.point1.argument !== null &&
                        detailPointStorage.point0.argument !== null
                      "
                    >
                      {{ ((detailPointStorage.point1.argument - detailPointStorage.point0.argument) / 10000).toFixed(2) }}
                    </div>
                  </td>
                  <td class="measurement-table-cell measurement-difference-cell measurement-background-color-grey">
                    <div
                      *ngIf="
                        detailPointStorage.point0 !== null &&
                        detailPointStorage.point1 !== null &&
                        detailPointStorage.point1.value !== null &&
                        detailPointStorage.point0.value !== null
                      "
                    >
                      {{ (detailPointStorage.point1.value - detailPointStorage.point0.value).toFixed(2) }}
                    </div>
                  </td>
                  <td></td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </ng-container>

      <ng-template #elseBlock>
        <div class="height-100-percent">
          <div class="measurement-table-wrapper">
            <table>
              <tr>
                <td class="measurement-table-cell"></td>
                <td class="measurement-table-cell">{{ 'Seconds' | transloco }}</td>
                <td class="measurement-table-cell">{{ 'mV' | transloco }}</td>
              </tr>
              <tr>
                <td class="measurement-table-cell">1.</td>
                <td class="measurement-table-cell">
                  <div>{{ (selectedRangeArguments.startValue / 10000).toFixed(2) }}</div>
                </td>
                <td class="measurement-table-cell">
                  <div>{{ selectedRangeValues.startValue }}</div>
                </td>
              </tr>
              <tr>
                <td class="measurement-table-cell">2.</td>
                <td class="measurement-table-cell">
                  <div>{{ (selectedRangeArguments.endValue / 10000).toFixed(2) }}</div>
                </td>
                <td class="measurement-table-cell">
                  <div>{{ selectedRangeValues.endValue }}</div>
                </td>
              </tr>
              <tr>
                <td class="measurement-table-cell"><em></em></td>
                <td class="measurement-table-cell measurement-difference-cell measurement-background-color-grey">
                  <div>{{ ((selectedRangeArguments.endValue - selectedRangeArguments.startValue) / 10000).toFixed(2) }}</div>
                </td>
                <td class="measurement-table-cell measurement-difference-cell measurement-background-color-grey">
                  <div>{{ (selectedRangeValues.endValue - selectedRangeValues.startValue).toFixed(2) }}</div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
