import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreclinicComponent } from './preclinic/preclinic.component';
import { CoreModule } from '@nida-web/core';
import { TranslocoModule } from '@ngneat/transloco';
import { PatientComponent } from './patient/patient.component';
import { PatientManagementModule } from '@nida-web/patient-management';
import { EmergencyRoomVitalSignsComponent } from './shared-components/emergency-room-vital-signs/emergency-room-vital-signs.component';
import { AnamnesisComponent } from './anamnesis/anamnesis.component';
import { ErTherapyComponent } from './er-therapy/er-therapy.component';
import { ErTherapyAbcdeComponent } from './shared-components/er-therapy-abcde/er-therapy-abcde.component';
import { InjuryLocalisationTableComponent } from './shared-components/injury-localisation-table/injury-localisation-table.component';
import { EmergencyRoomManagementRouterModule } from './emergency-room-management-router.module';
import { ERCloseComponent } from './shared-components/erclose/erclose.component';
import { NavigationModule } from '@nida-web/navigation';
import { EmergencyRoomRootComponent } from './emergency-room-root/emergency-room-root.component';
import { DiagnosisComponent, KeysPipeComponent } from './diagnosis/diagnosis.component';
import { FinalReportComponent } from './final-report/final-report.component';
import { TeamComponent } from './team/team.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CouncilComponent } from './council/council.component';
import { EmergencyRoomHeaderComponent } from './shared-components/emergency-room-header/emergency-room-header.component';
import { StartOfSymptomsComponent } from './shared-components/start-of-symptoms/start-of-symptoms.component';
import { ErTitleToolbarComponent } from './shared-components/er-title-toolbar/er-title-toolbar.component';
import {
  DxButtonGroupModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxNumberBoxModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxSliderModule,
  DxSwitchModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
  DxValidatorModule,
} from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    TranslocoModule,
    PatientManagementModule,
    NavigationModule,
    EmergencyRoomManagementRouterModule,
    ReactiveFormsModule,
    DxDataGridModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxSelectBoxModule,
    DxButtonGroupModule,
    DxDateBoxModule,
    DxRadioGroupModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxSliderModule,
    DxTooltipModule,
    DxSwitchModule,
    DxValidatorModule,
  ],
  declarations: [
    PreclinicComponent,
    PatientComponent,
    EmergencyRoomVitalSignsComponent,
    AnamnesisComponent,
    ErTherapyComponent,
    ErTherapyAbcdeComponent,
    InjuryLocalisationTableComponent,
    ERCloseComponent,
    EmergencyRoomRootComponent,
    DiagnosisComponent,
    FinalReportComponent,
    TeamComponent,
    EmergencyRoomHeaderComponent,
    CouncilComponent,
    StartOfSymptomsComponent,
    ErTitleToolbarComponent,
    KeysPipeComponent,
  ],
  exports: [PreclinicComponent],
})
export class EmergencyRoomManagementModule {}
