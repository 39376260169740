/**
 * NIDA Attachments Rest-Api
 * API Beschreibung für die NIDA Tabellen.
 *
 * OpenAPI spec version: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { ApiPDFItemList } from '../models/api-pdf-item-list';
import { ApiPDFItem } from '../models/api-pdf-item';
import { ApiPDFStatusList } from '../models/api-pdf-status-list';
import { ApiPDFStatus } from '../models/api-pdf-status';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  protected basePath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}attachments`;
    });
  }

  /**
   * Get all PDFs correspondending to the nidaId
   * Get all PDFs correspondending to the nidaId
   * @param nidaId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPDFListByNIDAID(nidaId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiPDFItemList>;
  public getPDFListByNIDAID(nidaId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiPDFItemList>>;
  public getPDFListByNIDAID(nidaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiPDFItemList>>;
  public getPDFListByNIDAID(nidaId: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getPDFListByNIDAID.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<ApiPDFItemList>('get', `${this.basePath}/pdfs/${encodeURIComponent(String(nidaId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all PDFs States correspondending to the nidaId
   * Get all PDFs correspondending to the nidaId
   * @param nidaId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPDFStatusListByNIDAID(nidaId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiPDFStatusList>;
  public getPDFStatusListByNIDAID(
    nidaId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ApiPDFStatusList>>;
  public getPDFStatusListByNIDAID(nidaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiPDFStatusList>>;
  public getPDFStatusListByNIDAID(nidaId: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling getPDFStatusListByNIDAID.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<ApiPDFStatusList>('get', `${this.basePath}/pdfs/${encodeURIComponent(String(nidaId))}/states`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * marks a PDF as read
   * marks a PDF as read
   * @param body PDF Status Object
   * @param nidaId
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchPDFStatus(body: ApiPDFStatus, nidaId: string, id: number, observe?: 'body', reportProgress?: boolean): Observable<ApiPDFItem>;
  public patchPDFStatus(
    body: ApiPDFStatus,
    nidaId: string,
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ApiPDFItem>>;
  public patchPDFStatus(
    body: ApiPDFStatus,
    nidaId: string,
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ApiPDFItem>>;
  public patchPDFStatus(body: ApiPDFStatus, nidaId: string, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchPDFStatus.');
    }

    if (nidaId === null || nidaId === undefined) {
      throw new Error('Required parameter nidaId was null or undefined when calling patchPDFStatus.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling patchPDFStatus.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ApiPDFItem>(
      'patch',
      `${this.basePath}/pdfs/${encodeURIComponent(String(nidaId))}/states/${encodeURIComponent(String(id))}`,
      {
        body,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }
}
