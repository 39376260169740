/**
 * NIDAserver Password API
 * API for administrat users Password
 *
 * OpenAPI spec version: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Password } from '../models/password';
import { PasswordStatus } from '../models/passwordStatus';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.basePath = apiUrl + 'password3';
    });
  }

  /**
   * Reset the mobile password of a user.
   *
   * @param body
   * @param id The id of the current user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public changeUserMobilePassword(body: Password, id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public changeUserMobilePassword(
    body: Password,
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public changeUserMobilePassword(body: Password, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public changeUserMobilePassword(body: Password, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling changeUserMobilePassword.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling changeUserMobilePassword.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/reset/mobile/${encodeURIComponent(String(id))}`, {
      body: body,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Change the password of an user
   *
   * @param body
   * @param id The id of the current user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public changeUserPassword(body: Password, id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public changeUserPassword(body: Password, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public changeUserPassword(body: Password, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public changeUserPassword(body: Password, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling changeUserPassword.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling changeUserPassword.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/change/${encodeURIComponent(String(id))}`, {
      body: body,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get the status of the mobile password of a user.
   *
   * @param id The id of the current user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public statusUserMobilePassword(id: number, observe?: 'body', reportProgress?: boolean): Observable<PasswordStatus>;
  public statusUserMobilePassword(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PasswordStatus>>;
  public statusUserMobilePassword(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PasswordStatus>>;
  public statusUserMobilePassword(id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling statusUserMobilePassword.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<PasswordStatus>('get', `${this.basePath}/status/mobile/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
