import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ItemStatus, PDFElement } from '@nida-web/api/generic-interfaces/attachments';
import { DxPopupComponent } from 'devextreme-angular';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { DataConverterService } from '@nida-web/shared/utils';
import { map, mergeMap, Observable } from 'rxjs';
import { ApiPDFStatus, AttachmentsService, PDFItemMappingService } from '@nida-web/api/rest/attachments';

@Component({
  selector: 'nida-web-pdf-list',
  templateUrl: './pdf-list.component.html',
  styleUrls: ['./pdf-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PDFListComponent implements OnInit {
  @Input() public pageSize: number;
  @Input() public disableDescriptionColumn: boolean;
  @Input() public nidaId: string;
  @Input() public download: boolean;

  @ViewChild('pdfPopUp') pdfPopUp: DxPopupComponent;
  @ViewChild('pdfViewer') public pdfViewer;

  public pdfList: PDFElement[];
  public currentBlob: Blob;
  public currentBlobSize: number;
  public downloadFileName: string;

  constructor(
    private attachmentsService: AttachmentsService,
    private pdfItemMappingService: PDFItemMappingService,
    protected sessionManagerService: SessionManagerService,
    private dataConverterService: DataConverterService
  ) {}

  ngOnInit(nidaId?: string) {
    this.getData(nidaId);
    this.downloadFileName = 'document.pdf';
  }

  getData(nidaId?: string) {
    if (nidaId) {
      this.nidaId = nidaId;
    }

    if (this.nidaId) {
      this.attachmentsService
        .getPDFStatusListByNIDAID(this.nidaId)
        .pipe(
          map((result) => this.pdfItemMappingService.mapEntries(result)),
          mergeMap((list1) => this.filterProtocolByPermission(list1)),
          mergeMap((list2) => this.filterTraumaRegisterByPermission(list2)),
          mergeMap((list3) => this.filterProtocolCompleteByPermission(list3))
        )
        .subscribe((resultList) => (this.pdfList = resultList));
    }
  }

  filterProtocolByPermission(list: PDFElement[]): Observable<PDFElement[]> {
    return this.sessionManagerService.hasPermission(['nida.pdfprotokoll', 'klinik.protokoll', 'nida.divi.protokoll']).pipe(
      map((hasPermission) => {
        return !hasPermission ? list.filter((pdf) => !(pdf.description === 'Protocol')) : list;
      })
    );
  }

  filterTraumaRegisterByPermission(list: PDFElement[]): Observable<PDFElement[]> {
    return this.sessionManagerService.hasPermission(['nida.pdf.traumaregister']).pipe(
      map((hasPermission) => {
        return !hasPermission ? list.filter((pdf) => !(pdf.description === 'Trauma register')) : list;
      })
    );
  }

  filterProtocolCompleteByPermission(list: PDFElement[]): Observable<PDFElement[]> {
    return this.sessionManagerService.hasPermission(['nida.pdf.protokoll.vollstaendig', 'nida.divi.protokoll']).pipe(
      map((hasPermission) => {
        return !hasPermission ? list.filter((pdf) => !(pdf.description === 'Protocol complete')) : list;
      })
    );
  }

  renamePdfByPermission(): void {
    // test if user has kvb permission
    this.sessionManagerService.hasPermission(['nida.divi.protokoll']).subscribe((result) => {
      if (result) {
        this.pdfList = this.pdfList.map((pdf) => {
          if (pdf.description === 'Protocol') {
            return { ...pdf, description: 'Protocol NIDAmobile' };
          } else {
            return pdf;
          }
        });
      }
    });
  }

  onRowClick($event) {
    const eventData: PDFElement = $event.data;

    if (eventData.nidaId && eventData.id) {
      const pdfStatus: ApiPDFStatus = {
        printStatus: ItemStatus.READ,
      };

      this.attachmentsService.patchPDFStatus(pdfStatus, eventData.nidaId, eventData.id).subscribe((pdf) => {
        this.currentBlob = pdf.pdfBlob ? this.dataConverterService.b64ToBlob(pdf.pdfBlob) : new Blob();

        if (!eventData.name) {
          const timeStamp = eventData.timeStamp ? new Date(eventData.timeStamp) : undefined;
          this.downloadFileName = this.generateDownloadFileName('DIVI_Protocol', timeStamp);
        } else {
          this.downloadFileName = eventData.name;
        }

        this.pdfPopUp.title = this.downloadFileName;
        this.pdfPopUp.instance.show();

        this.pdfList.map((pdfListItem) => {
          if (pdfListItem.id === pdf.id) {
            pdfListItem.status = 'Read';
          }
          return pdfListItem;
        });
      });
    }
  }

  refreshPDF() {
    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = this.currentBlob;
      this.pdfViewer.refresh();
    }
  }

  clearPDF() {
    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = null;
      this.pdfViewer.refresh();
    }
  }

  generateDownloadFileName(fileName: string, date?: Date): string {
    let name = fileName;
    if (date) {
      name = this.formatDate(date) + '_' + fileName;
    }
    return name;
  }

  /**
   * returns Date in format yyyy-mm-dd
   * @param date
   */
  formatDate(date: Date): string {
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);
    return newDate.toISOString().split('T')[0];
  }
}
