<div class="p-2">
  <nida-web-er-title-toolbar
    [disabled]="!(!vitalSignsForm.pristine || !preclinicForm.pristine)"
    [title]="'Preclinic'"
    (saveTrigger)="saveAll()"
    (revertTrigger)="resetAll()"
  ></nida-web-er-title-toolbar>
</div>
<div *ngIf="this.preclinicForm">
  <div class="mt-3">
    <form [formGroup]="preclinicForm">
      <div class="">
        <h3 class="d-inline mr-2 ml-2">
          <span style="color: #333">{{ 'Preclinic' | transloco }}</span> — {{ 'Start of symptoms' | transloco }}
        </h3>

        <dx-date-box
          (click)="autoFillDateOnFieldClick('onsetOfSymptoms')"
          class="d-inline-block"
          [readOnly]="false"
          formControlName="onsetOfSymptoms"
          type="datetime"
        >
        </dx-date-box>
      </div>
    </form>

    <nida-web-start-of-symptoms [preclinicFormGroup]="preclinicForm"></nida-web-start-of-symptoms>

    <div class="mb-2 mt-3">
      <h3 class="d-inline ml-2">
        <span style="color: #333">{{ 'Preclinic' | transloco }}</span> — {{ 'First' | transloco }} {{ 'Vitalsigns' | transloco }}
      </h3>
    </div>
    <div>
      <nida-web-emergency-room-vital-signs
        [vitalSigns]="preclinicalVitals"
        [formGroup]="vitalSignsForm"
      ></nida-web-emergency-room-vital-signs>
    </div>

    <div class="mb-2 mt-3">
      <h3 class="d-inline ml-2">
        <span style="color: #333">{{ 'Preclinic' | transloco }}</span> — {{ 'Diagnosis' | transloco }}
      </h3>
    </div>
    <div>
      <div *ngIf="preclinicalPatientinfo" class="row mx-0 p-0 percent-100-height">
        <div class="col-md-6 col-xl-5 basic-card-wrapper">
          <div class="basic-card-style diagnosis-tile">
            <div class="percent-100-height">
              <div class="basic-card-title basic-padding">
                {{ 'Localisation' | transloco }}
              </div>
              <div class="basic-padding">
                <nida-web-injury-localisation-table [preclinicForm]="this.preclinicForm"></nida-web-injury-localisation-table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-5 basic-card-wrapper">
          <form [formGroup]="preclinicForm">
            <div class="row">
              <div class="col-12 basic-padding-bottom">
                <div class="basic-card-style diagnosis-tile">
                  <div class="percent-100-height">
                    <div class="basic-card-title basic-padding">
                      {{ 'NACA-Score' | transloco }}
                    </div>
                    <div class="basic-padding">
                      <dx-button-group
                        [items]="nacaItems"
                        [selectedItems]="
                          this.preclinicForm.getRawValue().nacaScore !== undefined
                            ? [nacaItems[this.preclinicForm.getRawValue().nacaScore]]
                            : []
                        "
                        (onItemClick)="onBtnGroupChange($event)"
                      >
                      </dx-button-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 basic-padding-bottom">
                <div class="basic-card-style diagnosis-tile">
                  <div class="percent-100-height">
                    <div class="basic-card-title basic-padding">
                      {{ 'Other diagnosis' | transloco }}
                    </div>
                    <div class="basic-padding">
                      <div class="row basic-padding-bottom">
                        <div class="col-md-6 justify-content-center align-self-center">{{ 'Ultrasound' | transloco }}</div>
                        <div class="col-md-6 text-md-right">
                          <dx-text-box [readOnly]="false" formControlName="ultrasonic"></dx-text-box>
                        </div>
                      </div>
                      <div class="row basic-padding-bottom">
                        <div class="col-md-6 justify-content-center align-self-center">{{ 'ECG' | transloco }}</div>
                        <div class="col-md-6 text-md-right">
                          <dx-text-box [readOnly]="false" [value]="false"></dx-text-box>
                        </div>
                      </div>
                      <div class="row basic-padding-bottom">
                        <div class="col-md-6 justify-content-center align-self-center">{{ 'Pacer' | transloco }}</div>
                        <div class="col-md-6 text-md-right">
                          <dx-text-box [readOnly]="false" [value]="false"></dx-text-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="basic-card-style diagnosis-tile">
                  <div class="percent-100-height">
                    <div class="basic-card-title basic-padding">
                      {{ 'Comment' | transloco }}
                    </div>
                    <div class="basic-padding">
                      <dx-text-area [readOnly]="false" [height]="100" formControlName="comment"></dx-text-area>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="mb-2 mt-3">
      <h3 class="d-inline ml-2">
        <span style="color: #333">{{ 'Preclinic' | transloco }}</span> — {{ 'Therapy' | transloco }}
      </h3>
    </div>
    <div>
      <nida-web-er-therapy-abcde
        [abcdeForm]="preclinicForm"
        [medicalAccessList]="medicalAccessList"
        (saveAccess)="saveAccess($event)"
        (updateAccess)="updateAccess($event)"
        (deleteAccess)="deleteAccess($event)"
      >
      </nida-web-er-therapy-abcde>
    </div>
  </div>
</div>
