import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NavItem, ScreenService } from '@nida-web/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs';
import { Endpoint, PermissionsHelper, SessionManagerService } from '@nida-web/api/rest/authentication';
import { NavigationStore } from '../models/navigation-store.model';
import { takeUntil } from 'rxjs/operators';
import { Badge } from '../models/badge.model';
import { NavigationStoreStatus } from '../models/navigation-store-status.enum';
import { BadgeCount } from '../models/badge-count.model';
import { AvailableEndpointsStoreService } from '@nida-web/shared/feature';

export const NAV_CONFIG = new InjectionToken<NavItem[]>('navigation-items');

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly navigationConfiguration: NavItem[];
  private onNewNavigationRegistered: Subject<string> = new Subject<string>();
  private navigationRegistry: Map<string, NavigationStore> = new Map<string, NavigationStore>();

  public currentBadgeCount$ = new ReplaySubject<BadgeCount[]>(1);
  private badgeCount: BadgeCount[] = [];

  constructor(
    @Inject(NAV_CONFIG) navigationConfiguration: NavItem[],
    private translocoService: TranslocoService,
    private screen: ScreenService,
    private sessionManagerService: SessionManagerService,
    private availableEndpointsStoreService: AvailableEndpointsStoreService
  ) {
    this.navigationConfiguration = navigationConfiguration;
  }

  /*
  ===========
  New Navigation BEGINNING
  ===========
   */

  /**
   * Registers a new navigation
   * Updates the navigation items on language change or updated permissions of the user
   * @param name
   * @param navItems
   */
  public registerNewNavigation(name: string, navItems: NavItem[]): NavigationStore {
    const badgeItems$ = new BehaviorSubject<Badge[]>([]);
    const status$ = new BehaviorSubject<NavigationStoreStatus>(NavigationStoreStatus.INITIALIZING);
    const navItems$: Observable<NavItem[]> = this.sessionManagerService
      .getUserPermissionsIfLoggedIn()
      .pipe(
        map((userPermissions) => this.checkPermissionOfNavItems(navItems, userPermissions)),
        distinctUntilChanged(),
        map(() => this.replaceSingleSubmenuItems(navItems)),
        switchMap((navItemsWithNoSingleSubMenuItems) =>
          this.waitForLanguageChange().pipe(
            map(() => {
              // We need to parse it to JSON and back, in order to remove the object reference
              // If we don't to this, then on a translation change, we would translate the translated text
              return this.translateNavItems(JSON.parse(JSON.stringify(navItemsWithNoSingleSubMenuItems)));
            })
          )
        ),
        // Adds badge information to the nav items
        switchMap((navigationItems) =>
          badgeItems$.pipe(
            // We need to parse it to JSON and back, in order to remove the object reference
            map((badgeItems) => this.mergeBadgeInformationWithNavItems(JSON.parse(JSON.stringify(navigationItems)), badgeItems))
          )
        ),

        // Turn subject into a ReplaySubject, so new subscribers always get the current navigation and updates
        // The bufferSize of 1 is essential. If we don't provide any value,
        // then all values from the past will be emitted on the initial subscription
        shareReplay(1),

        takeUntil(this.sessionManagerService.onLoggedOut()),
        tap({
          // Complete all observables and the navigation registry, if the user logs out
          complete: () => {
            status$.next(NavigationStoreStatus.DESTROYED);
            status$.complete();
            badgeItems$.complete();
            this.navigationRegistry.delete(name);
          },
        })
      )
      .pipe(tap({ next: () => status$.next(NavigationStoreStatus.LOADED) }));

    const navigationStore: NavigationStore = {
      // NavigationStoreStatus.LOADED is called every time navItems$ emits next. So we skip the same values.
      status$: status$.pipe(distinctUntilChanged()),
      navItems$,
      badgeItems$,
      badgeStore: new Map<string, Badge>(),
    };
    this.navigationRegistry.set(name, navigationStore);

    this.onNewNavigationRegistered.next(name);

    navItems$.subscribe();

    return navigationStore;
  }

  /**
   * Emits a value, when the navigation with the given name is fully loaded
   * @param name
   */
  public onNavigationLoaded(name: string): Observable<NavigationStore> {
    const lookupInNavigationRegistry: NavigationStore | undefined = this.navigationRegistry.get(name);
    if (lookupInNavigationRegistry) {
      return lookupInNavigationRegistry.status$.pipe(
        takeWhile((status) => status === NavigationStoreStatus.LOADED),
        take(1),
        map(() => {
          return lookupInNavigationRegistry;
        })
      );
    } else {
      return this.onNewNavigationRegistered.pipe(
        takeWhile((newNavigationName) => newNavigationName === name),
        take(1),
        switchMap(() => {
          const lookupInNavigationRegistry = this.navigationRegistry.get(name);
          if (!lookupInNavigationRegistry)
            throw new Error(`New navigation (${name}) was created but could not be found in navigationRegistry`);

          return lookupInNavigationRegistry.status$.pipe(
            takeWhile((status) => status === NavigationStoreStatus.LOADED),
            take(1),
            map(() => {
              return lookupInNavigationRegistry;
            })
          );
        })
      );
    }
  }

  private mergeBadgeInformationWithNavItems(navigationItems: NavItem[], badges: Badge[]): NavItem[] {
    if (navigationItems.length === 0 || badges.length === 0) return navigationItems;

    navigationItems.forEach((navItem) => {
      const badgeFound = badges.find((badge) => badge.navItemId === navItem.id);
      if (badgeFound) {
        navItem.badge = badgeFound.number;
      } else if (navItem.items && navItem.items.length > 0) {
        this.mergeBadgeInformationWithNavItems(navItem.items, badges);
      }
    });

    return navigationItems;
  }

  /**
   * Creates a new badge if no badge exists
   * @param navigationName
   * @param navItemId
   * @param number
   */
  public updateBadge(navigationName: string, navItemId: string, number: number): void {
    const navigation = this.navigationRegistry.get(navigationName);
    if (!navigation) {
      console.warn(`updateBadge - Navigation with the name ${navigationName} could not be found.`);
      return;
    }

    const badge = navigation.badgeStore.get(navItemId);
    if (!badge) {
      navigation.badgeStore.set(navItemId, {
        number,
        navItemId,
      });
      navigation.badgeItems$.next([...navigation.badgeStore.values()]);
      return;
    } else {
      badge.number = number;
      navigation.badgeItems$.next([...navigation.badgeStore.values()]);
    }
  }

  public removeBadge(navigationName: string, navItemId: string): void {
    const navigation = this.navigationRegistry.get(navigationName);
    if (!navigation) {
      console.warn(`updateBadge - Navigation with the name ${navigationName} could not be found.`);
      return;
    }

    const badge = navigation.badgeStore.get(navItemId);
    if (!badge) {
      return;
    } else {
      navigation.badgeStore.delete(navItemId);
      navigation.badgeItems$.next([...navigation.badgeStore.values()]);
    }
  }

  /*
  ===========
  New Navigation END
  ===========
   */

  /**
   * All navigation items will be translated
   * Every child items array will be sorted asc (expect for the top level)
   */
  getNavigationItems(navItems: NavItem[]): Observable<NavItem[]> {
    return combineLatest([
      this.sessionManagerService.getUserPermissionsIfLoggedIn(),
      this.availableEndpointsStoreService.getEndpoints(),
    ]).pipe(
      map(([userPermissions, endpoints]) => {
        this.checkPermissionOfNavItems(navItems, userPermissions);
        this.checkEndpointsOfNavItems(navItems, endpoints);
        return navItems;
      }),
      distinctUntilChanged(),
      switchMap((navItems) =>
        this.sessionManagerService.getUserPermissionsIfLoggedIn().pipe(
          distinctUntilChanged(),

          map(() => navItems)
        )
      ),
      switchMap((navItems) =>
        this.waitForLanguageChange().pipe(
          map(() => {
            return JSON.parse(JSON.stringify(navItems));
          })
        )
      ),
      map((navItems) => {
        const navItemsWithNoSingleSubMenuItems = this.replaceSingleSubmenuItems(navItems);
        return this.translateNavItems(navItemsWithNoSingleSubMenuItems);
      })
    );
  }

  public setBadgeCount(path: string, count: number) {
    let i = 0;
    this.badgeCount.forEach((item) => {
      if (item.path === path) {
        this.badgeCount.splice(i, 1);
      }
      i++;
    });

    this.badgeCount.push({ path: path, count: count });

    this.currentBadgeCount$.next(this.badgeCount);
  }

  public getRawDefaultNavigationItems(): NavItem[] {
    return JSON.parse(JSON.stringify(this.navigationConfiguration));
  }

  /**
   * Emits only when the active language is changed
   * @private
   */
  private waitForLanguageChange(): Observable<string> {
    return this.translocoService.langChanges$.pipe(distinctUntilChanged());
  }

  /**
   * Replaces parent element with the child, if the parent has only one child element.
   * So that there are no more sub menus with only one child.
   * @private
   */
  private replaceSingleSubmenuItems(navItems: NavItem[]) {
    const flatMenu: NavItem[] = [];

    navItems.forEach((item) => {
      if (item.items && item.items.length === 1) {
        const newItem = { ...item.items };
        flatMenu.push(newItem[0]);

        // Nav items that have an empty items array will be removed
      } else if ((item.items && item.items.length > 1) || !item.items) {
        flatMenu.push(item);
      }
    });

    return flatMenu;
  }

  /**
   * Removes nav items if the user has no permission
   * @param navItems
   * @param userPermissions
   * @private
   */
  private checkPermissionOfNavItems(navItems: NavItem[], userPermissions: string[]): NavItem[] {
    if (navItems.length < 1) return [];

    for (let i = navItems.length - 1; i >= 0; i--) {
      if (navItems[i].items) {
        this.checkPermissionOfNavItems(navItems[i].items as NavItem[], userPermissions);
      }
      if (navItems[i].permissions) {
        const shouldNotHave: string[] = [];

        for (const singlePerm of navItems[i].permissions as string[]) {
          if (singlePerm.startsWith('!')) {
            shouldNotHave.push(singlePerm.split('!')[1]);
          }
        }
        if (
          !PermissionsHelper.isAllowedAccess(userPermissions, navItems[i].permissions as string[]) ||
          PermissionsHelper.isAllowedAccess(userPermissions, shouldNotHave)
        )
          navItems.splice(i, 1);
      }
    }

    return navItems;
  }

  /**
   * Removes nav items if the endpoints are not available
   * @param navItems
   * @param endpointsPaths
   * @private
   */
  public checkEndpointsOfNavItems(navItems: NavItem[], endpointsPaths: Endpoint[]): NavItem[] {
    // flatten path array
    const endpointsPathsList = endpointsPaths.map((a) => a.path);

    for (let i = navItems.length - 1; i >= 0; i--) {
      if (navItems[i].items) {
        this.checkEndpointsOfNavItems(navItems[i].items as NavItem[], endpointsPaths);
      }
      if (navItems[i].endpoints) {
        const requiredEndpoints = navItems[i].endpoints as string[];
        let endpointMatches = false;
        requiredEndpoints.forEach((requiredEndpoint) => {
          if (endpointsPathsList.includes(requiredEndpoint)) {
            endpointMatches = true;
          }
        });

        if (!endpointMatches) {
          navItems.splice(i, 1);
        }
      }
    }
    return navItems;
  }

  private translateNavItems(navItems: NavItem[]) {
    navItems.forEach((item) => {
      item.text = this.translocoService.translate(item.text);
      if (item.items) this.translateAndSortChildItems(item.items, !item.notSortItemsAlphabetically);
    });
    return navItems;
  }

  private translateAndSortChildItems(items: NavItem[], sortItemsAlphabetically: boolean) {
    items.forEach((item) => {
      item.text = this.translocoService.translate(item.text);

      if (item.items) {
        this.translateAndSortChildItems(item.items, !item.notSortItemsAlphabetically);
      }
    });

    if (sortItemsAlphabetically) items = items.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
  }

  public getMenuBarSizeByScreenSize(): string {
    const screenSize = this.screen.screenSize;
    // small hack to hide sidebar when no user is logged in
    // if (!this.userNameExists) {
    //   screenSize = 'screen-x-small';
    // }
    let menuSize: string;
    switch (screenSize) {
      case 'screen-x-small': {
        menuSize = 'hidden';
        break;
      }
      case 'screen-small' || 'screen-medium': {
        menuSize = 'small';
        break;
      }
      default: {
        menuSize = 'wide';
      }
    }
    return menuSize;
  }
}
