export class TwoFactorQrCodeUriCreator {
  /**
   * Creates a URI for a QR code that can be scanned by a 2FA app.
   * @param secret The 2FA secret.
   * @param label the label for the App.
   * @param issuer The issuer of the QR code.
   */
  public static createTwoFactorUri(secret: string, label: string, issuer?: string): string {
    const base = 'otpauth://totp/';
    const encodedLabel = encodeURIComponent(label);
    const encodedSecret = encodeURIComponent(secret);
    const issuerParameter = issuer ? `&issuer=${encodeURIComponent(issuer)}` : '';

    return `${base}${encodedLabel}?secret=${encodedSecret}${issuerParameter}`;
  }
}
