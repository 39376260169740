import { Injectable } from '@angular/core';
import { ProtocolServable } from '@nida-web/api/generic-interfaces/protocol';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArchiveStoreService {
  private archivingDone: BehaviorSubject<boolean>;

  constructor(private protocolServable: ProtocolServable) {
    this.archivingDone = new BehaviorSubject(false);
  }

  public archivePatient(protocolId: number, archiveFlag: boolean): BehaviorSubject<boolean> {
    this.protocolServable.archiveProtocol(protocolId, archiveFlag).subscribe((archivingState) => {
      this.archivingDone.next(archivingState);
    });
    return this.archivingDone;
  }
}
