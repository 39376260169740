import { Injectable } from '@angular/core';
import {
  EmergencyRoomServable,
  ERAnamnesis,
  ERCouncil,
  ERCouncilList,
  ERDiagnostic,
  ERFinalReport,
  ERIcd10,
  ERIcd10List,
  ERMedicalAccess,
  ERMedicalAccessList,
  EROrder,
  EROrderList,
  ERPatient,
  ERPreclinic,
  ERTherapy,
  ERTreatmentMember,
  ERTreatmentMemberList,
  ERVitals,
} from '@nida-web/api/generic-interfaces/emergencyroom';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { AnamnesisService } from '../actions/anamnesis.service';
import { CouncilsService } from '../actions/councils.service';
import { DiagnosticService } from '../actions/diagnostic.service';
import { FinalReportService } from '../actions/finalReport.service';
import { ICD10Service } from '../actions/iCD10.service';
import { MedicalAccessService } from '../actions/medicalAccess.service';
import { OrdersService } from '../actions/orders.service';
import { PatientService } from '../actions/patient.service';
import { PreclinicService } from '../actions/preclinic.service';
import { TherapyService } from '../actions/therapy.service';
import { TreatmentMemberService } from '../actions/treatmentMember.service';
import { VitalsService } from '../actions/vitals.service';
import { Council } from '../models/council';
import { Icd10 } from '../models/icd10';
import { MedicalAccess } from '../models/medicalAccess';
import { Order } from '../models/order';
import { TreatmentMember } from '../models/treatmentMember';
import { Anamnesis } from '../models/anamnesis';
import { Diagnostic } from '../models/diagnostic';
import { FinalReport } from '../models/finalReport';
import { Preclinic } from '../models/preclinic';
import { Therapy } from '../models/therapy';
import { ProtocolAdapterService } from '@nida-web/api/rest/nidaserver/protocol';
import { ProtocolToPreclinicMappingService } from '../mapping/protocol-to-preclinic-mapping.service';

@Injectable({
  providedIn: 'root',
})
export class EmergencyRoomAdapterService implements EmergencyRoomServable {
  public constructor(
    private anamnesisService: AnamnesisService,
    private councilsService: CouncilsService,
    private diagnosticService: DiagnosticService,
    private finalReportService: FinalReportService,
    private icd10Service: ICD10Service,
    private medicalAccessService: MedicalAccessService,
    private ordersService: OrdersService,
    private patientService: PatientService,
    private preclinicService: PreclinicService,
    private therapyService: TherapyService,
    private treatMemberService: TreatmentMemberService,
    private vitalsService: VitalsService,
    private protocolAdapterService: ProtocolAdapterService,
    private protocolToPreclinicMappingService: ProtocolToPreclinicMappingService
  ) {}

  public createCouncil(council: ERCouncil): Observable<ERCouncil> {
    const resultSubject = new ReplaySubject<ERCouncil>(1);

    if (council.id !== undefined) {
      delete council.id;
    }

    if (council.patientId === undefined) {
      resultSubject.error('Wrong use of Method. PatientId needs to be set!');
      resultSubject.complete();
    } else {
      this.councilsService.createCouncil(council as Council).subscribe((result) => {
        resultSubject.next(result as ERCouncil);
        resultSubject.complete();
      });
    }

    return resultSubject.asObservable();
  }

  public createICD10(icd10: ERIcd10): Observable<ERIcd10> {
    const resultSubject = new ReplaySubject<ERIcd10>(1);

    if (icd10.id !== undefined) {
      delete icd10.id;
    }

    if (icd10.finalReportId === undefined) {
      resultSubject.error('Wrong use of Method. finalReportId needs to be set!');
      resultSubject.complete();
    } else {
      this.icd10Service.createIcd10(icd10 as Icd10).subscribe((result) => {
        resultSubject.next(result as ERIcd10);
        resultSubject.complete();
      });
    }

    return resultSubject.asObservable();
  }

  public createMedicalAccess(medicalAccess: ERMedicalAccess): Observable<ERMedicalAccess> {
    const resultSubject = new ReplaySubject<ERMedicalAccess>(1);

    if (medicalAccess.id !== undefined) {
      delete medicalAccess.id;
    }

    if (medicalAccess.preclinicId === undefined && medicalAccess.therapyId === undefined) {
      resultSubject.error('Wrong use of Method. Therapy or Preclinic needs to be set!');
      resultSubject.complete();
    } else {
      this.medicalAccessService.createMedicalAccess(medicalAccess as MedicalAccess).subscribe((result) => {
        resultSubject.next(result as ERMedicalAccess);
        resultSubject.complete();
      });
    }

    return resultSubject.asObservable();
  }

  public createOrder(order: EROrder): Observable<EROrder> {
    const resultSubject = new ReplaySubject<EROrder>(1);

    if (order.id !== undefined) {
      delete order.id;
    }

    if (order.councilId === undefined) {
      resultSubject.error('Wrong use of Method. councilId needs to be set!');
      resultSubject.complete();
    } else {
      this.ordersService.createOrder(order as Order).subscribe((result) => {
        resultSubject.next(result as EROrder);
        resultSubject.complete();
      });
    }

    return resultSubject.asObservable();
  }

  public createTreatmentMember(treatmentMember: ERTreatmentMember): Observable<ERTreatmentMember> {
    const resultSubject = new ReplaySubject<ERTreatmentMember>(1);

    if (treatmentMember.id !== undefined) {
      delete treatmentMember.id;
    }

    if (treatmentMember.patientId === undefined) {
      resultSubject.error('Wrong use of Method. patientId needs to be set!');
      resultSubject.complete();
    } else {
      this.treatMemberService.createTreatmentMember(treatmentMember as TreatmentMember).subscribe((result) => {
        resultSubject.next(result as ERTreatmentMember);
        resultSubject.complete();
      });
    }

    return resultSubject.asObservable();
  }

  public deleteCouncil(councilId: number): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    this.councilsService.deleteCouncil(councilId).subscribe((result) => {
      if (result) {
        resultSubject.next(true);
      } else {
        resultSubject.next(false);
      }
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public deleteICD10(icd10Id: number): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    this.icd10Service.deleteIcd10(icd10Id).subscribe((result) => {
      if (result) {
        resultSubject.next(true);
      } else {
        resultSubject.next(false);
      }
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public deleteMedicalAccess(medicalAccessId: number): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    this.medicalAccessService.deleteMedicalAccess(medicalAccessId).subscribe((result) => {
      if (result) {
        resultSubject.next(true);
      } else {
        resultSubject.next(false);
      }
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public deleteOrder(orderId: number): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    this.ordersService.deleteOrder(orderId).subscribe((result) => {
      if (result) {
        resultSubject.next(true);
      } else {
        resultSubject.next(false);
      }
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public deleteTreatmentMember(treatmentMemberId: number): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    this.treatMemberService.deleteTreatmentMember(treatmentMemberId).subscribe((result) => {
      if (result) {
        resultSubject.next(true);
      } else {
        resultSubject.next(false);
      }
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public generateNewEntry(nidaId: string, protocolId: number): Observable<ERPatient> {
    const resultSubject = new ReplaySubject<ERPatient>(1);

    this.protocolAdapterService.getPreclinicalData(protocolId).subscribe((preclinicProtocol) => {
      const transformedPreclinicVitals: ERVitals = this.protocolToPreclinicMappingService.mapPreclinicVital(preclinicProtocol);

      const transformedPreclinicInformation: ERPreclinic = this.protocolToPreclinicMappingService.mapPreclinic(preclinicProtocol);

      const medicalAccess: ERMedicalAccess[] = this.protocolToPreclinicMappingService.mapPreclinicMedicalAccess(
        preclinicProtocol.therapy.circulationAccess
      );

      if (transformedPreclinicVitals.pupilsWidthRight === undefined) {
        transformedPreclinicVitals.pupilsWidthRight = '';
      }
      const obsVitalsPreclinic = this.vitalsService.createVital({ ...transformedPreclinicVitals });

      const obsVitalsAnamnesis = this.vitalsService.createVital({ pupilsWidthRight: '' });

      combineLatest([obsVitalsPreclinic, obsVitalsAnamnesis]).subscribe((clresultVitals) => {
        const obsPreclinic = this.preclinicService.createPreclinic({ ...transformedPreclinicInformation, vitalsId: clresultVitals[0].id });
        const obsTherapy = this.therapyService.createTherapy({ comment: '' });
        const obsAnamnesis = this.anamnesisService.createAnamnesis({ vitalsId: clresultVitals[1].id });
        const obsFinalReport = this.finalReportService.createFinalReport({ todo: '' });
        const obsDiagnostics = this.diagnosticService.createDiagnostic({ findings: '' });

        combineLatest([obsPreclinic, obsTherapy, obsAnamnesis, obsFinalReport, obsDiagnostics]).subscribe((clresultAll) => {
          for (const erMA of medicalAccess) {
            this.medicalAccessService.createMedicalAccess({
              ...erMA,
              preclinicId: clresultAll[0].id,
            });
          }

          this.patientService
            .createPatient({
              protocolId: protocolId,
              nidaId: nidaId,
              preclinicId: clresultAll[0].id,
              therapyId: clresultAll[1].id,
              anamnesisId: clresultAll[2].id,
              finalReportId: clresultAll[3].id,
              diagnosticId: clresultAll[4].id,
            })
            .subscribe((resultPatient) => {
              resultSubject.next(resultPatient as ERPatient);
              resultSubject.complete();
            });
        });
      });
    });

    return resultSubject.asObservable();
  }

  public getAnamnesis(anamnesisId: number): Observable<ERAnamnesis> {
    const resultSubject = new ReplaySubject<ERAnamnesis>(1);

    this.anamnesisService.getAnamnesis(anamnesisId).subscribe((result) => {
      resultSubject.next(result as ERAnamnesis);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getCouncilList(patientId: number): Observable<ERCouncilList> {
    const resultSubject = new ReplaySubject<ERCouncilList>(1);

    this.councilsService.getCouncil(patientId).subscribe((result) => {
      resultSubject.next(result as ERCouncilList);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getDiagnostic(diagnosticId: number): Observable<ERDiagnostic> {
    const resultSubject = new ReplaySubject<ERDiagnostic>(1);

    this.diagnosticService.getDiagnostic(diagnosticId).subscribe((result) => {
      resultSubject.next(result as ERDiagnostic);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getFinalReport(finalReportId: number): Observable<ERFinalReport> {
    const resultSubject = new ReplaySubject<ERFinalReport>(1);

    this.finalReportService.getFinalReport(finalReportId).subscribe((result) => {
      resultSubject.next(result as ERFinalReport);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getICD10List(finalReportId: number): Observable<ERIcd10List> {
    const resultSubject = new ReplaySubject<ERIcd10List>(1);

    this.icd10Service.getIcd10(finalReportId).subscribe((result) => {
      resultSubject.next(result as ERIcd10List);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getMedicalAccessListByPreclinic(preclinicId: number): Observable<ERMedicalAccessList> {
    const resultSubject = new ReplaySubject<ERMedicalAccessList>(1);

    this.medicalAccessService.getMedicalAccessByPreclinic(preclinicId).subscribe((result) => {
      resultSubject.next(result as ERMedicalAccessList);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getMedicalAccessListByTherapy(therapyId: number): Observable<ERMedicalAccessList> {
    const resultSubject = new ReplaySubject<ERMedicalAccessList>(1);

    this.medicalAccessService.getMedicalAccessByTherapy(therapyId).subscribe((result) => {
      resultSubject.next(result as ERMedicalAccessList);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getOrderList(councilId: number): Observable<EROrderList> {
    const resultSubject = new ReplaySubject<EROrderList>(1);

    this.ordersService.getOrder(councilId).subscribe((result) => {
      resultSubject.next(result as EROrderList);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getPatient(nidaId: string, protocolId: number): Observable<ERPatient> {
    const resultSubject = new ReplaySubject<ERPatient>(1);

    this.patientService.getPatientByProtocolId(nidaId).subscribe((result) => {
      if (result === undefined || result === null) {
        resultSubject.next({});
      } else {
        resultSubject.next(result as ERPatient);
        if (result.protocolId && protocolId > result.protocolId) {
          console.log('Info: Newer Protocol Available');
        }
      }

      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getPreclinic(preclinicId: number): Observable<ERPreclinic> {
    const resultSubject = new ReplaySubject<ERPreclinic>(1);

    this.preclinicService.getPreclinic(preclinicId).subscribe((result) => {
      resultSubject.next(result as ERPreclinic);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getTherapy(therapyId: number): Observable<ERTherapy> {
    const resultSubject = new ReplaySubject<ERTherapy>(1);

    this.therapyService.getTherapy(therapyId).subscribe((result) => {
      resultSubject.next(result as ERTherapy);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getTreatmentMemberList(patientId: number): Observable<ERTreatmentMemberList> {
    const resultSubject = new ReplaySubject<ERTreatmentMemberList>(1);

    this.treatMemberService.getTreatmentMember(patientId).subscribe((result) => {
      resultSubject.next(result as ERTreatmentMemberList);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public getVitals(vitalsId: number): Observable<ERVitals> {
    const resultSubject = new ReplaySubject<ERVitals>(1);

    this.vitalsService.getVital(vitalsId).subscribe((result) => {
      resultSubject.next(result as ERVitals);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateAnamnesis(anamnesisId: number, anamnesis: ERAnamnesis): Observable<ERAnamnesis> {
    const resultSubject = new ReplaySubject<ERAnamnesis>(1);

    this.anamnesisService.updateAnamnesis(anamnesis as Anamnesis, anamnesisId).subscribe((result) => {
      resultSubject.next(result as ERAnamnesis);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateCouncil(councilId: number, council: ERCouncil): Observable<ERCouncil> {
    const resultSubject = new ReplaySubject<ERCouncil>(1);

    this.councilsService.updateCouncil(council as Council, councilId).subscribe((result) => {
      resultSubject.next(result as ERCouncil);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateDiagnostic(diagnosticId: number, diagnostic: ERDiagnostic): Observable<ERDiagnostic> {
    const resultSubject = new ReplaySubject<ERDiagnostic>(1);

    this.diagnosticService.updateDiagnostic(diagnostic as Diagnostic, diagnosticId).subscribe((result) => {
      resultSubject.next(result as ERDiagnostic);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateFinalReport(finalReportId: number, finalReport: ERFinalReport): Observable<ERFinalReport> {
    const resultSubject = new ReplaySubject<ERFinalReport>(1);

    this.finalReportService.updateFinalReport(finalReport as FinalReport, finalReportId).subscribe((result) => {
      resultSubject.next(result as ERFinalReport);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateICD10(icd10Id: number, icd10: ERIcd10): Observable<ERIcd10> {
    const resultSubject = new ReplaySubject<ERIcd10>(1);

    this.icd10Service.updateIcd10(icd10 as Icd10, icd10Id).subscribe((result) => {
      resultSubject.next(result as ERIcd10);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateMedicalAccess(medicalAccessId: number, medicalAccess: ERMedicalAccess): Observable<ERMedicalAccess> {
    const resultSubject = new ReplaySubject<ERMedicalAccess>(1);

    this.medicalAccessService.updateMedicalAccess(medicalAccess as MedicalAccess, medicalAccessId).subscribe((result) => {
      resultSubject.next(result as ERMedicalAccess);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateOrder(orderId: number, order: EROrder): Observable<EROrder> {
    const resultSubject = new ReplaySubject<EROrder>(1);

    this.ordersService.updateOrder(order as Order, orderId).subscribe((result) => {
      resultSubject.next(result as EROrder);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updatePreclinic(preclinidId: number, preclinic: ERPreclinic): Observable<ERPreclinic> {
    const resultSubject = new ReplaySubject<ERPreclinic>(1);

    this.preclinicService.updatePreclinic(preclinic as Preclinic, preclinidId).subscribe((result) => {
      resultSubject.next(result as ERPreclinic);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateTherapy(therapyId: number, therapy: ERTherapy): Observable<ERTherapy> {
    const resultSubject = new ReplaySubject<ERTherapy>(1);

    this.therapyService.updateTherapy(therapy as Therapy, therapyId).subscribe((result) => {
      resultSubject.next(result as ERTherapy);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateTreatmentMember(treatmentMemberId: number, treatmentMember: ERTreatmentMember): Observable<ERTreatmentMember> {
    const resultSubject = new ReplaySubject<ERTreatmentMember>(1);

    this.treatMemberService.updateTreatmentMember(treatmentMember as TreatmentMember, treatmentMemberId).subscribe((result) => {
      resultSubject.next(result as ERTreatmentMember);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public updateVitals(vitalsId: number, vitals: ERVitals): Observable<ERVitals> {
    const resultSubject = new ReplaySubject<ERVitals>(1);

    this.vitalsService.updateVital(vitals as Diagnostic, vitalsId).subscribe((result) => {
      resultSubject.next(result as ERVitals);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }
}
