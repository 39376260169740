/**
 * Emergency Room
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { TreatmentMember } from '../models/treatmentMember';
import { TreatmentMemberList } from '../models/treatmentMemberList';

import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';

@Injectable({
  providedIn: 'root',
})
export class TreatmentMemberService {
  protected apiPrefix = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'er';
    });
  }

  /**
   *
   * Create Treatment Member
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createTreatmentMember(body: TreatmentMember, observe?: 'body', reportProgress?: boolean): Observable<TreatmentMember>;
  public createTreatmentMember(
    body: TreatmentMember,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TreatmentMember>>;
  public createTreatmentMember(body: TreatmentMember, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TreatmentMember>>;
  public createTreatmentMember(body: TreatmentMember, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createTreatmentMember.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<TreatmentMember>('post', `${this.apiPrefix}/treatment-member`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Delete treatment member
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteTreatmentMember(id: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
  public deleteTreatmentMember(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public deleteTreatmentMember(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public deleteTreatmentMember(id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteTreatmentMember.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<string>('delete', `${this.apiPrefix}/treatment-member/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
    });
  }

  /**
   *
   * Get treatment member
   * @param patientId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTreatmentMember(patientId: number, observe?: 'body', reportProgress?: boolean): Observable<TreatmentMemberList>;
  public getTreatmentMember(
    patientId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TreatmentMemberList>>;
  public getTreatmentMember(patientId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TreatmentMemberList>>;
  public getTreatmentMember(patientId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (patientId === null || patientId === undefined) {
      throw new Error('Required parameter patientId was null or undefined when calling getTreatmentMember.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<TreatmentMemberList>(
      'get',
      `${this.apiPrefix}/patient/${encodeURIComponent(String(patientId))}/treatment-member`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update treatment member
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTreatmentMember(body: TreatmentMember, id: number, observe?: 'body', reportProgress?: boolean): Observable<TreatmentMember>;
  public updateTreatmentMember(
    body: TreatmentMember,
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TreatmentMember>>;
  public updateTreatmentMember(
    body: TreatmentMember,
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TreatmentMember>>;
  public updateTreatmentMember(body: TreatmentMember, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateTreatmentMember.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateTreatmentMember.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<TreatmentMember>('put', `${this.apiPrefix}/treatment-member/${encodeURIComponent(String(id))}`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
