import { Injectable } from '@angular/core';
import { ERPreclinicDeprecated } from '@nida-web/api/generic-interfaces/protocol';
import { FreeTextList } from '../models/free-text-list.model';

@Injectable({
  providedIn: 'root',
})
export class FreeTextMappingService {
  public mapValues(rawData: FreeTextList, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    for (const singleText of rawData.data) {
      switch (singleText.description) {
        case 'Anamnese':
          refObj.incidentDescription.accidentDescription = singleText.content;
          break;
        case 'Bemerkungen':
          refObj.diagnostic.comments = singleText.content;
          break;
        default:
          break;
      }
    }

    return refObj;
  }
}
