import { Component, Input, OnInit } from '@angular/core';
import { EcgServable } from '@nida-web/api/generic-interfaces/ecg-viewer';
import { throwError } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';
import notify from 'devextreme/ui/notify';
import { DateService, ModuleSettingsService } from '@nida-web/core';

@Component({
  selector: 'nida-web-ecg-overview-head',
  templateUrl: './ecg-overview-head.component.html',
  styleUrls: ['./ecg-overview-head.component.scss'],
})
export class EcgOverviewHeadComponent implements OnInit {
  @Input() nidaId: string;
  @Input() fileName: string;
  @Input() timestamp: Date;
  public pdf: SafeResourceUrl | undefined;
  public ecgPdfEnabled: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private ecgViewerService: EcgServable,
    private location: Location,
    public dateService: DateService,
    private moduleSettingsService: ModuleSettingsService
  ) {
    this.ecgPdfEnabled = false;
  }

  ngOnInit() {
    this.setDefaults();
    this.moduleSettingsService.getSettings().subscribe((config) => {
      if (config && config.ecgPdf) {
        this.ecgPdfEnabled = config.ecgPdf;
        this.getEcgPdfFromRest();
      }
    });
  }

  setDefaults() {
    if (this.nidaId === undefined) {
      this.nidaId = '';
    }
    if (this.fileName === undefined) {
      this.fileName = 'ecg.pdf';
    }
    if (!this.isValidDate(this.timestamp)) {
      this.timestamp = new Date();
    }
  }

  isValidDate(timestamp: any) {
    return !isNaN(timestamp.valueOf());
  }

  // @ts-ignore
  capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);
  // @ts-ignore
  click = (e) => {
    const buttonText = e.component.option('text');
    notify('The ' + this.capitalize(buttonText) + ' button was clicked');
    return this.pdf;
  };

  getEcgPdfFromRest(): void {
    this.ecgViewerService.getEcgPdf(this.nidaId, this.timestamp).subscribe(
      (ecgPdf) => {
        if (ecgPdf === undefined) {
          return;
        }
        this.pdf = this.transform(URL.createObjectURL(new Blob([ecgPdf])));
      },
      (e) => {
        throwError(e);
      }
    );
  }

  transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  triggerNavigation() {
    this.location.back();
  }

  formatMoment(value: any, type: any) {
    return this.dateService.formatMoment(value, type);
  }
}
