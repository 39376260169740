import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EmergencyRoomRoleModel } from '../models/emergency-room-role.model';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
import { ERTreatmentMember } from '@nida-web/api/generic-interfaces/emergencyroom';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'nida-web-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TeamComponent implements OnInit {
  public roles: EmergencyRoomRoleModel[];
  public treatmentMemberList: ERTreatmentMember[];

  private patientId: number;

  constructor(private emergencyRoomStore: EmergencyRoomStoreService) {
    this.patientId = -1;
  }

  ngOnInit(): void {
    this.emergencyRoomStore.treatmentMemberListData.subscribe((response) => {
      if (this.emergencyRoomStore.erPatientId !== undefined) {
        this.patientId = this.emergencyRoomStore.erPatientId;
      }
      if (response && response.data) {
        this.treatmentMemberList = response.data;
      } else {
        this.treatmentMemberList = [];
      }
    });

    this.roles = [
      {
        roleId: 1,
        name: 'Chirurg',
      },
      {
        roleId: 2,
        name: 'MTRA',
      },
      {
        roleId: 3,
        name: 'Anästhesist',
      },
      {
        roleId: 4,
        name: 'Dermatologe',
      },
      {
        roleId: 5,
        name: 'Gynäkologe',
      },
      {
        roleId: 5,
        name: 'HNO',
      },
      {
        roleId: 6,
        name: 'Pädiater',
      },
      {
        roleId: 7,
        name: 'Psychiater',
      },
      {
        roleId: 8,
        name: 'MKG',
      },
      {
        roleId: 9,
        name: 'Neurochirurg',
      },
      {
        roleId: 10,
        name: 'Neurologe',
      },
      {
        roleId: 11,
        name: 'Internist',
      },
      {
        roleId: 12,
        name: 'Nuklearmediziner',
      },
      {
        roleId: 13,
        name: 'Ophthalmologe',
      },
      {
        roleId: 14,
        name: 'Orthopäde',
      },
      {
        roleId: 15,
        name: 'PRM',
      },
      {
        roleId: 16,
        name: 'Radiologe',
      },
      {
        roleId: 17,
        name: 'Rechtsmediziner',
      },
      {
        roleId: 18,
        name: 'Strahlentherapeut',
      },
      {
        roleId: 19,
        name: 'Urologe',
      },
    ];
  }

  onRowInserting(event): void {
    this.emergencyRoomStore
      .createTreatmentMember({
        patientId: this.patientId,
        role: event.data.role,
        name: event.data.name,
      })
      .subscribe(() => {
        this.notifyUser();
        this.emergencyRoomStore.getTreatmentMembers();
      });
  }
  onRowUpdating(event): void {
    this.emergencyRoomStore.updateTreatmentMember(event.key.id, event.key).subscribe(() => {
      this.notifyUser();
      this.emergencyRoomStore.getTreatmentMembers();
    });
  }
  onRowRemoving(event): void {
    this.emergencyRoomStore.deleteTreatmentMember(event.key.id).subscribe(() => {
      this.notifyUser();
      this.emergencyRoomStore.getTreatmentMembers();
    });
  }
  notifyUser() {
    notify({
      message: 'Änderungen erfolgreich übernommen!',
      type: 'Success',
      displayTime: 5000,
    });
  }
}
