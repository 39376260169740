<form [formGroup]="abcdeForm">
  <div class="row m-0">
    <div class="col-12 col-sm-6 col-xl-4 p-0">
      <div class="basic-card-wrapper percent-100-height">
        <div class="basic-card-style">
          <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
            <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">A</h2>
            {{ 'Airway' | transloco }}
          </div>
          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-7 d-flex align-items-center">
                <dx-check-box
                  [value]="!!this.abcdeForm.getRawValue().cervicalSpineImmobilizationTime"
                  [text]="'Cervical spine immobilization' | transloco"
                  readOnly="true"
                  (click)="autoFillDateOnCheckboxClick('cervicalSpineImmobilizationTime')"
                ></dx-check-box>
              </div>

              <div class="col-5 d-flex justify-content-end">
                <dx-date-box
                  formControlName="cervicalSpineImmobilizationTime"
                  (click)="autoFillDateOnFieldClick('cervicalSpineImmobilizationTime')"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-7 d-flex align-items-center">
                <dx-check-box
                  [value]="!!this.abcdeForm.getRawValue().intubationTime"
                  [text]="'Intubation' | transloco"
                  readOnly="true"
                  (click)="autoFillDateOnCheckboxClick('intubationTime')"
                ></dx-check-box>
              </div>

              <div class="col-5 d-flex justify-content-end">
                <dx-date-box formControlName="intubationTime" (click)="autoFillDateOnFieldClick('intubationTime')" type="datetime">
                </dx-date-box>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-7 d-flex align-items-center">
                <dx-check-box
                  [value]="!!this.abcdeForm.getRawValue().supraglotticRespiratoryAidTime"
                  [text]="'Supraglottic airway devices' | transloco"
                  readOnly="true"
                  (click)="autoFillDateOnCheckboxClick('supraglotticRespiratoryAidTime')"
                ></dx-check-box>
              </div>

              <div class="col-5 d-flex justify-content-end">
                <dx-date-box
                  formControlName="supraglotticRespiratoryAidTime"
                  (click)="autoFillDateOnFieldClick('supraglotticRespiratoryAidTime')"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-7 d-flex align-items-center">
                <dx-check-box
                  [value]="!!this.abcdeForm.getRawValue().surgicalRespiratoryAidTime"
                  [text]="'Surgical airway' | transloco"
                  readOnly="true"
                  (click)="autoFillDateOnCheckboxClick('surgicalRespiratoryAidTime')"
                ></dx-check-box>
              </div>

              <div class="col-5 d-flex justify-content-end">
                <dx-date-box
                  formControlName="surgicalRespiratoryAidTime"
                  (click)="autoFillDateOnFieldClick('surgicalRespiratoryAidTime')"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-xl-4 p-0">
      <div class="basic-card-wrapper percent-100-height">
        <div class="basic-card-style">
          <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
            <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">B</h2>
            {{ 'Respiration' | transloco }}
          </div>

          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-12">
                {{ 'Thoracic drainage' | transloco }}
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-7 d-flex align-items-center" style="padding-left: 30px">
                <dx-check-box
                  [value]="!!this.abcdeForm.getRawValue().thoracicDrainageLeftTime"
                  [text]="'Left' | transloco"
                  readOnly="true"
                  (click)="autoFillDateOnCheckboxClick('thoracicDrainageLeftTime')"
                ></dx-check-box>
              </div>

              <div class="col-5 d-flex justify-content-end">
                <dx-date-box
                  formControlName="thoracicDrainageLeftTime"
                  (click)="autoFillDateOnFieldClick('thoracicDrainageLeftTime')"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-7 d-flex align-items-center" style="padding-left: 30px">
                <dx-check-box
                  [value]="!!this.abcdeForm.getRawValue().thoracicDrainageRightTime"
                  [text]="'Right' | transloco"
                  readOnly="true"
                  (click)="autoFillDateOnCheckboxClick('thoracicDrainageRightTime')"
                ></dx-check-box>
              </div>

              <div class="col-5 d-flex justify-content-end">
                <dx-date-box
                  formControlName="thoracicDrainageRightTime"
                  (click)="autoFillDateOnFieldClick('thoracicDrainageRightTime')"
                  type="datetime"
                >
                </dx-date-box>
              </div>
            </div>
            <div class="row">
              <div class="col-7 d-flex align-items-center">
                <dx-check-box
                  [value]="!!this.abcdeForm.getRawValue().oxygenTime"
                  [text]="'Oxygen' | transloco"
                  readOnly="true"
                  (click)="autoFillDateOnCheckboxClick('oxygenTime')"
                ></dx-check-box>
              </div>

              <div class="col-5 d-flex justify-content-end">
                <dx-date-box formControlName="oxygenTime" (click)="autoFillDateOnFieldClick('oxygenTime')" type="datetime"> </dx-date-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-4">
      <div class="row">
        <div class="col-12 col-sm-6 col-xl-12 p-0">
          <div class="basic-card-wrapper percent-100-height">
            <div class="basic-card-style">
              <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
                <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">D</h2>
                {{ 'Neurology' | transloco }}
              </div>

              <div class="basic-padding">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().analgosedationTime"
                      [text]="'Analgosedation' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('analgosedationTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="analgosedationTime"
                      (click)="autoFillDateOnFieldClick('analgosedationTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-12 p-0">
          <div class="basic-card-wrapper percent-100-height">
            <div class="basic-card-style">
              <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
                <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">E</h2>
                {{ 'Examination' | transloco }}
              </div>

              <div class="basic-padding">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().thermoprotectionTime"
                      [text]="'Thermal protection' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('thermoprotectionTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="thermoprotectionTime"
                      (click)="autoFillDateOnFieldClick('thermoprotectionTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0">
    <div class="col-12 col-xl-12 p-0">
      <div class="basic-card-wrapper percent-100-height">
        <div class="basic-card-style">
          <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
            <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">C</h2>
            {{ 'Circulation' | transloco }}
          </div>

          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-12 col-lg-6 basic-padding-bottom">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().smallVolumeResuscitationTime"
                      [text]="'Small Volume Resuscitation' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('smallVolumeResuscitationTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="smallVolumeResuscitationTime"
                      (click)="autoFillDateOnFieldClick('smallVolumeResuscitationTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 basic-padding-bottom">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().cardiacMassageTime"
                      [text]="'Cardiac massage' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('cardiacMassageTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="cardiacMassageTime"
                      (click)="autoFillDateOnFieldClick('cardiacMassageTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 basic-padding-bottom">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().defibrillationTime"
                      [text]="'Defibrillation' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('defibrillationTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="defibrillationTime"
                      (click)="autoFillDateOnFieldClick('defibrillationTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 basic-padding-bottom">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().catecholaminesTime"
                      [text]="'Catecholamines' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('catecholaminesTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="catecholaminesTime"
                      (click)="autoFillDateOnFieldClick('catecholaminesTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 basic-padding-bottom">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().crystalloidsTime"
                      [text]="'Crystalloids' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('crystalloidsTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5">
                    <div class="percent-100-width d-flex justify-content-end">
                      <div>
                        <div class="d-flex justify-content-end">
                          <dx-date-box
                            formControlName="crystalloidsTime"
                            (click)="autoFillDateOnFieldClick('crystalloidsTime')"
                            type="datetime"
                          >
                          </dx-date-box>
                        </div>
                        <div class="d-flex mt-1">
                          <div class="pr-2 py-2">ml</div>
                          <dx-number-box formControlName="crystalloidsMl" [showSpinButtons]="true"></dx-number-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 basic-padding-bottom">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().colloidsTime"
                      [text]="'Colloids' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('colloidsTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5">
                    <div class="percent-100-width d-flex justify-content-end">
                      <div>
                        <div class="d-flex justify-content-end">
                          <dx-date-box formControlName="colloidsTime" (click)="autoFillDateOnFieldClick('colloidsTime')" type="datetime">
                          </dx-date-box>
                        </div>
                        <div class="d-flex mt-1">
                          <div class="pr-2 py-2">ml</div>
                          <dx-number-box formControlName="colloidsMl" [showSpinButtons]="true"></dx-number-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 basic-padding-bottom">
                <div class="row">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().tranexamicAcidTime"
                      [text]="'Tranexamic acid' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('tranexamicAcidTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box
                      formControlName="tranexamicAcidTime"
                      (click)="autoFillDateOnFieldClick('tranexamicAcidTime')"
                      type="datetime"
                    >
                    </dx-date-box>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <!--hemostasis-->
            <div class="row">
              <div class="col-12 col-lg-6 mb-2">
                <div class="row d-flex align-items-center">
                  <div class="col-7">
                    <strong>{{ 'Hemostasis' | transloco }}</strong>
                  </div>
                  <div class="col-5">
                    <dx-date-box formControlName="hemostasis" (click)="autoFillDateOnFieldClick('hemostasis')" type="datetime">
                    </dx-date-box>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-1">
                    <dx-check-box formControlName="pressureBandage" [text]="'Pressure bandage' | transloco"></dx-check-box>
                  </div>
                  <div class="col-12 mb-1">
                    <dx-check-box formControlName="tourniquet" [text]="'Tourniquet' | transloco"></dx-check-box>
                  </div>
                  <div class="col-12 mb-1">
                    <dx-check-box formControlName="hemostyptics" [text]="'Hemostyptics' | transloco"></dx-check-box>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-2">
                <div class="row d-flex align-items-center">
                  <div class="col-7">
                    <strong>{{ 'Fracture' | transloco }}</strong>
                  </div>
                  <div class="col-5">
                    <dx-date-box formControlName="fracture" (click)="autoFillDateOnFieldClick('fracture')" type="datetime"> </dx-date-box>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-1">
                    <dx-check-box formControlName="reduced" [text]="'Repositioned' | transloco"></dx-check-box>
                  </div>
                  <div class="col-12 mb-1">
                    <dx-check-box formControlName="immobilised" [text]="'Immobilized' | transloco"></dx-check-box>
                  </div>
                  <div class="col-12 mb-1">
                    <dx-check-box formControlName="mountedTrueToAxis" [text]="'Mounted true to axis' | transloco"></dx-check-box>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row d-flex align-items-center">
                  <div class="col-7 d-flex align-items-center">
                    <dx-check-box
                      [value]="!!this.abcdeForm.getRawValue().pelvicBeltTime"
                      [text]="'Pelvic belt' | transloco"
                      readOnly="true"
                      (click)="autoFillDateOnCheckboxClick('pelvicBeltTime')"
                    ></dx-check-box>
                  </div>

                  <div class="col-5 d-flex justify-content-end">
                    <dx-date-box formControlName="pelvicBeltTime" (click)="autoFillDateOnFieldClick('pelvicBeltTime')" type="datetime">
                    </dx-date-box>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-12">
                <h3 class="position-absolute mt-2" style="z-index: 10">{{ 'Access' | transloco }}</h3>
                <dx-data-grid
                  class="mt-1"
                  id="gridContainer"
                  [dataSource]="medicalAccessList"
                  [allowColumnReordering]="true"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [columnHidingEnabled]="true"
                  (onCellClick)="autoFillDateOnTableCellClick($event)"
                  (onRowInserting)="onRowInserting($event)"
                  (onRowRemoving)="onRowRemoving($event)"
                  (onRowUpdated)="onRowUpdated($event)"
                >
                  <dxo-paging [enabled]="true"></dxo-paging>
                  <dxo-editing useIcons="true" mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                  </dxo-editing>

                  <dxi-column
                    dataField="type"
                    dataType="string"
                    [caption]="'Type' | transloco"
                    cellTemplate="typeCellTemplate"
                    editCellTemplate="typeEditCellTemplate"
                  >
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <div *dxTemplate="let data of 'typeCellTemplate'">
                      <!-- diplayed option in cell -->
                      {{ data.value | transloco }}
                    </div>
                    <div *dxTemplate="let data of 'typeEditCellTemplate'">
                      <dx-select-box
                        (onValueChanged)="onValueChanged(data, $event)"
                        [dataSource]="circulationAccessOptions.type"
                        [(value)]="data.data.type"
                        fieldTemplate="field"
                        displayExpr="name"
                        valueExpr="value"
                        [placeholder]="'Select a value...' | transloco"
                      >
                        <div *dxTemplate="let data of 'field'">
                          <!-- chosen drop down option -->
                          <div>
                            <dx-text-box
                              [value]="data && data.value | transloco"
                              [readOnly]="true"
                              placeholder="Bitte auswählen"
                            ></dx-text-box>
                          </div>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                          <!-- Options in drop down -->
                          <div>
                            {{ data.name | transloco }}
                          </div>
                        </div>
                      </dx-select-box>
                    </div>
                  </dxi-column>

                  <dxi-column
                    dataField="location"
                    [caption]="'Location' | transloco"
                    cellTemplate="locationCellTemplate"
                    editCellTemplate="locationEditCellTemplate"
                  >
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <div *dxTemplate="let data of 'locationCellTemplate'">
                      <!-- diplayed option in cell -->
                      {{ data.value | transloco }}
                    </div>
                    <div *dxTemplate="let data of 'locationEditCellTemplate'">
                      <dx-select-box
                        (onValueChanged)="onValueChanged(data, $event)"
                        [dataSource]="circulationAccessOptions.location"
                        [(value)]="data.data.location"
                        fieldTemplate="field"
                        displayExpr="name"
                        valueExpr="value"
                        [placeholder]="'Select a value...' | transloco"
                      >
                        <div *dxTemplate="let data of 'field'">
                          <!-- chosen drop down option -->
                          <div>
                            <dx-text-box
                              [value]="data && data.value | transloco"
                              [readOnly]="true"
                              placeholder="Bitte auswählen"
                            ></dx-text-box>
                          </div>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                          <!-- Options in drop down -->
                          <div>
                            {{ data.name | transloco }}
                          </div>
                        </div>
                      </dx-select-box>
                    </div>
                  </dxi-column>

                  <dxi-column
                    dataField="side"
                    [caption]="'Side' | transloco"
                    cellTemplate="sideCellTemplate"
                    editCellTemplate="sideEditCellTemplate"
                  >
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <div *dxTemplate="let data of 'sideCellTemplate'">
                      <!-- diplayed option in cell -->
                      {{ data.value | transloco }}
                    </div>
                    <div *dxTemplate="let data of 'sideEditCellTemplate'">
                      <dx-select-box
                        (onValueChanged)="onValueChanged(data, $event)"
                        [dataSource]="circulationAccessOptions.side"
                        [(value)]="data.data.side"
                        fieldTemplate="field"
                        displayExpr="name"
                        valueExpr="value"
                        [placeholder]="'Select a value...' | transloco"
                      >
                        <div *dxTemplate="let data of 'field'">
                          <!-- chosen drop down option -->
                          <div>
                            <dx-text-box
                              [value]="data && data.value | transloco"
                              [readOnly]="true"
                              placeholder="Bitte auswählen"
                            ></dx-text-box>
                          </div>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                          <!-- Options in drop down -->
                          <div>
                            {{ data.name | transloco }}
                          </div>
                        </div>
                      </dx-select-box>
                    </div>
                  </dxi-column>

                  <dxi-column dataField="time" dataType="datetime" [caption]="'Time' | transloco">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                  </dxi-column>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
