import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Insurance } from '../models/insurance.model';
import { InsuranceList } from '../models/insurance-list.model';

@Injectable({
  providedIn: 'root',
})
export abstract class InsuranceServable {
  public abstract getInsurance(id: number): Observable<Insurance>;

  public abstract getInsuranceByProtocolId(protocolId: number): Observable<Insurance>;

  public abstract getInsuranceList(): Observable<InsuranceList>;

  public abstract putInsurance(insurance: Insurance, id: number): Observable<Insurance>;
}
