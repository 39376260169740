/**
 * NIDAserver Auth API
 * API for authenticaion and authorization against NIDAserver
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { SecondFactorResponse } from '../models/second-factor-response';
import { CustomHttpParameterCodec } from '../helper/custom-http-parameter-codec';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthenticationRestService {
  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder = new CustomHttpParameterCodec();

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.basePath = apiUrl + 'auth';
    });
  }

  /**
   * Athenticate the user and returns an access token
   * @param uuid Client ID (uuid)
   * @param code 2FA Code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public authValidate2Factor(
    uuid: string,
    code: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<SecondFactorResponse>;
  public authValidate2Factor(
    uuid: string,
    code: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<HttpResponse<SecondFactorResponse>>;
  public authValidate2Factor(
    uuid: string,
    code: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<HttpEvent<SecondFactorResponse>>;
  public authValidate2Factor(
    uuid: string,
    code: string,
    observe: any = 'body',
    reportProgress = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<any> {
    if (uuid === null || uuid === undefined) {
      throw new Error('Required parameter uuid was null or undefined when calling authValidate2Factor.');
    }
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling authValidate2Factor.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (uuid !== undefined && uuid !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>uuid, 'uuid');
    }
    if (code !== undefined && code !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>code, 'code');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<SecondFactorResponse>(`${this.basePath}/validate2factor`, null, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Activate the 2FA settings
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public activate2fa(
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'text/plain';
    }
  ): Observable<string>;
  public activate2fa(
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'text/plain';
    }
  ): Observable<HttpResponse<string>>;
  public activate2fa(
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'text/plain';
    }
  ): Observable<HttpEvent<string>>;
  public activate2fa(
    observe: any = 'body',
    reportProgress = false,
    options?: {
      httpHeaderAccept?: 'text/plain';
    }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<string>(`${this.basePath}/activate2fa`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * deactivate the 2FA settings
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deactivate2fa(
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<User>;
  public deactivate2fa(
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<HttpResponse<User>>;
  public deactivate2fa(
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<HttpEvent<User>>;
  public deactivate2fa(
    observe: any = 'body',
    reportProgress = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/plain';
    }
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<User>(`${this.basePath}/deactivate2fa`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * verify the 2FA settings
   * @param code 2FA Code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public verifysecondfactor(
    code: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'text/plain';
    }
  ): Observable<any>;
  public verifysecondfactor(
    code: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'text/plain';
    }
  ): Observable<HttpResponse<any>>;
  public verifysecondfactor(
    code: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'text/plain';
    }
  ): Observable<HttpEvent<any>>;
  public verifysecondfactor(
    code: string,
    observe: any = 'body',
    reportProgress = false,
    options?: {
      httpHeaderAccept?: 'text/plain';
    }
  ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error('Required parameter code was null or undefined when calling verifysecondfactor.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (code !== undefined && code !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>code, 'code');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<any>(`${this.basePath}/verifysecondfactor`, null, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }
}
