import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { EmergencyRoomIcd10Model } from '../models/emergency-room-icd10.model';
import { Observable, Subscription } from 'rxjs';
import { ICD10StoreService } from '../services/icd10.store.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
import { PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { ERFinalReport, ERIcd10, ERIcd10List } from '@nida-web/api/generic-interfaces/emergencyroom';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'nida-web-final-report',
  templateUrl: './final-report.component.html',
  styleUrls: ['./final-report.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FinalReportComponent implements OnInit, OnDestroy {
  // mock data
  selectBoxOptions;
  // mock data end

  icd10s: Array<EmergencyRoomIcd10Model>;
  protocolId: number;
  private subGetICD10: Subscription;
  icdSearchControl: UntypedFormControl;
  icdDescriptionSearchControl: UntypedFormControl;
  results$: Observable<EmergencyRoomIcd10Model[]>;
  resultsDescription$: Observable<EmergencyRoomIcd10Model[]>;

  public finalReportFormGroup: UntypedFormGroup;

  patient: PatientView;
  finalReport: ERFinalReport;
  icd10List: Array<ERIcd10>;
  searchResultsVisible: boolean;

  constructor(
    private icd10Service: ICD10StoreService,
    protected route: ActivatedRoute,
    private emergencyRoomStore: EmergencyRoomStoreService
  ) {
    // this.icd10 = [];
    this.searchResultsVisible = true;
  }

  ngOnInit(): void {
    this.getData();
    this.setSelectBoxOptions();
  }

  ngOnDestroy(): void {
    if (this.subGetICD10) {
      this.subGetICD10.unsubscribe();
    }
  }

  private getData() {
    this.getFinalReportData();
    this.getICD10ListData();
    this.getLocations();
  }

  getFinalReportData() {
    this.emergencyRoomStore.finalReportData.subscribe((response: ERFinalReport) => {
      if (response) {
        this.finalReport = response;
        this.initFormGroups();
      }
    });
  }

  getICD10ListData() {
    this.emergencyRoomStore.ICD10ListData.subscribe((response: ERIcd10List) => {
      if (response && response.data) {
        this.icd10List = response.data;
      }
    });
  }

  getLocations() {
    // TODO: DELETE - access from service
    const routingId = this.route.snapshot.paramMap.get('id');
    this.protocolId = parseInt(routingId ? routingId : '-1', 10);
    //DELETE END

    //this.icd10Service.loadICD10(this.protocolId);
    this.subGetICD10 = this.icd10Service.getICD10sLoaded().subscribe((loaded) => {
      if (loaded) {
        const icd10temp = this.icd10Service.getICD10s();
        this.icd10s = icd10temp.icd10List;
      }
    });
  }

  setSelectBoxOptions() {
    this.selectBoxOptions = [
      { name: 'Verlegung intern - Funktion (OP, HKL, oä)' },
      { name: 'Verlegung intern - Überwachung (ITS, IMC, oä)' },
      { name: 'Verlegung intern - Normalstation' },
      { name: 'Verlegung extern - Funktion (OP, HKL, oä)' },
      { name: 'Verlegung extern - Überwachung (ITS, IMC, oä)' },
      { name: 'Verlegung extern - Normalstation' },
      { name: 'Entlassung nach Hause' },
      { name: 'Entlassung zu weiterbehandelnder Arzt' },
      { name: 'Entlassung gegen ärztlichen Rat' },
      { name: 'Abbruch durch Patienten' },
      { name: 'Kein Arztkontakt' },
      { name: 'Sonstige Entlassung' },
      { name: 'Tod' },
    ];
  }

  initFormGroups() {
    this.finalReportFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(this.finalReport.id),
      finalDiagnosis: new UntypedFormControl(this.finalReport.finalDiagnosis),
      diagnosticsBeforeIcuComplete: new UntypedFormControl(this.finalReport.diagnosticsBeforeIcuComplete),
      todo: new UntypedFormControl(this.finalReport.todo),
      transferredDischargedTo: new UntypedFormControl(this.finalReport.transferredDischargedTo),
      shockRoomRegularEnd: new UntypedFormControl(this.finalReport.shockRoomRegularEnd),
      therapyRecommendation: new UntypedFormControl(this.finalReport.therapyRecommendation),
      handoverTime: new UntypedFormControl(this.finalReport.handoverTime),
      transferredDischargedTime: new UntypedFormControl(this.finalReport.transferredDischargedTime),
    });
  }

  onToolbarPreparing(e: any) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'newToolbar',
    });
  }

  onRowInserting(e: any) {
    console.log(e);

    if (this.finalReport && this.finalReport.id && e.data) {
      const ICD10Object = e.data;
      ICD10Object['finalReportId'] = this.finalReport.id;

      const subscription = this.emergencyRoomStore.createICD10(ICD10Object).subscribe((response) => {
        if (response) {
          this.icd10List[this.icd10List.length - 1] = response;

          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    } else {
      console.log('ERROR: final report or its id was not existing, or the events data');
    }
  }

  onRowRemoving(e: any) {
    if (this.finalReport && this.finalReport.id && e.data) {
      const subscription = this.emergencyRoomStore.deleteICD10(e.data.id).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    } else {
      console.log('ERROR: final report or its id was not existing, or the events data');
    }
  }

  onRowUpdated(e: any) {
    if (this.finalReport && this.finalReport.id && e.data) {
      const ICD10Object = e.data;
      ICD10Object['finalReportId'] = this.finalReport.id;

      console.log(ICD10Object);
      const subscription = this.emergencyRoomStore.updateICD10(e.data.id, ICD10Object).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    } else {
      console.log('ERROR: final report or its id was not existing, or the events data');
    }
  }

  autoFillDateOnFieldClick(key: string) {
    if (!this.finalReportFormGroup.value[key]) {
      this.finalReportFormGroup.patchValue({ [key]: new Date() });
      this.finalReportFormGroup.markAsDirty();
    }
  }

  resetFormGroups() {
    this.finalReportFormGroup.reset();
  }

  submitFormGroups() {
    if (!this.finalReportFormGroup.pristine && this.finalReport.id) {
      // TODO: Better implementation?
      const currentReport = this.finalReportFormGroup.getRawValue();
      currentReport.id = this.finalReport.id;

      const subscription = this.emergencyRoomStore.updateFinalReport(this.finalReport.id, currentReport).subscribe((response) => {
        if (response) {
          this.finalReport = response;
          this.initFormGroups();

          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }

        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }

  selectedICD10(icd10) {
    this.icd10s.unshift(icd10);
    this.searchResultsVisible = false;
  }

  reset() {
    this.searchResultsVisible = false;
  }

  setSearchResultsVisibleTrue() {
    // console.log('setSearchResultsVisibleTrue');
    // console.log(this.searchResultsVisible);
    this.searchResultsVisible = true;
    // console.log(this.searchResultsVisible);
  }
}
