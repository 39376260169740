import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientComponent } from './patient/patient.component';
import { PreclinicComponent } from './preclinic/preclinic.component';
import { ErTherapyComponent } from './er-therapy/er-therapy.component';
import { AnamnesisComponent } from './anamnesis/anamnesis.component';
import { EmergencyRoomRootComponent } from './emergency-room-root/emergency-room-root.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { FinalReportComponent } from './final-report/final-report.component';
import { TeamComponent } from './team/team.component';
import { CouncilComponent } from './council/council.component';

const routes: Routes = [
  {
    path: 'current-patients/details/:id/emergency-room/:protocolId',
    component: EmergencyRoomRootComponent,
    children: [
      {
        path: '',
        component: PatientComponent,
      },
      {
        path: 'preclinic',
        component: PreclinicComponent,
      },
      {
        path: 'anamnesis',
        component: AnamnesisComponent,
      },
      {
        path: 'diagnosis',
        component: DiagnosisComponent,
      },
      {
        path: 'therapy',
        component: ErTherapyComponent,
      },
      {
        path: 'completion',
        component: FinalReportComponent,
      },
      {
        path: 'team',
        component: TeamComponent,
      },
      {
        path: 'council',
        component: CouncilComponent,
      },
    ],
  },
  {
    path: 'patients/details/:id/emergency-room/:protocolId',
    component: EmergencyRoomRootComponent,
    children: [
      {
        path: '',
        component: PatientComponent,
      },
      {
        path: 'preclinic',
        component: PreclinicComponent,
      },
      {
        path: 'anamnesis',
        component: AnamnesisComponent,
      },
      {
        path: 'diagnosis',
        component: DiagnosisComponent,
      },
      {
        path: 'therapy',
        component: ErTherapyComponent,
      },
      {
        path: 'completion',
        component: FinalReportComponent,
      },
      {
        path: 'team',
        component: TeamComponent,
      },
      {
        path: 'council',
        component: CouncilComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmergencyRoomManagementRouterModule {
  public id: string;
}
