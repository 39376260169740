import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService, NavItem } from '@nida-web/core';
// import { NavigationDataService } from '../../../../patient-management/src/lib/services/common/navigation-data.service';
import { Observable, Subscription } from 'rxjs';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
import { NavAppInfoService, NavigationService } from '@nida-web/navigation';
// import FamilyAddNewMember from '@streamlinehq/streamlinehq/img/streamline-light/family-add-new-member-8uepey.svg'

@Component({
  selector: 'nida-web-emergency-room-root',
  templateUrl: './emergency-room-root.component.html',
  styleUrls: ['./emergency-room-root.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class EmergencyRoomRootComponent implements OnInit, OnDestroy {
  public id: string | null;
  public protocolId: number | null;
  public navigation: Array<NavItem> | null;

  public idSub: Subscription;
  public protocolIdSub: Subscription;
  public breadcrumbSub: Subscription;

  public current: boolean;
  private alreadyLoaded: boolean;

  public navigationItems$: Observable<NavItem[]>;

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    // private naviService: NavigationDataService,
    private emergencyRoomStoreService: EmergencyRoomStoreService,
    public navAppInfoService: NavAppInfoService,
    private route: ActivatedRoute,
    public navigationService: NavigationService
  ) {
    this.alreadyLoaded = false;
  }

  ngOnInit(): void {
    this.alreadyLoaded = false;

    this.emergencyRoomStoreService.unsubscribeAllSubscriptions();
    if (this.breadcrumbSub) {
      this.breadcrumbSub.unsubscribe();
    }
    this.getIds();
    this.storeIds();

    if (this.id && this.protocolId) {
      this.checkOrigin();
      this.setNavigation();
    }
  }

  ngOnDestroy(): void {
    this.emergencyRoomStoreService.unsubscribeAllSubscriptions();
    this.breadcrumbSub.unsubscribe();
  }

  checkOrigin() {
    this.breadcrumbSub = this.breadcrumbService.isCreated.subscribe((response) => {
      if (response && !this.alreadyLoaded) {
        this.alreadyLoaded = true;
        this.current = this.breadcrumbService.isFragmentInPath('current-patients');
        if (this.id && this.protocolId) {
          this.getData(this.id, this.protocolId, this.current);
        }
      }
      if (this.breadcrumbSub) {
        this.breadcrumbSub.unsubscribe();
      }
    });
  }

  getIds() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.protocolId = Number(this.route.snapshot.paramMap.get('protocolId'));
  }

  storeIds() {
    if (this.id && this.protocolId) {
      this.emergencyRoomStoreService.setId(this.id, this.protocolId);
    }
  }

  getRoot() {
    // console.log(this.breadcrumbService.getUpperLevelPath());
  }

  triggerNavigationBack() {
    this.router.navigate([this.breadcrumbService.getUpperLevelPath()]).then();
  }

  setNavigation() {
    this.navigation = [
      {
        text: 'Patient',
        path: '.',
        icon: 'ambulance-human',
      },
      {
        text: 'Anamnese',
        path: 'anamnesis',
        icon: 'light-medical-folder',
      },
      {
        text: 'Diagnose',
        path: 'diagnosis',
        icon: 'light-medical-file',
      },
      {
        text: 'Therapie',
        path: 'therapy',
        icon: 'drugs-pill',
      },
      {
        text: 'Präklinik',
        path: 'preclinic',
        icon: 'light-ambulance-car',
      },
      {
        text: 'Konsil',
        path: 'council',
        icon: 'medical-personnel-doctor',
      },
      {
        text: 'Team',
        path: 'team',
        icon: 'team-meeting',
      },
      {
        text: 'Abschluss',
        path: 'completion',
        icon: 'task-checklist-check',
      },
    ];

    this.navigationItems$ = this.navigationService.getNavigationItems(this.navigation);
  }

  getData(id: string, protocolId: number, current: boolean) {
    this.emergencyRoomStoreService.getData(id, protocolId, current);
  }
}
