import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/de';
import 'dayjs/locale/zh';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(private translocoService: TranslocoService) {
    dayjs.extend(localizedFormat.default);
  }

  formatMoment(value: string, type: string): string {
    let formatedTimeStamp = '';
    const date = dayjs(value);
    if (value !== undefined && date.isValid()) {
      dayjs.locale(this.translocoService.getActiveLang());
      switch (type) {
        case 'time':
          formatedTimeStamp = dayjs(value).format('LT');
          break;
        case 'fulltime':
          formatedTimeStamp = dayjs(value).format('LTS');
          break;
        case 'medium':
          formatedTimeStamp = [dayjs(value).format('L'), dayjs(value).format('LT')].join(', ');
          break;
        default:
          formatedTimeStamp = dayjs(value).format(this.getDateFormat(this.translocoService.getActiveLang()));
      }
    }
    return formatedTimeStamp;
  }

  createCountDown(value: string): string {
    const date = dayjs(value);
    const now = dayjs();
    const timeDifferenceInMinutes = date.diff(now, 'minutes');
    if (timeDifferenceInMinutes > 60) {
      return `noch ${Math.floor(timeDifferenceInMinutes / 60)}Std ${timeDifferenceInMinutes % 60}min`;
    }
    return `noch ${timeDifferenceInMinutes}min`;
  }

  getDateFormat(languageCode = 'en'): string {
    let dateFormat: string;
    switch (languageCode) {
      case 'en': {
        dateFormat = 'MM/DD/YYYY';
        break;
      }
      case 'de': {
        dateFormat = 'DD.MM.YYYY';
        break;
      }
      case 'zh': {
        dateFormat = 'YYYY-MM-DD';
        break;
      }
      default: {
        dateFormat = 'MM/DD/YYYY';
      }
    }

    return dateFormat;
  }
}
