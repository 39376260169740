export class LocalStorageSettings {
  constructor(
    notificationMessages?: boolean,
    notificationSounds?: boolean,
    notificationFilter?: boolean,
    notificationFilterRule?: string,
    notificationAudioFileName?: string
  ) {
    this.notificationMessages = notificationMessages !== undefined ? notificationMessages : true;
    this.notificationSounds = notificationSounds !== undefined ? notificationSounds : true;
    this.notificationFilter = notificationFilter !== undefined ? notificationFilter : false;
    this.notificationFilterRule = notificationFilterRule !== undefined ? notificationFilterRule : '';
    this.notificationAudioFileName = notificationAudioFileName !== undefined ? notificationAudioFileName : '';
  }

  notificationMessages: boolean;
  notificationSounds: boolean;
  notificationFilter: boolean;
  notificationFilterRule: string;
  notificationAudioFileName: string;
}
