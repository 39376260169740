<nida-web-site-wrapper headline="Support">
  <nida-web-card headline="How can we help you?">
    <div class="row col-12 mb-3">
      {{ 'supportPageMessage' | transloco }}
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-3">{{ 'Telephone' | transloco }}</div>
          <div class="col-9">
            <a href="tel:08004321112"><i class="dx-icon-tel"></i> 0800/4321112</a>
          </div>
        </div>
        <div class="row basic-padding-bottom">
          <div class="col-3">E-Mail</div>
          <div class="col-9">
            <a href="mailto:service@ztm.de"><i class="dx-icon-email"></i> service@ztm.de</a>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><!--{{ 'Ticket System' | transloco }}--></div>
          <div class="col-9">
            <!--                        <a href="https://www.ztm.de/support" target="_blank"><i class="dx-icon-link"></i> Open</a>-->
            <div>
              <dx-button
                stylingMode="outlined"
                [text]="'Open ticket' | transloco"
                type="default"
                (onClick)="navigateToPage('https://www.ztm.de/support')"
              >
              </dx-button>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="col-md-3">
        <img alt="ZTM" class="ztm-logo" src="./assets/resources/ztm_logo_grau.png" />
      </div>-->
    </div>
    <div class="row col-12 mt-3">
      {{
        'The service times are Monday to Friday, from 8 a.m. to 4 p.m. The IT service is on public holidays (Bavaria) not occupied.'
          | transloco
      }}
    </div>
  </nida-web-card>
  <nida-web-card
    class="mt-3"
    [translatedHeadline]="'The following media will assist you in getting started and using NIDAklinik' | transloco"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-4 mb-3">
          <nida-web-ui-support-tile
            [pictureLinks]="['assets/img/support/elearning_rd.svg', 'assets/img/support/code_rd.png']"
            [buttonLink]="'https://rise.articulate.com/share/T1K_saSQDxQVBNgWfNX0NhPzQVqK4cv1#/'"
            [buttonText]="'Open E-Learning' | transloco"
            [translatedHeadtext]="'NIDAklinik for rescue service' | transloco"
            [translatedText]="
              'In this e-learning course, you, as a rescue services employee, ' +
                'will learn everything you need to know about using NIDAklinik.' | transloco
            "
          >
          </nida-web-ui-support-tile>
        </div>
        <div class="col-xl-4 mb-3">
          <nida-web-ui-support-tile
            [pictureLinks]="['assets/img/support/elearning_cl.svg', 'assets/img/support/code_clinic.png']"
            [buttonLink]="'https://rise.articulate.com/share/1A4w3zHyoUKxGQRLDYXhqZMegUCd3dwi#/'"
            [buttonText]="'Open E-Learning' | transloco"
            [translatedHeadtext]="'NIDAklinik for clinics' | transloco"
            [translatedText]="
              'In this e-learning course, you, as a clinic employee, ' + 'will learn everything you need to know about using NIDAklinik.'
                | transloco
            "
          >
          </nida-web-ui-support-tile>
        </div>
        <div class="col-xl-4">
          <nida-web-ui-support-tile
            [pictureLinks]="['assets/img/support/manual_icon.svg']"
            [buttonLink]="'https://handbuch.ztm.de/nidaklinik'"
            [buttonText]="'Open Handbook' | transloco"
            [translatedHeadtext]="'Digital manual for clinics' | transloco"
            [translatedText]="
              'Our digital manual serves you as an instruction manual for NIDAklinik. ' + 'Here you can learn more about operation and use.'
                | transloco
            "
          >
          </nida-web-ui-support-tile>
        </div>
      </div>
    </div>
  </nida-web-card>
</nida-web-site-wrapper>
