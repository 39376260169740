import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
import { ERMedicalAccess, ERPreclinic, ERVitals } from '@nida-web/api/generic-interfaces/emergencyroom';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'nida-web-preclinic',
  templateUrl: './preclinic.component.html',
  styleUrls: ['./preclinic.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PreclinicComponent implements OnInit {
  public preclinicalPatientinfo: ERPreclinic;
  public preclinicalVitals: ERVitals;
  public vitalSignsForm: UntypedFormGroup;
  public preclinicForm: UntypedFormGroup;
  public medicalAccessList: Array<ERMedicalAccess>;

  public nacaItems = [{ text: 'I' }, { text: 'II' }, { text: 'III' }, { text: 'IV' }, { text: 'V' }, { text: 'VI' }, { text: 'VII' }];

  constructor(private emergencyRoomStoreService: EmergencyRoomStoreService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.emergencyRoomStoreService.vitalsPreclinicData.subscribe((preclinicVitalData) => {
      if (preclinicVitalData) {
        this.preclinicalVitals = preclinicVitalData;
        this.emergencyRoomStoreService.preclinicData.subscribe((preclinicData) => {
          if (preclinicData) {
            this.preclinicalPatientinfo = preclinicData;
            this.initFormGroups();
          }
        });
      }
    });

    this.emergencyRoomStoreService.medicalAccessListByPreclinicData.subscribe((response) => {
      if (response && response.data) {
        this.medicalAccessList = response.data;
      }
    });
  }

  initFormGroups() {
    this.vitalSignsForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.preclinicalVitals.id),
      respiratoryFrequency: new UntypedFormControl(this.preclinicalVitals.respiratoryFrequency),
      alcohol: new UntypedFormControl(this.preclinicalVitals.alcohol),
      respiratoryTractClear: new UntypedFormControl(this.preclinicalVitals.respiratoryTractClear),
      drugs: new UntypedFormControl(this.preclinicalVitals.drugs),
      etco2: new UntypedFormControl(this.preclinicalVitals.etco2),
      fio2: new UntypedFormControl(this.preclinicalVitals.fio2),
      gcs: new UntypedFormControl(this.preclinicalVitals.gcs),
      heartRate: new UntypedFormControl(this.preclinicalVitals.heartRate),
      intoxication: new UntypedFormControl(this.preclinicalVitals.intoxication),
      bodyTemperature: new UntypedFormControl(this.preclinicalVitals.bodyTemperature),
      pupilsLightLeft: new UntypedFormControl(this.preclinicalVitals.pupilsLightLeft),
      pupilsLightRight: new UntypedFormControl(this.preclinicalVitals.pupilsLightRight),
      pupilsWidthLeft: new UntypedFormControl(this.preclinicalVitals.pupilsWidthLeft),
      pupilsWidthRight: new UntypedFormControl(this.preclinicalVitals.pupilsWidthRight),
      rrSys: new UntypedFormControl(this.preclinicalVitals.rrSys),
      pain: new UntypedFormControl(this.preclinicalVitals.pain),
      spo2: new UntypedFormControl(this.preclinicalVitals.spo2),
      gcsEyes: new UntypedFormControl(this.preclinicalVitals.gcsEyes),
      gcsMotoric: new UntypedFormControl(this.preclinicalVitals.gcsMotoric),
      gcsVerbal: new UntypedFormControl(this.preclinicalVitals.gcsVerbal),
    });

    this.preclinicForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.preclinicalPatientinfo.id),
      vitalsId: new UntypedFormControl(this.preclinicalPatientinfo.vitalsId),
      onsetOfSymptoms: new UntypedFormControl(this.preclinicalPatientinfo.onsetOfSymptoms),
      accidentTime: new UntypedFormControl(this.preclinicalPatientinfo.accidentTime),
      arrivalTimeRescueTeam: new UntypedFormControl(this.preclinicalPatientinfo.arrivalTimeRescueTeam),
      alarmTime: new UntypedFormControl(this.preclinicalPatientinfo.alarmTime),
      startOfTransport: new UntypedFormControl(this.preclinicalPatientinfo.startOfTransport),
      asa: new UntypedFormControl(this.preclinicalPatientinfo.asa),
      accidentCause: new UntypedFormControl(this.preclinicalPatientinfo.accidentCause),
      accidentMechanism: new UntypedFormControl(this.preclinicalPatientinfo.accidentMechanism),
      accidentType: new UntypedFormControl(this.preclinicalPatientinfo.accidentType),
      accidentDescription: new UntypedFormControl(this.preclinicalPatientinfo.accidentDescription),
      aisCranialBrain: new UntypedFormControl(this.preclinicalPatientinfo.aisCranialBrain),
      aisFace: new UntypedFormControl(this.preclinicalPatientinfo.aisFace),
      aisThorax: new UntypedFormControl(this.preclinicalPatientinfo.aisThorax),
      aisAbdomen: new UntypedFormControl(this.preclinicalPatientinfo.aisAbdomen),
      aisSpine: new UntypedFormControl(this.preclinicalPatientinfo.aisSpine),
      aisPelvic: new UntypedFormControl(this.preclinicalPatientinfo.aisPelvic),
      aisUpperExtremities: new UntypedFormControl(this.preclinicalPatientinfo.aisUpperExtremities),
      aisLowerExtremities: new UntypedFormControl(this.preclinicalPatientinfo.aisLowerExtremities),
      aisSoftParts: new UntypedFormControl(this.preclinicalPatientinfo.aisSoftParts),
      nacaScore: new UntypedFormControl(this.preclinicalPatientinfo.nacaScore),
      ultrasonic: new UntypedFormControl(this.preclinicalPatientinfo.ultrasonic),
      comment: new UntypedFormControl(this.preclinicalPatientinfo.comment),

      cervicalSpineImmobilizationTime: new UntypedFormControl(this.preclinicalPatientinfo.cervicalSpineImmobilizationTime),
      intubationTime: new UntypedFormControl(this.preclinicalPatientinfo.intubationTime),
      thoracicDrainageLeftTime: new UntypedFormControl(this.preclinicalPatientinfo.thoracicDrainageLeftTime),
      thoracicDrainageRightTime: new UntypedFormControl(this.preclinicalPatientinfo.thoracicDrainageRightTime),
      oxygenTime: new UntypedFormControl(this.preclinicalPatientinfo.oxygenTime),
      analgosedationTime: new UntypedFormControl(this.preclinicalPatientinfo.analgosedationTime),
      thermoprotectionTime: new UntypedFormControl(this.preclinicalPatientinfo.thermoprotectionTime),
      smallVolumeResuscitationTime: new UntypedFormControl(this.preclinicalPatientinfo.smallVolumeResuscitationTime),
      cardiacMassageTime: new UntypedFormControl(this.preclinicalPatientinfo.cardiacMassageTime),
      catecholaminesTime: new UntypedFormControl(this.preclinicalPatientinfo.catecholaminesTime),
      crystalloidsTime: new UntypedFormControl(this.preclinicalPatientinfo.crystalloidsTime),
      crystalloidsMl: new UntypedFormControl(this.preclinicalPatientinfo.crystalloidsMl),
      colloidsTime: new UntypedFormControl(this.preclinicalPatientinfo.colloidsTime),
      colloidsMl: new UntypedFormControl(this.preclinicalPatientinfo.colloidsMl),
      hemostasis: new UntypedFormControl(this.preclinicalPatientinfo.hemostasis),
      fracture: new UntypedFormControl(this.preclinicalPatientinfo.fracture),
      pelvicBeltTime: new UntypedFormControl(this.preclinicalPatientinfo.pelvicBeltTime),
      defibrillationTime: new UntypedFormControl(this.preclinicalPatientinfo.defibrillationTime),
      pressureBandage: new UntypedFormControl(this.preclinicalPatientinfo.pressureBandage),
      tourniquet: new UntypedFormControl(this.preclinicalPatientinfo.tourniquet),
      hemostyptics: new UntypedFormControl(this.preclinicalPatientinfo.hemostyptics),
      immobilised: new UntypedFormControl(this.preclinicalPatientinfo.immobilised),
      mountedTrueToAxis: new UntypedFormControl(this.preclinicalPatientinfo.mountedTrueToAxis),

      supraglotticRespiratoryAidTime: new UntypedFormControl(this.preclinicalPatientinfo.supraglotticRespiratoryAidTime),
      surgicalRespiratoryAidTime: new UntypedFormControl(this.preclinicalPatientinfo.surgicalRespiratoryAidTime),
      tranexamicAcidTime: new UntypedFormControl(this.preclinicalPatientinfo.tranexamicAcidTime),
      reduced: new UntypedFormControl(this.preclinicalPatientinfo.reduced),
    });
  }

  autoFillDateOnFieldClick(date: string) {
    if (this.preclinicForm.getRawValue()[date] === null || this.preclinicForm.getRawValue()[date] === undefined) {
      this.preclinicForm.patchValue({ [date]: new Date() });
      this.preclinicForm.markAsDirty();
    }
  }

  saveAccess(e: any) {
    const object = e.data;
    object['preclinicId'] = this.preclinicalPatientinfo.id;

    const subscription = this.emergencyRoomStoreService.createMedicalAccess(object).subscribe((response) => {
      if (response && this.medicalAccessList) {
        // this.medicalAccessList[this.medicalAccessList?.length-1] = response;
        notify({
          message: 'Änderungen erfolgreich übernommen!',
          type: 'Success',
          displayTime: 5000,
        });
      } else {
        notify({
          message: 'Vorgang nicht erfolgreich beendet!',
          type: 'Error',
          displayTime: 5000,
        });
      }
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  updateAccess(e: any) {
    const object = e.data;
    object['preclinicId'] = this.preclinicalPatientinfo.id;

    if (e.data.id) {
      const subscription = this.emergencyRoomStoreService.updateMedicalAccess(e.data.id, object).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    } else {
      console.log('ERROR: Access id was wrong or simply not existent.');
    }
  }

  deleteAccess(e: any) {
    if (e.data.id) {
      const subscription = this.emergencyRoomStoreService.deleteMedicalAccess(e.data.id).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    } else {
      console.log('ERROR: Access id was wrong or simply not existent.');
    }
  }

  onBtnGroupChange(event) {
    if (this.preclinicForm.getRawValue().nacaScore !== event.itemIndex) {
      this.preclinicForm.patchValue({ nacaScore: event.itemIndex });
      this.preclinicForm.markAsDirty();
    }
  }

  // EVENT TRIGGERED:

  saveAll() {
    const vitals: ERVitals = this.vitalSignsForm.getRawValue() as ERVitals;
    const preclinic: ERPreclinic = this.preclinicForm.getRawValue() as ERPreclinic;
    if (vitals.id !== undefined) {
      this.emergencyRoomStoreService.updateVitals(vitals.id, vitals).subscribe((vitalsResult) => {
        this.preclinicalVitals = vitalsResult;
        if (preclinic.id !== undefined) {
          this.emergencyRoomStoreService.updatePreclinic(preclinic.id, preclinic).subscribe((preclinicResult) => {
            this.preclinicalPatientinfo = preclinicResult;
            this.emergencyRoomStoreService.preclinicData.next(preclinicResult);
            notify({
              message: 'Änderungen erfolgreich übernommen!',
              type: 'Success',
              displayTime: 5000,
            });
            this.initFormGroups();
          });
        }
      });
    }
    // this.submitForms();
  }

  resetAll() {
    /*
    FormGroup.reset() sets back any data (also pre existing in the db)
    FormGroup.reset() acts more like "clear"
     */

    // this.diagnosisFormGroup.reset()
    this.initFormGroups();
  }
}
