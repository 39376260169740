import { Injectable } from '@angular/core';
import { ERPreclinicDeprecated } from '@nida-web/api/generic-interfaces/protocol';
import { Protocol } from '../models/protocol.model';

@Injectable({
  providedIn: 'root',
})
export class ProtocolMappingService {
  public mapValues(rawData: Protocol, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    if (rawData.timeStatusAlarm) {
      const actDate = new Date();
      actDate.setHours(parseInt(rawData.timeStatusAlarm.split(':')[0], 10), parseInt(rawData.timeStatusAlarm.split(':')[1], 10));
      refObj.dispatchTimestamps.alarmDateTime = this.concatDates(
        actDate,
        rawData.dateStatusAlarm ? new Date(rawData.dateStatusAlarm) : undefined
      );
    }

    if (rawData.timeStatus4) {
      const actDate = new Date();
      actDate.setHours(parseInt(rawData.timeStatus4.split(':')[0], 10), parseInt(rawData.timeStatus4.split(':')[1], 10));
      refObj.dispatchTimestamps.arrivalDateTime = this.concatDates(
        actDate,
        rawData.dateStatus4 ? new Date(rawData.dateStatus4) : undefined
      );
    }

    if (rawData.timeStatus7) {
      const actDate = new Date();
      actDate.setHours(parseInt(rawData.timeStatus7.split(':')[0], 10), parseInt(rawData.timeStatus7.split(':')[1], 10));
      refObj.dispatchTimestamps.startTransportDateTime = this.concatDates(
        actDate,
        rawData.dateStatus7 ? new Date(rawData.dateStatus7) : undefined
      );
    }

    return refObj;
  }

  private concatDates(time: Date | undefined, date: Date | undefined): Date | undefined {
    if (date && time) {
      date.setTime(time.getTime());
      return date;
    } else {
      return time;
    }
  }
}
