import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { UserList } from '../models/user-list.model';

/**
 * Service-Interface for User managing. Used for all user-realted actions.
 */
@Injectable({ providedIn: 'root' })
export abstract class UserServable {
  /**
   * Returns a User-Object
   * @param userID of the wanted user.
   * @return Observable which will deliver the user asynchronous.
   */
  abstract getUser(userID: number): Observable<User>;

  /**
   * Returns a list of all active Users.
   * @return Observable which will deliver the complete Userlist asynchronous.
   */
  abstract getAllUsers(tenant: number): Observable<UserList>;

  /**
   * Updates a User by its ID
   * @param user which will be updated with the given values.
   * @return true if the operation was successfull, false if not.
   */
  abstract updateUser(user: User): Observable<boolean>;

  /**
   * Removes a User by its ID
   * @param userID of the user which should be deleted
   * @return true if the operation was successfull, false if not.
   */
  abstract deleteUser(userID: number): Observable<boolean>;

  /**
   * Adds a new User to the system
   * @param user without id which will be added
   * @return ID of the new User given by the system
   */
  abstract addUser(user: User): Observable<User | undefined>;

  /**
   * Updates a User by its ID
   * @param user which will be updated with the given values.
   * @param newPassword new password for the user.
   * @return true if the operation was successfull, false if not.
   */
  abstract changePassword(user: User, newPassword: string): Observable<boolean>;
}
