import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { Observable, ReplaySubject } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { take } from 'rxjs/operators';
import { AppInfoService } from '../services/app-info.service';
import { Environment } from '../models/Environment';
import { ModuleSettingsService } from '@nida-web/core';

@Injectable({
  providedIn: 'root',
})
export class WebclientPreviewGuard {
  constructor(
    private router: Router,
    private sessionManagerService: SessionManagerService,
    private moduleSettingsService: ModuleSettingsService,
    private appInfo: AppInfoService,
    @Inject('environment') private environment: Environment
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAllowedToPreview = new ReplaySubject<boolean | UrlTree>(1);
    const authType = this.environment.authType;
    this.moduleSettingsService.getSettings().subscribe((settings) => {
      // check Preview permission only when using nida (server) login is active AND we are using the NIDAclient
      if (!settings.previewGuard || authType !== 'nida' || this.appInfo.title !== 'NIDAclient') {
        isAllowedToPreview.next(true);
      } else {
        this.sessionManagerService
          .getSessionInformation()
          .pipe(take(1))
          .subscribe((sessionInfo) => {
            // pipe
            if (sessionInfo.loggedIn) {
              this.sessionManagerService.hasPermission(['nida.webclient.preview']).subscribe((hasPermission) => {
                if (hasPermission) {
                  isAllowedToPreview.next(true);
                } else {
                  notify({
                    message:
                      'Ihr Account ist zur Verwendung des NIDAwebClients nicht freigeschaltet. Bitte wenden Sie sich an Ihren ' +
                      'NIDA Verantwortlichen!',
                    type: 'Error',
                    displayTime: 5000,
                  });
                  isAllowedToPreview.next(this.router.parseUrl('logout'));
                }
              });
            } else {
              isAllowedToPreview.next(this.router.parseUrl('login'));
            }
          });
      }
    });
    return isAllowedToPreview;
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate();
  }
}
