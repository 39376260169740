<div class="user-panel">
  <div class="user-info">
    <div class="icon icon-bold-user"></div>
    <div class="user-name">{{ sessionInfo.userName }}</div>
  </div>
  <dx-context-menu
    *ngIf="menuMode === 'context'"
    [items]="menuItems"
    [position]="menuPosition"
    cssClass="user-menu"
    itemTemplate="itemTemplate"
    showEvent="dxclick"
    target=".user-button"
    width="170px"
  >
    <div *dxTemplate="let itemObj of 'itemTemplate'">
      <i class="icon icon-{{ itemObj.icon }}"></i>
      {{ itemObj.text | transloco }}
    </div>
  </dx-context-menu>
  <dx-list *ngIf="menuMode === 'list'" [items]="menuItems" class="dx-toolbar-menu-action"></dx-list>
</div>
