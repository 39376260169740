import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
import { ERMedicalAccess, ERTherapy } from '@nida-web/api/generic-interfaces/emergencyroom';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'nida-web-er-therapy',
  templateUrl: './er-therapy.component.html',
  styleUrls: ['./er-therapy.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ErTherapyComponent implements OnInit {
  public medicalAccessList: ERMedicalAccess[] | null;
  public therapyData: ERTherapy;

  public therapyForm: UntypedFormGroup;

  constructor(private emergencyRoomStore: EmergencyRoomStoreService) {}

  ngOnInit(): void {
    this.emergencyRoomStore.medicalAccessListByTherapyData.subscribe((response) => {
      if (response && response.data) {
        this.medicalAccessList = response.data;
        // console.log(response.data);
      } else {
        this.medicalAccessList = null;
      }
    });

    this.emergencyRoomStore.therapyData.subscribe((response) => {
      if (response !== undefined && response.id !== undefined) {
        this.therapyData = response;
        this.initFormGroups();
      }
    });
  }

  initFormGroups(): void {
    this.therapyForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.therapyData.id),

      cervicalSpineImmobilizationTime: new UntypedFormControl(this.therapyData.cervicalSpineImmobilizationTime),
      intubationTime: new UntypedFormControl(this.therapyData.intubationTime),
      thoracicDrainageLeftTime: new UntypedFormControl(this.therapyData.thoracicDrainageLeftTime),
      thoracicDrainageRightTime: new UntypedFormControl(this.therapyData.thoracicDrainageRightTime),
      oxygenTime: new UntypedFormControl(this.therapyData.oxygenTime),
      analgosedationTime: new UntypedFormControl(this.therapyData.analgosedationTime),
      thermoprotectionTime: new UntypedFormControl(this.therapyData.thermoprotectionTime),
      smallVolumeResuscitationTime: new UntypedFormControl(this.therapyData.smallVolumeResuscitationTime),
      cardiacMassageTime: new UntypedFormControl(this.therapyData.cardiacMassageTime),
      catecholaminesTime: new UntypedFormControl(this.therapyData.catecholaminesTime),
      crystalloidsTime: new UntypedFormControl(this.therapyData.crystalloidsTime),
      crystalloidsMl: new UntypedFormControl(this.therapyData.crystalloidsMl),
      colloidsTime: new UntypedFormControl(this.therapyData.colloidsTime),
      colloidsMl: new UntypedFormControl(this.therapyData.colloidsMl),
      hemostasis: new UntypedFormControl(this.therapyData.hemostasis),
      fracture: new UntypedFormControl(this.therapyData.fracture),
      pelvicBeltTime: new UntypedFormControl(this.therapyData.pelvicBeltTime),
      laminectomyTime: new UntypedFormControl(this.therapyData.laminectomyTime),
      thoracotomyTime: new UntypedFormControl(this.therapyData.thoracotomyTime),
      laparotomyTime: new UntypedFormControl(this.therapyData.laparotomyTime),
      revascularizationTime: new UntypedFormControl(this.therapyData.revascularizationTime),
      embolisationTime: new UntypedFormControl(this.therapyData.embolisationTime),
      pelvisStabilisationTime: new UntypedFormControl(this.therapyData.pelvisStabilisationTime),
      extremitiesStabilisationTime: new UntypedFormControl(this.therapyData.extremitiesStabilisationTime),
      comment: new UntypedFormControl(this.therapyData.comment),
      defibrillationTime: new UntypedFormControl(this.therapyData.defibrillationTime),
      pressureBandage: new UntypedFormControl(this.therapyData.pressureBandage),
      tourniquet: new UntypedFormControl(this.therapyData.tourniquet),
      hemostyptics: new UntypedFormControl(this.therapyData.hemostyptics),
      immobilised: new UntypedFormControl(this.therapyData.immobilised),
      mountedTrueToAxis: new UntypedFormControl(this.therapyData.mountedTrueToAxis),

      supraglotticRespiratoryAidTime: new UntypedFormControl(this.therapyData.supraglotticRespiratoryAidTime),
      surgicalRespiratoryAidTime: new UntypedFormControl(this.therapyData.surgicalRespiratoryTime),
      tranexamicAcidTime: new UntypedFormControl(this.therapyData.tranexamicAcidTime),
      reduced: new UntypedFormControl(this.therapyData.reduced),

      cerebralPressureReliefTime: new UntypedFormControl(this.therapyData.cerebralPressureReliefTime),
    });
  }

  autoFillDateOnTableCellClick(event) {
    if (event.rowType === 'data') {
      if (!event.value) {
        if (event.columnIndex === 3) {
          event.component.cellValue(event.row.rowIndex, event.columnIndex, new Date());
        }
      }
    }
  }

  autoFillDateOnCheckboxClick(date: string) {
    if (this.therapyForm.getRawValue()[date] === null || this.therapyForm.getRawValue()[date] === undefined) {
      this.therapyForm.controls[date].patchValue(new Date());
      this.therapyForm.markAsDirty();
    } else {
      this.therapyForm.controls[date].patchValue(null);
      this.therapyForm.markAsDirty();
    }
  }

  autoFillDateOnFieldClick(date: string) {
    if (this.therapyForm.getRawValue()[date] === null || this.therapyForm.getRawValue()[date] === undefined) {
      this.therapyForm.controls[date].patchValue(new Date());
      this.therapyForm.markAsDirty();
    }
  }

  saveAccess(e: any) {
    const object = e.data;
    object['therapyId'] = this.therapyData.id;

    const subscription = this.emergencyRoomStore.createMedicalAccess(object).subscribe((response) => {
      if (response && this.medicalAccessList) {
        // this.medicalAccessList[this.medicalAccessList?.length-1] = response;
        notify({
          message: 'Änderungen erfolgreich übernommen!',
          type: 'Success',
          displayTime: 5000,
        });
      } else {
        notify({
          message: 'Vorgang nicht erfolgreich beendet!',
          type: 'Error',
          displayTime: 5000,
        });
      }
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  updateAccess(e: any) {
    const object = e.data;
    object['therapyId'] = this.therapyData.id;

    if (e.data.id) {
      const subscription = this.emergencyRoomStore.updateMedicalAccess(e.data.id, object).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    } else {
      console.log('ERROR: Access id was wrong or simply not existent.');
    }
  }

  deleteAccess(e: any) {
    if (e.data.id) {
      const subscription = this.emergencyRoomStore.deleteMedicalAccess(e.data.id).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    } else {
      console.log('ERROR: Access id was wrong or simply not existent.');
    }
  }

  // EVENT TRIGGERED:

  saveAll() {
    const rawTherapy = this.therapyForm.getRawValue();

    rawTherapy.surgicalRespiratoryTime = rawTherapy.surgicalRespiratoryAidTime;
    delete rawTherapy.surgicalRespiratoryAidTime;

    const therapy: ERTherapy = rawTherapy as ERTherapy;
    if (therapy.id !== undefined) {
      this.emergencyRoomStore.updateTherapy(therapy.id, therapy).subscribe((therapyResult) => {
        if (therapyResult) {
          this.therapyData = therapyResult;
          this.initFormGroups();
          // this.medicalAccessList[this.medicalAccessList?.length-1] = response;
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
      });
    }
    // this.submitForms();
  }

  resetAll() {
    /*
    FormGroup.reset() sets back any data (also pre existing in the db)
    FormGroup.reset() acts more like "clear"
     */

    // this.diagnosisFormGroup.reset()
    this.initFormGroups();
  }
}
