import { Component, NgZone, OnInit } from '@angular/core';
import { PatientTableComponent } from '../patient-table/patient-table.component';
import { Router } from '@angular/router';
import { PatientArchiveStoreService } from '../services/store/patient-archive.store.service';
import { ColumnProviderService, DateService } from '@nida-web/core';
import { TranslocoService } from '@ngneat/transloco';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { PatientMappingService } from '@nida-web/api/rest/patient-management';
import { ElasticStoreService } from '../services/store/elastic.store.service';
import { ElasticQueryString, ElasticRestService } from '@nida-web/api/rest/nidaserver/elastic';

@Component({
  selector: 'nida-web-patient-table-archive',
  templateUrl: '../patient-table/patient-table.component.html',
  styleUrls: ['../patient-table/patient-table.component.scss'],
})
export class PatientTableArchiveComponent extends PatientTableComponent implements OnInit {
  constructor(
    protected router: Router,
    protected columnService: ColumnProviderService,
    protected dateService: DateService,
    protected zone: NgZone,
    protected transloco: TranslocoService,
    protected session: SessionManagerService,
    protected patientListArchiveStoreService: PatientArchiveStoreService,
    protected exampleElkRestService: ElasticRestService,
    protected patientMappingService: PatientMappingService,
    protected elasticStoreService: ElasticStoreService
  ) {
    super(router, columnService, dateService, transloco, session, exampleElkRestService, patientMappingService, elasticStoreService, zone);
    this.gridName = 'patientArchiveGrid';
    this.configName = 'ArchivePatientColumns';
    this.columnService.buildTableColumns('ArchivePatientColumns');
  }

  ngOnInit(): void {
    this.getColumns();
    this.initializeColumnHovered();
    this.elasticStoreService.indexName = 'nidatracker-patient-archive';
    const queryString: ElasticQueryString = {
      query: '1',
      fields: ['protokoll.archiviert'],
    };
    this.elasticStoreService.constQueryStrings = [queryString];
    this.elasticStoreService.setDefaultSort('protokoll.id', 'desc', 'long');
    this.fetchData();
  }

  triggerNavigation(input: any) {
    this.router.navigate(['/patients/details/' + input]).then();
  }

  onRowClick(e: any): void {
    if (!this.contentClicked) {
      this.triggerNavigation(e.data.protocolId);
    } else {
      this.contentClicked = false;
    }

    this.onRowPrepared(e);
  }
}
