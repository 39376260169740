<nida-web-card class="mb-4" [translatedHeadline]="headerName">
  <form (submit)="trySaveNewPassword()" [formGroup]="passwordChangeForm">
    <dx-validation-group #passwordChange>
      <div *ngIf="requireUserName" class="pb-2">
        <dx-text-box
          value="userName"
          placeholder="{{ 'Username' | transloco }}{{ ' (optional)' }}"
          formControlName="userName"
          [showClearButton]="true"
          [label]="'Username' | transloco"
          width="100%"
          stylingMode="outlined"
          labelMode="floating"
        >
          <dx-validator>
            <dxi-validation-rule message="Maximal 100 Zeichen" type="stringLength" [max]="100"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>

      <div *ngIf="requireCurrentPassword" class="row mb-3">
        <div class="col-12">
          <dx-text-box
            class="required-field"
            value="oldPassword"
            placeholder="{{ 'Current password' | transloco }}"
            [(mode)]="passwordMode"
            formControlName="oldPassword"
            [showClearButton]="true"
            [label]="'Current password' | transloco"
            width="100%"
            stylingMode="outlined"
            labelMode="floating"
          >
            <dxi-button name="showPassword" location="after" [options]="passwordButton"></dxi-button>
            <dx-validator>
              <dxi-validation-rule
                message="{{ 'settings.changeUserPassword.oldPassword' | transloco }}"
                type="required"
              ></dxi-validation-rule>
              <dxi-validation-rule [message]="'validations.stringLength.max' | transloco: { value: 100 }" type="stringLength" [max]="100">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <nida-web-ui-shared-password-hints></nida-web-ui-shared-password-hints>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <dx-text-box
            class="required-field"
            value="newPassword"
            placeholder="{{ 'New password' | transloco }}"
            [(mode)]="passwordMode"
            formControlName="newPassword"
            [showClearButton]="true"
            [label]="'New password' | transloco"
            width="100%"
            stylingMode="outlined"
            labelMode="floating"
          >
            <dxi-button name="showPassword" location="after" [options]="passwordButton"></dxi-button>
            <dx-validator [validationRules]="passwordValidationRules"></dx-validator>
          </dx-text-box>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <dx-text-box
            class="required-field"
            value="repeatNewPassword"
            placeholder="{{ 'Repeat password' | transloco }}"
            [(mode)]="passwordMode"
            formControlName="passwordReplay"
            [showClearButton]="true"
            mode="password"
            [label]="'Repeat password' | transloco"
            width="100%"
            stylingMode="outlined"
            labelMode="floating"
          >
            <dxi-button name="showPassword" location="after" [options]="passwordButton"></dxi-button>
            <dx-validator>
              <dxi-validation-rule message="{{ 'Repeat password required' | transloco }}" type="required"></dxi-validation-rule>
              <dxi-validation-rule
                [comparisonTarget]="this.passwordComparison"
                message="{{ 'settings.changeUserPassword.notEqual' | transloco }}"
                type="compare"
              ></dxi-validation-rule>
              <dxi-validation-rule [message]="'validations.stringLength.max' | transloco: { value: 100 }" type="stringLength" [max]="100">
              </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>
      <div class="row">
        <div class="col-9 d-none d-sm-block"></div>
        <div class="col d-flex justify-content-end">
          <ng-container *ngIf="redirect">
            <dx-button [text]="buttonName" [useSubmitBehavior]="true" type="default" width="100%"></dx-button>
          </ng-container>
          <ng-container *ngIf="!redirect">
            <dx-button [text]="buttonName" [useSubmitBehavior]="true" type="default" width="100%"></dx-button>
          </ng-container>
        </div>
      </div>
    </dx-validation-group>
  </form>
</nida-web-card>
