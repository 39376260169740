import { Injectable } from '@angular/core';
import { Findings } from '@nida-web/api/generic-interfaces/patient-management';
import { FindingMapping } from '../models/finding-mapping.model';
import { MappingConfigServable } from '../interfaces/mapping-config-servable.interface';
import { MappingFunctionsProviderService } from './mapping-functions-provider.service';
import { throwError } from 'rxjs';
import { Finding } from '../models/finding';

@Injectable({
  providedIn: 'root',
})
export class FindingMappingService {
  private findingMapping?: FindingMapping;

  constructor(
    private mappingConfigServable: MappingConfigServable,
    private mappingFunctionsProviderService: MappingFunctionsProviderService
  ) {
    this.mappingConfigServable.getMappingConfig().subscribe((newMappingConf) => {
      if (newMappingConf.findingMapping !== undefined) {
        this.findingMapping = newMappingConf.findingMapping;
      }
    });
  }

  public mapValues(data: Finding[]): Findings {
    const findingMapping = this.findingMapping;
    const resultObj = new Findings();
    if (findingMapping === undefined) {
      throwError('Mapping not Loaded! Critical!');
    } else {
      for (const item of data) {
        for (const el of Object.entries(findingMapping).filter(
          (elItem) => item['befund.beschreibung'] === elItem[1].sourceItem && item['befund.befund_art'] === elItem[1].sourceGroup
        )) {
          switch (el[1].sourceDataType) {
            case 'integer': {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              resultObj[el[0]] = this.mappingFunctionsProviderService['mapping' + el[1].mappingFunction](item['befund.wert_int']);
              break;
            }
            case 'float': {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              resultObj[el[0]] = this.mappingFunctionsProviderService['mapping' + el[1].mappingFunction](item['befund.wert_float']);
              break;
            }
            case 'datetime': {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              resultObj[el[0]] = this.mappingFunctionsProviderService['mapping' + el[1].mappingFunction](item['befund.wert_datetime']);
              break;
            }
            case 'string': {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              resultObj[el[0]] = this.mappingFunctionsProviderService['mapping' + el[1].mappingFunction](item['befund.wert_string']);
              break;
            }
            default: {
              break;
            }
          }
        }
      }
    }
    return resultObj;
  }
}
