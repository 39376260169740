import { Component, Input, OnInit } from '@angular/core';
import { DetailData, PatientView } from '@nida-web/api/generic-interfaces/patient-management';

@Component({
  selector: 'nida-web-kis-tile',
  templateUrl: './kis-tile.component.html',
  styleUrls: ['./kis-tile.component.scss'],
})
export class KisTileComponent implements OnInit {
  @Input() patientData: PatientView;
  @Input() detailData: DetailData;
  @Input() loaded: boolean;

  constructor() {
    this.detailData = new DetailData();
    this.patientData = new PatientView('-1', 0);
    this.loaded = false;
  }

  ngOnInit(): void {}
}
