import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthTypeConfiguration } from '../models/authType-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class AuthTypeSettingsService {
  private authTypeConfigSubj: Subject<AuthTypeConfiguration>;

  constructor() {
    this.authTypeConfigSubj = new ReplaySubject<AuthTypeConfiguration>(1);
  }

  public updateSettings(authType: AuthTypeConfiguration): void {
    this.authTypeConfigSubj.next(authType);
  }

  public getSettings(): Observable<AuthTypeConfiguration> {
    return this.authTypeConfigSubj.asObservable();
  }
}
