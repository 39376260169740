import { Injectable } from '@angular/core';
import { ERPreclinicDeprecated } from '@nida-web/api/generic-interfaces/protocol';
import { ApiMeasureList } from '../models/api-measure-list';

@Injectable({
  providedIn: 'root',
})
export class MeasureMappingService {
  public mapValues(rawData: ApiMeasureList, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    for (const singleMeasure of rawData.data) {
      switch (singleMeasure.value_1) {
        case 'Thoraxdrainage':
          switch (singleMeasure.value_3) {
            case 'rechts':
            case 'links':
              refObj.therapy.thoraxDrainage = singleMeasure.timeStamp;
              break;
          }
          break;
        case 'Wärmeerhalt':
          refObj.therapy.thermalProtection = singleMeasure.timeStamp;
          break;
        case 'Verband':
          if (singleMeasure.timeStamp && (!refObj.therapy.hemostasisStart || refObj.therapy.hemostasisStart > singleMeasure.timeStamp)) {
            refObj.therapy.hemostasisStart = singleMeasure.timeStamp;
          }
          refObj.therapy.hemostasisBandage = true;
          break;
      }

      switch (singleMeasure.value_2) {
        case 'Intubation':
          switch (singleMeasure.value_3) {
            case 'Endotrachealtubus':
            case 'Larynxtubus':
            case 'Combitubus':
            case 'fiberoptische / elektrische Intubationshilfe':
            case 'elektrische Intubationshilfe':
            case 'fiberoptische Intubationshilfe':
              if (singleMeasure.timeStamp && (!refObj.therapy.intubation || refObj.therapy.intubation > singleMeasure.timeStamp)) {
                refObj.therapy.intubation = singleMeasure.timeStamp;
              }
              break;

            case 'Wendl-Tubus':
            case 'Larynxmaske':
            case 'Guedeltubus':
            case 'supraglottische Atemwegshilfe':
              if (
                singleMeasure.timeStamp &&
                (!refObj.therapy.supraglotticAirwayDevice || refObj.therapy.supraglotticAirwayDevice > singleMeasure.timeStamp)
              ) {
                refObj.therapy.supraglotticAirwayDevice = singleMeasure.timeStamp;
              }
              break;
            case 'Koniotomie':
              if (singleMeasure.timeStamp && (!refObj.therapy.surgicalAirway || refObj.therapy.surgicalAirway > singleMeasure.timeStamp)) {
                refObj.therapy.surgicalAirway = singleMeasure.timeStamp;
              }
              break;
          }
          break;
        case 'HWS-Immobilisation':
          refObj.therapy.immobilisationHWS = singleMeasure.timeStamp;
          break;
        case 'O2-Gabe':
          if (singleMeasure.timeStamp && (!refObj.therapy.oxygen || refObj.therapy.oxygen > singleMeasure.timeStamp)) {
            refObj.therapy.oxygen = singleMeasure.timeStamp;
          }
          break;
        case 'Zugang':
          if (singleMeasure.value_6) {
            refObj.therapy.circulationAccess.push({
              time: singleMeasure.timeStamp,
              place: singleMeasure.value_3,
              side: singleMeasure.value_5,
              type: singleMeasure.value_6,
            });
          }
          break;
        case 'Defibrilation':
          if (singleMeasure.timeStamp && (!refObj.therapy.defibrillation || refObj.therapy.defibrillation > singleMeasure.timeStamp)) {
            refObj.therapy.defibrillation = singleMeasure.timeStamp;
          }
          break;
      }
    }

    return refObj;
  }
}
