<div>
  <div class="basic-padding">
    <dx-button text="{{ 'Back' | transloco }}" icon="back" (click)="triggerNavigation()"></dx-button>
  </div>
</div>
<div class="content-block full-height">
  <div class="dx-card responsive-paddings full-height">
    <div class="d-flex justify-content-end mb-3">
      <dx-button (click)="openEKGPopUp()" icon="fullscreen"><i class="icon icon-expand"></i></dx-button>
    </div>
    <iframe class="full-height" [src]="corpulsComplete" frameBorder="0" width="100%">Browser not supported.</iframe>
  </div>
</div>
