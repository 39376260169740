<!--
<nida-web-card headline="Emergencyroom">
  <div class="text-center">
    <a class="d-inline-block" (click)="openNav()">
      <i class="icon icon-light-medical-folder active-icon basic-icon-size"></i>
    </a>
  </div>
</nida-web-card>
-->

<!--
<dx-button (click)="openNav()" [text]="'ER Documentation' | transloco" type="default"></dx-button>
-->

<a (click)="openNav()">
  <i class="icon icon-light-medical-folder active-icon basic-icon-size"></i>
</a>
