import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LogoutErrorInterceptorService {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // what should happen here?
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorCodeHandling(error);
        return throwError(error);
      })
    );
  }

  private errorCodeHandling(error: HttpErrorResponse) {
    switch (error.status) {
      case 401: {
        if (
          !error.url?.includes('auth/validate') &&
          !error.url?.includes('auth/me') &&
          !error.url?.includes('auth/logout') &&
          !error.url?.includes('keycloak')
        ) {
          this.router.navigate(['logout']).then(); // TODO: LÖSUNG FÜR KEYCLOAK SUCHEN!!
        }
        break;
      }
    }
  }
}
