import { Injectable } from '@angular/core';
import { ERPreclinicDeprecated, ERSeverityEnum } from '@nida-web/api/generic-interfaces/protocol';
import { DiagnosisList } from '../models/diagnosis-list.model';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisMappingService {
  public mapValues(rawData: DiagnosisList, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    for (const singleDiagnosis of rawData.data) {
      switch (singleDiagnosis.type) {
        case 'Diagnose (führend)':
          if (singleDiagnosis.content?.startsWith('Trauma')) {
            refObj.diagnostic.aisLocalisation.push({
              name: singleDiagnosis.content?.split(' ')[1],
              severity: ERSeverityEnum.NONE, // TODO String / Severity Umrechnung! Tödlich ignorieren
            });
          }
          break;
        case 'Infektion':
          if (singleDiagnosis.content === 'Infektion/Besiedlung mit multiresistenten Erregern (abgedeckt)') {
            refObj.diagnostic.multiresistantCovered = true;
          }
          if (singleDiagnosis.content === 'Infektion/Besiedlung mit multiresistenten Erregern (offen)') {
            refObj.diagnostic.multiresistantOpen = true;
          }
          break;
        default:
      }
    }

    return refObj;
  }
}
