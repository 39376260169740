<ng-content></ng-content>

<div *ngIf="userPermissions" class="menu-container">
  <dx-tree-view
    (onContentReady)="updateSelection($event)"
    (onInitialized)="onMenuInitialized($event)"
    (onItemClick)="onItemClick($event)"
    (onSelectionChanged)="updateSelection($event)"
    [dataSource]="items"
    expandEvent="click"
    itemTemplate="itemTemplate"
    width="100%"
  >
    <div *dxTemplate="let itemObj of 'itemTemplate'" [routerLink]="itemObj.path" [queryParams]="itemObj.params">
      <div *ngIf="compactMode" class="md-menu-item" style="padding: 1.15rem 1.5rem">
        <div
          *ngIf="replaceIconsWithNumbers; else elseCompactMode"
          style="font-size: 1rem; font-weight: bold; border: 2px solid white; border-radius: 5px; padding: 0.2rem 0.5rem 0.2rem 0.5rem"
        >
          {{ itemObj.icon }}
        </div>
        <ng-template #elseCompactMode>
          <i class="icon icon-{{ itemObj.icon }}"></i>
        </ng-template>
      </div>
      <div *ngIf="!compactMode" class="md-menu-item">
        <div
          *ngIf="replaceIconsWithNumbers; else elseNoCompactMode"
          style="
            font-size: 1rem;
            font-weight: bold;
            border: 2px solid white;
            border-radius: 5px;
            margin-right: 0.9rem;
            padding: 0.2rem 0.5rem 0.2rem 0.5rem;
          "
        >
          {{ itemObj.icon }}
        </div>
        <ng-template #elseNoCompactMode>
          <i class="icon icon-{{ itemObj.icon }}" *ngIf="!itemObj.notify"></i>
          <i class="icon icon-{{ itemObj.icon }} ring-bell" *ngIf="itemObj.notify"></i>
        </ng-template>
        <div class="d-flex align-items-center">
          {{ itemObj.text }}
        </div>
        <div *ngIf="itemObj.badge" class="ml-2 d-flex align-items-center">
          <div class="badge badge-danger">{{ itemObj.badge }}</div>
        </div>
      </div>
    </div>
  </dx-tree-view>
</div>
