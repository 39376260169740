import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-patient-row-settings',
  templateUrl: './patient-row-settings.component.html',
  styleUrls: ['./patient-row-settings.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PatientRowSettingsComponent implements OnInit {
  @Input() rawRowData: any;
  @Output() closing: EventEmitter<any> = new EventEmitter();
  @Output() preventDetail: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  changeVisibility() {
    this.rawRowData.data.settings.visible = !this.rawRowData.data.settings.visible;
  }

  triggerClosing() {
    this.closing.emit();
  }

  preventDetailOpening() {
    this.preventDetail.emit();
  }
}
