import { Injectable } from '@angular/core';
import { LocalstorageUserSettingsService } from '@nida-web/shared/local-storage';
import { NewNotification } from '../models/new-notification.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessageService {
  private active: boolean;
  private status: string;

  constructor(private localstorageUserService: LocalstorageUserSettingsService, private transloco: TranslocoService) {
    if (!('Notification' in window) || Notification === undefined) {
      this.active = false;
      this.status = 'Unsupported by browser';
    } else {
      if (Notification.permission === 'granted') {
        this.active = true;
        this.status = 'OK'; // TODO: if user erlaubt => ok; wenn nicht: denied by user;
      } else {
        this.active = false;
        this.status = 'Permission denied';
      }
    }
  }

  public getActive(): boolean {
    return this.active;
  }

  public getStatus(): string {
    return this.status;
  }

  pushNotificationMessage(notification: NewNotification) {
    if (this.active) {
      if (notification.iconPath === null) {
        notification.iconPath = '';
      }

      new Notification(notification.title, { body: notification.text, icon: notification.iconPath, vibrate: [100, 50, 100] });
    }
  }

  public initializeNotificationStatus() {
    const currentUserSettings = this.localstorageUserService.currentUserSettings;
    if (currentUserSettings['notificationMessages']) {
      this.enable();
    } else {
      this.disable();
    }
  }

  public disable(): void {
    this.localstorageUserService.setCurrentUserSettingByKey('notificationMessages', false);

    if (this.active) {
      this.active = false;
      this.status = 'Disabled by user';
    }
  }

  public enable(): void {
    this.localstorageUserService.setCurrentUserSettingByKey('notificationMessages', true);

    if (!this.active) {
      if ('Notification' in window && Notification !== undefined && Notification.permission === 'granted') {
        this.active = true;
        this.status = 'OK';
        this.pushNotificationMessage({
          title: this.transloco.translate('Notifications enabled'),
          text: this.transloco.translate('Notifications successfully enabled.'),
          iconPath: 'assets/resources/ambulance-car.png',
        });
      } else {
        this.status = 'Permission denied or unsupported by browser';
        this.active = false;
      }
    }
  }
}
