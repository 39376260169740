import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class MappingFunctionsProviderService {
  private static priorityMap: Map<string, number> = new Map<string, number>();
  private static sexMap: Map<string, number> = new Map<string, number>();

  constructor() {
    MappingFunctionsProviderService.priorityMap = new Map<string, number>();
    MappingFunctionsProviderService.sexMap = new Map<string, number>();

    this.buildMappingObjects();
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingNormalSet(item) {
    return item;
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingEmptyStringOnNull(item) {
    if (item === null) {
      return '';
    } else {
      return item;
    }
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingZeroNumberOnNull(item) {
    if (item === null) {
      return 0;
    } else {
      return item;
    }
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingPrioritySet(item) {
    this.buildMappingObjects();
    if (item === null) {
      return -1;
    } else {
      return MappingFunctionsProviderService.priorityMap.get(item);
    }
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingDateSet(item) {
    if (item === null) {
      return null;
    }
    return new Date(item);
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingSexSet(item) {
    this.buildMappingObjects();
    return MappingFunctionsProviderService.sexMap.get(item);
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingAgeTypeSet(item) {
    if (item === null || item === '') {
      return 'Jahre';
    } else {
      return item;
    }
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingIsCirculationStable(item): string | null {
    let circulationValue: string;
    if (item === 'instabil') {
      circulationValue = 'instabil';
    } else if (item === 'stabil') {
      circulationValue = 'stabil';
    } else {
      circulationValue = item;
    }
    return circulationValue;
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingYesNoToBoolean(item): boolean | null {
    let yesNoEmpty: boolean | null = null;
    switch (item) {
      case 'ja':
        yesNoEmpty = true;
        break;
      case 'nein':
        yesNoEmpty = false;
        break;
    }
    return yesNoEmpty;
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingZeroOneToBoolean(item): boolean {
    let zeroOneBool = false;
    switch (item) {
      case 1:
        zeroOneBool = true;
        break;
    }
    return zeroOneBool;
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingIsYes(item) {
    return item === 'ja';
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingIsSeen(item) {
    return item !== null && item !== 2;
  }

  // @ts-ignore, will be called at this['mapping' + el[1].mappingFunction]
  public mappingIsNew(item) {
    return item !== 2;
  }

  public calculateAge(actDate: Date, birthDate: Date): number | null {
    if (actDate === null || birthDate === null) {
      return null;
    }
    return dayjs(actDate).diff(birthDate, 'years');
  }

  private buildMappingObjects(): void {
    MappingFunctionsProviderService.priorityMap.set('sofortige Priorität', 2);
    MappingFunctionsProviderService.priorityMap.set('dringende Priorität', 1);
    MappingFunctionsProviderService.priorityMap.set('normale Priorität', 0);
    MappingFunctionsProviderService.sexMap.set('d', 2);
    MappingFunctionsProviderService.sexMap.set('x', 2);
    MappingFunctionsProviderService.sexMap.set('m', 1);
    MappingFunctionsProviderService.sexMap.set('w', 0);
  }
}
