import { Injectable } from '@angular/core';
import { StartPageConfiguration } from '../models/startPage-configuration.model';
import { Router } from '@angular/router';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { DecamelizePipe } from '../pipes/decamelize.pipe';
import { StartPageSettingsService } from './startPage-settings.service';

@Injectable({
  providedIn: 'root',
})
export class StartPageRoutingService {
  constructor(
    private startPageSettingsService: StartPageSettingsService,
    private router: Router,
    private decamelize: DecamelizePipe,
    protected sessionManagerService: SessionManagerService
  ) {}

  calculateRoute(): boolean {
    this.startPageSettingsService.getSettings().subscribe((settings: StartPageConfiguration) => {
      const startPageRedirectUrl = settings.startPageRedirectUrl;
      let startUrl: string = startPageRedirectUrl.default;
      // loop defined roles of start pages. if we have this role we use the url as our start page
      // first hit gives the start page, following hits will be ignored
      Object.entries(startPageRedirectUrl).forEach(([role, url]) => {
        this.sessionManagerService.hasPermission([this.decamelize.transform(role, '.')]).subscribe((result) => {
          if (result) {
            startUrl = url;
            return;
          }
        });
      });
      this.router.navigate([startUrl]);
    });
    return true;
  }
}
