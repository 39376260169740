import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  initNotifications() {
    if ('Notification' in window) {
      if (Notification.permission !== 'denied') {
        Notification.requestPermission().then();
      }
    }
  }
}
