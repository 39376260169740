import { Injectable } from '@angular/core';
import { Kis } from '../../models/kis.model';
import { ApiKis } from '../../models/api-kis.model';

@Injectable({
  providedIn: 'root',
})
export class KisMappingService {
  constructor() {}

  public mapEntry(apiKis: ApiKis): Kis {
    const apiPatientBirthdate = apiKis.patientBirthdate;
    let mappedPatientBirthdate: undefined | Date;
    mappedPatientBirthdate = undefined;

    if (apiPatientBirthdate) {
      mappedPatientBirthdate = new Date(apiPatientBirthdate);
    }
    const apiInsuredBirthdate = apiKis.insuredBirthdate;
    let mappedInsuredBirthdate: undefined | Date;
    mappedInsuredBirthdate = undefined;

    if (apiInsuredBirthdate) {
      mappedInsuredBirthdate = new Date(apiInsuredBirthdate);
    }

    return { ...apiKis, patientBirthdate: mappedPatientBirthdate, insuredBirthdate: mappedInsuredBirthdate };
  }
}
