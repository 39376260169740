import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-er-title-toolbar',
  templateUrl: './er-title-toolbar.component.html',
  styleUrls: ['./er-title-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ErTitleToolbarComponent {
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() showOnlyTitle: boolean;

  @Output() saveTrigger = new EventEmitter<boolean>();
  @Output() revertTrigger = new EventEmitter<boolean>();

  developmentFalse: boolean;

  constructor() {
    this.developmentFalse = false;

    if (this.showOnlyTitle) {
      this.showOnlyTitle = false;
    }
  }

  save() {
    this.saveTrigger.emit();
  }

  revert() {
    this.revertTrigger.emit();
  }
}
