import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientViewList } from '../models/patient-view-list.model';
import { PatientView } from '../models/patient-view.model';

@Injectable({
  providedIn: 'root',
})
export abstract class PatientListServable {
  public abstract setReadProtocol(nidaId: string, id: number): Observable<boolean>;

  public abstract getPatientList(notArchived?: 0 | 1, revision?: number): Observable<PatientViewList>;

  public abstract getPatient(nidaId: string): Observable<PatientView>;

  public abstract getPatientListAfterDate(dateAfter: string): Observable<PatientViewList>;
}
