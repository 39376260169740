import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ERSeverityEnum } from '@nida-web/api/generic-interfaces/protocol';
import { Localisation } from './localisation.model';

@Component({
  selector: 'nida-web-injury-localisation-table',
  templateUrl: './injury-localisation-table.component.html',
  styleUrls: ['./injury-localisation-table.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class InjuryLocalisationTableComponent implements OnInit {
  public localisation: Localisation[];

  public radioItems: number[] = [0, 1, 2, 3];

  @Input() public preclinicForm: UntypedFormGroup;
  @Input() public disabled: boolean;

  constructor() {
    this.localisation = [];
  }

  ngOnInit(): void {
    Object.keys(this.preclinicForm.getRawValue()).forEach((key) => {
      if (key.startsWith('ais')) {
        this.localisation.push(new Localisation(this.displayName(key), key));
      }
    });
  }

  setSeverityClass(severity: number): string {
    if (severity === undefined || severity === null) {
      return 'none';
    }

    switch (severity) {
      case ERSeverityEnum.NONE:
        return 'none';
      case ERSeverityEnum.LIGHT:
        return 'light';
      case ERSeverityEnum.MIDDLE:
        return 'medium';
      case ERSeverityEnum.HEAVY:
        return 'heavy';
      default:
        return 'none';
    }
  }

  displayName(localisationKey: string) {
    if (localisationKey.startsWith('ais')) {
      localisationKey = localisationKey.replace('ais', '');
      localisationKey = localisationKey.replace(/([a-z])_?([A-Z])/, '$1 $2');
    }
    return localisationKey;
  }
}
