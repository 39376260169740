import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { LogoConfiguration } from '../models/logo-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class LogoSettingsService {
  private logoConfigSubj: Subject<LogoConfiguration>;

  constructor() {
    this.logoConfigSubj = new ReplaySubject<LogoConfiguration>(1);
  }

  public updateSettings(settings: LogoConfiguration): void {
    this.logoConfigSubj.next(settings);
  }

  public getSettings(): Observable<LogoConfiguration> {
    return this.logoConfigSubj.asObservable();
  }
}
