import { Injectable } from '@angular/core';
import { EcgRestService } from '../actions/ecg.rest.service';
import {
  Ecg,
  EcgLead,
  EcgLeadData,
  EcgLeadDataByType,
  EcgLeadMeta,
  EcgList,
  EcgMeta,
  EcgServable,
} from '@nida-web/api/generic-interfaces/ecg-viewer';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiEcgList } from '../models/api-ecg-list.model';
import { ApiEcg413 } from '../models/api-ecg-4-13.model';
import { ApiEcgLead } from '../models/api-ecg-lead.model';
import { ApiEcgLeadData } from '../models/api-ecg-lead-data.model';
import { ApiEcgLeadMeta } from '../models/api-ecg-lead-meta.model';

@Injectable({
  providedIn: 'root',
})
export class EcgRestAdapterService implements EcgServable {
  constructor(private ecgRestService: EcgRestService) {}

  getEcgList(nidaId: string): Observable<EcgList> {
    const resultSubject: ReplaySubject<EcgList> = new ReplaySubject<EcgList>(1);

    this.ecgRestService.getEcgList(nidaId).subscribe((apiEcgList: ApiEcgList) => {
      const ecgList: EcgList = [];
      // check if REST Request returns no data here and then just return an empty array to get an empty list
      if (apiEcgList) {
        for (const apiEcgListItem of apiEcgList.data) {
          const ecgListItem = { ...apiEcgListItem };
          ecgList.push(ecgListItem);
        }
      }
      resultSubject.next(ecgList);
    });
    return resultSubject.asObservable();
  }

  getEcg(nidaId: string, ecgId: number, fragment: string): Observable<Ecg> {
    const resultSubject: ReplaySubject<Ecg> = new ReplaySubject<Ecg>(1);
    this.ecgRestService.getEcgJson(ecgId, nidaId, fragment).subscribe((apiEcg: ApiEcg413) => {
      // is13LeadEcg may be used to decide if we have a 13 or 4 lead ecg
      // const is13LeadEcg: boolean = this.determineIfIsApiEcg4OrApiEcg13(apiEcg);
      const ecgLeadDummy: EcgLead = new EcgLead();
      const ecg: Ecg = new Ecg(ecgLeadDummy, ecgLeadDummy, ecgLeadDummy, ecgLeadDummy, ecgLeadDummy, ecgLeadDummy);
      type ecgLeadTypes = 'I' | 'II' | 'III' | 'aVR' | 'aVL' | 'aVF' | 'naVR' | 'v1' | 'v2' | 'v3' | 'v4' | 'v5' | 'v6';

      Object.entries(apiEcg).forEach((lead: [ecgLeadTypes, ApiEcgLead]) => {
        const leadType: ecgLeadTypes = lead[0];
        const leadValue: ApiEcgLead = lead[1];
        const ecgLead: EcgLead = new EcgLead();
        const leadValueMetaData: ApiEcgLeadMeta = leadValue.metadata;
        const ecgLeadMeta: EcgLeadMeta = { ...leadValueMetaData };

        ecgLead.metadata = ecgLeadMeta;
        const leadValueData: ApiEcgLeadData = leadValue.data;
        const leadDataItemCount: number = ecgLeadMeta.length || 0;

        const ecgLeadDataByType: EcgLeadDataByType = new EcgLeadDataByType();
        if (leadValueData !== undefined) {
          ecgLeadDataByType.gainControl = leadValueData.gain_control;
          ecgLeadDataByType.value = leadValueData.value;
          ecgLeadDataByType.valid = leadValueData.valid;
          ecgLeadDataByType.synd = leadValueData.synd;
          ecgLeadDataByType.pacer = leadValueData.pacer;
          ecgLeadDataByType.lowpass = leadValueData.lowpass;
          ecgLeadDataByType.highpass = leadValueData.highpass;
          ecgLeadDataByType.gap = leadValueData.gap;
        }
        ecgLead.dataByType = ecgLeadDataByType;

        for (let i = 0; i < leadDataItemCount; i += 1) {
          const ecgLeadData: EcgLeadData = new EcgLeadData();
          ecgLeadData.id = i;
          ecgLeadData.gainControl = leadValueData.gain_control[i];
          ecgLeadData.value = leadValueData.value[i];
          ecgLeadData.valid = leadValueData.valid[i];
          ecgLeadData.synd = leadValueData.synd[i];
          ecgLeadData.pacer = leadValueData.pacer[i];
          ecgLeadData.lowpass = leadValueData.lowpass[i];
          ecgLeadData.highpass = leadValueData.highpass[i];
          ecgLeadData.gap = leadValueData.gap[i];
          ecgLead.data.push(ecgLeadData);
        }
        ecg[leadType] = ecgLead;
      });
      resultSubject.next(ecg);
    });

    return resultSubject.asObservable();
  }

  getEcgMeta(nidaId: string, ecgId: number): Observable<EcgMeta> {
    const resultSubject: ReplaySubject<EcgMeta> = new ReplaySubject<EcgMeta>(1);

    this.ecgRestService.getEcgMetaJson(ecgId, nidaId, 'meta').subscribe((apiEcgMeta) => {
      const ecgMeta = { ...apiEcgMeta };
      resultSubject.next(ecgMeta);
    });

    return resultSubject.asObservable();
  }

  getEcgPdf(nidaId: string, timestamp: Date): Observable<ArrayBuffer> {
    const resultSubject: ReplaySubject<ArrayBuffer> = new ReplaySubject<ArrayBuffer>(1);

    this.ecgRestService.getEcgPdf(nidaId, timestamp).subscribe(
      (apiEcgPdf: ArrayBuffer) => {
        resultSubject.next(apiEcgPdf);
      },
      () => {
        resultSubject.next(new ArrayBuffer(0));
      }
    );

    return resultSubject.asObservable();
  }
}
