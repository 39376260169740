<div class="component-wrapper" style="display: flex">
  <div class="content-title" style="width: 100%">
    <div>{{ title | transloco }}</div>
  </div>
  <div *ngIf="!showOnlyTitle" class="content-toolbar" style="width: 100%; display: flex; justify-content: flex-end">
    <div>
      <!-- TODO: Find way to set back switches (yet not possible) -->
      <dx-button
        class="toolbar-button"
        [disabled]="disabled"
        (click)="revert()"
        stylingMode="contained"
        text="Zurücksetzen"
        type="normal"
      ></dx-button
      ><!-- TODO: Revert-Icon (kreisförmig gedrehter Pfeil) ! -->
    </div>
    <div>
      <dx-button
        class="toolbar-button"
        [disabled]="disabled"
        (click)="save()"
        stylingMode="contained"
        text="Speichern"
        type="default"
      ></dx-button>
      <!-- TODO: Speicher-Icon (Diskette) ! -->
    </div>
  </div>
</div>
