import { Injectable } from '@angular/core';
import { LocalstorageService } from './localstorage.service';
import { LocalStorageSettings } from '../models/local-storage-settings.model';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageUserSettingsService {
  public currentUserSettings: LocalStorageSettings;
  public userId: number;
  public settingsObject: string;
  private readonly defaultSettingsObject: LocalStorageSettings;

  constructor(private localstorageService: LocalstorageService) {
    this.userId = -1;
    this.settingsObject = 'userSettings';
    this.defaultSettingsObject = new LocalStorageSettings();
    this.currentUserSettings = new LocalStorageSettings();
  }

  initializeSettings(userId: number) {
    this.userId = userId;
    this.checkUserSettings();
  }

  checkUserSettings() {
    if (this.localstorageService.checkObjectExistence(this.settingsObject)) {
      if (this.localstorageService.ifKeyInObject(this.settingsObject, this.userId.toString())) {
        // ... settingsObject and user settings exist

        const mainSettingsObject = this.localstorageService.receiveValueByKeySafe(this.settingsObject);
        this.currentUserSettings = JSON.parse(mainSettingsObject)[this.userId];
      } else {
        // ... settingsObject exists
        // ... but no user settings

        this.localstorageService.saveSubobjectInObject(this.userId.toString(), this.defaultSettingsObject, this.settingsObject);
        this.currentUserSettings = this.defaultSettingsObject;
      }
    } else {
      this.createEmptySettingsObject(this.settingsObject);
      this.localstorageService.saveSubobjectInObject(this.userId.toString(), this.defaultSettingsObject, this.settingsObject);
      this.currentUserSettings = this.defaultSettingsObject;
    }
  }

  setCurrentUserSettingByKey(settingKey: string, value: any) {
    switch (settingKey) {
      case 'notificationSounds':
        this.currentUserSettings.notificationSounds = value;
        break;
      case 'notificationMessages':
        this.currentUserSettings.notificationMessages = value;
        break;
      case 'notificationFilter':
        this.currentUserSettings.notificationFilter = value;
        break;
      case 'notificationFilterRule':
        this.currentUserSettings.notificationFilterRule = value;
        break;
      case 'notificationAudioFileName':
        this.currentUserSettings.notificationAudioFileName = value;
        break;
    }

    this.updateCurrentUserSettings();
  }

  getSettingByKey(settingKey: string) {
    const settings = this.getCurrentUserSettings();
    return Object.entries(settings).filter((entry) => entry[0] === settingKey)[0][1];
  }

  getCurrentUserSettings() {
    if (this.currentUserSettings === undefined) {
      this.checkUserSettings();
      return this.currentUserSettings;
    } else {
      return this.currentUserSettings;
    }
  }

  updateCurrentUserSettings() {
    this.localstorageService.saveSubobjectInObject(this.userId.toString(), this.currentUserSettings, this.settingsObject);
  }

  createEmptySettingsObject(desiredObjectKey: any) {
    this.localstorageService.saveObject(desiredObjectKey, {});
  }
}
