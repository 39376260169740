import { Injectable } from '@angular/core';
import { NotificationMessageService } from './notification-message.service';
import { NotificationSoundService } from './notification-sound.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BadgeCounterService } from '@nida-web/navigation';
import { NewNotification } from '../models/new-notification.model';
import { DxiFilterBuilderField } from 'devextreme-angular/ui/nested/base/filter-builder-field-dxi';
import DataSource from 'devextreme/data/data_source';
import { LocalstorageUserSettingsService } from '@nida-web/shared/local-storage';
import { BehaviorSubject, Observable } from 'rxjs';

interface Lookup<T, K extends keyof T, H extends keyof T> {
  valueField: K;
  displayField: H;
  data: Array<T>;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationBrokerService {
  private filterEnabled: boolean;
  private filterFieldList: Partial<DxiFilterBuilderField>[];
  private filterValue: any[];
  private filterInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private badgeCounterService: BadgeCounterService,
    private notificationMessageService: NotificationMessageService,
    private notificationSoundService: NotificationSoundService,
    private localstorageUserService: LocalstorageUserSettingsService
  ) {
    this.filterEnabled = false;
    this.filterFieldList = [];
    this.filterValue = [];
  }

  setFilterValue(filterValue: any[]) {
    this.localstorageUserService.setCurrentUserSettingByKey('notificationFilterRule', JSON.stringify(filterValue));

    this.filterValue = filterValue;
  }

  getFilterValue(): any[] {
    return this.filterValue;
  }

  addFilterField<T, K extends keyof T, H extends keyof T>(caption: string, dataField: string, dataType: string, lookup?: Lookup<T, K, H>) {
    if (lookup) {
      this.filterFieldList.push({
        caption,
        dataField,
        dataType,
        lookup: { dataSource: lookup.data, valueExpr: lookup.valueField.toString(), displayExpr: lookup.displayField.toString() },
      });
    } else {
      this.filterFieldList.push({ caption, dataField, dataType });
    }
  }

  getFilterFieldList(): DxiFilterBuilderField[] {
    return this.filterFieldList as DxiFilterBuilderField[];
  }

  resetFilterFieldList(): void {
    this.filterFieldList = [];
  }

  enableBroker(): void {
    this.notificationMessageService.initializeNotificationStatus();
    this.notificationSoundService.initializeNotificationStatus();
    const currentUserSettings = this.localstorageUserService.currentUserSettings;
    if (currentUserSettings['notificationFilter'] !== undefined) {
      this.setFilterStatus(currentUserSettings['notificationFilter']);
    }

    if (currentUserSettings['notificationFilterRule'] !== undefined && currentUserSettings['notificationFilterRule'] !== '') {
      this.setFilterValue(JSON.parse(currentUserSettings['notificationFilterRule']));
    }
  }

  addToCounter(): void {
    this.badgeCounterService.add();
  }

  notifyUser(notification: NewNotification, rawValue?: any): void {
    if (!this.filterEnabled || rawValue === undefined) {
      this.notificationMessageService.pushNotificationMessage(notification);
      this.notificationSoundService.playAudio();
    } else {
      const dataSourceFilterCheck: DataSource = new DataSource([rawValue]);
      dataSourceFilterCheck.filter(this.filterValue);
      dataSourceFilterCheck.load().then((data) => {
        if (data.length > 0) {
          this.notificationMessageService.pushNotificationMessage(notification);
          this.notificationSoundService.playAudio();
        }
      });
    }
  }

  remFromCounter(): void {
    this.badgeCounterService.remove();
  }

  getFilterStatus(): boolean {
    return this.filterEnabled;
  }

  getFilterInitializedStatus(): Observable<boolean> {
    return this.filterInitialized.asObservable();
  }

  setFilterInitializedStatus(status: boolean): void {
    this.filterInitialized.next(status);
  }

  setFilterStatus(filterEnabled: boolean): void {
    if (this.filterFieldList.length === 0) {
      console.warn('NotificationBroker - Warning: No Filterlist available.');
    }
    this.localstorageUserService.setCurrentUserSettingByKey('notificationFilter', filterEnabled);

    this.filterEnabled = filterEnabled;
  }
}
