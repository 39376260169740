import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ServerStatusService } from '@nida-web/api/rest/authentication';
import { Endpoint } from '@nida-web/api/rest/authentication';

@Injectable({
  providedIn: 'root',
})
export class AvailableEndpointsStoreService {
  private endpointsCache: Endpoint[] | null = null;

  constructor(private serverStatusService: ServerStatusService) {}

  getEndpoints(forceReload = false): Observable<Endpoint[]> {
    // Wenn der Cache vorhanden ist und kein Neuladen erzwungen wird, gib den Cache zurück
    if (this.endpointsCache && !forceReload) {
      return of(this.endpointsCache);
    }

    return this.serverStatusService.getAvailableEndpoints().pipe(
      map((endpoints) => (endpoints.data ? endpoints.data : [])),
      tap((endpoints) => (this.endpointsCache = endpoints))
    );
  }

  reloadEndpoints(): void {
    this.getEndpoints(true).subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public static endpointsContained(availableEndpoints: Endpoint[], requiredEndpoints: Endpoint[]): boolean {
    return requiredEndpoints.every((requiredEndpoint) => {
      const matchingEndpoint = availableEndpoints.find((endpoint) => endpoint.path === requiredEndpoint.path);

      if (!matchingEndpoint) return false;

      // Überprüfe, ob alle erforderlichen Methoden vorhanden sind
      return requiredEndpoint.methods?.every((method) => matchingEndpoint.methods?.includes(method)) || false;
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public static pathsContained(availableEndpoints: Endpoint[], requiredPaths: string[]): boolean {
    return requiredPaths.every((requiredPath) => availableEndpoints.some((endpoint) => endpoint.path === requiredPath));
  }
}
