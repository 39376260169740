import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MappingConfigSubjectService } from './mapping-config-subject.service';
import { ColumnConfigSubjectService } from './column-config-subject.service';
import { MappingConfig } from '@nida-web/api/rest/patient-management';
import { AppInfoService } from './app-info.service';
import { ColumnConfig } from '@nida-web/core';
import { ConfigSubjectService } from './config-subject.service';
import { ApiConfig } from '../models/ApiConfig';
import { Environment } from '../models/Environment';
import { CountryCode } from '../models/country-code';

@Injectable()
export class ConfigService {
  constructor(
    private http: HttpClient,
    private configSubject: ConfigSubjectService,
    private mappingConfigSubjectService: MappingConfigSubjectService,
    private columnConfigSubjectService: ColumnConfigSubjectService,
    private appInfoService: AppInfoService,
    @Inject('environment') private environment: Environment
  ) {}

  private static checkForHttps(httpsUrl: string): boolean {
    return httpsUrl.length > 0;
  }

  private getApiConfig(configbasepath: string, product: string, customerKey = 'default'): Promise<ApiConfig> {
    return new Promise<ApiConfig>((resolve) => {
      this.http.get<ApiConfig>(configbasepath + product + '/' + customerKey + '/config.json').subscribe((response) => {
        resolve(response);
      });
    });
  }

  private getMappingConfig(configbasepath: string, product: string, customerKey = 'default'): Promise<MappingConfig> {
    return new Promise<MappingConfig>((resolve) => {
      this.http.get<MappingConfig>(configbasepath + product + '/' + customerKey + '/mapping.json').subscribe((response) => {
        resolve(response);
      });
    });
  }

  private getColumnConfig(configbasepath: string, product: string, customerKey = 'default'): Promise<ColumnConfig> {
    return new Promise<ColumnConfig>((resolve) => {
      this.http.get<ColumnConfig>(configbasepath + product + '/' + customerKey + '/columns.json').subscribe((response) => {
        resolve(response);
      });
    });
  }

  public loadConfig(): Promise<ApiConfig> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<ApiConfig>(async (resolve) => {
      const customerKey = localStorage.getItem('customer_Key');
      let configFolder: string;

      if (customerKey) {
        configFolder = customerKey;
      } else {
        configFolder = this.environment.customer;
      }

      this.appInfoService.title = this.environment.product;

      await this.getMappingConfig(this.environment.configbasepath, this.environment.product, configFolder).then(
        (mappingConfig: MappingConfig) => {
          this.mappingConfigSubjectService.updateMappingConfigSubject(mappingConfig);
        }
      );

      await this.getColumnConfig(this.environment.configbasepath, this.environment.product, configFolder).then((columnConfig) => {
        this.columnConfigSubjectService.updateColumnConfigSubject(columnConfig);
      });

      await this.getApiConfig(this.environment.configbasepath, this.environment.product, configFolder).then((apiconfig: ApiConfig) => {
        this.configSubject.updateApiConfigSubject(apiconfig);
        if (apiconfig.TargetSystems.NIDAserver) {
          if (ConfigService.checkForHttps(apiconfig.TargetSystems.NIDAserver.https)) {
            this.configSubject.updateApiUrlSubject(apiconfig.TargetSystems.NIDAserver.https);
          } else {
            this.configSubject.updateApiUrlSubject(apiconfig.TargetSystems.NIDAserver.http);
          }
        } else {
          this.configSubject.updateApiUrlSubject(this.environment.defaultApiUrl);
        }

        if (apiconfig.TargetSystems.NIDAgateway) {
          this.configSubject.updateApiUrlGatewaySubject(apiconfig.TargetSystems.NIDAgateway.https);
        }

        if (apiconfig.ProductDisplayName) {
          this.appInfoService.title = apiconfig.ProductDisplayName;
        }

        if (apiconfig.ProductDefaultCountryCode) {
          if (!Object.values<string>(CountryCode).includes(apiconfig.ProductDefaultCountryCode)) {
            console.error('CONFIG-SETUP-WARNING: ' + apiconfig.ProductDefaultCountryCode + ' is not valid! Falling back to "de"!');
            apiconfig.ProductDefaultCountryCode = CountryCode.DE;
          }
          this.appInfoService.countryCode = apiconfig.ProductDefaultCountryCode;
        } else {
          this.appInfoService.countryCode = CountryCode.DE;
        }

        resolve(apiconfig);
      });
    });
  }
}
