import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenService, LogoSettingsService } from '@nida-web/core';
import { SessionManagerService } from '@nida-web/api/rest/authentication';

interface userMenuItem {
  text: string;
  icon: string;
  onClick: () => void;
  permissions?: string[];
}

@Component({
  selector: 'nida-web-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() menuToggleEnabled = false;
  @Input() title: string;
  @Input() headerTemplate: TemplateRef<any> | null;

  @Output() menuToggle = new EventEmitter<boolean>();

  public logoSettings: string[];
  public userMenuItems: userMenuItem[] = [
    {
      text: 'Settings',
      icon: 'bold-settings',

      onClick: () => {
        this.router.navigate(['settings'], { relativeTo: this.route }).then();
      },
    },
    {
      text: 'navigation.personalData',
      icon: 'address-book',
      onClick: () => {
        this.router.navigate(['user/active-user/me'], { relativeTo: this.route }).then();
      },
      permissions: ['administration.rechterollen'],
    },
    {
      text: 'Logout',
      icon: 'bold-exit',
      onClick: () => {
        this.router.navigate(['logout']).then();
      },
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private screenService: ScreenService,
    private sessionManager: SessionManagerService,
    private logoSettingsService: LogoSettingsService
  ) {
    this.logoSettings = [];
    this.checkPermission();
  }

  public ngOnInit(): void {
    this.logoSettingsService.getSettings().subscribe((settings) => {
      for (const [logoName, show] of Object.entries(settings)) {
        if (show) {
          this.logoSettings.push(logoName);
        }
      }
    });
  }

  checkPermission() {
    this.userMenuItems.forEach((userMenuItem, index, userMenuItems) => {
      if (userMenuItem.permissions && userMenuItem.permissions.length > 0) {
        this.sessionManager.hasPermission(userMenuItem.permissions).subscribe((hasPermission) => {
          if (!hasPermission) {
            userMenuItems.splice(index, 1);
          }
        });
      }
    });
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  };

  getScreen(): string {
    return this.screenService.screenSize;
  }
}
