import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ColumnConfig, ColumnConfigServable } from '@nida-web/core';

@Injectable({
  providedIn: 'root',
})
export class ColumnConfigSubjectService implements ColumnConfigServable {
  private mappingConfig = new ReplaySubject<ColumnConfig>(1);

  public readonly mappingConfigObservable = this.mappingConfig.asObservable();

  public updateColumnConfigSubject(columnConfig: ColumnConfig): void {
    this.mappingConfig.next(columnConfig);
  }

  getColumnConfig(): Observable<ColumnConfig> {
    return this.mappingConfigObservable;
  }
}
