import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppUpdateService } from './services/app-update.service';
import { CheckForUpdateService } from './services/check-for-update.service';

@NgModule({
  imports: [CommonModule],
  providers: [AppUpdateService, CheckForUpdateService],
})
export class SharedDataAccessModule {}
