import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { ProtocolService } from '../actions/protocol.service';
import { MeasureMappingService } from '../mapping/measure-mapping.service';
import { ResultMappingService } from '../mapping/result-mapping.service';
import { ERPreclinicDeprecated, ProtocolServable } from '@nida-web/api/generic-interfaces/protocol';
import { ProtocolMappingService } from '../mapping/protocol-mapping.service';
import { DiagnosisMappingService } from '../mapping/diagnosis-mapping.service';
import { FreeTextMappingService } from '../mapping/free-text-mapping.service';
import { InjuryMappingService } from '../mapping/injury-mapping.service';
import { VitalSignsRestService } from '@nida-web/api/rest/patient-management';
import { VitalSignMappingService } from '../mapping/vital-sign-mapping.service';
import { Protocol } from '../models/protocol.model';
import { ArchiveFlag } from '../models/archiveFlag';
import { FindingMappingService } from '../mapping/finding-mapping.service';

@Injectable({
  providedIn: 'root',
})
export class ProtocolAdapterService implements ProtocolServable {
  private static REQ_VAL = 11;

  constructor(
    private protocolService: ProtocolService,
    private vitalSignsService: VitalSignsRestService,
    private protocolMapping: ProtocolMappingService,
    private measureMapping: MeasureMappingService,
    private resultMapping: ResultMappingService,
    private diagnosisMapping: DiagnosisMappingService,
    private freeTextMapping: FreeTextMappingService,
    private injuryMapping: InjuryMappingService,
    private findingMapping: FindingMappingService,
    private vitalSignMapping: VitalSignMappingService
  ) {}

  public archiveProtocol(protocolId: number, archive: boolean): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);
    const body: ArchiveFlag = {
      archived: archive ? 1 : 0,
    };

    this.protocolService.archiveProtocol(body, protocolId.toString()).subscribe((protocoll: Protocol) => {
      resultSubject.next(protocoll.archived === 1);
    });
    return resultSubject;
  }

  public getPreclinicalData(protocolId: number): Observable<ERPreclinicDeprecated> {
    const resultSubj = new ReplaySubject<ERPreclinicDeprecated>(1);

    let requestCounter = 0;

    const resultObj: ERPreclinicDeprecated = {
      diagnostic: { aisLocalisation: [] },
      dispatchTimestamps: {},
      fistVitalSigns: {},
      incidentDescription: {},
      therapy: { circulationAccess: [] },
    };

    const requestCountingSubject = new ReplaySubject<number>(1);
    requestCountingSubject.subscribe((reqCounter) => {
      if (reqCounter >= ProtocolAdapterService.REQ_VAL) {
        resultSubj.next(resultObj);
      }
    });

    this.protocolService.getProtocolById(protocolId.toString()).subscribe((protocoll) => {
      this.protocolMapping.mapValues(protocoll, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.protocolService.getDiagnosisByProtocolId(protocolId).subscribe((diagnosisList) => {
      this.diagnosisMapping.mapValues(diagnosisList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.protocolService.getFreeTextsByProtocolId(protocolId).subscribe((freeTextList) => {
      this.freeTextMapping.mapValues(freeTextList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.protocolService.getInjuriesByProtocolId(protocolId).subscribe((injuryList) => {
      this.injuryMapping.mapValues(injuryList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.protocolService.getMeasuresByProtocolId(protocolId).subscribe((measureList) => {
      this.measureMapping.mapValues(measureList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.protocolService.getResultsByProtocolId(protocolId).subscribe((resultList) => {
      this.resultMapping.mapValues(resultList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.protocolService.getFindingByProtocolId(protocolId).subscribe((resultList) => {
      this.findingMapping.mapValues(resultList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    /**
     * @deprecated please use data from this.protocolService.getFindingByProtocolId instead.
     * @todo check if everything works after deactivation
     */
    // this.detailService.getDetailBefund(protocolId.toString()).subscribe((findingList: ApiFindingList) => {
    //   this.findingMapping.mapValues(findingList, resultObj);
    // requestCountingSubject.next(++requestCounter);
    // });

    this.vitalSignsService.getAfByProtocolId(protocolId).subscribe((vitalSignList: any) => {
      this.vitalSignMapping.mapAFValues(vitalSignList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.vitalSignsService.getSpo2ByProtocolId(protocolId).subscribe((vitalSignList: any) => {
      this.vitalSignMapping.mapSPO2Values(vitalSignList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.vitalSignsService.getCo2ByProtocolId(protocolId).subscribe((vitalSignList: any) => {
      this.vitalSignMapping.mapCO2Values(vitalSignList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    this.vitalSignsService.getHfByProtocolId(protocolId).subscribe((vitalSignList: any) => {
      this.vitalSignMapping.mapHFValues(vitalSignList, resultObj);
      requestCountingSubject.next(++requestCounter);
    });

    return resultSubj.asObservable();
  }
}
