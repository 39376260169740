<div>
  <div style="padding: 10px">
    <nida-web-er-title-toolbar [title]="'Councils'" [showOnlyTitle]="true"></nida-web-er-title-toolbar>
  </div>
  <div class="mb-3 mt-1" style="margin-left: 10px; margin-right: 10px">
    <ng-container *ngIf="councils">
      <div
        *ngFor="let council of councils; index as index"
        class="row mx-0 mt-0 basic-font-color-council"
        style="margin-bottom: 20px; box-shadow: 0 4px 8px 0 darkgrey"
      >
        <form
          [formGroup]="councilsFormArray[index]"
          (keydown.enter)="$event.preventDefault()"
          (submit)="submitForm(index)"
          style="width: 100%"
        >
          <div class="col-12 bg-white">
            <div class="row" style="padding-top: 0.8rem; padding-bottom: 0.8rem">
              <div class="col-10 col-md-11 d-flex align-items-center">
                <div class="d-inline-block pl-3 pr-4">
                  <strong style="font-size: 2.2rem">{{ index + 1 }}</strong>
                </div>
                <div class="d-inline-block ml-1">
                  <div class="d-inline-block mb-1 mb-md-0 mr-sm-5">
                    <p class="mb-0" style="font-size: 0.95rem">Fachrichtung</p>
                    <p class="mb-0 font-weight-bold" style="font-size: 1.1rem">
                      {{ councilsFormArray[index].value.discipline | transloco | displayNan }}
                    </p>
                  </div>
                  <div class="d-block d-sm-inline-block">
                    <p class="mb-0" style="font-size: 0.95rem">Name</p>
                    <p class="mb-0 font-weight-bold" style="font-size: 1.1rem">{{ councilsFormArray[index].value.name | displayNan }}</p>
                  </div>
                </div>
              </div>
              <div
                class="col-2 col-md-1 flex-container justify-content-center align-self-center"
                (click)="councilsMeta[index].opened = !councilsMeta[index].opened"
              >
                <ng-container *ngIf="councilsMeta[index].opened; else elseBlock">
                  <i class="dx-icon-chevronup chevron-font-size chevron-basics"></i>
                </ng-container>
                <ng-template #elseBlock>
                  <i class="dx-icon-chevrondown chevron-font-size chevron-basics"></i>
                </ng-template>
              </div>
            </div>

            <div *ngIf="councilsMeta[index].opened" class="row pt-2 pb-3" style="border-top: 1px solid rgb(221, 221, 221)">
              <div class="col-md-5 col-lg-4 mb-2 mb-sm-0 bg-white justify-content-center align-self-center">
                <div class="row">
                  <div class="col-12 mb-2">
                    <div class="row">
                      <div class="col-12 col-xl-4 mb-1 mb-xl-0 justify-content-center align-self-center">
                        <p class="m-0">{{ 'Specialization' | transloco }}</p>
                      </div>

                      <div class="col-12 col-xl-8 d-flex justify-content-xl-end">
                        <dx-select-box
                          [dataSource]="departmentsDataSource"
                          displayExpr="name"
                          valueExpr="name"
                          [grouped]="true"
                          [searchEnabled]="true"
                          formControlName="discipline"
                          class="w-100"
                        >
                          <dx-validator>
                            <dxi-validation-rule message="Bitte ausfüllen" type="required"></dxi-validation-rule>
                          </dx-validator>
                        </dx-select-box>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-2">
                    <div class="row">
                      <div class="col-12 col-xl-4 mb-1 mb-xl-0 justify-content-center align-self-center">
                        <p class="m-0">{{ 'Name' | transloco }}</p>
                      </div>

                      <div class="col-12 col-xl-8 d-flex justify-content-xl-end">
                        <dx-text-box style="width: 100%" formControlName="name">
                          <dx-validator>
                            <dxi-validation-rule message="Bitte ausfüllen" type="required"></dxi-validation-rule>
                          </dx-validator>
                        </dx-text-box>
                        <!--
                    <dx-date-box width="119" [(value)]="council.specializations.requested" type="date"> </dx-date-box>
                    <dx-date-box width="88" [value]="council.specializations.requested" type="time"> </dx-date-box>
                    -->
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-2">
                    <div class="row">
                      <div class="col-12 col-xl-4 mb-1 mb-xl-0 justify-content-center align-self-center">
                        <p class="m-0">{{ 'Requested' | transloco }}</p>
                      </div>

                      <div class="col-12 col-xl-8 d-flex justify-content-xl-end">
                        <dx-date-box
                          style="width: 100%"
                          (click)="autoFillCurrentRequestedDate(council)"
                          formControlName="requestTime"
                          type="datetime"
                        >
                          <!--                        <dx-validator>-->
                          <!--                          <dxi-validation-rule message="Bitte ausfüllen" type="required"></dxi-validation-rule>-->
                          <!--                        </dx-validator>-->
                        </dx-date-box>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 col-xl-4 mb-1 mb-xl-0 justify-content-center align-self-center">
                        <p class="m-0">{{ 'Arrived' | transloco }}</p>
                      </div>

                      <div class="col-12 col-xl-8 d-flex justify-content-xl-end">
                        <dx-date-box
                          class="w-100"
                          (click)="autoFillCurrentArrivedDate(council)"
                          formControlName="arrivalTime"
                          type="datetime"
                        >
                          <!--                        <dx-validator>-->
                          <!--                          <dxi-validation-rule message="Bitte ausfüllen" type="required"></dxi-validation-rule>-->
                          <!--                        </dx-validator>-->
                        </dx-date-box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-lg-8 bg-white justify-content-center align-self-center">
                <div class="row d-flex">
                  <div class="col-12">
                    <p class="mb-1">{{ 'Description' | transloco }}</p>
                    <dx-text-area autoResizeEnabled="true" [minHeight]="140" [maxHeight]="200" formControlName="description">
                    </dx-text-area>
                  </div>
                </div>
              </div>
              <div class="col-12" style="display: flex; justify-content: flex-end">
                <!--              <dx-button class="mt-3" [text]="'Revert' | transloco" type="normal"></dx-button>-->
                <dx-button
                  [text]="'Delete' | transloco"
                  (click)="deleteCouncil(index)"
                  class="mt-3 button-spacer-left-10"
                  type="normal"
                ></dx-button>
                <dx-button
                  [disabled]="councilsFormArray[index].pristine"
                  [text]="'Save' | transloco"
                  [useSubmitBehavior]="true"
                  class="mt-3 button-spacer-left-10"
                  type="default"
                ></dx-button>
              </div>
              <div class="col-12" style="margin-top: 10px; border-top: 1px solid rgb(221, 221, 221)">
                <h3 class="position-absolute mt-2" style="z-index: 10">{{ 'Arrangements' | transloco }}</h3>
                <dx-data-grid
                  class="mt-1"
                  id="gridContainer"
                  [dataSource]="councilsMeta[index].arrangements"
                  [disabled]="!councils[index].id"
                  [allowColumnReordering]="true"
                  [columnHidingEnabled]="true"
                  [showRowLines]="true"
                  [showBorders]="true"
                  (onInitNewRow)="onInitNewRow($event)"
                  (onCellClick)="autoFillDate($event)"
                  (onRowInserting)="saveOrder($event, council.id, index)"
                  (onRowRemoving)="deleteOrder($event)"
                  (onRowUpdated)="updateOrder($event)"
                >
                  <dxo-paging [enabled]="true"></dxo-paging>
                  <dxo-editing useIcons="true" mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                  </dxo-editing>

                  <dxi-column dataField="description" [caption]="'Arrangement' | transloco">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                  </dxi-column>

                  <dxi-column dataField="time" width="150" dataType="datetime" [caption]="'Point of time' | transloco">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                  </dxi-column>

                  <dxi-column dataField="excecuted" width="100" dataType="boolean" [caption]="'performed' | transloco">
                    <dxi-validation-rule
                      type="custom"
                      [message]="'Pflichtfeld' | transloco"
                      [validationCallback]="performedCheck"
                    ></dxi-validation-rule>
                  </dxi-column>

                  <dxi-column dataField="excecutedBy" [caption]="'by' | transloco">
                    <dxi-validation-rule
                      type="custom"
                      [message]="'Pflichtfeld' | transloco"
                      [validationCallback]="performedByCheck"
                    ></dxi-validation-rule>
                  </dxi-column>

                  <dxi-column dataField="orderTime" width="150" dataType="datetime" [caption]="'Point of time' | transloco">
                    <dxi-validation-rule
                      type="custom"
                      [message]="'Pflichtfeld' | transloco"
                      [validationCallback]="performedTimeCheck"
                    ></dxi-validation-rule>
                  </dxi-column>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-12 d-flex align-items-center">
        <dx-button class="mr-3" icon="add" [disabled]="newEntryMode" (click)="createEmptyCouncil()"></dx-button>
        <h3 class="m-0">{{ 'Add council' | transloco }}</h3>
      </div>
    </div>
  </div>
</div>
