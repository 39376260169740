import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CallHandlerConfig } from '../models/call-handler-config.model';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { MQTTSubscribeListenerService } from '@nida-web/api-mqtt-wrapper';
import { Subscription } from 'rxjs';
import { DxPopupComponent } from 'devextreme-angular';
import { CallService } from '../actions/call.service';
import { MqttConnectionState } from '@meddv/ngx-mqtt';

export const CALL_HANDLER_MAIN_CONFIG = new InjectionToken<CallHandlerConfig>('call-handler-config');

@Injectable({
  providedIn: 'root',
})
export class CallHandlerService {
  private topicSub: Subscription;
  private componentRef: DxPopupComponent;
  private mh: HTMLAudioElement;
  constructor(
    @Inject(CALL_HANDLER_MAIN_CONFIG) private callHandlerConfig: CallHandlerConfig,
    private sessionManager: SessionManagerService,
    private mqttService: MQTTSubscribeListenerService,
    private callService: CallService /*private injector: Injector,
    private resolver: ComponentFactoryResolver*/
  ) {
    this.mh = new Audio('assets/sounds/mh.wav');

    /*const factory = this.resolver.resolveComponentFactory<DxPopupComponent>(DxPopupComponent);
    this.componentRef = factory.create(this.injector);
    this.componentRef.instance.width = '250';
    this.componentRef.instance.height = '300';
    this.componentRef.instance.shading = false;
    this.componentRef.instance.position = 'right bottom';
    this.componentRef.instance.title = 'Anruf RTW 5';
    this.componentRef.instance.contentTemplate = '<nida-web-call-handler-popup-content></nida-web-call-handler-popup-content>';*/

    this.sessionManager.getSessionInformation().subscribe((info) => {
      if (info.loggedIn) {
        this.sessionManager.hasPermission(['nida.tna.calls:rw']).subscribe((result) => {
          if (result) {
            this.mqttService.getConnectionStatusObservable().subscribe((mqttStatus) => {
              if (mqttStatus === MqttConnectionState.CONNECTED) {
                this.topicSub = this.mqttService.registerTopic(this.callHandlerConfig.incomingCallTopic).subscribe((msg) => {
                  if (this.componentRef && this.componentRef.instance) {
                    const jsonMsg = JSON.parse(msg);
                    this.callService.getCall(jsonMsg.id.data).subscribe((callMeta) => {
                      if (callMeta.callState === 'requested') {
                        this.componentRef.title = 'Anruf ' + callMeta.ressourceName;
                        this.callIncomingPopUp();
                      } else {
                        this.componentRef.instance.hide();
                      }
                    });
                  }
                });
              } else {
                this.mqttService.unregisterTopic(this.callHandlerConfig.incomingCallTopic);
                if (this.topicSub) {
                  this.topicSub.unsubscribe();
                }
              } //TODO Else case?
            });
          }
        });
      } else {
        this.mqttService.unregisterTopic(this.callHandlerConfig.incomingCallTopic);
        if (this.topicSub) {
          this.topicSub.unsubscribe();
        }

        if (this.componentRef && this.componentRef.instance) {
          this.componentRef.instance.hide();
        }
      }
    });
  }

  public setPopupChild(popup: DxPopupComponent) {
    this.componentRef = popup;
  }

  private callIncomingPopUp() {
    this.mh.volume = 0.2;
    this.mh.play().then(() => {
      // do nothing
    });
    this.componentRef.instance.show();
  }

  public onClick($event: MouseEvent) {
    console.log($event);
  }
}
