import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Patient, PatientServable } from '@nida-web/api/generic-interfaces/patient-management';
import { PatientRestService } from '../actions/patient.rest.service';

@Injectable({
  providedIn: 'root',
})
export class PatientRestAdapterService implements PatientServable {
  constructor(private patientRestService: PatientRestService) {}

  getPatient(patientId: number): Observable<Patient> {
    const resultSubject = new ReplaySubject<Patient>(1);

    this.patientRestService.getPatientById(patientId.toString()).subscribe((patient) => {
      resultSubject.next(patient);
    });

    return resultSubject.asObservable();
  }

  getPatientByProtocolId(protocolId: number): Observable<Patient> {
    const resultSubject = new ReplaySubject<Patient>(1);

    this.patientRestService.getPatientByProtocolId(protocolId.toString()).subscribe((patient) => {
      resultSubject.next(patient);
    });

    return resultSubject.asObservable();
  }

  putPatient(patient: Patient, id: number): Observable<Patient> {
    const resultSubject = new ReplaySubject<Patient>(1);

    this.patientRestService.savePatient(patient, id.toString()).subscribe((updatedPatient) => {
      resultSubject.next(updatedPatient);
    });

    return resultSubject.asObservable();
  }
}
