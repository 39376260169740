import { Component, Input, OnInit } from '@angular/core';
import { Patient, PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'nida-web-patient-kis',
  templateUrl: './patient-kis.component.html',
  styleUrls: ['./patient-kis.component.scss'],
})
export class PatientKisComponent implements OnInit {
  @Input() loaded: boolean;
  @Input() patientData: PatientView;
  @Input() detailData: Patient;

  constructor(protected route: ActivatedRoute, protected location: Location) {
    this.patientData = new PatientView('-1', 0);
    this.loaded = false;
  }

  ngOnInit(): void {
    const data: any = this.location.getState();
    if (data && data.state) {
      const detailData: Patient | undefined = data.state.detailData;
      const patientData: PatientView | undefined = data.state.patientData;
      if (detailData) {
        this.detailData = detailData;
      }
      if (patientData) {
        this.patientData = patientData;
      }
    }
    this.loaded = true;
  }

  navigateBack(): void {
    this.location.back();
  }
}
