import { Injectable } from '@angular/core';
import { NavItem } from '@nida-web/core';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class BadgeCounterService {
  private activeNavItem: NavItem | undefined;

  public constructor(private navigationServer: NavigationService) {
    this.activeNavItem = this.navigationServer
      .getRawDefaultNavigationItems()
      .find((navitem) => navitem.path === 'current-patients/overview');
  }

  public add(): void {
    if (!this.activeNavItem) return;
    if (this.activeNavItem.badge === undefined) {
      this.activeNavItem.badge = 1;
      // this.nav[0].badge = 0;
    } else {
      this.activeNavItem.badge++;
      // this.nav[0].badge++;
    }
  }

  public remove(): void {
    if (!this.activeNavItem) return;
    if (this.activeNavItem.badge !== undefined) {
      this.activeNavItem.badge--;
      // this.nav[0].badge--;
      if (this.activeNavItem.badge <= 0) {
        this.activeNavItem.badge = undefined;
        // this.nav[0].badge = null;
      }
    }
  }

  public reset(): void {
    if (!this.activeNavItem) return;
    this.activeNavItem.badge = undefined;
    // this.nav[0].badge = null;
  }

  public getActive(): number {
    if (!this.activeNavItem) return 0;
    return this.activeNavItem.badge ? this.activeNavItem.badge : 0;
  }
}
