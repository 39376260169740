// @ts-ignore
import * as CryptoJS from 'crypto-js';

export class HashCreator {
  /**
   * Generates a hash for the given plain text value and returns a base64-encoded result
   *
   * @param {string} plainText
   * @param {string} salt - Base64 encoded
   * @param {string} encodeBase64
   * @param {string} hashAlgorithm - options SHA1, SHA256, SHA384, SHA512, MD5, default: SHA256, fallback: MD5
   */
  public static createHash(plainText: string, salt: string, encodeBase64 = true, hashAlgorithm = 'SHA256'): string {
    // Turn a base-64 encoded string into CryptoJS WordArray
    const saltWord = CryptoJS.enc.Base64.parse(salt);

    // Convert the string to UTF 8
    const plainTextUtf8 = CryptoJS.enc.Utf8.parse(plainText);

    // Concatenate plain text and salt
    const plainTextWithSalt = plainTextUtf8.concat(saltWord);

    // Because we support multiple hashing algorithms, we must define
    // hash object as a common (abstract) base class. We will specify the
    // actual hashing algorithm class later during object creation.
    let hashFunction;

    // Make sure hashing algorithm name is specified.
    if (hashAlgorithm == null) {
      hashAlgorithm = '';
    }

    // Initialize appropriate hashing algorithm class.
    switch (hashAlgorithm.toUpperCase()) {
      case 'SHA1':
        hashFunction = CryptoJS.SHA1;
        break;

      case 'SHA256':
        hashFunction = CryptoJS.SHA256;
        break;

      case 'SHA384':
        hashFunction = CryptoJS.SHA384;
        break;

      case 'SHA512':
        hashFunction = CryptoJS.SHA512;
        break;

      default:
        hashFunction = CryptoJS.MD5;
        break;
    }

    // Compute hash value of our plain text with appended salt.
    const hash = hashFunction(plainTextWithSalt);

    // Concatenate hash and original salt.
    const hashWithSalt = hash.concat(saltWord);

    let enc: any = null;
    // Set Base46 encoding function
    if (encodeBase64) {
      enc = CryptoJS.enc.Base64;
    }

    // Convert result into a base64-encoded string.
    // Return the result.
    return hashWithSalt.toString(enc);
  }
}
