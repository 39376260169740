import { Injectable } from '@angular/core';
import { ConfigSubjectService } from './config-subject.service';

@Injectable({
  providedIn: 'root',
})
export class CorpulsBindingService {
  private bindingLink: string;
  private paramsString: string;
  private params: string[];

  constructor(private configSubjectService: ConfigSubjectService) {
    this.bindingLink = '';
    this.paramsString = '?';
    this.configSubjectService.apiConfig.subscribe((apiobj) => {
      this.bindingLink = apiobj.Corpuls.widgetUrl;
      this.params = apiobj.Corpuls.AllowedParams;
    });
  }

  public setParameter(paramName: string, paramValue: string) {
    if (this.params.includes(paramName)) {
      if (this.paramsString === '?') {
        this.paramsString += paramName + '=' + paramValue;
      } else {
        this.paramsString += '&' + paramName + '=' + paramValue;
      }
    }
  }

  public clearParameters() {
    this.paramsString = '?';
  }

  public generateLink(view: 'livedata' | 'liveview' | 'typelabel' | 'handbook'): string {
    let generatedLink = '';
    switch (view) {
      case 'typelabel':
        generatedLink = this.bindingLink + 'type-label/?locale=de';
        break;
      case 'handbook':
        generatedLink = this.bindingLink + 'app/?servicehandler=manualServiceHandler&locale=de&filetype=GAN';
        break;
      default:
        generatedLink = this.bindingLink + view + '/' + this.paramsString;
        break;
    }
    this.clearParameters();
    return generatedLink;
  }

  public setBindingLink(bindingLink: string): void {
    this.bindingLink = bindingLink;
  }
}
