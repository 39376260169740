/**
 * NIDAelch Elasticsearch integration API
 * Elasticsearch integration provides fast index search and aggregation capabilities in conjunction with NIDAelch. This API is
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { CreatePit } from '../models/createPit';
import { Observable } from 'rxjs';
import { PitId } from '../models/pitId';
import { QueryDSL } from '../models/queryDSL';
import { ElasticResult } from '../models/elasticResult';

@Injectable({
  providedIn: 'root',
})
export class ElasticRestService {
  protected basePath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.basePath = apiUrl + 'elasticsearch';
    });
  }

  /**
   * Forward PIT creation request to elasticsearch
   * The PIT is not associated with the session in any way. It is the client\&#39;s responsibility to handle and prolong the PIT as
   * appropriate.
   * @param indexName
   * @param createPit New PIT
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createPit(
    indexName: string,
    createPit: CreatePit,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<PitId>;
  public createPit(
    indexName: string,
    createPit: CreatePit,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<PitId>>;
  public createPit(
    indexName: string,
    createPit: CreatePit,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<PitId>>;
  public createPit(
    indexName: string,
    createPit: CreatePit,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (indexName === null || indexName === undefined) {
      throw new Error('Required parameter indexName was null or undefined when calling createPit.');
    }
    if (createPit === null || createPit === undefined) {
      throw new Error('Required parameter createPit was null or undefined when calling createPit.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<PitId>(`${this.basePath}/${encodeURIComponent(String(indexName))}/search/pit`, createPit, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Forward PIT deletion request to elasticsearch
   * Drops an elasticsearch PIT
   * @param indexName
   * @param pitId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePit(
    indexName: string,
    pitId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public deletePit(
    indexName: string,
    pitId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public deletePit(
    indexName: string,
    pitId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public deletePit(
    indexName: string,
    pitId: string,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (indexName === null || indexName === undefined) {
      throw new Error('Required parameter indexName was null or undefined when calling deletePit.');
    }
    if (pitId === null || pitId === undefined) {
      throw new Error('Required parameter pitId was null or undefined when calling deletePit.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.delete<any>(
      `${this.basePath}/${encodeURIComponent(String(indexName))}/search/pit/${encodeURIComponent(String(pitId))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Forward a count API request to elasticsearch. Only the query component is being forwarded.
   * @param indexName
   * @param queryDSL New elastic query object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public elasticCount(
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<any>;
  public elasticCount(
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<HttpResponse<any>>;
  public elasticCount(
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<HttpEvent<any>>;
  public elasticCount(
    indexName: string,
    queryDSL: QueryDSL,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<any> {
    if (indexName === null || indexName === undefined) {
      throw new Error('Required parameter indexName was null or undefined when calling elasticCount.');
    }
    if (queryDSL === null || queryDSL === undefined) {
      throw new Error('Required parameter queryDSL was null or undefined when calling elasticCount.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<any>(`${this.basePath}/${encodeURIComponent(String(indexName))}/count`, queryDSL, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Forward a query to elasticsearch
   * @param indexName
   * @param queryDSL New elastic query object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @param options
   */
  public elasticQuery(
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<ElasticResult>;
  public elasticQuery(
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<HttpResponse<ElasticResult>>;
  public elasticQuery(
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<HttpEvent<ElasticResult>>;
  public elasticQuery(
    indexName: string,
    queryDSL: QueryDSL,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<any> {
    if (indexName === null || indexName === undefined) {
      throw new Error('Required parameter indexName was null or undefined when calling elasticQuery.');
    }
    if (queryDSL === null || queryDSL === undefined) {
      throw new Error('Required parameter queryDSL was null or undefined when calling elasticQuery.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const responseType: 'text' | 'json' = 'json';

    return this.httpClient.post<ElasticResult>(`${this.basePath}/${encodeURIComponent(String(indexName))}/search`, queryDSL, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Search using the PIT
   * Forward search request to elasticsearch using the specified PIT
   * @param pitId
   * @param indexName
   * @param queryDSL New elastic query object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryPit(
    pitId: string,
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public queryPit(
    pitId: string,
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public queryPit(
    pitId: string,
    indexName: string,
    queryDSL: QueryDSL,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public queryPit(
    pitId: string,
    indexName: string,
    queryDSL: QueryDSL,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (pitId === null || pitId === undefined) {
      throw new Error('Required parameter pitId was null or undefined when calling queryPit.');
    }
    if (indexName === null || indexName === undefined) {
      throw new Error('Required parameter indexName was null or undefined when calling queryPit.');
    }
    if (queryDSL === null || queryDSL === undefined) {
      throw new Error('Required parameter queryDSL was null or undefined when calling queryPit.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<any>(
      `${this.basePath}/${encodeURIComponent(String(indexName))}/search/pit/${encodeURIComponent(String(pitId))}`,
      queryDSL,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
