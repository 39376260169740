import { Component, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { BootstrapBreakPoint } from '@nida-web/shared/utils';

@Component({
  selector: 'nida-web-site-wrapper',
  templateUrl: './site-wrapper.component.html',
  styleUrls: ['./site-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SiteWrapperComponent /* implements OnInit*/ {
  @Input() headline: string;
  @Input() translatedHeadline: string;
  @Input() disableContentPadding = false;
  @Input() disableHeadline = false;
  @Input() disableHeadlineMarginBottom = false;

  @Input() enableBackButton = false;
  @Input() enableCustomBackButton = false;
  @Input() enableBackButtonClickedEvent = false;
  @Output() backButtonClicked = new EventEmitter();

  // Breakpoint needs to be defined, in order to provide responsiveness for the buttons on mobile devices
  @Input() breakpointForButtonTemplate: BootstrapBreakPoint;
  @Input() buttonTemplate: TemplateRef<any>;

  constructor(private location: Location) {}

  triggerBack() {
    if (this.enableBackButtonClickedEvent) {
      this.backButtonClicked.emit();
    } else {
      this.location.back();
    }
  }

  triggerCustomBack() {
    this.backButtonClicked.emit();
  }
}
