import { Injectable } from '@angular/core';
import { ApiPDFItemList } from '../models/api-pdf-item-list';
import { PDFElement } from '@nida-web/api/generic-interfaces/attachments';
import { ApiPDFItem } from '../models/api-pdf-item';

@Injectable({
  providedIn: 'root',
})
export class PDFItemMappingService {
  public mapEntries(itemList: ApiPDFItemList): PDFElement[] {
    const resutList: PDFElement[] = [];

    for (const apiPDF of itemList.data) {
      resutList.push(this.mapEntry(apiPDF));
    }

    return resutList;
  }

  public mapEntry(apiPDF: ApiPDFItem): PDFElement {
    const mappedPDF: PDFElement = {};

    mappedPDF.nidaId = apiPDF.nidaId;
    mappedPDF.timeStamp = apiPDF.timeStamp;
    mappedPDF.description = apiPDF.description;
    mappedPDF.content = apiPDF.pdfBlob;
    mappedPDF.id = apiPDF.id;
    mappedPDF.name = apiPDF.name;

    if (!mappedPDF.description) {
      mappedPDF.description = 'Protocol';
    } else if (mappedPDF.description === 'vollstaendig_') {
      mappedPDF.description = 'Protocol complete';
    } else if (mappedPDF.description === 'fakturierbar_') {
      mappedPDF.description = 'Protocol billable';
    } else if (mappedPDF.description === 'eingereicht_') {
      mappedPDF.description = 'Protocol submitted';
      // TODO mapp to final description of 'traumaregister' also important for permission!
    } else if (mappedPDF.description === 'traumaregister') {
      mappedPDF.description = 'Trauma register';
    }

    switch (apiPDF.printStatus) {
      case 1:
        mappedPDF.status = 'Read';
        break;
      case undefined:
      case null:
      default:
        mappedPDF.status = 'New';
        break;
    }

    return mappedPDF;
  }
}
