<div class="darkgrey-font-color">
  <dx-validation-group #targetGroup>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="userCredentialsForm" (submit)="triggerSave()">
      <div class="row mb-3">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              {{ 'Username' | transloco }} <span class="dx-field-item-required-mark">&nbsp;*</span>
            </div>
            <div class="col-8 align-items-center">
              <dx-text-box class="width-100-percent" formControlName="userName" data-cy="userName" [readOnly]="!nidaAuthFlag">
                <dx-validator>
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxi-validation-rule message="Maximal 32 Zeichen" type="stringLength" [max]="32"> </dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              {{ 'First name' | transloco }} <span class="dx-field-item-required-mark">&nbsp;*</span>
            </div>
            <div class="col-8 align-items-center">
              <dx-text-box class="width-100-percent" formControlName="firstName" data-cy="firstName" [readOnly]="!nidaAuthFlag">
                <dx-validator>
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxi-validation-rule message="Maximal 64 Zeichen" type="stringLength" [max]="64"> </dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              {{ 'Last name' | transloco }} <span class="dx-field-item-required-mark">&nbsp;*</span>
            </div>
            <div class="col-8 align-items-center">
              <dx-text-box class="width-100-percent" formControlName="lastName" data-cy="lastName" [readOnly]="!nidaAuthFlag">
                <dx-validator>
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                  <dxi-validation-rule message="Maximal 64 Zeichen" type="stringLength" [max]="64"> </dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>

          <div class="row mt-2" [ngStyle]="{ display: showEmail }">
            <div class="col-4 d-flex align-items-center">
              {{ 'email' | transloco }}
            </div>
            <div class="col-8 align-items-center">
              <dx-text-box class="width-100-percent" formControlName="email" data-cy="email"> </dx-text-box>
            </div>
          </div>

          <ng-container *ngIf="nidaAuthFlag">
            <div class="row mt-2">
              <div class="col-12">
                <nida-web-ui-shared-password-hints></nida-web-ui-shared-password-hints>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4 d-flex align-items-center">
                {{ 'Password' | transloco }} <span *ngIf="newEntryMode" class="dx-field-item-required-mark">&nbsp;*</span>
              </div>
              <div class="col-8 align-items-center">
                <dx-text-box class="width-100-percent" formControlName="userPassword" mode="password" data-cy="userPassword">
                  <dx-validator [validationRules]="passwordValidationRules"> </dx-validator>
                </dx-text-box>
              </div>
            </div>
          </ng-container>
          <div *ngIf="!nidaAuthFlag" class="p-3 messageBox">
            <p style="word-break: keep-all; white-space: normal">{{ 'notEditableDataMessage' | transloco }}</p>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="group-card" style="width: 100%; height: 100%">
            <div class="basic-inner-wrapper basic-flex-div">
              <div>
                <h4 class="overview-title">{{ 'Available groups' | transloco }}</h4>
              </div>
              <div></div>
            </div>

            <div *ngIf="getLoaded()" class="basic-inner-wrapper group-card-basics">
              <div id="list-demo">
                <div class="widget-container max-height-detailcomponent">
                  <dx-list
                    #groupList
                    [dataSource]="groupStorage"
                    [selectedItemKeys]="onlyPreSelectedItemKeys"
                    [selectionMode]="selectionModeValue"
                    [showSelectionControls]="true"
                    [pageLoadMode]="'scrollBottom'"
                    [searchEnabled]="true"
                    searchExpr="groupName"
                  >
                    <div *dxTemplate="let data of 'item'">
                      {{ data.groupName }}
                    </div>
                  </dx-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <dx-button
            (click)="triggerCancel()"
            useSubmitBehavior="false"
            [text]="'Cancel' | transloco"
            [width]="120"
            class="mr-2"
            stylingMode="contained"
            type="normal"
          >
          </dx-button>
          <dx-button useSubmitBehavior="true" [text]="'Save' | transloco" [width]="120" stylingMode="contained" type="default"> </dx-button>
        </div>
      </div>
    </form>
  </dx-validation-group>
</div>
