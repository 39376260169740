import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MqttModule } from '@meddv/ngx-mqtt';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MqttModule.forRoot({
      connectOnCreate: false,
      hostname: '',
      port: 0,
      protocol: 'wss',
      protocolVersion: 5,
    }),
  ],
  providers: [],
})
export class MQTTWrapperModule {}
