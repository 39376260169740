<div (mouseleave)="initializeColumnHovered(); forceRerender()">
  <dx-data-grid
    #patientGrid
    (onRowClick)="onRowClick($event)"
    (onRowPrepared)="onRowPrepared($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    [allowColumnReordering]="true"
    [columnMinWidth]="15"
    [dataSource]="this.dataSource"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
    [showColumnLines]="false"
    [showRowLines]="true"
    [wordWrapEnabled]="true"
    [remoteOperations]="true"
    id="patientGrid"
    noDataText="{{ 'No missions found' | transloco }}"
    width="100%"
  >
    <dxo-state-storing [enabled]="true" [savingTimeout]="500" [storageKey]="gridName"></dxo-state-storing>
    <dxo-filter-row [visible]="filter"></dxo-filter-row>
    <dxo-header-filter [visible]="filter"></dxo-header-filter>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100, 500]"></dxo-pager>
    <dxo-search-panel #searchInput [visible]="true" [(text)]="searchValue" placeholder="{{ 'Search...' | transloco }}"></dxo-search-panel>
    <ng-container *ngFor="let column of columns; index as index">
      <dxi-column
        [allowReordering]="column['allowReordering']"
        [allowResizing]="column['allowResizing']"
        [allowSorting]="column['allowSorting']"
        [allowExporting]="column['allowExporting']"
        [calculateSortValue]="column['calculateSortValue']"
        [caption]="column['caption'] ? (column['caption'] | transloco) : ('' | transloco)"
        [cellTemplate]="column['dxTemplate']"
        [cssClass]="column.type === 'status' ? 'statusCol' : columnHovered[index] === true ? 'column-hover-effect' : ''"
        [dataField]="column['dataField']"
        [hidingPriority]="column['hidingPriority']"
        [minWidth]="column['minWidth']"
        [visible]="column['visibility']"
        [width]="column['width']"
        [dataType]="column['dataType']"
        [allowFiltering]="column['allowFilter']"
        [allowHeaderFiltering]="column['allowHeaderFiltering']"
        [trueText]="column['trueText']"
        [falseText]="column['falseText']"
        [filterOperations]="column['filterOperations']"
        alignment="left"
      >
      </dxi-column>
    </ng-container>

    <div
      *dxTemplate="let d of 'statusColor'"
      [class]="
        d.value === -1
          ? 'template-statusBasic'
          : d.value === 0
          ? 'template-statusBasic status0'
          : d.value === 1
          ? 'template-statusBasic status1'
          : 'template-statusBasic status2'
      "
    ></div>

    <div *dxTemplate="let d of 'rowStatus'" class="template-rowStatus">
      <ng-container *ngIf="!d.data.arrivalTime">
        <i [title]="'No pre-registration, date equals protocol date' | transloco" class="dx-icon-doc template-options-font-size"></i>
        <br />
      </ng-container>
      <ng-container *ngIf="!d.data.seen && !d.data.new">
        <i [title]="'Update for patient available' | transloco" class="icon icon-bold-reload template-options-font-size"></i>
        <br />
      </ng-container>
      <ng-container *ngIf="d.data.seen && !d.data.settings.visible">
        <i class="icon icon-bold-view-off template-options-font-size"></i> <br />
      </ng-container>
    </div>

    <div *dxTemplate="let d of 'arrivalTime'" class="template-arrivalTime">
      <div>
        <div class="arrivalTime-spacer">
          <div *ngIf="!displayDateIfToday(d.value)" class="arrivalTime-additional-date-display">
            <div>
              <ng-container *ngIf="d.value; else elseBlock0">
                {{ formatMoment(d.value, 'day') }}
              </ng-container>
              <ng-template #elseBlock0>
                {{ formatMoment(d.data.date, 'day') }}
              </ng-template>
            </div>
          </div>
        </div>

        <div>
          <div>
            <ng-container *ngIf="d.value; else elseBlock1">
              {{ formatMoment(d.value, 'time') }}
            </ng-container>
            <ng-template #elseBlock1>
              {{ formatMoment(d.data.date, 'time') }}
            </ng-template>
          </div>
        </div>

        <div class="arrivalTime-spacer">
          <div class="arrivalTime-additional-date-display">
            <div>
              <ng-container *ngIf="d.value">
                <nida-web-countdown [end]="d.data['arrivalTime']"></nida-web-countdown>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *dxTemplate="let d of 'diagnosis'">
      <div>
        {{ d.value | transloco | translocoWord }}
      </div>
    </div>

    <div *dxTemplate="let d of 'gender'" class="template-gender">
      <ng-container *ngIf="d.value === 0">
        <i class="icon icon-bold-gender-female"></i>
      </ng-container>
      <ng-container *ngIf="d.value === 1">
        <i class="icon icon-bold-gender-male"></i>
      </ng-container>
      <ng-container *ngIf="d.value === 2">
        <i class="icon icon-bold-gender-diverse"></i>
      </ng-container>
    </div>

    <div *dxTemplate="let d of 'circulation'" [class]="d.value === 0 ? 'template-gender gender-false' : 'template-gender'">
      <ng-container [ngSwitch]="d.value">
        <div *ngSwitchCase="'instabil'">
          <span [ngClass]="'color-red'">{{ 'Unstable_lowercase' | transloco }}</span>
        </div>
        <div *ngSwitchCase="'stabil'">
          <span [ngClass]="">{{ 'Stable_lowercase' | transloco }}</span>
        </div>
        <div *ngSwitchDefault></div>
      </ng-container>
    </div>

    <div *dxTemplate="let d of 'yesNoEmpty'" class="template-gender">
      <ng-container [ngSwitch]="d.value">
        <div *ngSwitchCase="true">
          <span [ngClass]="'color-red'">{{ 'Yes' | transloco }}</span>
        </div>
        <div *ngSwitchCase="false">
          <span>{{ 'No' | transloco }}</span>
        </div>
        <div *ngSwitchDefault></div>
      </ng-container>
    </div>

    <div *dxTemplate="let d of 'gcs'" [class.color-red]="d.value && d.value <= 8 && d.value >= 0">
      {{ d.value | replaceVitalParameter }}
    </div>

    <div *dxTemplate="let d of 'attachments'">
      <ng-container *ngIf="d.value.picture">
        <i class="attachment icon icon-picture-landscape"></i>
      </ng-container>
      <ng-container *ngIf="d.value.video">
        <i class="attachment icon icon-video-player"></i>
      </ng-container>
      <ng-container *ngIf="d.value.pdf">
        <ng-container *ngIf="!d.value.pdfStatus">
          <i class="attachment icon icon-office-file-pdf"></i>
        </ng-container>
        <ng-container *ngIf="d.value.pdfStatus">
          <i class="attachment icon icon-office-file-pdf pdf-status-read"></i>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="d.value.ekg">
        <i class="attachment icon icon-graph-stats-square"></i>
      </ng-container>
    </div>

    <div *dxTemplate="let d of 'hl7ExportAt'">
      <ng-container *ngIf="d.value">
        <i class="icon icon-hyperlink"></i>
      </ng-container>
    </div>

    <div *dxTemplate="let d of 'options'" style="position: relative">
      <div>
        <i (click)="onRowButtonClick(d)" class="icon icon-bold-navigation-menu-vertical"></i>
      </div>

      <nida-web-patient-row-settings
        (closing)="triggerClosing(d)"
        (preventDetail)="preventDetailOpening()"
        *ngIf="d.data['settings']['clicked']"
        [rawRowData]="d"
      ></nida-web-patient-row-settings>
    </div>
  </dx-data-grid>
</div>
