<div>
  <div class="overview-info">
    <div class="overview-head-item">{{ 'Date' | transloco }}: {{ formatMoment(this.timestamp, 'date') }}</div>
    <div class="overview-head-item">{{ 'Start time' | transloco }}: {{ formatMoment(this.timestamp, 'time') }}</div>
    <div class="overview-head-item" *ngIf="ecgPdfEnabled">
      <ng-container *ngIf="this.pdf; else elseBlock">
        <a
          [download]="this.fileName"
          [href]="this.pdf"
          class="dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-text dx-button-content"
        >
          <div class="dx-button-content">
            <div class="dx-button-text">
              {{ 'Print PDF' | transloco }}
            </div>
          </div>
        </a>
      </ng-container>
      <ng-template #elseBlock>
        <dx-button disabled="true" stylingMode="contained" type="normal" [text]="'Print PDF' | transloco"> </dx-button>
      </ng-template>
    </div>
  </div>
</div>
