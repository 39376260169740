<nida-web-card [height100]="true" headline="Rescue unit" style="height: 100%">
  <div *ngIf="loaded.value && patientData">
    <div class="row">
      <div class="col">
        <div class="vehicle-name">
          {{ patientData.vehicle | displayNan }}
        </div>
      </div>
      <div class="col d-flex align-items-center">
        <a
          *ngIf="patientData.vehiclePhone !== undefined && patientData.vehiclePhone !== null && patientData.vehiclePhone !== ''"
          [href]="'tel:' + patientData.vehiclePhone"
        >
          <i class="dx-icon-tel"></i>{{ patientData.vehiclePhone | displayNan }}
        </a>
        <span *ngIf="patientData.vehiclePhone === undefined || patientData.vehiclePhone === null || patientData.vehiclePhone === ''">{{
          patientData.vehiclePhone | displayNan
        }}</span>
      </div>
    </div>

    <hr class="my-2" />

    <div class="row">
      <div class="col">
        {{ 'Dispatch center' | transloco }}
      </div>
      <div class="col d-flex align-items-center">
        <a
          *ngIf="patientData.dispatchTel !== undefined && patientData.dispatchTel !== null && patientData.dispatchTel !== ''"
          [href]="'tel:' + patientData.dispatchTel"
        >
          <i class="dx-icon-tel"></i>{{ patientData.dispatchTel | displayNan }}
        </a>
        <span *ngIf="patientData.dispatchTel === undefined || patientData.dispatchTel === null || patientData.dispatchTel === ''">{{
          patientData.dispatchTel | displayNan
        }}</span>
      </div>
    </div>
  </div>
  <!--                <div style="flex: 1">-->
  <!--                  <div class="vehicle-name">-->
  <!--                    {{ patientData.vehicle | displayNan }}-->
  <!--                  </div>-->
  <!--                  <div class="basic-flex-container content-justify-center">-->
  <!--                    <div class="basic-padding-small">-->
  <!--                      <a-->
  <!--                        *ngIf="missionData.vehicle_tel !== undefined && missionData.vehicle_tel !== null && missionData.vehicle_tel !== ''"-->
  <!--                        [href]="'tel:' + missionData.vehicle_tel"-->
  <!--                      >-->
  <!--                        <i class="dx-icon-tel"></i>{{ missionData.vehicle_tel | displayNan }}-->
  <!--                      </a>-->
  <!--                      <span-->
  <!--                        *ngIf="missionData.vehicle_tel === undefined || missionData.vehicle_tel === null || missionData.vehicle_tel === ''"-->
  <!--                        >{{ missionData.vehicle_tel | displayNan }}</span-->
  <!--                      >-->
  <!--                    </div>-->
  <!--                  </div>-->
  <!--                </div>-->
</nida-web-card>
