export class PermissionsHelper {
  /**
   * Does decide based on the provided roles how to check
   * @param rolesAllowed: Roles user has
   * @param currentRoles: Roles user needs
   */
  public static isAllowedAccess(rolesAllowed: string[], currentRoles: string[]): boolean {
    let foundRwRo = false;
    rolesAllowed.forEach((role) => {
      if (role.includes(':rw') || role.includes(':ro')) {
        foundRwRo = true;
      }
    });

    if (foundRwRo) {
      currentRoles.forEach((role) => {
        if (!role.includes(':rw') && !role.includes(':ro')) {
          foundRwRo = false;
        }
      });
    }

    if (foundRwRo) {
      // DOES CHECK for :rw and :ro
      const intersectedRoles = currentRoles.reduce((acc, curr) => {
        return [...acc, ...rolesAllowed.filter((role) => role === curr)];
      }, []);
      return intersectedRoles.length > 0;
    } else {
      // DOES NOT CHECK for :rw and :ro and ALLOWS WILDCARDS
      const intersectedRoles = currentRoles.reduce((acc, curr) => {
        return [
          ...acc,
          ...rolesAllowed.filter(
            (role) => PermissionsHelper.getComparablePermission(role) === PermissionsHelper.getComparablePermission(curr)
          ),
        ];
      }, []);
      const wildCardRoles = currentRoles.filter((value) => /\*.+\*/.test(value));
      wildCardRoles.forEach((wildCard) => {
        for (let i = 0; i < rolesAllowed.length; i++) {
          if (rolesAllowed[i].toLowerCase().includes(wildCard.replace(/\*/g, '').toLowerCase())) {
            intersectedRoles.push(wildCard);
            break;
          }
        }
      });
      return intersectedRoles.length > 0;
    }
  }

  /**
   * Also checks :rw and :ro permissions
   * @param rolesAllowed
   * @param currentRoles
   */
  public static isAllowedAccessWithRwRo(rolesAllowed: string[], currentRoles: string[]): boolean {
    const intersectedRoles = currentRoles.reduce((acc, curr) => {
      return [...acc, ...rolesAllowed.filter((role) => role === curr)];
    }, []);
    return intersectedRoles.length > 0;
  }

  // return string without :ro or :rw
  public static getComparablePermission(perm: string): string {
    return perm.toLowerCase().trim().replace(':rw', '').replace(':ro', '');
  }
}
