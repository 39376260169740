// noinspection SpellCheckingInspection

import { NavItem } from '@nida-web/core';

export const navigation: NavItem[] = [
  {
    text: 'Current patients',
    path: 'current-patients/overview',
    icon: 'bold-registration',
    permissions: ['klinik.protokoll', '!nida.divi.protokollliste'],
  },
  {
    text: 'Dashboard',
    path: 'divi/protocol-dashboard',
    icon: 'bold-columns',
    permissions: ['nida.divi.dashboard'],
  },
  {
    text: 'New protocol',
    path: 'divi/create-new-protocol',
    icon: 'bold-medical-file',
    permissions: ['nida.divi.dashboard'],
  },
  {
    text: 'Workplace',
    path: 'workplace',
    icon: 'bold-columns',
    permissions: ['!klinik.protokoll', 'klinik.admin', '!nida.kapa.assignments.view:ro'], // TODO: TNA PERMISSION
  },
  {
    text: 'Missions',
    path: 'missions/current',
    icon: 'alarm-bell-1',
    permissions: ['!klinik.protokoll', 'klinik.admin', '!nida.kapa.assignments.view:ro'], // TODO: TNA PERMISSION
  },
  {
    text: 'nidaRed.missionsArchive',
    path: 'missions/archive',
    icon: 'bold-patientlist',
    permissions: ['!klinik.protokoll', 'klinik.admin', '!nida.kapa.assignments.view:ro'], // TODO: TNA PERMISSION
  },
  {
    text: 'Orders',
    icon: 'paper-write',
    notSortItemsAlphabetically: true,
    items: [
      {
        text: 'navigation.orders.protocols',
        path: 'protocols/light',
        icon: 'ambulance-car',
        permissions: ['nida.protokollliste', '!nida.protokoll.suche'],
      },
      {
        text: 'Protocols',
        path: 'divi/protocol-table',
        icon: 'ambulance-car',
        permissions: ['nida.divi.protokollliste'],
      },
      {
        text: 'navigation.orders.protocols',
        path: 'protocols/full',
        icon: 'ambulance-car',
        permissions: ['nida.protokoll.suche', '!nida.divi.protokollliste'],
      },
      {
        text: 'navigation.orders.recycle',
        path: 'protocols/recycle',
        icon: 'bin',
        permissions: ['nida.protokollloeschen', '!nida.divi.protokollliste'],
      },
      {
        text: 'navigation.orders.evm',
        path: 'protocols/evm',
        icon: 'bold-add',
        permissions: ['nida.evm.gesamt'],
      },
      {
        text: 'navigation.orders.zek',
        path: 'protocols/zek',
        icon: 'alarm-bell-ring-1',
        permissions: ['nida.zek.gesamt'],
      },
    ],
  },
  {
    text: 'navigation.taskMonitoring.taskMonitoring',
    icon: 'streamline-icon-task-list-search140x140',
    permissions: ['verwaltung.aufgabenueberwachung'],
    items: [
      {
        text: 'navigation.taskMonitoring.disinfection',
        path: 'task-monitoring/disinfection',
        icon: 'hygiene-hand-wipe-paper',
        permissions: ['verwaltung.desinfektionsnachweis'],
      },
      {
        text: 'navigation.taskMonitoring.task',
        path: 'task-monitoring/tasks',
        icon: 'list-to-do',
        permissions: ['verwaltung.aufgabenueberwachung'],
      },
      {
        text: 'navigation.taskMonitoring.vehicleCheck',
        path: 'task-monitoring/vehicle-check',
        icon: 'car-repair-checklist',
        permissions: ['verwaltung.checkliste'],
      },
      {
        text: 'navigation.taskMonitoring.routineDisinfection',
        path: 'task-monitoring/routine-disinfection',
        icon: 'vaccine-protection-sanitizer-spray',
        permissions: ['verwaltung.routinedesinfektion'],
      },
    ],
  },
  {
    text: 'Management',
    icon: 'customer-relationship-management-categorization',
    items: [
      {
        text: 'navigation.logBook',
        path: 'vehicle-log',
        icon: 'e-car-checking',
        permissions: ['verwaltung.fahrtenbuch:ro', 'verwaltung.fahrtenbuch:rw'],
      },
    ],
  },
  {
    text: 'Master data',
    icon: 'tablet-touch-1',
    items: [
      {
        text: 'navigation.masterData.addresses',
        path: 'master-data/addresses',
        icon: 'address-book',
        permissions: ['stammdaten.adressen:ro', 'stammdaten.adressen:rw'],
      },
      {
        text: 'navigation.masterData.tasks',
        path: 'master-data/tasks',
        icon: 'bold-patientlist',
        permissions: ['stammdaten.aufgaben:ro', 'stammdaten.aufgaben:rw'],
      },
      {
        text: 'navigation.masterData.files',
        path: 'master-data/files',
        icon: 'office-file-hierarchy',
        permissions: ['stammdaten.nidaupdater:rw', 'stammdaten.nidaupdater:rw'],
      },
      {
        text: 'navigation.masterData.disinfectants',
        path: 'master-data/disinfectants',
        icon: 'disinfectant',
        permissions: ['stammdaten.desinfektionsmittel:ro', 'stammdaten.desinfektionsmittel:rw'],
      },
      {
        text: 'navigation.masterData.missionTypes',
        path: 'master-data/missiontypes',
        icon: 'medical-notes1',
        permissions: ['stammdaten.einsatzarten:ro', 'stammdaten.einsatzarten:rw'],
      },
      {
        text: 'navigation.masterData.vehicleCheck',
        path: 'master-data/vehicle-check',
        icon: 'car-actions-check',
        permissions: ['stammdaten.fahrzeugchecklisten:ro', 'stammdaten.fahrzeugchecklisten:rw'],
      },
      {
        text: 'navigation.masterData.devices',
        path: 'master-data/devices',
        icon: 'print-text',
        permissions: ['stammdaten.geraete:ro', 'stammdaten.geraete:rw'],
      },
      {
        text: 'navigation.masterData.groups',
        path: 'master-data/groups',
        icon: 'human-resources-hierarchy',
        permissions: ['stammdaten.gruppen:ro', 'stammdaten.gruppen:rw'],
      },
      {
        text: 'navigation.masterData.hardware',
        path: 'master-data/hardware',
        icon: 'phone-mobile-phone',
        permissions: ['stammdaten.hardware:ro', 'stammdaten.hardware:rw'],
      },
      {
        text: 'navigation.masterData.infections',
        path: 'master-data/infections',
        icon: 'medical-specialty-virus',
        permissions: ['stammdaten.infektionen:ro', 'stammdaten.infektionen:rw'],
      },
      {
        text: 'navigation.masterData.licensePlates',
        path: 'master-data/licenseplates',
        icon: 'bold-bus-ticket',
        permissions: ['stammdaten.kennzeichen:ro', 'stammdaten.kennzeichen:rw', '!nida.kapa.management'],
      },
      {
        text: 'navigation.masterData.costbearer',
        path: 'master-data/cost-bearer',
        icon: 'cash-payment-bag',
        permissions: ['stammdaten.kostentraeger:ro', 'stammdaten.kostentraeger:rw'],
      },
      {
        text: 'navigation.masterData.clinics',
        path: 'master-data/clinics',
        icon: 'bold-registration',
        permissions: ['stammdaten.kliniken:ro', 'stammdaten.kliniken:rw'],
      },
      {
        text: 'navigation.masterData.drugs',
        path: 'master-data/drugs',
        icon: 'pill1',
        permissions: ['stammdaten.medikamente:ro', 'stammdaten.medikamente:rw', '!nida.divi.dashboard'],
      },
      {
        text: 'navigation.masterData.mobileHardware',
        path: 'master-data/mobile-hardware',
        icon: 'programming-rss',
        permissions: ['stammdaten.mobilehardware:ro', 'stammdaten.mobilehardware:rw'],
      },
      {
        text: 'navigation.masterData.callsigns',
        path: 'master-data/callsigns',
        icon: 'bold-signal',
        permissions: ['stammdaten.rufnamen:ro', 'stammdaten.rufnamen:rw', '!nida.kapa.management'],
      },
      {
        text: 'navigation.masterData.smsWhitelist',
        path: 'master-data/sms-whitelist',
        icon: 'phone-action-text',
        permissions: ['stammdaten.smswhitelist:ro', 'stammdaten.smswhitelist:rw', '!nida.divi.protokollliste'],
      },
      {
        text: 'navigation.masterData.locations',
        path: 'master-data/locations',
        icon: 'bold-region',
        permissions: ['stammdaten.standorte:ro', 'stammdaten.standorte:rw', '!nida.kapa.management'],
      },
      {
        text: 'navigation.masterData.textBlocks',
        path: 'master-data/textblocks',
        icon: 'text-options',
        permissions: ['stammdaten.autotext:ro', 'stammdaten.autotext:rw'],
      },
      {
        text: 'navigation.masterData.webAddresses',
        path: 'master-data/urls',
        icon: 'network-www',
        permissions: ['stammdaten.url:ro', 'stammdaten.url:rw'],
      },
      {
        text: 'navigation.masterData.wlan',
        path: 'master-data/wlan',
        icon: 'computer-connection-wifi',
        permissions: ['stammdaten.wlan:ro', 'stammdaten.wlan:rw'],
      },
      {
        text: 'navigation.masterData.targets',
        path: 'master-data/targets',
        icon: 'house-target',
        permissions: ['stammdaten.ziele:ro', 'stammdaten.ziele:rw'],
      },
    ],
  },
  {
    text: 'navigation.administration.administration',
    icon: 'bold-administration',
    items: [
      {
        text: 'navigation.administration.userManagement',
        path: 'user/active-user',
        icon: 'address-book',
        permissions: ['administration.personal'],
      },
      {
        text: 'navigation.administration.roleManagement',
        path: 'user/roles',
        icon: 'bold-patientlist',
        permissions: ['administration.personal.rollen'],
      },
    ],
  },
  {
    text: 'navigation.logs.logs',
    icon: 'bold-time-clock-file',
    items: [
      {
        text: 'navigation.logs.role',
        path: 'logs/roles',
        icon: 'bold-multiple-actions-time',
        permissions: ['nida.log.rollen'],
      },
      {
        text: 'navigation.logs.protocol',
        path: 'logs/protocol',
        icon: 'bold-common-file-text-clock',
        permissions: ['nida.log.protokollaufruf'],
      },
    ],
  },
  {
    text: 'Patients archive',
    path: 'patients/overview',
    icon: 'bold-patientlist',
    permissions: ['klinik.protokoll', '!nida.divi.protokollliste'],
  },
  {
    text: 'New assignment',
    path: '/assignment/create',
    icon: 'bold-medical-file',
    permissions: ['nida.kapa.assignments.control:rw', '!klinik.protokoll'],
  },
  {
    text: 'Current assignments',
    path: '/assignments/current',
    icon: 'ambulance-car',
    permissions: ['nida.kapa.assignments.view:ro'],
  },
  {
    text: 'Assignment archive',
    path: '/assignments',
    icon: 'bold-patientlist',
    permissions: ['nida.kapa.assignments.view:ro'],
  },
  /*  {
    text: 'Regional overview',
    path: 'management/monitoring/area-overview',
    icon: 'bold-region',
    permissions: ['nida.kapa.capacities.control:rw', 'nida.kapa.capacities.view:ro'],
  },*/
  {
    text: 'Capacities',
    path: 'management/monitoring',
    icon: 'bold-hospital-bedroom-graph',
    permissions: ['nida.kapa.capacities.control:rw', 'nida.kapa.capacities.view:ro'],
  },
  {
    text: 'Internal hints',
    path: 'hints',
    icon: 'bold-mangement',
    permissions: ['nida.kapa.hints.view', 'nida.kapa.hints.control'],
  },
  {
    text: 'All hints',
    path: 'hints/all',
    icon: 'bold-mangement',
    permissions: ['nida.kapa.hints.view'],
  },
  {
    text: 'Management_',
    path: 'management',
    icon: 'bold-administration',
    permissions: ['nida.kapa.management'],
  },
  {
    text: 'User management',
    path: 'users',
    icon: 'bold-administration',
    permissions: ['klinik.admin', '!nida.kapa.management'],
  },
  {
    text: 'navigation.personalData',
    path: 'user/active-user/me',
    icon: 'address-book',
    permissions: ['start.persoenliche.daten', 'administration.personal', '!nida.divi.protokollliste'],
  },
  {
    text: 'Settings',
    path: 'settings',
    icon: 'bold-settings',
  },
  {
    text: 'Support',
    path: 'support',
    icon: 'bold-question-circle',
    permissions: ['klinik.protokoll', '!nida.divi.protokollliste'],
  },
  {
    text: 'navigation.support',
    path: 'client-support',
    icon: 'bold-question-circle',
    permissions: ['nida', '!nida.divi.protokollliste'],
  },
  {
    text: 'Log out',
    path: 'logout',
    icon: 'bold-exit',
  },
];
