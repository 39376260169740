import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ColumnConfig } from '../models/column-config.model';

@Injectable({
  providedIn: 'root',
})
export abstract class ColumnConfigServable {
  public abstract getColumnConfig(): Observable<ColumnConfig>;
}
