<div style="height: 100%; display: flex; flex-direction: column">
  <nida-web-emergency-room-header></nida-web-emergency-room-header>
  <nida-web-side-nav-outer-toolbar style="overflow: auto" [navigationItems$]="navigationItems$" [hiddenHeader]="true">
    <nida-web-breadcrumbs></nida-web-breadcrumbs>
    <!--    <div (click)="triggerNavigationBack()" class="padding-wrapper administration-back-button">&#8249; {{ 'Back' | transloco }}</div>-->
    <router-outlet></router-outlet>
    <nida-web-footer>
      &copy; {{ navAppInfoService.cpYear }} <a href="https://www.meddv.de/">medDV GmbH</a> | Version {{ navAppInfoService.version }}
    </nida-web-footer>
  </nida-web-side-nav-outer-toolbar>
</div>
