<div>
  <ng-container *ngIf="loaded; else elseBlock" class="ecg-main-font-color ecg-main-font">
    <div class="basic-display-flex basic-flex-wrap">
      <div class="basic-flex-1 width-100-percent">
        <div class="basic-margin-10">
          <div *ngFor="let lead of leadTypesToBeShown; index as index">
            <div
              *ngIf="index < dividingFactor"
              [ngClass]="{
                'lead-channel-chosen': channelStatus[lead]['chosen'],
                'lead-channel-unchosen': !channelStatus[lead]['chosen']
              }"
            >
              <div class="lead-channel">
                <div class="basic-padding-5 min-width">
                  <div class="flex-focused-container basic-bottom-padding-5">
                    {{ lead }}
                  </div>
                  <div class="flex-focused-container">
                    <div>
                      <dx-check-box (onValueChanged)="manageCheckBox($event, lead)"></dx-check-box>
                    </div>
                  </div>
                </div>

                <div class="TEST-A">
                  <dx-chart [dataSource]="restEcg[lead].data">
                    <dxo-size [height]="65" [width]="400"></dxo-size>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-argument-axis [label]="{ visible: false }"></dxo-argument-axis>
                    <dxo-value-axis [label]="{ visible: false }"></dxo-value-axis>

                    <dxo-common-axis-settings [tick]="{ visible: false }">
                      <dxo-grid [color]="'lightgrey'" [visible]="true"></dxo-grid>
                      <dxo-minor-grid [color]="'lightgrey'" [visible]="true"></dxo-minor-grid>
                    </dxo-common-axis-settings>
                    <dxo-argument-axis [visible]="false"></dxo-argument-axis>
                    <dxo-value-axis [visible]="false" [visualRange]="zoomRange"></dxo-value-axis>

                    <dxi-series
                      [color]="channelColor"
                      [hoverStyle]="{ width: 1 }"
                      [name]="lead"
                      [width]="1"
                      argumentField="id"
                      valueField="value"
                    >
                      <dxo-point [visible]="false"></dxo-point>
                    </dxi-series>
                  </dx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="basic-flex-1 width-100-percent">
        <div class="basic-margin-10">
          <div *ngFor="let lead of leadTypesToBeShown; index as index">
            <div
              *ngIf="index > dividingFactor - 1"
              [ngClass]="{
                'lead-channel-chosen': channelStatus[lead]['chosen'],
                'lead-channel-unchosen': !channelStatus[lead]['chosen']
              }"
            >
              <div class="lead-channel">
                <div class="basic-padding-5 min-width">
                  <div class="flex-focused-container basic-bottom-padding-5">
                    {{ lead }}
                  </div>
                  <div class="flex-focused-container">
                    <div>
                      <dx-check-box (onValueChanged)="manageCheckBox($event, lead)"></dx-check-box>
                    </div>
                  </div>
                </div>

                <div class="TEST-A">
                  <dx-chart [dataSource]="restEcg[lead].data" class="TEST-B">
                    <dxo-size [height]="65" [width]="400"></dxo-size>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-argument-axis [label]="{ visible: false }"></dxo-argument-axis>
                    <dxo-value-axis [label]="{ visible: false }"></dxo-value-axis>

                    <dxo-common-axis-settings [tick]="{ visible: false }">
                      <dxo-grid [color]="'lightgrey'" [visible]="true"></dxo-grid>
                      <dxo-minor-grid [color]="'lightgrey'" [visible]="true"></dxo-minor-grid>
                    </dxo-common-axis-settings>
                    <dxo-argument-axis [visible]="false"></dxo-argument-axis>
                    <dxo-value-axis [visible]="false" [visualRange]="zoomRange"></dxo-value-axis>

                    <dxi-series
                      [color]="channelColor"
                      [hoverStyle]="{ width: 1 }"
                      [name]="lead"
                      [width]="1"
                      argumentField="id"
                      valueField="value"
                    >
                      <dxo-point [visible]="false"></dxo-point>
                    </dxi-series>
                  </dx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #elseBlock>
    <div class="loader-wrapper">
      <div>
        <nida-web-meddv-loader></nida-web-meddv-loader>
      </div>
    </div>
  </ng-template>
</div>
