import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { RespiratoryRateData } from '../models/vital-signs/respiratory-rate-data';
import { BloodPressureData } from '../models/vital-signs/blood-pressure-data';
import { BloodSugarData } from '../models/vital-signs/blood-sugar-data';
import { SpCoData } from '../models/vital-signs/sp-co-data';
import { EtCo2Data } from '../models/vital-signs/et-co2-data';
import { HemoglobinData } from '../models/vital-signs/hemoglobin-data';
import { HeartRateData } from '../models/vital-signs/heart-rate-data';
import { PulseData } from '../models/vital-signs/pulse-data';
import { SpO2Data } from '../models/vital-signs/sp-o2-data';
import { TemperatureData } from '../models/vital-signs/temperature-data';

@Injectable({
  providedIn: 'root',
})
export abstract class VitalSignsServable {
  public abstract getAfByProtocolId(protocolId: number): ReplaySubject<RespiratoryRateData>;

  public abstract getBdByProtocolId(protocolId: number): ReplaySubject<BloodPressureData>;

  public abstract getBzByProtocolId(protocolId: number): ReplaySubject<BloodSugarData>;

  public abstract getCoByProtocolId(protocolId: number): ReplaySubject<SpCoData>;

  public abstract getCo2ByProtocolId(protocolId: number): ReplaySubject<EtCo2Data>;

  public abstract getHbByProtocolId(protocolId: number): ReplaySubject<HemoglobinData>;

  public abstract getHfByProtocolId(protocolId: number): ReplaySubject<HeartRateData>;

  public abstract getPulsByProtocolId(protocolId: number): ReplaySubject<PulseData>;

  public abstract getSpo2ByProtocolId(protocolId: number): ReplaySubject<SpO2Data>;

  public abstract getTempByProtocolId(protocolId: number): ReplaySubject<TemperatureData>;

  public abstract getPulseRegularByProtocolId(protocolId: number): ReplaySubject<PulseData>;
}
