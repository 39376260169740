import { Component, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Country, GlobalizeLanguageService } from '@nida-web/transloco-translation';

@Component({
  selector: 'nida-web-language-select-box',
  templateUrl: './language-select-box.component.html',
  styleUrls: ['./language-select-box.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LanguageSelectBoxComponent {
  public countries: Country[];
  private activeLanguage: string;

  constructor(private transService: TranslocoService, private globalizeLanguageService: GlobalizeLanguageService) {
    this.activeLanguage = this.transService.getActiveLang();
    this.countries = this.globalizeLanguageService.getCountries();
  }

  changeLanguage(langChangeEvent: any) {
    const countryCode = langChangeEvent.itemData.Code;
    this.activeLanguage = countryCode;
    this.transService.setActiveLang(countryCode);
    this.globalizeLanguageService.setLanguage(countryCode);
    localStorage.setItem('language', countryCode);
  }

  getActiveLanguage() {
    return this.activeLanguage;
  }
}
