<form [formGroup]="preclinicForm">
  <ng-container *ngFor="let local of this.localisation; let i = index">
    <div class="row" [ngClass]="{ 'basic-padding-bottom': i + 1 < this.localisation.length }">
      <div class="col-4">{{ local.displayName | transloco }}</div>
      <div class="col-8">
        <div class="row">
          <dx-radio-group
            [ngClass]="setSeverityClass(preclinicForm.getRawValue()[local.formControlName])"
            [formControlName]="local.formControlName"
            [readOnly]="disabled"
            [width]="'100%'"
            [layout]="'horizontal'"
            [items]="radioItems"
          >
            <ng-container *dxTemplate="let priority of 'item'"> </ng-container>
          </dx-radio-group>
        </div>
      </div>
    </div>
  </ng-container>
</form>
<!--<div class="row basic-padding-bottom">-->
<!--  <div class="col-4">{{ 'Face' | transloco }}</div>-->
<!--  <div class="col-8">-->
<!--    <div class="row">-->
<!--      <dx-radio-group-->
<!--        [ngClass]="this.activeSeverity[1]"-->
<!--        [(value)]="this.activeSeverity[1]"-->
<!--        [width]="'100%'" [layout]="'horizontal'" [items]="['none', 'light', 'medium', 'heavy']">-->
<!--        <ng-container *dxTemplate="let priority of 'item'">-->
<!--        </ng-container>-->
<!--      </dx-radio-group>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="row basic-padding-bottom">-->
<!--  <div class="col-4">{{ 'Thorax' | transloco }}</div>-->
<!--  <div class="col-8">-->
<!--    <div class="row">-->
<!--      <dx-radio-group-->
<!--        [ngClass]="this.activeSeverity[2]"-->
<!--        [(value)]="this.activeSeverity[2]"-->
<!--        [width]="'100%'" [layout]="'horizontal'" [items]="['none', 'light', 'medium', 'heavy']">-->
<!--        <ng-container *dxTemplate="let priority of 'item'">-->
<!--        </ng-container>-->
<!--      </dx-radio-group>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<hr />
<div class="basic-padding-bottom d-flex align-items-center justify-content-center">
  <div class="d-flex">
    <div class="mr-3">
      <dx-radio-group [ngClass]="'none'" [value]="'none' | transloco" [layout]="'horizontal'" [items]="['none' | transloco]">
      </dx-radio-group>
    </div>
    <div class="mr-3">
      <dx-radio-group [ngClass]="'light'" [value]="'light' | transloco" [layout]="'horizontal'" [items]="['light' | transloco]">
      </dx-radio-group>
    </div>
    <div class="mr-3">
      <dx-radio-group [ngClass]="'medium'" [value]="'medium' | transloco" [layout]="'horizontal'" [items]="['medium' | transloco]">
      </dx-radio-group>
    </div>
    <div class="mr-3">
      <dx-radio-group [ngClass]="'heavy'" [value]="'heavy' | transloco" [layout]="'horizontal'" [items]="['heavy' | transloco]">
      </dx-radio-group>
    </div>
  </div>
</div>
