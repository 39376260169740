<div>
  <div class="row mb-1 is-flex negative-margin">
    <div class="col-12 col-md-6 tile-wrapper">
      <nida-web-vital-data-tile
        [data]="bloodPressure"
        [displayLeftTileTwoEmptyValues]="true"
        [displayRightTileTwoEmptyValues]="true"
      ></nida-web-vital-data-tile>
    </div>
    <div class="col-12 col-md-6 tile-wrapper">
      <nida-web-vital-data-tile [data]="bloodSugar"></nida-web-vital-data-tile>
    </div>
    <div class="col-12 col-md-6 tile-wrapper">
      <nida-web-vital-data-tile [data]="temperature"></nida-web-vital-data-tile>
    </div>
    <div class="col-12 col-md-6 tile-wrapper">
      <nida-web-vital-data-tile [data]="heartRate"></nida-web-vital-data-tile>
    </div>
    <div class="col-12 col-md-6 tile-wrapper">
      <nida-web-vital-data-tile [data]="respiratoryRate"></nida-web-vital-data-tile>
    </div>
    <div class="col-12 col-md-6 tile-wrapper">
      <nida-web-vital-data-tile [data]="pulse"></nida-web-vital-data-tile>
    </div>
    <div class="col-12 col-md-6 tile-wrapper">
      <nida-web-vital-data-tile [data]="spO2"></nida-web-vital-data-tile>
    </div>
    <div class="col-12 col-md-6 tile-wrapper pt-4">
      <div class="values d-flex border-grey">
        <div class="left-values py-1 d-flex align-items-center justify-content-center">
          <p class="m-0 text-center">{{ 'Measurement' | transloco }} {{ 'Primary findings' | transloco }}</p>
        </div>

        <div class="right-values py-1 d-flex align-items-center justify-content-center">
          <p class="m-0 text-center">{{ 'Last measurement' | transloco }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row negative-margin">
    <div class="col-12 col-md-3">
      <p class="m-0">{{ 'nm = not measurable' | transloco }}</p>
    </div>
    <div class="col-12 col-md-3">
      <p class="m-0">{{ 'um = unmeasured' | transloco }}</p>
    </div>
  </div>
</div>
