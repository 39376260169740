import { Injectable } from '@angular/core';
import { ApiResultList } from '../models/api-result-list';
import { ERPreclinicDeprecated } from '@nida-web/api/generic-interfaces/protocol';

@Injectable({
  providedIn: 'root',
})
export class ResultMappingService {
  public mapValues(rawData: ApiResultList, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    for (const singleResult of rawData.data) {
      switch (singleResult.value_1) {
        case 'NACA':
          refObj.diagnostic.scoreNACA = singleResult.value_2;
          break;
        case 'HDM Beginn Datum':
          break; // TODO Format!!
        case 'HDM Beginn Zeit':
          break; // TODO Format!!
        case 'Patientenalter':
          refObj.diagnostic.patientAge = singleResult.value_2;
          break;
        case 'Geburtsdaten, kl. 28 Tage':
          refObj.diagnostic.ageUnder28Days = singleResult.value_2;
          break;
        case 'Patientengewicht in kg':
          refObj.diagnostic.bodyWeight = singleResult.value_2;
          break;
        case 'Patientengröße in cm':
          refObj.diagnostic.bodySize = singleResult.value_2;
          break;
        case 'Allgemeinzustand vor dem Ereignis':
          refObj.diagnostic.generalCondition = singleResult.value_2;
          break;
        case 'Ersthelfermassnahmen':
          refObj.diagnostic.firstAidAction = singleResult.value_2;
          break;
        case 'First Responder vor Ort':
          refObj.diagnostic.firstResponder = singleResult.value_2;
          break;
        case 'zeit_pat_an_firstresponder':
          refObj.diagnostic.firstResponderTime = singleResult.value_2;
          break;
        case 'Einsatzart':
          if (singleResult.value_2?.startsWith('Fehleinsatz - Fehleinsatz - ')) {
            refObj.diagnostic.missionAbort = singleResult.value_2;
          }
          break;
        case 'Vorangemeldet':
          refObj.diagnostic.preRegistration = singleResult.value_2;
          break;
        case 'BMI Kategorie':
          refObj.diagnostic.bmiCategory = singleResult.value_2;
          break;
        case 'Symptombeginn':
          refObj.diagnostic.symptomBegin = singleResult.value_2;
          break;
        // TODO: Zwei mal Symptombeginn? Prüfen bitte
        // eslint-disable-next-line no-duplicate-case
        case 'Symptombeginn':
          refObj.diagnostic.symptomBeginCollapse = singleResult.value_2;
          break;
      }
    }

    return refObj;
  }
}
