import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { EmergencyRoomStoreService } from '../../services/emergency-room.store.service';
import { PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { ERAnamnesis, ERPreclinic } from '@nida-web/api/generic-interfaces/emergencyroom';
import Timeout = NodeJS.Timeout;
import { BreadcrumbService } from '@nida-web/core';
import { Router } from '@angular/router';

@Component({
  selector: 'nida-web-emergency-room-header',
  templateUrl: './emergency-room-header.component.html',
  styleUrls: ['./emergency-room-header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class EmergencyRoomHeaderComponent implements OnInit, OnDestroy {
  patient: PatientView;
  anamnesisData: ERAnamnesis;
  preclinicData: ERPreclinic;

  currentTime: Date;
  currentTimeInterval: Timeout;
  symptomDuration: string;

  constructor(
    private router: Router,
    private emergencyRoomStore: EmergencyRoomStoreService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.setTime();
    this.getData();
  }

  ngOnDestroy(): void {
    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
  }

  setTime() {
    this.currentTime = new Date();

    this.currentTimeInterval = setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  private getData() {
    this.emergencyRoomStore.patientData.subscribe((response) => {
      if (response) {
        this.patient = response;
      } else {
        this.patient = <PatientView>{};
      }
    });
    this.emergencyRoomStore.anamnesisData.subscribe((response) => {
      if (response) {
        this.anamnesisData = response;
        this.calculateSymptomDuration();
      } else {
        this.anamnesisData = <ERAnamnesis>{};
      }
    });
    this.emergencyRoomStore.preclinicData.subscribe((response) => {
      if (response) {
        this.preclinicData = response;
        this.calculateSymptomDuration();
      } else {
        this.preclinicData = <ERAnamnesis>{};
      }
    });
  }

  calculateSymptomDuration() {
    // TODO: Review implementation quality!;
    if (this.preclinicData && this.preclinicData.id && this.preclinicData.onsetOfSymptoms) {
      const start = new Date(this.preclinicData.onsetOfSymptoms).getTime();
      let end: number;

      if (this.anamnesisData && this.anamnesisData.symptomEnd) {
        end = new Date(this.anamnesisData.symptomEnd).getTime();
      } else {
        end = new Date().getTime();
      }

      const hours = (end - start) / 1000 / 60 / 60;
      const minutes = (hours % 1) * 60;
      let hoursString = String(Math.round(hours));
      let minutesString = String(Math.round(minutes));

      if (hoursString.length === 1) {
        hoursString = '0' + hoursString;
      }
      if (minutesString.length === 1) {
        minutesString = '0' + minutesString;
      }

      this.symptomDuration = hoursString + ':' + minutesString;
    }
  }

  triggerNavigationBack() {
    this.router.navigate([this.breadcrumbService.getUpperLevelPath()]).then();
  }
}
