<dx-select-box
  id="language-select"
  (onItemClick)="changeLanguage($event)"
  [dataSource]="countries"
  [value]="getActiveLanguage()"
  displayExpr="Language"
  fieldTemplate="field"
  valueExpr="Code"
>
  <div *dxTemplate="let data of 'field'">
    <div class="custom-item">
      <img alt="{{ data.Language | transloco }}" src="{{ data && data.FlagImageSrc }}" height="90%" />
      <dx-text-box
        class="language-name"
        [value]="data && data.Language | transloco"
        [inputAttr]="{ 'aria-label': data.Language | transloco }"
        [readOnly]="true"
      ></dx-text-box>
    </div>
  </div>
  <div *dxTemplate="let data of 'item'">
    <div class="custom-item">
      <img alt="{{ data.Language | transloco }}" src="{{ data && data.FlagImageSrc }}" height="100%" />
      <div class="language-name">
        {{ data.Language | transloco }}
      </div>
    </div>
  </div>
</dx-select-box>
