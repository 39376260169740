import { Injectable } from '@angular/core';
import {
  EmergencyRoomServable,
  ERAnamnesis,
  ERCouncil,
  ERCouncilList,
  ERDiagnostic,
  ERFinalReport,
  ERIcd10,
  ERIcd10List,
  ERMedicalAccess,
  ERMedicalAccessList,
  EROrder,
  EROrderList,
  ERPatient,
  ERPreclinic,
  ERTherapy,
  ERTreatmentMember,
  ERTreatmentMemberList,
  ERVitals,
} from '@nida-web/api/generic-interfaces/emergencyroom';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PatientArchiveStoreService, PatientListStoreService } from '@nida-web/patient-management';
import { Patient, PatientServable, PatientView } from '@nida-web/api/generic-interfaces/patient-management';

@Injectable({
  providedIn: 'root',
})
export class EmergencyRoomStoreService {
  private closeRoute: string;

  public nidaId: string;
  public erPatientId: number;

  public protocolId: number;

  public patientData: BehaviorSubject<PatientView>;
  public patientDetails: BehaviorSubject<Patient>;
  public anamnesisData: BehaviorSubject<ERAnamnesis>;
  public councilListData: BehaviorSubject<ERCouncilList>;
  public diagnosticData: BehaviorSubject<ERDiagnostic>;
  public preclinicData: BehaviorSubject<ERPreclinic>;
  public finalReportData: BehaviorSubject<ERFinalReport>;
  public therapyData: BehaviorSubject<ERTherapy>;
  public medicalAccessListByPreclinicData: BehaviorSubject<ERMedicalAccessList>;
  public medicalAccessListByTherapyData: BehaviorSubject<ERMedicalAccessList>;
  public ICD10ListData: BehaviorSubject<ERIcd10List>;
  public treatmentMemberListData: BehaviorSubject<ERTreatmentMemberList>;

  public vitalsPreclinicData: BehaviorSubject<ERVitals>;
  public vitalsAnamnesisData: BehaviorSubject<ERVitals>;
  public orderListData: BehaviorSubject<any>; // TODO: Any needed, but any not allowed

  public subscriptionArray: Array<Subscription>;

  constructor(
    private patientArchiveStore: PatientArchiveStoreService,
    private patientCurrentStore: PatientListStoreService,
    private emergencyRoomService: EmergencyRoomServable,
    private patientServable: PatientServable
  ) {
    this.resetAllSubjects();
    this.subscriptionArray = [];

    this.closeRoute = '';
  }

  public getCloseLink(): string {
    return this.closeRoute;
  }

  public setCloseLink(closeRoute: string): void {
    this.closeRoute = closeRoute;
  }

  setId(nidaId: string, protocolId: number) {
    this.nidaId = nidaId;
    this.protocolId = protocolId;
  }

  getData(id: string, protocolId: number, current: boolean) {
    this.getPatientData(id, current);
    this.getPatientDetails(protocolId);

    const subscription = this.patientDetails.subscribe((patientDetails) => {
      if (patientDetails && patientDetails.id) {
        this.emergencyRoomService.getPatient(id, protocolId).subscribe((erPatient) => {
          if (erPatient) {
            console.log('Getting everysing data');
            this.getAllTableData(erPatient);
          } else {
            this.emergencyRoomService.generateNewEntry(id, protocolId).subscribe((erPatient) => {
              this.getAllTableData(erPatient);
            });
          }
        });
      }
    });

    this.subscriptionArray.push(subscription);
  }

  getPatientData(id: string, current: boolean) {
    if (current) {
      const subscription = this.patientCurrentStore.patientsLoaded.subscribe((response) => {
        if (response) {
          this.patientData.next(this.patientCurrentStore.getDetailInformation(id));
        } else {
          this.patientCurrentStore.renewList();
        }
      });
      this.subscriptionArray.push(subscription);
    } else {
      const subscription = this.patientArchiveStore.patientsLoaded.subscribe((response) => {
        if (response) {
          this.patientData.next(this.patientArchiveStore.getDetailInformation(id));
        } else {
          this.patientArchiveStore.renewList();
        }
      });
      this.subscriptionArray.push(subscription);
    }
  }

  getPatientDetails(protocolId: number) {
    const subscription = this.patientServable.getPatientByProtocolId(protocolId).subscribe((response) => {
      if (response && protocolId) {
        this.patientDetails.next(response);
      }
    });
    this.subscriptionArray.push(subscription);
  }

  getAllTableData(erPatient: ERPatient) {
    this.erPatientId = erPatient.id ? erPatient.id : -1;
    const array = [
      { key: 'anamnesis', id: erPatient.anamnesisId }, // getAnamnesis
      { key: 'councilList', id: erPatient.id }, // getCouncilList
      { key: 'diagnostic', id: erPatient.diagnosticId }, // getDiagnostic
      { key: 'preclinic', id: erPatient.preclinicId }, // getPreclinic
      { key: 'finalReport', id: erPatient.finalReportId }, // getFinalReport
      { key: 'therapy', id: erPatient.therapyId }, // getTherapy
      { key: 'medicalAccessListByPreclinic', id: erPatient.preclinicId }, // getMedicalAccessListByPreclinic
      { key: 'medicalAccessListByTherapy', id: erPatient.therapyId }, // getMedicalAccessListByTherapy
      { key: 'ICD10List', id: erPatient.finalReportId }, // getICD10List
      { key: 'treatmentMemberList', id: erPatient.id }, // getTreatmentMemberList
    ];

    array.forEach((element) => {
      if (element.id) {
        this.getPageDataByKey(element.key, element.id);
      } else {
        console.log('ERROR: Given < ' + element.key + ' > id was falsy');
      }
    });

    // vitals:
    this.preclinicData.subscribe((response) => {
      if (response && response.vitalsId) {
        // getPageByDataKey not fitting
        this.emergencyRoomService.getVitals(response.vitalsId).subscribe((vitalResponse) => {
          this.vitalsPreclinicData.next(vitalResponse);
        });
      }
    });
    this.anamnesisData.subscribe((response) => {
      if (response && response.vitalsId) {
        // getPageByDataKey not fitting
        this.emergencyRoomService.getVitals(response.vitalsId).subscribe((vitalResponse) => {
          this.vitalsAnamnesisData.next(vitalResponse);
        });
      }
    });

    // ordersList:
    const orderListObject = {};
    this.subscriptionArray.push(
      this.councilListData.subscribe((councilList) => {
        if (councilList && councilList.data) {
          councilList.data.forEach((council) => {
            if (council && council.id) {
              this.subscriptionArray.push(
                this.emergencyRoomService.getOrderList(council.id).subscribe((response: EROrderList) => {
                  if (council.id) {
                    orderListObject[council.id] = response.data; // response = {data: [...]}
                    /*
                Creating a new object:
                {
                  123: [...],
                  234: [...],
                  <councilId>: [<orderArray>]
                  ...
                }
                */
                    this.orderListData.next(orderListObject);
                  }
                })
              );
            }
          });
          // TODO: Check if this part of the code runs really after the forEach is done;
        }
      })
    );
  }

  getCouncils() {
    this.getPageDataByKey('councilList', this.erPatientId);
  }
  getTreatmentMembers() {
    this.getPageDataByKey('treatmentMemberList', this.erPatientId);
  }

  getPageDataByKey(key: string, id: string | number) {
    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1); // anamnesis => Anamnesis
    const functionKey = 'get' + capitalizedKey; // getAnamnesis
    const variableKey = key + 'Data'; // anamnesisData

    const idValue = id; // e.g. 255

    if (idValue) {
      this.emergencyRoomService[functionKey](idValue).subscribe((response) => {
        this[variableKey].next(response);
      });
    }
  }

  updatePreclinic(preclinidId: number, preclinic: ERPreclinic): Observable<ERPreclinic> {
    return this.emergencyRoomService.updatePreclinic(preclinidId, preclinic);
  }

  updateAnamnesis(anamnesisId: number, anamnesis: ERAnamnesis): Observable<ERAnamnesis> {
    return this.emergencyRoomService.updateAnamnesis(anamnesisId, anamnesis);
  }

  updateTherapy(therapyId: number, therapy: ERTherapy): Observable<ERTherapy> {
    return this.emergencyRoomService.updateTherapy(therapyId, therapy);
  }

  updateVitals(vitalsId: number, vitals: ERVitals): Observable<ERVitals> {
    return this.emergencyRoomService.updateVitals(vitalsId, vitals);
  }

  updateDiagnostic(diagnosticId: number, diagnostic: ERDiagnostic): Observable<ERDiagnostic> {
    return this.emergencyRoomService.updateDiagnostic(diagnosticId, diagnostic);
  }

  updateFinalReport(finalReportId: number, finalReport: ERFinalReport): Observable<ERFinalReport> {
    return this.emergencyRoomService.updateFinalReport(finalReportId, finalReport);
  }

  createCouncil(council: ERCouncil) {
    return this.emergencyRoomService.createCouncil(council);
  }

  createTreatmentMember(treatmentMember: ERTreatmentMember) {
    return this.emergencyRoomService.createTreatmentMember(treatmentMember);
  }

  updateCouncil(councilId: number, council: ERCouncil): Observable<ERCouncil> {
    return this.emergencyRoomService.updateCouncil(councilId, council);
  }

  deleteCouncil(councilId: number): Observable<boolean> {
    return this.emergencyRoomService.deleteCouncil(councilId);
  }

  getOrderList(councilId: number) {
    return this.emergencyRoomService.getOrderList(councilId);
  }

  createOrder(erOrder: EROrder) {
    return this.emergencyRoomService.createOrder(erOrder);
  }

  updateOrder(orderId: number, order: EROrder): Observable<EROrder> {
    return this.emergencyRoomService.updateOrder(orderId, order);
  }

  deleteOrder(orderId: number): Observable<boolean> {
    return this.emergencyRoomService.deleteOrder(orderId);
  }

  createMedicalAccess(erMedcialAccess: ERMedicalAccess) {
    return this.emergencyRoomService.createMedicalAccess(erMedcialAccess);
  }

  updateMedicalAccess(medicalAccessId: number, medicalAccess: ERMedicalAccess): Observable<ERMedicalAccess> {
    return this.emergencyRoomService.updateMedicalAccess(medicalAccessId, medicalAccess);
  }

  deleteMedicalAccess(medicalAccessId: number): Observable<boolean> {
    return this.emergencyRoomService.deleteMedicalAccess(medicalAccessId);
  }

  createICD10(idc10: ERIcd10) {
    return this.emergencyRoomService.createICD10(idc10);
  }

  updateICD10(icd10Id: number, icd10: ERIcd10): Observable<ERIcd10> {
    return this.emergencyRoomService.updateICD10(icd10Id, icd10);
  }

  deleteICD10(icd10Id: number): Observable<boolean> {
    return this.emergencyRoomService.deleteICD10(icd10Id);
  }

  updateTreatmentMember(treatmentMemberId: number, treatmentMember: ERTreatmentMember): Observable<ERTreatmentMember> {
    return this.emergencyRoomService.updateTreatmentMember(treatmentMemberId, treatmentMember);
  }

  deleteTreatmentMember(treatmentMemberId: number): Observable<boolean> {
    return this.emergencyRoomService.deleteTreatmentMember(treatmentMemberId);
  }

  unsubscribeAllSubscriptions() {
    this.subscriptionArray.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptionArray = [];
    this.resetAllSubjects();
  }

  private resetAllSubjects() {
    this.patientData = new BehaviorSubject<PatientView>(new PatientView('', -1));
    this.patientDetails = new BehaviorSubject<Patient>({} as Patient);
    this.anamnesisData = new BehaviorSubject<ERAnamnesis>({} as ERAnamnesis);
    this.councilListData = new BehaviorSubject<ERCouncilList>({} as ERCouncilList);
    this.diagnosticData = new BehaviorSubject<ERDiagnostic>({} as ERDiagnostic);
    this.preclinicData = new BehaviorSubject<ERPreclinic>({} as ERPreclinic);
    this.finalReportData = new BehaviorSubject<ERFinalReport>({} as ERFinalReport);
    this.therapyData = new BehaviorSubject<ERTherapy>({} as ERTherapy);
    this.medicalAccessListByPreclinicData = new BehaviorSubject<ERMedicalAccessList>({} as ERMedicalAccessList);
    this.medicalAccessListByTherapyData = new BehaviorSubject<ERMedicalAccessList>({} as ERMedicalAccessList);
    this.ICD10ListData = new BehaviorSubject<ERIcd10List>({} as ERIcd10List);
    this.treatmentMemberListData = new BehaviorSubject<ERTreatmentMemberList>({} as ERTreatmentMemberList);

    this.vitalsPreclinicData = new BehaviorSubject<ERVitals>({} as ERVitals);
    this.vitalsAnamnesisData = new BehaviorSubject<ERVitals>({} as ERVitals);
    this.orderListData = new BehaviorSubject<any>({}); // TODO: Any needed, but any not allowed
  }
}
