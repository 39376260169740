import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-meddv-loader',
  templateUrl: './meddv-loader.component.html',
  styleUrls: ['./meddv-loader.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MeddvLoaderComponent implements OnInit {
  @Input() size?: 'large' | 'medium' | 'small';

  ngOnInit() {
    if (!this.size) {
      this.size = 'medium';
    }
  }
}
