import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adtMessageConverter',
})
export class AdtMessageConverterPipe implements PipeTransform {
  transform(adtCode: string): string {
    let adtMessageType: string;
    switch (adtCode) {
      case '':
        adtMessageType = 'ohne';
        break;
      case 'ADT_A01':
        adtMessageType = 'stationäre Aufnahme';
        break;
      case 'ADT_A02':
        adtMessageType = 'Verlegung';
        break;
      case 'ADT_A03':
        adtMessageType = 'Entlassung';
        break;
      case 'ADT_A04':
        adtMessageType = 'Ambulanter Besuch';
        break;
      case 'ADT_A05':
        adtMessageType = 'Voraufnahme eines Patienten';
        break;
      case 'ADT_A06':
        adtMessageType = 'Fallstatuswechsel ambulant - stationär';
        break;
      case 'ADT_A07':
        adtMessageType = 'Fallstatuswechsel stationär - ambulant';
        break;
      case 'ADT_A08':
        adtMessageType = 'Patientenänderung';
        break;
      default:
        adtMessageType = adtCode;
        break;
    }
    return adtMessageType;
  }
}
