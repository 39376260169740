import { Injectable, OnDestroy } from '@angular/core';
import { Tenant, TenantService } from '@nida-web/api/rest/authentication';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetTenantsService implements OnDestroy {
  public tenantList: Array<Tenant>;
  public tenantsLoaded: Subject<boolean>;
  private subscription: Subscription;

  constructor(private tenantService: TenantService) {
    this.tenantsLoaded = new BehaviorSubject(false);
  }

  private static sortTenants(tenants: Array<Tenant>): Array<Tenant> {
    return tenants.sort((a, b) => {
      const aName = a.name ? a.name.toUpperCase() : '';
      const bName = b.name ? b.name.toUpperCase() : '';

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  public loadTenants(): void {
    this.subscription = this.tenantService.getTenants().subscribe((response) => {
      this.tenantList = GetTenantsService.sortTenants(response.data);
      this.tenantsLoaded.next(true);
    });
  }

  public getTenants(): Array<Tenant> {
    return this.tenantList;
  }

  public getTenantsAsObservable(): Subscription {
    return this.subscription;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
