import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { PatientConfiguration } from '../models/patient-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class PatientSettingsService {
  private patientConfigSubj: Subject<PatientConfiguration>;

  constructor() {
    this.patientConfigSubj = new ReplaySubject<PatientConfiguration>(1);
  }

  public updateSettings(settings: PatientConfiguration): void {
    this.patientConfigSubj.next(settings);
  }

  public getSettings(): Observable<PatientConfiguration> {
    return this.patientConfigSubj.asObservable();
  }
}
