/**
 * NIDAserver Auth API
 * API for authenticaion and authorization against NIDAserver
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * login method descriptor
 */
export interface Method {
  method?: Method.MethodEnum;
}

// Database: personal.typ
// hashed = 0
// plain = 1
// oidc = 2

export namespace Method {
  export type MethodEnum = 'hashed' | 'plain' | 'oidc';
  export const MethodEnum = {
    Hashed: 'hashed' as MethodEnum,
    Plain: 'plain' as MethodEnum,
    Oidc: 'oidc' as MethodEnum,
  };
}
