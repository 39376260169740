/**
 * NIDA Configuration
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { Config } from '../models/config';
import { NoContent } from '../models/noContent';
import { Configs } from '../models/configs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public defaultHeaders: HttpHeaders;
  public configuration: Configuration;
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(private httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.subscription = new Subscription();

    const configSub = this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'app-config/config';
    });
    this.subscription.add(configSub);

    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
  }

  /**
   *
   * Create config object
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createConfig(body: Config, observe?: 'body', reportProgress?: boolean): Observable<Config>;
  public createConfig(body: Config, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Config>>;
  public createConfig(body: Config, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Config>>;
  public createConfig(body: Config, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createConfig.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Config>('post', `${this.apiPrefix}`, {
      body,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   *
   * Delete config object
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteConfig(id: number, observe?: 'body', reportProgress?: boolean): Observable<NoContent>;
  public deleteConfig(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoContent>>;
  public deleteConfig(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoContent>>;
  public deleteConfig(id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteConfig.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.httpClient.request<NoContent>('delete', `${this.apiPrefix}/${encodeURIComponent(String(id))}`, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   *
   * Get config object
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getConfig(id: number, observe?: 'body', reportProgress?: boolean): Observable<Config>;
  public getConfig(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Config>>;
  public getConfig(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Config>>;
  public getConfig(id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getConfig.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Config>('get', `${this.apiPrefix}/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   *
   * Get config object
   * @param group
   * @param key
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getConfigs(group: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<Configs>;
  public getConfigs(group: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Configs>>;
  public getConfigs(group: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Configs>>;
  public getConfigs(group: string, key: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (group === null || group === undefined) {
      throw new Error('Required parameter group was null or undefined when calling getConfigs.');
    }

    if (key === null || key === undefined) {
      throw new Error('Required parameter key was null or undefined when calling getConfigs.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Configs>(
      'get',
      `${this.apiPrefix}/${encodeURIComponent(String(group))}/${encodeURIComponent(String(key))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   *
   * Get config object
   * @param group
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getConfigsByGroup(group: string, observe?: 'body', reportProgress?: boolean): Observable<Configs>;
  public getConfigsByGroup(group: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Configs>>;
  public getConfigsByGroup(group: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Configs>>;
  public getConfigsByGroup(group: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (group === null || group === undefined) {
      throw new Error('Required parameter group was null or undefined when calling getConfigsByGroup.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Configs>('get', `${this.apiPrefix}/group/${encodeURIComponent(String(group))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   *
   * Update config object
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateConfig(body: Config, id: number, observe?: 'body', reportProgress?: boolean): Observable<Config>;
  public updateConfig(body: Config, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Config>>;
  public updateConfig(body: Config, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Config>>;
  public updateConfig(body: Config, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateConfig.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateConfig.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Config>('put', `${this.apiPrefix}/${encodeURIComponent(String(id))}`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
}
