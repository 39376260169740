import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'nida-web-ecg-viewer',
  templateUrl: './ecg-viewer.component.html',
  styleUrls: ['./ecg-viewer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class EcgViewerComponent {
  @Output() chosenChannels: any = [];
  @Input() nidaId: string;
  @Input() ecgId: number;
  @Input() timestamp: Date;

  constructor(private route: ActivatedRoute, private location: Location) {
    this.nidaId = '';
    this.ecgId = 0;
    this.timestamp = new Date();

    this.handleEcgId(this.route.snapshot.params['ecgId']);
    this.handleNidaId(this.route.snapshot.params['nidaId']);
    this.handleTimestamp(this.route.snapshot.params['timestamp']);
  }

  handleEcgId(ecgId: string | null): void {
    const ecgIdParam: string | null = ecgId;
    if (ecgIdParam !== null) {
      const ecgIdAsInt: number = parseInt(ecgIdParam, 10);
      if (Number.isFinite(ecgIdAsInt)) {
        this.ecgId = ecgIdAsInt;
      }
    }
  }

  handleNidaId(nidaId: string | null): void {
    const nidaIdParam: string | null = nidaId;
    if (nidaIdParam !== null) {
      this.nidaId = nidaIdParam;
    }
  }

  handleTimestamp(timestamp: string | null): void {
    const timestampParam: string | null = timestamp;
    if (timestampParam !== null) {
      this.timestamp = new Date(timestampParam);
    }
  }

  triggerChosenChannels(input: any) {
    this.chosenChannels = input;
  }

  triggerNavigation() {
    this.location.back();
  }
}
