import { Injectable } from '@angular/core';
import { PatientView, PatientViewList, PatientListServable } from '@nida-web/api/generic-interfaces/patient-management';
import { Observable, ReplaySubject } from 'rxjs';
import { PatientViewRESTService } from '../actions/patient-view.rest.service';
import { PatientMappingService } from '../mapping/patient-mapping.service';

@Injectable({
  providedIn: 'root',
})
export class PatientViewRESTAdapterService implements PatientListServable {
  constructor(private patientRESTService: PatientViewRESTService, private patientMappingService: PatientMappingService) {}

  getPatient(nidaId: string): Observable<PatientView> {
    const resultSubject = new ReplaySubject<PatientView>(1);

    this.patientRESTService.getSinglePatient(nidaId).subscribe((rawPatient) => {
      resultSubject.next(this.patientMappingService.mapEntry(rawPatient));
    });

    return resultSubject.asObservable();
  }

  getPatientList(notArchived?: 0 | 1, revision?: number): Observable<PatientViewList> {
    const resultSubject = new ReplaySubject<PatientViewList>(1);

    this.patientRESTService.getList(notArchived, revision).subscribe((rawList) => {
      const patientList: PatientViewList = [];

      if (rawList === null || rawList === undefined || rawList.data === undefined) {
        resultSubject.next(patientList);
        return;
      }

      for (const rawPatient of rawList.data) {
        patientList.push(this.patientMappingService.mapEntry(rawPatient));
      }

      resultSubject.next(patientList);
    });

    return resultSubject;
  }

  getPatientListAfterDate(dateAfter: string): Observable<PatientViewList> {
    const resultSubject = new ReplaySubject<PatientViewList>(1);

    this.patientRESTService.getListAfterDate(dateAfter).subscribe((rawList) => {
      const patientList: PatientViewList = [];

      if (rawList === null || rawList === undefined || rawList.data === undefined) {
        resultSubject.next(patientList);
        return;
      }

      for (const rawPatient of rawList.data) {
        patientList.push(this.patientMappingService.mapEntry(rawPatient));
      }

      resultSubject.next(patientList);
    });

    return resultSubject;
  }

  setReadProtocol(nidaId: string, id: number): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    this.patientRESTService.protocolRead(nidaId, id).subscribe((result) => {
      if (result) {
        resultSubject.next(true);
      } else {
        resultSubject.next(false);
      }
    });

    return resultSubject;
  }
}
