import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { PasswordService } from '../actions/password.service';
import { PasswordStatus } from '../models/passwordStatus';
import { Password } from '../models/password';

@Injectable({
  providedIn: 'root',
})
export class PasswordAdapterService {
  public constructor(private passwordService: PasswordService) {}

  public statusUserMobilePassword(id: number): Observable<PasswordStatus> {
    const resultSubject = new ReplaySubject<PasswordStatus>(1);

    this.passwordService.statusUserMobilePassword(id).subscribe((result) => {
      resultSubject.next(result as PasswordStatus);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }

  public changeUserMobilePassword(body: Password, id: number): Observable<PasswordStatus> {
    const resultSubject = new ReplaySubject<PasswordStatus>(1);

    this.passwordService.changeUserMobilePassword(body, id).subscribe((result) => {
      resultSubject.next(result as PasswordStatus);
      resultSubject.complete();
    });

    return resultSubject.asObservable();
  }
}
