<div style="padding: 10px">
  <nida-web-er-title-toolbar
    [title]="'Diagnosis'"
    [disabled]="!!diagnosisFormGroup.pristine"
    (saveTrigger)="saveAll()"
    (revertTrigger)="resetAll()"
  ></nida-web-er-title-toolbar>
</div>

<form [formGroup]="diagnosisFormGroup">
  <div class="row m-0">
    <div *ngFor="let tile of tiles; index as index" class="col-12 col-sm-6 col-md-4 col-xl-2 p-0">
      <div class="basic-card-wrapper percent-100-height">
        <div class="basic-card-style">
          <div class="basic-card-title basic-padding">
            {{ tile.caption }}
          </div>
          <div class="">
            <div class="row m-0 pad-10 pb-0">
              <div class="col-12 p-0">
                <div (click)="checkDateEntryExistence(tile.dateKey)">
                  <dx-date-box [formControlName]="tile.dateKey" type="datetime"></dx-date-box>
                </div>
              </div>
            </div>
            <div class="row pad-10 m-0">
              <div class="col-8 p-0 d-flex align-items-center">
                <p class="my-0 ml-0 mr-3">
                  {{ 'path. Befund' | transloco }}
                </p>
              </div>
              <div class="col-4 p-0 d-flex align-items-center justify-content-end">
                <dx-switch [formControlName]="tile.pathoKey" [switchedOnText]="'Yes' | transloco" [switchedOffText]="'No' | transloco">
                </dx-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0">
    <div class="col-lg-8 p-0">
      <div class="basic-card-wrapper">
        <div class="basic-card-style">
          <div class="basic-card-title basic-padding">
            {{ 'Reports' | transloco }}
          </div>
          <div class="basic-card-body">
            <div class="row m-0 pad-10">
              <dx-text-area
                formControlName="findings"
                class="w-100"
                [minHeight]="140"
                [maxHeight]="270"
                name="description"
                placeholder="{{ 'Description' | transloco }}"
              ></dx-text-area>
            </div>
          </div>
          <!--        [(value)]="data"-->
          <!--        (keyup)="saveText()"-->
        </div>
      </div>
    </div>
  </div>
</form>
