import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectBoxComponent } from './language-select-box/language-select-box.component';
import { DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [LanguageSelectBoxComponent],
  imports: [CommonModule, DxSelectBoxModule, DxTextBoxModule, TranslocoModule],
  exports: [LanguageSelectBoxComponent],
})
export class LanguageSelectModule {}
