<div>
  <div style="padding: 10px">
    <nida-web-er-title-toolbar
      [title]="'Anamnesis'"
      [disabled]="!(!vitalsAnamnesisFormGroup.pristine || !anamnesisFormGroup.pristine)"
      (saveTrigger)="submitFormGroups()"
      (revertTrigger)="resetFormGroups()"
    ></nida-web-er-title-toolbar>
  </div>

  <div>
    <nida-web-emergency-room-vital-signs
      [formGroup]="vitalsAnamnesisFormGroup"
      [vitalSigns]="vitalsAnamnesisData"
      [disabled]="false"
    ></nida-web-emergency-room-vital-signs>
  </div>

  <form [formGroup]="anamnesisFormGroup">
    <div class="row m-0">
      <div class="col-12 col-sm-6 col-md-6 p-0">
        <div class="basic-card-wrapper percent-100-height">
          <div class="basic-card-style">
            <div class="basic-card-title basic-padding">
              {{ 'Anamnesis' | transloco }}
            </div>
            <div class="basic-padding">
              <div class="row basic-padding-bottom">
                <div class="col-12 mb-1">
                  <strong>{{ 'Allergy' | transloco }}</strong>
                </div>

                <div class="col-md-6 basic-padding-bottom">
                  <div class="row">
                    <div class="col-6 justify-content-center align-self-center">{{ 'Contrast agent' | transloco }}</div>
                    <div class="col-6 justify-content-end d-flex align-self-end">
                      <dx-switch
                        formControlName="allergyContrastMedium"
                        [switchedOnText]="'Yes' | transloco"
                        [switchedOffText]="'No' | transloco"
                      >
                      </dx-switch>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 basic-padding-bottom">
                  <div class="row">
                    <div class="col-6 justify-content-center align-self-center">{{ 'Antibiotics' | transloco }}</div>
                    <div class="col-6 justify-content-end d-flex align-self-end">
                      <dx-switch
                        formControlName="allergyAntibiotics"
                        [switchedOnText]="'Yes' | transloco"
                        [switchedOffText]="'No' | transloco"
                      >
                      </dx-switch>
                    </div>
                  </div>
                </div>

                <div class="col-12">{{ 'Other' | transloco }}</div>
                <div class="col-12 basic-padding-bottom">
                  <dx-text-area [minHeight]="60" formControlName="allergyOthers"></dx-text-area>
                </div>

                <div class="col-12">
                  <div class="row mb-1">
                    <div class="col-12 col-xl-6 mb-1 mb-xl-0 justify-content-center align-self-center">
                      <strong>{{ 'Pregnancy' | transloco }}</strong>
                    </div>
                    <div class="col-12 col-xl-6">
                      <dx-select-box
                        formControlName="pregnancy"
                        displayExpr="name"
                        valueExpr="name"
                        [dataSource]="selectBoxOptions"
                        fieldTemplate="field"
                      >
                        <div *dxTemplate="let data of 'field'">
                          <dx-text-box
                            [value]="data && data.name | transloco"
                            [readOnly]="true"
                            placeholder="Bitte auswählen"
                          ></dx-text-box>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                          <div>{{ data.name | transloco }}</div>
                        </div>
                      </dx-select-box>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row mb-1">
                    <div class="col-12 col-xl-6 mb-1 mb-xl-0 justify-content-center align-self-center">
                      <strong>{{ 'Tetanus protection' | transloco }}</strong>
                    </div>
                    <div class="col-12 col-xl-6">
                      <dx-select-box
                        formControlName="tetanusProtection"
                        displayExpr="name"
                        valueExpr="name"
                        [placeholder]="'Select a value...' | transloco"
                        [dataSource]="selectBoxOptions"
                        fieldTemplate="field"
                      >
                        <div *dxTemplate="let data of 'field'">
                          <dx-text-box
                            [value]="data && data.name | transloco"
                            [readOnly]="true"
                            placeholder="Bitte auswählen"
                          ></dx-text-box>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                          <div>{{ data.name | transloco }}</div>
                        </div>
                      </dx-select-box>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row mb-1">
                    <div class="col-12 col-xl-6 mb-1 mb-xl-0 justify-content-center align-self-center">
                      <strong>{{ 'Isolation' | transloco }}</strong>
                    </div>
                    <div class="col-12 col-xl-6">
                      <div>
                        <dx-select-box
                          formControlName="isolation"
                          displayExpr="name"
                          valueExpr="name"
                          [placeholder]="'Select a value...' | transloco"
                          [dataSource]="isolationBoxOptions"
                          fieldTemplate="field"
                        >
                          <div *dxTemplate="let data of 'field'">
                            <dx-text-box
                              [value]="data && data.name | transloco"
                              [readOnly]="true"
                              placeholder="Bitte auswählen"
                            ></dx-text-box>
                          </div>
                          <div *dxTemplate="let data of 'item'">
                            <div>{{ data.name | transloco }}</div>
                          </div>
                        </dx-select-box>
                      </div>
                      <div *ngIf="anamnesisFormGroup.value.isolation === 'Multi-resistant germ'">
                        <dx-select-box
                          formControlName="multiresistantGerm"
                          displayExpr="name"
                          valueExpr="name"
                          [placeholder]="'Select a value...' | transloco"
                          [dataSource]="multiResistantGermBoxOptions"
                          fieldTemplate="field"
                        >
                          <div *dxTemplate="let data of 'field'">
                            <dx-text-box
                              [value]="data && data.name | transloco"
                              [readOnly]="true"
                              placeholder="Bitte auswählen"
                            ></dx-text-box>
                          </div>
                          <div *dxTemplate="let data of 'item'">
                            <div>{{ data.name | transloco }}</div>
                          </div>
                        </dx-select-box>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-xl-6 mb-1 mb-xl-0 justify-content-center align-self-center">
                      <strong>{{ 'Anticoagulants' | transloco }}</strong>
                    </div>
                    <div class="col-12 col-xl-6">
                      <dx-select-box
                        formControlName="anticoagulation"
                        displayExpr="name"
                        valueExpr="name"
                        [placeholder]="'Select a value...' | transloco"
                        [dataSource]="anticoagulantsBoxOptions"
                        fieldTemplate="field"
                      >
                        <div *dxTemplate="let data of 'field'">
                          <dx-text-box
                            [value]="data && data.name | transloco"
                            [readOnly]="true"
                            placeholder="Bitte auswählen"
                          ></dx-text-box>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                          <div>{{ data.name | transloco }}</div>
                        </div>
                      </dx-select-box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 p-0">
        <div class="basic-card-wrapper percent-100-height">
          <div class="basic-card-style">
            <div class="basic-card-title basic-padding">
              {{ 'Emergency anamnesis of patient' | transloco }}
            </div>
            <div class="basic-padding">
              <div class="row">
                <div class="col-xl-6">
                  <div class="row basic-padding-bottom">
                    <div class="col-12">
                      {{ 'Responsible doctor' | transloco }}
                    </div>
                    <div class="col-12">
                      <dx-text-box formControlName="responsibleDoctor"></dx-text-box>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="row basic-padding-bottom">
                    <div class="col-12">{{ 'First medical contact' | transloco }}</div>
                    <div class="col-12 justify-content-start d-flex align-self-start">
                      <dx-date-box
                        formControlName="firstMedicalContact"
                        (click)="checkDateExistence('firstMedicalContact')"
                        type="datetime"
                      >
                      </dx-date-box>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row basic-padding-bottom">
                <div class="col-12">
                  {{ 'CEDIS main symptom' | transloco }}
                </div>
                <div class="col-12">
                  <dx-select-box
                    [dataSource]="cedisBoxOptions"
                    displayExpr="name"
                    valueExpr="name"
                    [grouped]="true"
                    [searchEnabled]="true"
                    formControlName="cedisLeadingSymptom"
                    fieldTemplate="field"
                  >
                    <div *dxTemplate="let data of 'field'">
                      <dx-text-box
                        [value]="data && data.code + ' ' + data.name | transloco"
                        [placeholder]="'Select a value...' | transloco"
                      ></dx-text-box>
                    </div>
                    <div *dxTemplate="let data of 'item'">
                      <div>{{ data.code }} {{ data.name | transloco }}</div>
                    </div>
                  </dx-select-box>
                </div>
              </div>
              <div class="row">
                <!--                <div class="col-xl-6">-->
                <!--                  <div class="row basic-padding-bottom">-->
                <!--                    <div class="col-12" style="color: red">{{ 'Symptom beginning' | transloco }}</div>-->
                <!--                    <div class="col-12 justify-content-start d-flex align-self-start">-->
                <!--                      <dx-date-box formControlName="onsetOfSymptoms" (click)="checkDateExistence('onsetOfSymptoms')" type="datetime">-->
                <!--                      </dx-date-box>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="col-xl-6">
                  <div class="row basic-padding-bottom">
                    <div class="col-12">
                      {{ 'Symptom end' | transloco }}
                    </div>
                    <div class="col-12 justify-content-start d-flex align-self-start">
                      <dx-date-box formControlName="symptomEnd" type="datetime" (click)="checkDateExistence('symptomEnd')"></dx-date-box>
                      <!-- TODO: Missing property -->
                      <!-- TODO: Add formControlName + name for missing property -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col">
                  <div class="basic-padding-bottom">
                    {{ 'Emergency anamnesis' | transloco }} / {{ 'Relevant self-medication' | transloco }} /
                    {{ 'Physical examination' | transloco }}
                  </div>
                  <div class="basic-padding-bottom">
                    <dx-text-area formControlName="emergencyAnamnesis" autoResizeEnabled="true" [minHeight]="110" [maxHeight]="180">
                    </dx-text-area>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
