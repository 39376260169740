/**
 * Findings Datatype
 */
export class Findings {
  tisLocalisation?: number;
  tisTraumaType?: number;
  tisCirculation?: number;
  tisBreathing?: number;
  tisAwareness?: number;

  initial_gcs?: number | string;
  initial_gcs_eyes?: number | string;
  initial_gcs_motoric?: number | string;
  initial_gcs_verbal?: number | string;
  circulation?: number | string;
  intubation?: number | string;
  priority?: number | string;
  arrival_time?: number | string;

  supplement1key?: number | string;
  supplement1value?: number | string;
  supplement2key?: number | string;
  supplement2value?: number | string;
  supplement3key?: number | string;
  supplement3value?: number | string;

  pain?: number | string;
  pain_radiation?: number | string;
  event_time?: number | string;
  event_date?: number | string;
  cardio_intubated?: number | string;
  cardio_catecholamins?: number | string;
  cardio_cpr?: number | string;
  cardio_stemi?: number | string;
  cardio_catheter?: number | string;
  cardio_bypass?: number | string;
  cardio_heartattack?: number | string;

  pees_eyes?: number | string;
  pees_eyes_value?: number | string;
  pees_view?: number | string;
  pees_view_value?: number | string;
  pees_arm_leg?: number | string;
  pees_arm_leg_value?: number | string;
  pees_twitching?: number | string;
  pees_twitching_value?: number | string;
  pees_mouth?: number | string;
  pees_mouth_value?: number | string;
  pees_extremities?: number | string;
  pees_extremities_value?: number | string;
  pees_duration_seizure?: number | string;
  pees_duration_seizure_value?: number | string;
  pees_duration_consciousness?: number | string;
  pees_duration_consciousness_value?: number | string;
  pees_tongue_bite?: number | string;
  pees_tongue_bite_value?: number | string;
  pees_epilepsy?: number | string;
  pees_epilepsy_value?: number | string;
  pees_sum?: number | string;

  speech_disorder?: number | string;

  stroke_4iss_vigilance?: number | string;
  stroke_4iss_vigilance_value?: number | string;
  stroke_4iss_hemiparese?: number | string;
  stroke_4iss_hemiparese_value?: number | string;
  stroke_4iss_hemiparese_side?: number | string;
  stroke_4iss_hemiparese_side_value?: number | string;
  stroke_4iss_turn?: number | string;
  stroke_4iss_turn_value?: number | string;

  // generic stroke new
  stroke_anticoagulation?: string; // stroke_gerinnungshemmung
  stroke_marcumar?: string; // stroke_marcumar
  stroke_last_seen_well_text?: string; // stroke_last_seen_well_text
  stroke_last_seen_well_time?: number | string; // stroke_last_seen_well_zeit
  stroke_last_seen_well_date?: number | string; // stroke_last_seen_well_datum

  stroke_prae_mrs?: string; // stroke_prae_mrs
  stroke_previousillness_atrialfibrillation?: string; // stroke_vorerkrankung_vorhofflimmern
  stroke_previousillness_epilepsy?: string; // stroke_vorerkrankung_epilepsie
  stroke_previousillness_migraine?: string; // stroke_vorerkrankung_migraene
  stroke_previousillness_diabetes?: string; // stroke_vorerkrankung_diabetes
  stroke_previousillness_hypertension?: string; // stroke_vorerkrankung_hypertonie
  stroke_injuries?: string; // stroke_verletzungen

  // snihss
  stroke_snihss_consciousness_text?: string; // stroke_snihss_bewusstsein_text
  stroke_snihss_consciousness_nr?: number; // stroke_snihss_bewusstsein_nr
  stroke_snihss_face_text?: string; // stroke_snihss_gesicht_text
  stroke_snihss_face_nr?: number; // stroke_snihss_gesicht_nr
  stroke_snihss_motorics_text?: string; // stroke_snihss_motorik_text
  stroke_snihss_motorics_nr?: number; // stroke_snihss_motorik_nr
  stroke_snihss_sensitivity_text?: string; // stroke_snihss_sensibilitaet_text
  stroke_snihss_sensitivity_nr?: number; // stroke_snihss_sensibilitaet_nr
  stroke_snihss_speech_impediment_text?: string; // stroke_snihss_sprachstoerung_text
  stroke_snihss_speech_impediment_nr?: number; // stroke_snihss_sprachstoerung_nr
  stroke_snihss_washed_out_text?: string; // stroke_snihss_verschwaschen_text
  stroke_snihss_washed_out_nr?: number; // stroke_snihss_verschwaschen_nr
  stroke_snihss_sum?: number; // stroke_snihss_summe

  // face2ad
  stroke_face2ad_face?: string; // stroke_face2ad_gesicht
  stroke_face2ad_face_nr?: number; // stroke_face2ad_gesicht_nr
  stroke_face2ad_parese_arm?: string; // stroke_face2ad_parese_arm
  stroke_face2ad_parese_arm_nr?: number; // stroke_face2ad_parese_arm_nr
  stroke_face2ad_consciousness?: string; // stroke_face2ad_bewusstsein
  stroke_face2ad_consciousness_nr?: number; // stroke_face2ad_bewusstsein_nr
  stroke_face2ad_stoveview?: string; // stroke_face2ad_herdblick
  stroke_face2ad_stoveview_nr?: number; // stroke_face2ad_herdblick_nr
  stroke_face2ad_atrialfibrillation?: string; // stroke_face2ad_vorhofflimmern
  stroke_face2ad_atrialfibrillation_nr?: number; // stroke_face2ad_vorhofflimmern_nr
  stroke_face2ad_rr_dia_85?: string; // stroke_face2ad_rr_dia_85
  stroke_face2ad_rr_dia_85_nr?: number; // stroke_face2ad_rr_dia_85_nr
  stroke_face2ad_sum?: number; // stroke_face2ad_summe
  stroke_face2ad_parese_bein?: string; // stroke_face2ad_parese_bein
  stroke_face2ad_aphasie?: string; // stroke_face2ad_aphasie
  stroke_face2ad_side?: string; // stroke_face2ad_seite
}
