<div class="responsive-paddings">
  <div class="basic-padding">
    <dx-button text="{{ 'Back' | transloco }}" icon="back" (click)="navigateBack()"></dx-button>
  </div>
  <div class="row mx-0 p-0">
    <div class="col mx-0 p-1 p-md-10 basic-direction-column basic-padding">
      <nida-web-patient-personal-data
        [detailData]="detailData"
        [loaded]="loaded"
        [patientData]="patientData"
      ></nida-web-patient-personal-data>
    </div>
    <div class="col mx-0 p-1 p-md-10 basic-direction-column basic-padding">
      <nida-web-patient-insurance [protocolId]="patientData.protocolId"></nida-web-patient-insurance>
    </div>
  </div>
  <div class="row mx-0 p-0">
    <div class="col mx-0 p-1 p-md-10 basic-direction-column basic-padding">
      <div class="basic-card-style" style="border-radius: 0.35rem">
        <ng-container *ngIf="patientData.hl7ExportAt !== undefined && patientData.hl7ExportAt !== null; else toKisList">
          <nida-web-kis-link [nidaId]="patientData.nidaId" [protocolId]="patientData.protocolId"></nida-web-kis-link>
        </ng-container>
        <ng-template #toKisList class="basic-card-style">
          <div class="basic-card-title basic-padding">{{ 'His data sets' | transloco }}</div>
          <nida-web-kis-list
            [insuredNumber]="detailData.insuranceNumber"
            [nidaId]="patientData.nidaId"
            [patientBirthdate]="patientData.birthday ? patientData.birthday.toISOString() : undefined"
            [patientFirstName]="patientData.firstName"
            [patientLastName]="patientData.lastName"
            [protocolId]="patientData.protocolId"
          ></nida-web-kis-list>
        </ng-template>
      </div>
    </div>
  </div>
</div>
