import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ERVitals } from '@nida-web/api/generic-interfaces/emergencyroom';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'nida-web-emergency-room-vital-signs',
  templateUrl: './emergency-room-vital-signs.component.html',
  styleUrls: ['./emergency-room-vital-signs.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class EmergencyRoomVitalSignsComponent implements OnInit {
  public reactions;
  public width;

  @Input() public formGroup: UntypedFormGroup;
  @Input() public vitalSigns: ERVitals;
  @Input() public disabled: boolean;

  constructor() {}

  ngOnInit(): void {
    this.reactions = [{ name: 'prompt' }, { name: 'träge' }, { name: 'keine' }];
    this.width = [{ name: 'weit' }, { name: 'mittel' }, { name: 'eng' }];
  }

  public statusFormat(ratio: any): string {
    return '' + Math.round(ratio * 10) + '/10';
  }
}
