<p></p>

<div class="content-block">
  <div class="responsive-paddings">
    <div class="container-fluid" style="width: 60%">
      <div class="row">
        <div class="col login-header">
          <ng-container *ngFor="let logo of logoSettings; let noBorder = last">
            <img *ngIf="noBorder" alt="{{ logo }}" class="{{ logo }}-logo" src="./assets/img/logos/{{ logo }}.png" />
            <img *ngIf="!noBorder" alt="{{ logo }}" class="{{ logo }}-logo logo-border" src="./assets/img/logos/{{ logo }}.png" />
          </ng-container>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="title">{{ 'NIDAmobile Passwort setzen' }}</div>
      </div>
      <div class="row justify-content-center">
        <div class="login-subheader">
          {{ 'Es wurde noch kein NIDAmobile Passwort von Ihnen gesetzt, bitte setzen sie dieses nun.' | transloco }}
        </div>
      </div>
      <nida-web-password-management
        [headerName]="'Passwort setzen' | transloco"
        [buttonName]="'Weiter' | transloco"
        [requireCurrentPassword]="false"
        [redirect]="false"
        [nidaMobile]="true"
        [requireUserName]="true"
      ></nida-web-password-management>
      <div class="row justify-content-center pb-2">
        <div class="col-lg-4 col-md-8 col-sm-12">
          <div class="dx-field"></div>
        </div>
      </div>
    </div>
  </div>
</div>
