import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { CallHandlerService } from '../services/call-handler.service';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'nida-web-call-handler-popup-content',
  templateUrl: './call-handler-popup-content.component.html',
  styleUrls: ['./call-handler-popup-content.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CallHandlerPopupContentComponent implements AfterViewInit {
  @ViewChild('phpopup') phPopUp: DxPopupComponent;

  constructor(private callHandler: CallHandlerService) {}

  onClick($event: MouseEvent) {
    this.callHandler.onClick($event);
  }

  ngAfterViewInit(): void {
    this.callHandler.setPopupChild(this.phPopUp);
  }
}
