import { NgModule } from '@angular/core';
import { PatientRowSettingsComponent } from './patient-row-settings/patient-row-settings.component';
import { PatientVitalDataComponent } from './patient-vital-data/patient-vital-data.component';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ImageLayoutViewerModule } from '@nida-web/image-layout-viewer';
import { EcgViewerModule } from '@nida-web/ecg-viewer';
import { MQTTWrapperModule } from '@nida-web/api-mqtt-wrapper';
import { PatientArchiveStoreService } from './services/store/patient-archive.store.service';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoTranslationModule } from '@nida-web/transloco-translation';
import { PatientVitalDataTileComponent } from './patient-vital-data-tile/patient-vital-data-tile.component';
import { PatientDetailArchiveComponent } from './patient-detail-archive/patient-detail-archive.component';
import { PatientDetailArchiveElkComponent } from './patient-detail-archive-elk/patient-detail-archive-elk.component';
import { PatientDetailCurrentComponent } from './patient-detail-current/patient-detail-current.component';
import { PatientTableArchiveComponent } from './patient-table-archive/patient-table-archive.component';
import { PatientTableCurrentComponent } from './patient-table-current/patient-table-current.component';
import { PatientListStoreService } from './services/store/patient-list.store.service';
import { PatientPersonalDataComponent } from './patient-personal-data/patient-personal-data.component';
import { PatientInsuranceComponent } from './patient-insurance/patient-insurance.component';
import { PatientKisComponent } from './patient-kis/patient-kis.component';
import { KisModule } from '@nida-web/kis';

import { CoreModule } from '@nida-web/core';
import { InsuranceStoreService } from './services/store/insurance.store.service';
import { ReplaceVitalParameterPipe } from './pipe/replaceVitalParameter.pipe';
import { PDFViewerModule } from '@nida-web/pdf-viewer';
import { PatientArchiveComponent } from './patient-archive/patient-archive.component';
import { ErTileComponent } from './er-tile/er-tile.component';
import { SharedUiModule } from '@nida-web/shared/ui';
import { PatientDetailRescueUnitComponent } from './patient-detail-rescue-unit/patient-detail-rescue-unit.component';
import { PatientDetailPreregistrationInformationComponent } from './patient-detail-preregistration-information/patient-detail-preregistration-information.component';
import { UiVitalDataTileComponent } from './ui-vital-data-tile/ui-vital-data-tile.component';
import { PatientManagementRoutingModule } from './patient-management-routing.module';
import { CorpulsViewComponent } from './corpuls-view/corpuls-view.component';
import { OverviewPatientsComponent } from './overview-patients/overview-patients.component';
import { PatientBoardComponent } from './patient-board/patient-board.component';
import { ReplaceGcsParameterPipe } from './pipe/replaceGcsParameter.pipe';

@NgModule({
  declarations: [
    PatientRowSettingsComponent,
    PatientVitalDataComponent,
    PatientDetailComponent,
    PatientArchiveComponent,
    PatientVitalDataTileComponent,
    PatientDetailArchiveComponent,
    PatientDetailArchiveElkComponent,
    PatientDetailCurrentComponent,
    PatientTableArchiveComponent,
    PatientTableCurrentComponent,
    PatientPersonalDataComponent,
    PatientInsuranceComponent,
    PatientKisComponent,
    ReplaceVitalParameterPipe,
    ReplaceGcsParameterPipe,
    ErTileComponent,
    PatientDetailRescueUnitComponent,
    PatientDetailPreregistrationInformationComponent,
    UiVitalDataTileComponent,
    CorpulsViewComponent,
    OverviewPatientsComponent,
    PatientBoardComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    TranslocoTranslationModule,
    CoreModule,
    DxDataGridModule,
    RouterModule,
    ImageLayoutViewerModule,
    EcgViewerModule,
    MQTTWrapperModule,
    KisModule,
    PDFViewerModule,
    SharedUiModule,
    PatientManagementRoutingModule,
    DxButtonModule,
  ],
  exports: [
    PatientDetailCurrentComponent,
    PatientDetailArchiveComponent,
    PatientTableArchiveComponent,
    PatientTableCurrentComponent,
    PatientPersonalDataComponent,
    PatientInsuranceComponent,
    PatientKisComponent,
    ReplaceVitalParameterPipe,
  ],
  providers: [PatientArchiveStoreService, PatientListStoreService, InsuranceStoreService],
})
export class PatientManagementModule {}
