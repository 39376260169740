/**
 * Emergency Room
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Preclinic } from '../models/preclinic';

import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';

@Injectable({
  providedIn: 'root',
})
export class PreclinicService {
  protected apiPrefix = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable) {
    this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'er';
    });
  }

  /**
   *
   * Create Preclinic Information
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createPreclinic(body: Preclinic, observe?: 'body', reportProgress?: boolean): Observable<Preclinic>;
  public createPreclinic(body: Preclinic, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Preclinic>>;
  public createPreclinic(body: Preclinic, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Preclinic>>;
  public createPreclinic(body: Preclinic, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createPreclinic.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Preclinic>('post', `${this.apiPrefix}/preclinic`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Get Preclinic
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPreclinic(id: number, observe?: 'body', reportProgress?: boolean): Observable<Preclinic>;
  public getPreclinic(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Preclinic>>;
  public getPreclinic(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Preclinic>>;
  public getPreclinic(id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getPreclinic.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Preclinic>('get', `${this.apiPrefix}/preclinic/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Update Preclinic
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePreclinic(body: Preclinic, id: number, observe?: 'body', reportProgress?: boolean): Observable<Preclinic>;
  public updatePreclinic(body: Preclinic, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Preclinic>>;
  public updatePreclinic(body: Preclinic, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Preclinic>>;
  public updatePreclinic(body: Preclinic, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updatePreclinic.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updatePreclinic.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Preclinic>('put', `${this.apiPrefix}/preclinic/${encodeURIComponent(String(id))}`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
