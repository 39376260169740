<div class="basic-flex-container kis-mapping">
  <ng-container *ngIf="nidaId !== undefined && protocolId !== undefined; else toNoNidaID">
    <div class="basic-padding">
      <div class="caption">
        {{ 'His link' | transloco }}
      </div>
      <div class="info">
        {{ 'Transfer data to this case in the his' | transloco }}
      </div>
    </div>
    <div class="basic-padding">
      <dx-button
        (onClick)="sendKisMapping()"
        [useSubmitBehavior]="true"
        stylingMode="outlined"
        text="{{ 'Link' | transloco }}"
        type="default"
        width="100%"
      ></dx-button>
    </div>
  </ng-container>
  <ng-template #toNoNidaID class="basic-card-style">
    {{ 'Sorry, no NIDA ID and / or protocol ID given' | transloco }}
  </ng-template>
</div>
