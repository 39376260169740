import { Component, HostBinding, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { PatientListStoreService, PatientNotificationService } from '@nida-web/patient-management';
import { NotificationBrokerService } from '@nida-web/shared/notifications';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { LocalstorageUserSettingsService } from '@nida-web/shared/local-storage';
import { ScreenService } from '@nida-web/core';
import { AppUpdateService, AppInfoService, CheckForUpdateService } from '@nida-web/shared/data-access';
import { NotificationService } from '@nida-web/shared/utils';

@Component({
  selector: 'tracker-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private sessionManager: SessionManagerService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private notificationBroker: NotificationBrokerService,
    private notificationService: NotificationService,
    private localstorageUserSettingsService: LocalstorageUserSettingsService,
    protected swUpdate: SwUpdate, // TODO how to add them without notice that they are unused? Remove them did not work...
    protected checkForUpdateService: CheckForUpdateService,
    protected appUpdateService: AppUpdateService,
    protected patientsstoreService: PatientListStoreService,
    protected patientNotificcation: PatientNotificationService
  ) {
    //(if you would add initializeSettings and enableBroker when setSessionInformation is done)
    //maybe it is possible to outsource the BadgeCounterService, if it would not import base-root-navigation
    this.sessionManager.getSessionInformation().subscribe((sessionInfo) => {
      if (sessionInfo.loggedIn) {
        this.localstorageUserSettingsService.initializeSettings(sessionInfo.userId !== undefined ? sessionInfo.userId : -1);
        this.notificationBroker.enableBroker();
      }
    });
    this.checkForUpdateService.checkForUpdate();
  }

  //sets the current screen size in the ScreenService
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(' ');
  }

  ngOnInit(): void {
    this.notificationService.initNotifications();
  }
}
