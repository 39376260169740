import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  checkObjectExistence(objectKey: string): boolean {
    const result = localStorage.getItem(objectKey);

    return result !== undefined && result !== null;
  }

  receiveValueByKey(key: string): string | null {
    return localStorage.getItem(key);
  }

  receiveValueByKeySafe(key: string): string {
    const item = localStorage.getItem(key);
    const value = item !== null ? item : '';
    return value;
  }

  receiveObjectByKey(objectKey: string): JSON {
    return JSON.parse(this.receiveValueByKeySafe(objectKey));
  }

  saveKeyAndValue(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  saveObject(objectKey: string, objectValue: any): void {
    localStorage.setItem(objectKey, JSON.stringify(objectValue));
  }

  saveSubobjectInObject(daughterObjectKey: string, value: any, motherObjectKey: string): void {
    const motherObject: any = this.receiveObjectByKey(motherObjectKey);
    motherObject[daughterObjectKey] = value;
    this.saveObject(motherObjectKey, motherObject);
  }

  ifKeyInObject(objectKey: string, key: string): boolean {
    const actualObject: any = this.receiveValueByKey(objectKey);

    if (actualObject === undefined || actualObject === null || actualObject === '') {
      return false;
    }
    return key in JSON.parse(actualObject);
  }
}
