import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { NavItem, ScreenService } from '@nida-web/core';
import { PermissionsHelper, SessionInformation, SessionManagerService } from '@nida-web/api/rest/authentication';
import { SubNavigationHandlerService } from '../services/sub-navigation-handler.service';
import { Observable, Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'nida-web-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavOuterToolbarComponent implements OnInit, OnDestroy {
  menuItems: any;
  allmenuItems: any;
  selectedRoute = '';

  menuOpenedByBurger = false;
  menuOpened: boolean;
  subMenuOpened = false;
  subMenuItems: NavItem[] = [];
  temporaryMenuOpened = false;

  @Input() title: string;
  @Input() navigationItems$: Observable<NavItem[]>;
  @Input() hiddenHeader: boolean;
  @Input() replaceIconsWithNumbers: boolean;
  @Input() readonly = false;
  @Input() headerTemplate: TemplateRef<any>;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;
  userNameExists = false;
  public user: SessionInformation;
  public userPermissions: string[];
  public subMenuMode: string;
  public subMinMenuSize: number;

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private screen: ScreenService,
    private sessionManagerService: SessionManagerService,
    private subNavService: SubNavigationHandlerService,
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.sessionManagerService
      .getSessionInformation()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data && data.userName && data.userName.length > 0) {
          this.userNameExists = true;
          this.updateDrawer();
        } else {
          this.userNameExists = false;
          this.updateDrawer();
        }
      });

    this.subNavService
      .getSubNavigationItems()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((items) => {
        this.subMenuItems = items;
        this.subMenuOpened = this.subMenuItems.length > 0;
        if (!this.subMenuOpened) {
          if (this.title) {
            this.title = this.title.split(' ')[0];
          }
          this.subMenuMode = 'overlap';
          this.subMinMenuSize = 0;
          this.menuOpened = true;
        } else {
          if (this.title) {
            this.title = this.title.split(' ')[0];
          }
          this.title = this.title + ' - ' + items[0].text;
          this.subMenuItems.shift();
          this.subMenuMode = 'shrink';
          this.subMinMenuSize = 77;
          this.menuOpened = false;
        }
      });
  }

  get hideMenuAfterNavigation(): boolean {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  ngOnInit() {
    // this.menuItems = this.navigationItems;
    // this.allmenuItems = this.navigationItems;
    this.sessionManagerService
      .getSessionInformation()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        if (user && user.userName && user.userName.length > 0) {
          this.userPermissions = user.permissions ? user.permissions : [];

          //this.checkMenuItemsPermissions();
        }
      });

    // flatten Menu if only one sub-item exists, e.g. orders if only kvb list exists
    // const flatMenu: NavItem[] = [];
    // this.menuItems.forEach((item) => {
    //   if (item.items && item.items.length === 1) {
    //     const newItem = { ...item.items };
    //     flatMenu.push(newItem[0]);
    //   } else {
    //     flatMenu.push(item);
    //   }
    // });
    // if (flatMenu.length > 0) {
    //   this.menuItems = flatMenu;
    // }

    this.menuOpened = this.screen.sizes['screen-large'];

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /*
    Checks only one nested level
   */
  checkMenuItemsPermissions() {
    let cacheMenuItems = [...this.allmenuItems];

    // check and remove ONLY the items of an navigation item
    cacheMenuItems.forEach((item) => {
      if (item.items) {
        if (item.path) {
          throwError('A navigation item should NOT have a path AND items', item);
        }
        item.items = item.items.filter((m) => this.checkAccess(m.permissions));
      }
    });

    cacheMenuItems = cacheMenuItems.filter((m) => {
      if (!m.path && (!m.items || m.items.length === 0)) {
        //if the items (children) array is empty then remove the navigation item
        return false;
      } else {
        //if the navigation item has no items (children)
        if (m.permissions) {
          //if permission not granted then remove the navigation item
          return this.checkAccess(m.permissions);
        } else {
          //if navigation item has no permission then NOT remove it
          return true;
        }
      }
    });

    this.menuItems = cacheMenuItems;
  }

  checkAccess(permission: string[]): boolean {
    if (permission) {
      const shouldNotHave: string[] = [];

      for (const singlePerm of permission) {
        if (singlePerm.startsWith('!')) {
          shouldNotHave.push(singlePerm.split('!')[1]);
        }
      }

      return (
        PermissionsHelper.isAllowedAccess(this.userPermissions, permission) &&
        !PermissionsHelper.isAllowedAccess(this.userPermissions, shouldNotHave)
      );
    }
    return true;
  }

  updateDrawer(): void {
    const menuSize = this.navigationService.getMenuBarSizeByScreenSize();
    const isLarge = this.screen.sizes['screen-large'];
    // reset burger menu button on every resize of window
    this.menuOpenedByBurger = false;
    // react on menu size provided by screen
    switch (menuSize) {
      case 'hidden': {
        this.menuMode = 'overlap';
        this.minMenuSize = 0;
        this.menuOpened = false;
        break;
      }
      case 'small': {
        this.menuMode = 'overlap';
        this.minMenuSize = 77;
        this.menuOpened = false;
        break;
      }
      // case 'wide' is the same as default, so we do not need it
      default: {
        this.menuOpened = true;

        this.menuMode = 'shrink';
        this.minMenuSize = 77;
      }
    }
    this.shaderEnabled = !isLarge;
    this.changeDetectorRef.markForCheck();
  }

  burgerClick(): void {
    // check if sidebar menu was opened by clicking on burger menu
    this.subNavService.closeSubNavigation();

    if (this.menuOpenedByBurger) {
      this.menuOpenedByBurger = false;
      this.menuOpened = !this.menuOpened;
      this.updateDrawer();
    } else {
      this.menuOpenedByBurger = true;
      const menuSize = this.navigationService.getMenuBarSizeByScreenSize();
      this.menuOpened = !this.menuOpened;
      switch (menuSize) {
        case 'small' || 'hidden': {
          this.menuMode = 'shrink';
          this.minMenuSize = 77;
          break;
        }
        // case 'wide' is the same as default, so we do not need it explicitly because they act both the same -> small menu when clicked
        default: {
          this.menuMode = 'overlap';
          this.minMenuSize = 77;
        }
      }
    }
  }

  /**
   * Updates the drawer only on small devices, in order to open up the navigation
   */
  navigationClickedOnSmallDevice(): void {
    this.updateDrawer();
  }
}
