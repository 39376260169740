import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowManagementService {
  private windowMap: Map<string, Window>;
  constructor() {
    this.windowMap = new Map<string, Window>();
  }

  public switchOrCreateWindow(key: string, link: string) {
    if (this.windowMap.has(key)) {
      const activeWindow = this.windowMap.get(key);
      if (activeWindow && !activeWindow.closed) {
        activeWindow.location = link;
        activeWindow.focus();
      } else {
        const newWindow = window.open(link, key, 'toolbar=no,location=no,status=no,titlebar=no');
        if (!newWindow) throw Error('Window could not be recreated!');
        this.windowMap.set(key, newWindow);
        newWindow.focus();
      }
    } else {
      const newWindow = window.open(link, key, 'toolbar=no,location=no,status=no,titlebar=no');
      if (newWindow === null) throw Error('Window could not be created!');
      this.windowMap.set(key, newWindow);
      newWindow.focus();
    }
  }

  public switchOpenWindow(key: string, link: string) {
    if (this.windowMap.has(key)) {
      const activeWindow = this.windowMap.get(key);
      if (activeWindow && !activeWindow.closed) {
        activeWindow.location = link;
        activeWindow.focus();
      }
    }
  }

  public switchOpenWindowToNoData(key: string) {
    if (this.windowMap.has(key)) {
      const activeWindow = this.windowMap.get(key);

      if (activeWindow) {
        activeWindow.location = 'about:blank';
      }
    }
  }
}
