<dx-popup
  #phpopup
  width="250"
  height="300"
  [shading]="false"
  [position]="'right bottom'"
  [title]="'Anruf RTW 5'"
  [contentTemplate]="'popUpContent'"
>
  <div *dxTemplate="let data of 'popUpContent'">
    <div class="center-text">
      <svg width="100px" height="60px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 60">
        <rect x="0" y="0" width="50" height="50" stroke-width="1px" stroke="none" fill="blue">
          <animate attributeName="fill" from="blue" to="none" dur="0.5s" repeatCount="indefinite" />
        </rect>
        <rect x="50" y="0" width="50" height="50" stroke-width="1px" stroke="none" fill="blue">
          <animate attributeName="fill" from="blue" to="none" begin="0.25s" dur="0.5s" repeatCount="indefinite" />
        </rect>
      </svg>
    </div>
    <div class="center-text margin-bottom-25">TNA benötigt!</div>
    <div class="center-text mouse-style-pointer" (click)="onClick($event)">
      <i class="dx-icon-tel incoming-call-icon"></i>
    </div>
    <div class="center-text mouse-style-pointer" (click)="onClick($event)">
      <strong class="incoming-call-text">Annehmen</strong>
    </div>
  </div>
</dx-popup>
