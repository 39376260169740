import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { UserDetailComponent } from '../user-detail/user-detail.component';
import { throwError } from 'rxjs';

@Component({
  selector: 'nida-web-new-user-entry',
  templateUrl: './new-user-entry.component.html',
  styleUrls: ['./new-user-entry.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class NewUserEntryComponent implements OnInit {
  @ViewChild('userDetail', { static: false }) userDetail: UserDetailComponent | undefined;
  public groups = null;

  constructor(private location: Location) {}

  ngOnInit() {}

  triggerNavigation() {
    this.location.back();
  }

  cancel() {
    this.triggerNavigation();
  }

  saveChanges() {
    if (this.userDetail === undefined) {
      throwError('Detailkomponent hasnt been initalized!');
      return;
    }

    this.userDetail.manageSaving().subscribe((answer) => {
      if (answer) {
        this.triggerNavigation();
      }
    });
  }
}
