import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ERMedicalAccess } from '@nida-web/api/generic-interfaces/emergencyroom';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'nida-web-er-therapy-abcde',
  templateUrl: './er-therapy-abcde.component.html',
  styleUrls: ['./er-therapy-abcde.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ErTherapyAbcdeComponent implements OnInit {
  public circulationAccessOptions: any;

  @Input()
  medicalAccessList: ERMedicalAccess[] | null;

  @Input()
  abcdeForm: UntypedFormGroup;

  @Output() saveAccess = new EventEmitter<any>();
  @Output() updateAccess = new EventEmitter<any>();
  @Output() deleteAccess = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.medicalAccessList === undefined) {
      this.medicalAccessList = [];
    }
    this.circulationAccessOptions = {
      type: [
        { name: 'Venous access', value: 'Venous access' },
        { name: 'Arterial access', value: 'Arterial access' },
        { name: 'Intraosseous access', value: 'Intraosseous access' },
        { name: 'Central venous catheter', value: 'Central venous catheter' },
        { name: 'Peripheral venous catheter', value: 'Peripheral venous catheter' },
      ],
      location: [
        { name: 'Elbow', value: 'Elbow' },
        { name: 'Radialis', value: 'Radialis' },
        { name: 'Back of Hand', value: 'Back of Hand' },
        { name: 'Upper Arm', value: 'Upper Arm' },
        { name: 'Lower Arm', value: 'Lower Arm' },
        { name: 'Neck', value: 'Neck' },
        { name: 'Head', value: 'Head' },
        { name: 'Leg', value: 'Leg' },
        { name: 'Foot', value: 'Foot' },
        { name: 'Other', value: 'Other' },
      ],
      side: [
        { name: 'Right', value: 'Right' },
        { name: 'Left', value: 'Left' },
      ],
    };
  }

  autoFillDateOnTableCellClick(event) {
    if (event.rowType === 'data') {
      if (!event.value) {
        if (event.columnIndex === 3) {
          event.component.cellValue(event.row.rowIndex, event.columnIndex, new Date());
        }
      }
    }
  }

  autoFillDateOnCheckboxClick(date: string) {
    if (this.abcdeForm.getRawValue()[date] === null || this.abcdeForm.getRawValue()[date] === undefined) {
      this.abcdeForm.controls[date].patchValue(new Date());
      this.abcdeForm.markAsDirty();
    } else {
      this.abcdeForm.controls[date].patchValue(null);
      this.abcdeForm.markAsDirty();
    }
  }

  autoFillDateOnFieldClick(date: string) {
    if (this.abcdeForm.getRawValue()[date] === null || this.abcdeForm.getRawValue()[date] === undefined) {
      this.abcdeForm.controls[date].patchValue(new Date());
      this.abcdeForm.markAsDirty();
    }
  }

  onRowInserting(e: any) {
    this.saveAccess.emit(e);
  }

  onRowRemoving(e: any) {
    this.deleteAccess.emit(e);
  }

  onRowUpdated(e: any) {
    this.updateAccess.emit(e);
  }

  onValueChanged(gridCellInfo: any, event: any) {
    gridCellInfo.setValue(event.value);
  }
}
