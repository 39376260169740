import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MQTTConfig } from '../models/mqttconfig.model';

@Injectable({
  providedIn: 'root',
})
export abstract class MQTTConfigServable {
  public abstract getMQTTConfiguration(): Observable<MQTTConfig>;
}
