import { Component, Input, ViewEncapsulation } from '@angular/core';
import { VitalSignTiles } from '../models/vitalSignTiles.model';

@Component({
  selector: 'nida-web-vital-data-tile',
  templateUrl: './ui-vital-data-tile.component.html',
  styleUrls: ['./ui-vital-data-tile.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UiVitalDataTileComponent {
  @Input() data: VitalSignTiles;
  @Input() displayLeftTileTwoEmptyValues: boolean;
  @Input() displayRightTileTwoEmptyValues: boolean;
}
