import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@nida-web/shared/data-access';
import { OverviewPatientsComponent } from './overview-patients/overview-patients.component';
import { CorpulsViewComponent } from './corpuls-view/corpuls-view.component';
import { EcgViewerComponent } from '@nida-web/ecg-viewer';
import { BaseRootComponent } from '@nida-web/navigation';
import { PatientBoardComponent } from './patient-board/patient-board.component';
import { PatientKisComponent } from './patient-kis/patient-kis.component';
import { PatientDetailCurrentComponent } from './patient-detail-current/patient-detail-current.component';
import { PatientDetailArchiveElkComponent } from './patient-detail-archive-elk/patient-detail-archive-elk.component';

const routes: Routes = [
  {
    path: '',
    component: BaseRootComponent,
    children: [
      {
        path: 'patients',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'overview',
            canActivate: [AuthGuard],
            component: OverviewPatientsComponent,
          },
          {
            path: 'details/:id',
            component: PatientDetailArchiveElkComponent,
          },
          {
            path: 'details/:id/corpuls',
            component: CorpulsViewComponent,
          },
          {
            path: 'details/:id/kisPage',
            component: PatientKisComponent,
          },
        ],
      },
      {
        path: 'ecg/details/:ecgId/:nidaId/:timestamp',
        component: EcgViewerComponent,
      },
      {
        path: 'kisPage',
        component: OverviewPatientsComponent,
      },
      {
        path: 'current-patients/overview',
        canActivate: [AuthGuard],
        component: PatientBoardComponent,
      },
      {
        path: 'current-patients/details',
        canActivate: [AuthGuard],
        component: PatientDetailCurrentComponent,
      },
      {
        path: 'current-patients/details/:id',
        component: PatientDetailCurrentComponent,
      },
      {
        path: 'current-patients/details/:id/corpuls',
        component: CorpulsViewComponent,
      },
      {
        path: 'current-patients/details/:id/kisPage',
        component: PatientKisComponent,
      },
      {
        path: 'current-patients/kisPage',
        component: PatientBoardComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientManagementRoutingModule {}
