import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallHandlerConfig } from './models/call-handler-config.model';
import { CALL_HANDLER_MAIN_CONFIG, CallHandlerService } from './services/call-handler.service';
import { CallHandlerPopupContentComponent } from './call-handler-popup-content/call-handler-popup-content.component';
import { DxPopupModule } from 'devextreme-angular';
import { CallService } from './actions/call.service';
import { MQTTWrapperModule } from '@nida-web/api-mqtt-wrapper';

@NgModule({
  imports: [CommonModule, MQTTWrapperModule, DxPopupModule],
  providers: [CallHandlerService, CallService],
  declarations: [CallHandlerPopupContentComponent],
  exports: [CallHandlerPopupContentComponent],
})
export class CallHandlerModule {
  static forRoot(config: CallHandlerConfig): ModuleWithProviders<CallHandlerModule> {
    return {
      ngModule: CallHandlerModule,
      providers: [{ provide: CALL_HANDLER_MAIN_CONFIG, useValue: config }, CallHandlerService],
    };
  }
}
