import { Injectable } from '@angular/core';
import { ERPreclinicDeprecated } from '@nida-web/api/generic-interfaces/protocol';
import { ApiFindingList } from '../models/api-finding-list';

@Injectable({
  providedIn: 'root',
})
export class FindingMappingService {
  public mapValues(rawData: ApiFindingList, refObj: ERPreclinicDeprecated): ERPreclinicDeprecated {
    for (const singleFinding of rawData.data) {
      switch (singleFinding.description) {
        case 'GCS':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.scoreGCS = singleFinding.valueInteger;
          }
          break;
        case 'GCS Augen':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.gcsEyes = singleFinding.valueInteger;
          }
          break;
        case 'GCS verbal':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.gcsVerbal = singleFinding.valueInteger;
          }
          break;
        case 'GCS motorisch':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.gcsMotoric = singleFinding.valueInteger;
          }
          break;
        case 'Pupillenreaktion links':
          refObj.fistVitalSigns.pupilsExpanseLeft = singleFinding.valueString;
          break;
        case 'Pupillenreaktion rechts':
          refObj.fistVitalSigns.pupilsExpanseRight = singleFinding.valueString;
          break;
        case 'Lichtreaktion links':
          refObj.fistVitalSigns.pupilsLightReactionLeft = singleFinding.valueString;
          break;
        case 'Lichtreaktion rechts':
          refObj.fistVitalSigns.pupilsLightReactionRight = singleFinding.valueString;
          break;
        case 'Schmerzen':
          if (singleFinding.type === 'eb_schmerzen') {
            console.log('refObj', refObj);
            console.log('singleFinding', singleFinding);
            refObj.fistVitalSigns.painLevel = singleFinding.valueInteger;
          }
          break;
        case 'Atmung':
          // TODO: Wann ist die Atmung frei?
          break;
        case 'Auffälligkeiten':
          if (singleFinding.type === 'eb_neuro') {
            if (singleFinding.valueString === 'ohne pathologischen Befund') {
              refObj.fistVitalSigns.withoutPathologicalFindings = true;
            }
            refObj.fistVitalSigns.neurologicalAbnormalities = singleFinding.valueString;
          }
          break;
        case 'Zeitpunkt':
          if (singleFinding.type === 'eb_zeit') {
            refObj.fistVitalSigns.findingTime = singleFinding.valueDateTime;
          }
          break;
        case 'Bewusstseinslage':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.consciousness = singleFinding.valueString;
          }
          break;
        case 'Extremitätenbewegung Bein links':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.extremitiesLegLeft = singleFinding.valueInteger;
          }
          break;
        case 'Extremitätenbewegung Bein rechts':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.extremitiesLegRight = singleFinding.valueInteger;
          }
          break;
        case 'Extremitätenbewegung Arm links':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.extremitiesArmLeft = singleFinding.valueInteger;
          }
          break;
        case 'Extremitätenbewegung Arm rechts':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.extremitiesArmRight = singleFinding.valueInteger;
          }
          break;
        case 'Neurologie':
          if (singleFinding.type === 'eb_neuro') {
            refObj.fistVitalSigns.neurological = singleFinding.valueString;
          }
          break;
      }
    }
    return refObj;
  }
}
