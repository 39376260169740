import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { GroupList } from '../models/group-list.model';
import { Group } from '../models/group.model';

/**
 * Service-Interface for Group managing. Used for all permission related groups.
 */
@Injectable()
export abstract class GroupServable {
  /**
   * Method to get a single Group by a group id
   *
   * @param groupID of the wanted group
   * @return Observable which will deliver the group asynchronous.
   */
  abstract getGroup(groupID: number): Observable<Group>;

  /**
   * Method which delivers all active Groups.
   * @return Observable which will deliver a List of Groups asynchronous.
   */
  abstract getAllGroups(): Observable<GroupList>;

  /**
   * Method which delivers all active Groups of a specific User.
   * @param userID of the User
   * @return Observable which will deliver a List of user-specific groups asynchronous
   */
  abstract getGroupsByUserId(userID: number): Observable<GroupList>;

  /**
   * Method which delivers all active Groups of a specific User if we have a rescue service server.
   * @param userID of the User
   * @return Observable which will deliver a List of user-specific groups asynchronous
   */
  abstract getRescueServiceGroupsByUserId(userID: number): Observable<GroupList>;

  /**
   * Adds a user to a group.
   * @param userID of the user
   * @param groupID of the wanted group
   * @param idMandanten
   * @return true if the operation was successfull, false if not.
   */
  abstract addUser(userID: number, groupID: number, idMandanten: number): Observable<boolean>;

  /**
   * Removes a user from a group
   * @param userID of the user
   * @param groupID of the wanted group
   * @return true if the operation was successfull, false if not.
   */
  abstract removeUser(userID: number, groupID: number): Observable<boolean>;
}
