import { Injectable } from '@angular/core';
import { PDFElement } from '../models/pdf-element.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class AttachmentsServable {
  public readonly pdfList: Observable<PDFElement[]>;
  public readonly lastPdfId: Observable<number | null>;

  /**
   * Get all PDFs (including different versions) of a mission by nidaId
   * @param nidaId of the mission
   * @return Observable which will receive the complete PDF List
   */
  public abstract getPDFList(nidaId: string): Observable<PDFElement[]>;

  /**
   * The last pdf's id is saved as "lastPdfId"
   * This functions resets slot to null
   */
  public abstract resetLastPdfId(): void;
}
