<div style="margin-left: 10px; margin-right: 10px">
  <h2 class="mb-2 ml-0">{{ 'Team' | transloco }}</h2>
  <div class="row">
    <div class="col-lg-11 col-xl-9">
      <dx-data-grid
        class="p-2 py-3 bg-white"
        style="box-shadow: 0 4px 8px 0 darkgrey"
        id="gridContainer"
        [dataSource]="treatmentMemberList"
        [allowColumnReordering]="true"
        [showBorders]="true"
        [showRowLines]="true"
        [rowAlternationEnabled]="false"
        (onRowInserted)="onRowInserting($event)"
        (onRowUpdated)="onRowUpdating($event)"
        (onRowRemoved)="onRowRemoving($event)"
      >
        <dxo-paging [enabled]="true"></dxo-paging>
        <dxo-editing useIcons="true" mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"> </dxo-editing>
        <dxi-column dataField="role" [caption]="'Role' | transloco">
          <dxi-validation-rule type="required"></dxi-validation-rule>
          <dxo-lookup [dataSource]="roles" valueExpr="name" displayExpr="name"> </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="name" [caption]="'Name' | transloco">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
