import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Findings } from '../models/findings.model';
import { PictureList } from '../models/picture-list.model';
import { Picture } from '../models/picture.model';
import { Video } from '../models/video.model';
import { AudioRecordList } from '../models/audioRecordList';

@Injectable({
  providedIn: 'root',
})
export abstract class PatientDetailServable {
  public abstract getPictureList(nidaIdPictures: string): Observable<PictureList>;

  public abstract getDetailFinding(
    revision: string,
    findingType?: 'voranmeldung' | 'eb_atmung' | 'eb_neuro' | 'eb_kreislauf'
  ): Observable<Findings>;

  public abstract getPatientPicture(nidaIdPictures: string, pictureId: string): Observable<Picture>;

  public abstract getDetailVideos(): Observable<Video>;

  public abstract getAudioList(protocolId: number): Observable<AudioRecordList>;
  public abstract getAudioRecordById(protocolId: number, id: number): Observable<Blob>;
}
