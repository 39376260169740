<nida-web-card [height100]="true" headline="Payer" style="height: 100%">
  <ng-container *ngIf="loaded">
    <ng-container *ngIf="insuranceData && patientData">
      <div class="row">
        <div class="col-md-6 mb-2">
          <div class="basic-subject-title">{{ 'Insurance' | transloco }}</div>
          <div class="basic-font-medium" style="word-break: break-word">{{ insuranceData.name | displayNan }}</div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="basic-subject-title">{{ 'Health insurance id' | transloco }}</div>
          <div class="basic-font-medium">{{ insuranceData?.ic | displayNan }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <div class="basic-subject-title">{{ 'Insurance membership number' | transloco }}</div>
          <div class="basic-font-medium">{{ patientData.insuranceNumber | displayNan }}</div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="basic-subject-title">{{ 'Status' | transloco }}</div>
          <div class="basic-font-medium">{{ patientData.insuranceStatus | displayNan }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2 mb-sm-0">
          <div class="basic-subject-title">{{ 'Private-insured' | transloco }}</div>
          <div class="basic-font-medium">{{ patientData.privateInsured | displayNan }}</div>
        </div>
        <div class="col-md-6">
          <div class="basic-subject-title">{{ 'Exempted' | transloco }}</div>
          <div class="basic-font-medium">{{ patientData.insuranceExempt | displayNan }}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</nida-web-card>
