/**
 * NIDA Protocol Rest-Api
 * API Beschreibung für die NIDA Tabellen.
 *
 * OpenAPI spec version: 1.0.1
 * Contact: info@meddv.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiMeasureList } from '../models/api-measure-list';
import { ApiResultList } from '../models/api-result-list';
import { Configuration } from '../helper/configuration';
import { ConfigRESTServable } from '@nida-web/api/generic-interfaces/config';
import { ArchiveFlag } from '../models/archiveFlag';
import { DeletedFlag } from '../models/deletedFlag';
import { Protocol } from '../models/protocol.model';
import { DiagnosisList } from '../models/diagnosis-list.model';
import { FreeTextList } from '../models/free-text-list.model';
import { InjuryList } from '../models/injury-list.model';
import { ApiFindingList } from '../models/api-finding-list';
import { ProtocolIdentifierList } from '../models/protocolIdentifierList';
import { PrivateNote } from '../models/private-note.model';
import { Addendum } from '../models/addendum.model';
import { AddendumList } from '../models/addendum-list.model';
import { PrivateNoteDetail } from '../models/private-note-detail.model';

@Injectable({
  providedIn: 'root',
})
export class ProtocolService {
  protected basePath: string;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.basePath = '';
    this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.basePath = `${apiUrl}dispatch`;
    });
  }

  /**
   * @param protocolId The ID of the protocol data set we want to work with
   * @param addendum
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addNewProtocolAddendum(
    protocolId: number,
    addendum: Addendum,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<Addendum>;
  public addNewProtocolAddendum(
    protocolId: number,
    addendum: Addendum,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<Addendum>>;
  public addNewProtocolAddendum(
    protocolId: number,
    addendum: Addendum,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<Addendum>>;
  public addNewProtocolAddendum(
    protocolId: number,
    addendum: Addendum,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling addNewProtocolAddendum.');
    }
    if (addendum === null || addendum === undefined) {
      throw new Error('Required parameter addendum was null or undefined when calling addNewProtocolAddendum.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<Addendum>(`${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/addendum`, addendum, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get all addenda corresponding to the protocol
   * Get all addenda corresponding to the protocol
   * @param protocolId The ID of the protocol data set we want to work with
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProtocolAddendumById(
    protocolId: number,
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<Addendum>;
  public getProtocolAddendumById(
    protocolId: number,
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<Addendum>>;
  public getProtocolAddendumById(
    protocolId: number,
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<Addendum>>;
  public getProtocolAddendumById(
    protocolId: number,
    id: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getProtocolAddendumById.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getProtocolAddendumById.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Addendum>(
      `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/addendum/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all addenda corresponding to the protocol
   * Get all addenda corresponding to the protocol
   * @param protocolId The ID of the protocol data set we want to work with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProtocolAddendumByProtocolId(
    protocolId: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<AddendumList>;
  public getProtocolAddendumByProtocolId(
    protocolId: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<AddendumList>>;
  public getProtocolAddendumByProtocolId(
    protocolId: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<AddendumList>>;
  public getProtocolAddendumByProtocolId(
    protocolId: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getProtocolAddendumByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<AddendumList>(`${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/addendum`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param protocolId The ID of the protocol data set we want to work with
   * @param id
   * @param addendum
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProtocolAddendum(
    protocolId: number,
    id: number,
    addendum: Addendum,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<Addendum>;
  public updateProtocolAddendum(
    protocolId: number,
    id: number,
    addendum: Addendum,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<Addendum>>;
  public updateProtocolAddendum(
    protocolId: number,
    id: number,
    addendum: Addendum,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<Addendum>>;
  public updateProtocolAddendum(
    protocolId: number,
    id: number,
    addendum: Addendum,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling updateProtocolAddendum.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateProtocolAddendum.');
    }
    if (addendum === null || addendum === undefined) {
      throw new Error('Required parameter addendum was null or undefined when calling updateProtocolAddendum.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.put<Addendum>(
      `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/addendum/${encodeURIComponent(String(id))}`,
      addendum,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all private note corresponding to the protocol
   * Get all addenda corresponding to the protocol
   * @param protocolId The ID of the protocol data set we want to work with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProtocolPrivateNoteByProtocolId(
    protocolId: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<PrivateNoteDetail>;
  public getProtocolPrivateNoteByProtocolId(
    protocolId: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<PrivateNoteDetail>>;
  public getProtocolPrivateNoteByProtocolId(
    protocolId: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<PrivateNoteDetail>>;
  public getProtocolPrivateNoteByProtocolId(
    protocolId: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getProtocolPrivateNoteByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<PrivateNoteDetail>(`${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/privatenote`, {
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * @param protocolId The ID of the protocol data set we want to work with
   * @param id
   * @param privateNote
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProtocolPrivateNote(
    protocolId: number,
    id: number,
    privateNote: PrivateNote,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<PrivateNote>;
  public updateProtocolPrivateNote(
    protocolId: number,
    id: number,
    privateNote: PrivateNote,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<PrivateNote>>;
  public updateProtocolPrivateNote(
    protocolId: number,
    id: number,
    privateNote: PrivateNote,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<PrivateNote>>;
  public updateProtocolPrivateNote(
    protocolId: number,
    id: number,
    privateNote: PrivateNote,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling updateProtocolPrivateNote.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateProtocolPrivateNote.');
    }
    if (privateNote === null || privateNote === undefined) {
      throw new Error('Required parameter privateNote was null or undefined when calling updateProtocolPrivateNote.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.put<PrivateNote>(
      `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/privatenote/${encodeURIComponent(String(id))}`,
      privateNote,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * @param protocolId The ID of the protocol data set we want to work with
   * @param privateNote
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addNewProtocolPrivateNote(
    protocolId: number,
    privateNote: PrivateNote,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<PrivateNote>;
  public addNewProtocolPrivateNote(
    protocolId: number,
    privateNote: PrivateNote,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<PrivateNote>>;
  public addNewProtocolPrivateNote(
    protocolId: number,
    privateNote: PrivateNote,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<PrivateNote>>;
  public addNewProtocolPrivateNote(
    protocolId: number,
    privateNote: PrivateNote,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling addNewProtocolPrivateNote.');
    }
    if (privateNote === null || privateNote === undefined) {
      throw new Error('Required parameter privateNote was null or undefined when calling addNewProtocolPrivateNote.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.post<PrivateNote>(
      `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/privatenote`,
      privateNote,
      {
        responseType: <any>responseType,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * marks protocol as archived
   * sets/unsets the archive Flag for one protocol
   * @param body Archive flag Object
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public archiveProtocol(body: ArchiveFlag, id: string, observe?: 'body', reportProgress?: boolean): Observable<Protocol>;
  public archiveProtocol(body: ArchiveFlag, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Protocol>>;
  public archiveProtocol(body: ArchiveFlag, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Protocol>>;
  public archiveProtocol(body: ArchiveFlag, id: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling archiveProtocol.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling archiveProtocol.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Protocol>('patch', `${this.basePath}/protocols/${encodeURIComponent(String(id))}/archive`, {
      body,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all diagnosis correspondending to the protocol
   * Get all diagnosis correspondending to the protocol
   * @param protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDiagnosisByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<DiagnosisList>;
  public getDiagnosisByProtocolId(
    protocolId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DiagnosisList>>;
  public getDiagnosisByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DiagnosisList>>;
  public getDiagnosisByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getDiagnosisByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<DiagnosisList>('get', `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/diagnosis`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all finding data correspondending to the protocol
   * Get all finding data correspondending to the protocol
   * @param protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFindingByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiFindingList>;
  public getFindingByProtocolId(
    protocolId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ApiFindingList>>;
  public getFindingByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiFindingList>>;
  public getFindingByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getFindingByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<ApiFindingList>('get', `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/finding`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all freetexts correspondending to the protocol
   * Get all freetexts correspondending to the protocol
   * @param protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFreeTextsByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<FreeTextList>;
  public getFreeTextsByProtocolId(
    protocolId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FreeTextList>>;
  public getFreeTextsByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FreeTextList>>;
  public getFreeTextsByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getFreeTextsByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<FreeTextList>('get', `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/freetexts`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all injuries correspondending to the protocol
   * Get all injuries correspondending to the protocol
   * @param protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInjuriesByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<InjuryList>;
  public getInjuriesByProtocolId(protocolId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InjuryList>>;
  public getInjuriesByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InjuryList>>;
  public getInjuriesByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getInjuriesByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<InjuryList>('get', `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/injuries`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all measures correspondending to the protocol
   * Get all measures correspondending to the protocol
   * @param protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMeasuresByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiMeasureList>;
  public getMeasuresByProtocolId(
    protocolId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ApiMeasureList>>;
  public getMeasuresByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiMeasureList>>;
  public getMeasuresByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getMeasuresByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<ApiMeasureList>('get', `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/measures`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get one Protocol data set by it&#x27;s id
   * Get one protocol data set by it&#x27;s Id
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProtocolById(id: string, observe?: 'body', reportProgress?: boolean): Observable<Protocol>;
  public getProtocolById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Protocol>>;
  public getProtocolById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Protocol>>;
  public getProtocolById(id: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getProtocolById.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Protocol>('get', `${this.basePath}/protocols/${encodeURIComponent(String(id))}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Get all results correspondending to the protocol
   * Get all results correspondending to the protocol
   * @param protocolId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getResultsByProtocolId(protocolId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResultList>;
  public getResultsByProtocolId(
    protocolId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ApiResultList>>;
  public getResultsByProtocolId(protocolId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResultList>>;
  public getResultsByProtocolId(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getResultsByProtocolId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<ApiResultList>('get', `${this.basePath}/protocols/${encodeURIComponent(String(protocolId))}/results`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Save Protocol
   *
   * @param body Protocol Object
   * @param id The ID of the data set we want to work with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveProtocol(body: Protocol, id: number, observe?: 'body', reportProgress?: boolean): Observable<Protocol>;
  public saveProtocol(body: Protocol, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Protocol>>;
  public saveProtocol(body: Protocol, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Protocol>>;
  public saveProtocol(body: Protocol, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling saveProtocol.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling saveProtocol.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Protocol>('put', `${this.basePath}/protocols/${encodeURIComponent(String(id))}`, {
      body,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
  /**
   * marks protocol as deleted
   * sets/unsets the deleted flag for one protocol
   * @param body Deleted flag object
   * @param id The ID of the data set we want to work with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public softDeleteProtocol(body: DeletedFlag, id: number, observe?: 'body', reportProgress?: boolean): Observable<Protocol>;
  public softDeleteProtocol(
    body: DeletedFlag,
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Protocol>>;
  public softDeleteProtocol(body: DeletedFlag, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Protocol>>;
  public softDeleteProtocol(body: DeletedFlag, id: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling softDeleteProtocol.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling softDeleteProtocol.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Protocol>('patch', `${this.basePath}/protocols/${encodeURIComponent(String(id))}/delete`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Get the protocol ids from a mission.
   * @param missionId The unique mission ID. (nida_id)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProtocolIds(missionId: string, observe?: 'body', reportProgress?: boolean): Observable<ProtocolIdentifierList>;
  public getProtocolIds(
    missionId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProtocolIdentifierList>>;
  public getProtocolIds(missionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProtocolIdentifierList>>;
  public getProtocolIds(missionId: string, observe: any = 'body', reportProgress = false): Observable<any> {
    if (missionId === null || missionId === undefined) {
      throw new Error('Required parameter missionId was null or undefined when calling getProtocolIds.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    // const consumes: string[] = [];

    return this.httpClient.request<ProtocolIdentifierList>('get', `${this.basePath}/mission/${encodeURIComponent(String(missionId))}/ids`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
