import { Injectable } from '@angular/core';
import { LocalstorageUserSettingsService } from '@nida-web/shared/local-storage';

@Injectable({
  providedIn: 'root',
})
export class NotificationSoundService {
  private audio: HTMLAudioElement;
  private audioFileName: string;
  private active: boolean;
  private status: string;

  constructor(private localstorageUserService: LocalstorageUserSettingsService) {
    this.audio = new Audio('assets/sounds/gong.wav');
    this.audioFileName = 'gong';
    this.active = true;
    this.status = 'OK';
  }

  public playAudio() {
    if (this.active) {
      this.audio
        .play()
        .then(() => {
          // do nothing
        })
        .catch((error: { name: string }) => {
          this.active = false;
          if (error.name === 'NotAllowedError') {
            this.status = 'Permission denied';
          } else {
            this.status = 'Unknown Error: ' + error.name;
          }
        });
    }
  }

  public getActive(): boolean {
    return this.active;
  }

  public getStatus(): string {
    return this.status;
  }

  public initializeNotificationStatus() {
    const currentUserSettings = this.localstorageUserService.currentUserSettings;
    if (currentUserSettings['notificationSounds']) {
      this.enable();
    } else {
      this.disable();
    }

    if (
      currentUserSettings.notificationAudioFileName !== null &&
      currentUserSettings.notificationAudioFileName !== undefined &&
      currentUserSettings.notificationAudioFileName !== ''
    ) {
      this.changeAudioFile(currentUserSettings.notificationAudioFileName);
    }
  }

  public enable() {
    if (!this.active) {
      this.localstorageUserService.setCurrentUserSettingByKey('notificationSounds', true);

      this.active = true;
      this.status = 'OK';
      this.playAudio();
    }
  }

  public disable() {
    this.localstorageUserService.setCurrentUserSettingByKey('notificationSounds', false);

    if (this.active) {
      this.active = false;
      this.status = 'Disabled by user';
    }
  }

  public changeAudioFile(audioFileName: string) {
    this.audioFileName = audioFileName;
    this.audio.src = 'assets/sounds/' + audioFileName + '.wav';
    this.localstorageUserService.setCurrentUserSettingByKey('notificationAudioFileName', audioFileName);
  }

  public getAudioFileName(): string {
    return this.audioFileName;
  }
}
