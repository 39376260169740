import { Component, ViewEncapsulation } from '@angular/core';
import { LogoSettingsService } from '@nida-web/core';

@Component({
  selector: 'nida-web-set-initial-nidamobile-password',
  templateUrl: './set-initial-nidamobile-password.component.html',
  styleUrls: ['./set-initial-nidamobile-password.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SetInitialNidamobilePasswordComponent {
  logoSettings: string[];

  constructor(private logoSettingsService: LogoSettingsService) {
    this.logoSettings = [];
  }

  ngOnInit(): void {
    this.getLogo();
  }

  getLogo() {
    this.logoSettingsService.getSettings().subscribe((settings) => {
      console.log(settings);
      for (const [logoName, show] of Object.entries(settings)) {
        if (show) {
          this.logoSettings.push(logoName);
        }
      }
    });
  }
}
