import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { PatientView, PatientListServable } from '@nida-web/api/generic-interfaces/patient-management';
import { SessionManagerService } from '@nida-web/api/rest/authentication';

@Injectable({
  providedIn: 'root',
})
export class PatientArchiveStoreService {
  public patientsLoaded: BehaviorSubject<boolean>;
  private renewListSubject: Subject<boolean>;

  private patients: PatientView[];

  constructor(private patientListServable: PatientListServable, private currentUserServable: SessionManagerService) {
    this.patients = [];
    this.renewListSubject = new ReplaySubject<boolean>(1);
    this.patientsLoaded = new BehaviorSubject(false);
    this.currentUserServable.getSessionInformation().subscribe((userObject) => {
      if (!(userObject !== null && userObject !== undefined && userObject.userId !== undefined && userObject.userId > -1)) {
        this.patients = [];
      }
    });
  }

  // NOt in use anymore - replaced by ELK call
  public getPatientList(protocolId?: number): PatientView[] {
    this.renewList(protocolId);
    return this.patients;
  }

  public renewList(protocolId?: number): void {
    this.patientListServable.getPatientList(undefined, protocolId).subscribe((obj) => {
      if (obj === undefined || obj === null) {
        return;
      }

      for (const newPatient of obj) {
        let alreadyIn = false;
        // skip the entry if patient is not marked for archive list
        if (newPatient.archived === undefined || !newPatient.archived) {
          const indexOfArchivedPatient = this.patients.findIndex((patient) => patient.protocolId === newPatient.protocolId);
          if (indexOfArchivedPatient >= 0) {
            this.patients.splice(indexOfArchivedPatient, 1);
          }
          alreadyIn = true;
          continue;
        }
        for (let i = 0; i < this.patients.length; i++) {
          if (this.patients[i].nidaId === newPatient.nidaId) {
            this.patients[i].hl7ExportAt = newPatient.hl7ExportAt;
            const attachments = this.patients[i].attachments;
            alreadyIn = true;
            if (
              this.patients[i].protocolId < newPatient.protocolId ||
              (this.patients[i].protocolId === newPatient.protocolId && this.patients[i].seen !== newPatient.seen) ||
              (newPatient.attachments !== undefined &&
                attachments !== undefined &&
                this.patients[i].protocolId === newPatient.protocolId &&
                attachments.picture !== newPatient.attachments.picture) ||
              (attachments !== undefined &&
                newPatient.attachments !== undefined &&
                this.patients[i].protocolId === newPatient.protocolId &&
                attachments.pdf !== newPatient.attachments.pdf) ||
              (attachments !== undefined &&
                newPatient.attachments !== undefined &&
                attachments.pdfStatus !== newPatient.attachments.pdfStatus)
            ) {
              this.patients[i] = newPatient;
            }
          }
        }

        if (!alreadyIn) {
          this.patients.push(newPatient);
        }
      }
      this.orderPatientsByHighestProtocolId();

      this.renewListSubject.next(true);

      if (!this.patientsLoaded.value) {
        this.patientsLoaded.next(true);
      }
    });
  }

  public getDetailInformation(id: string): PatientView {
    if (this.patientsLoaded.value) {
      const patient: PatientView = this.patients.filter((element) => element.nidaId === id)[0];

      if (patient && !patient.seen) {
        this.patientListServable.setReadProtocol(patient.nidaId, patient.protocolId).subscribe((result) => {
          if (!result) {
            throw new Error('Error on set Protocol read.');
          }
        });
      }

      return patient;
    } else {
      return new PatientView('', -1);
    }
  }

  private orderPatientsByHighestProtocolId() {
    this.patients.sort((a: PatientView, b: PatientView) => {
      if (a.protocolId < b.protocolId) {
        return 1;
      }
      if (a.protocolId > b.protocolId) {
        return -1;
      }
      return 0;
    });
  }
  public getRenewInformation(): Observable<boolean> {
    return this.renewListSubject.asObservable();
  }
}
