import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Insurance, InsuranceServable } from '@nida-web/api/generic-interfaces/patient-management';
import { SessionManagerService } from '@nida-web/api/rest/authentication';

@Injectable({
  providedIn: 'root',
})
export class InsuranceStoreService {
  public insurancesLoaded: BehaviorSubject<boolean>;

  private insurances: Insurance[];
  private insurance: Insurance;

  constructor(private insuranceServable: InsuranceServable, private currentUserServable: SessionManagerService) {
    this.insurances = [];
    this.insurancesLoaded = new BehaviorSubject(false);
    this.currentUserServable.getSessionInformation().subscribe((userObject) => {
      if (!(userObject !== null && userObject !== undefined && userObject.userId !== undefined && userObject.userId > -1)) {
        this.insurances = [];
      }
    });
  }

  public getInsuranceList(): Insurance[] {
    this.insuranceServable.getInsuranceList().subscribe((newInsurances) => {
      if (newInsurances === undefined || newInsurances === null) {
      } else {
        for (const newInsurance of newInsurances.data) {
          this.insurances.push(newInsurance);
        }
        if (!this.insurancesLoaded.value) {
          this.insurancesLoaded.next(true);
        }
      }
    });
    return this.insurances;
  }

  public getInsurancesLoaded(): boolean {
    return this.insurancesLoaded.value;
  }

  public getInsuranceByProtocolId(protocolId: number): Insurance {
    this.insuranceServable.getInsuranceByProtocolId(protocolId).subscribe((insurance) => {
      this.insurance = insurance;
    });
    return this.insurance;
  }
}
