import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigRESTServable, Configuration, CustomHttpUrlEncodingCodec } from '@nida-web/api/generic-interfaces/config';
import { APIMember } from '../models/api-member.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionsMemberRESTService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(protected httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'permissions';
    });
    this.subscription.add(configSub);
  }

  /**
   * Add membership
   * For this Operation you need this permission administration.personal.rollen:rw
   * @param body Member Object
   */
  public addmembership(body: APIMember): Observable<APIMember> {
    if (!body) {
      throw new Error('Required parameter body was null or undefined when calling addmembership.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json', 'text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<APIMember>(`${this.apiPrefix}/member`, body, {
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }

  /**
   * Delete menbership
   * For this Operation you need this permission administration.personal.rollen:rw
   * @param uid the member uid
   * @param gid the member gid
   * @param _idMandanten the mandant id of the Person who is deleting membership
   */
  public deleteMembership(uid: number, gid: number, _idMandanten: number, observe?: 'body', reportProgress?: boolean): Observable<string> {
    if (isNaN(uid) && uid < 0) {
      throw new Error('Required parameter uid was null or undefined when calling deleteMembership.');
    }

    if (isNaN(gid) && gid < 0) {
      throw new Error('Required parameter gid was null or undefined when calling deleteMembership.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (uid) {
      queryParameters = queryParameters.set('uid', uid as any);
    }
    if (gid) {
      queryParameters = queryParameters.set('gid', gid as any);
    }

    let headers = this.defaultHeaders;

    // to determine accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const consumes: string[] = ['text/plain'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.httpClient.request<string>('delete', `${this.apiPrefix}/member`, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
      withCredentials: this.configuration.withCredentials,
      params: queryParameters,
      headers,
      observe,
      reportProgress,
    });
  }
}
