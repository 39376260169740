import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { MappingConfig, MappingConfigServable } from '@nida-web/api/rest/patient-management';

@Injectable({
  providedIn: 'root',
})
export class MappingConfigSubjectService implements MappingConfigServable {
  private mappingConfig = new ReplaySubject<MappingConfig>(1);

  public readonly mappingConfigObservable = this.mappingConfig.asObservable();

  public updateMappingConfigSubject(mappingConfig: MappingConfig): void {
    this.mappingConfig.next(mappingConfig);
  }

  getMappingConfig(): Observable<MappingConfig> {
    return this.mappingConfigObservable;
  }
}
