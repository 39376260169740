import { NgZone, OnInit, ViewChild, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DateService } from '@nida-web/core';
import { PatientView } from '@nida-web/api/generic-interfaces/patient-management';
import { ColumnProviderService } from '@nida-web/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { StatusColor } from '../models/status-color.enum';
import { PatientColumnEntry } from '../models/patient-column-entry.model';
import { TranslocoService } from '@ngneat/transloco';

import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import CustomStore from 'devextreme/data/custom_store';
import { PatientMappingService } from '@nida-web/api/rest/patient-management';
import { ElasticStoreService } from '../services/store/elastic.store.service';
import { ElasticRestService, QueryDSL } from '@nida-web/api/rest/nidaserver/elastic';
import { formatDate } from '@angular/common';

@Component({
  template: '',
})
export abstract class PatientTableComponent implements OnInit {
  @ViewChild('patientGrid', { static: false }) dataGrid: any;
  @ViewChild('searchInput') searchInput;

  public data: PatientView[];
  public columns: PatientColumnEntry[];
  public columnHovered: any;
  public resetTooltipVisible: boolean;
  public statusColor = StatusColor;
  public tooltip: boolean;
  public filter: boolean;
  public mousedown: boolean;
  public exportTexts: Record<string, unknown>;
  public gridName: string;
  protected contentClicked: boolean;
  public configName: string;
  public dataSource: CustomStore | PatientView[];
  protected elasticQuery: QueryDSL;
  searchValue: string;

  /**
   * Specifies the maximum number of data items per page
   * used to pass the pageSize from excel export to the load function
   * @private
   */
  private pageSize: number | undefined;
  /**
   * Index of the current page
   * used to pass the pageIndex from excel export to the load function
   * @private
   */
  private pageIndex: number | undefined;

  public now: Date;

  protected constructor(
    protected router: Router,
    protected columnService: ColumnProviderService,
    protected dateService: DateService,
    private translocoService: TranslocoService,
    private sessionManager: SessionManagerService,
    protected exampleElkRestService: ElasticRestService,
    protected patientMappingService: PatientMappingService,
    protected elasticStoreService: ElasticStoreService,
    protected zone: NgZone
  ) {
    this.configName = 'PatientColumns';
    this.columns = [];
    this.data = [];
    this.tooltip = false;
    this.filter = false;
    this.mousedown = false;
    this.resetTooltipVisible = false;
    this.contentClicked = false;
    this.pageSize = undefined;
    this.pageIndex = undefined;
    this.exportTexts = {
      exportAll: this.translocoService.translate('Export to excel'),
    };
    this.gridName = 'patientGrid';
    this.now = new Date();
  }

  ngOnInit(): void {
    this.getColumns();
    this.initializeColumnHovered();
  }

  fetchData() {
    this.dataSource = new CustomStore({
      key: 'nidaId',
      load: (loadOptions) => {
        const mapEntry = this.patientMappingService.mapEntry;
        const mapFieldsToRest = this.patientMappingService.mapFieldsToRest;
        // export calls load function without skip/take
        // pass skip/take for correct export
        if (
          loadOptions?.take === undefined &&
          loadOptions?.skip === undefined &&
          this.pageSize !== undefined &&
          this.pageIndex !== undefined
        ) {
          loadOptions.take = this.pageSize;
          loadOptions.skip = this.pageIndex * this.pageSize;
        }
        return this.elasticStoreService.fireQuery(loadOptions, mapEntry, mapFieldsToRest, this.dataGrid);
      },
    });
  }

  getColumns(): void {
    this.columns = this.columnService.getTableColumns(this.configName);
  }

  /*rebuildAdaptiveDetail(row: any) {
   console.log('acknowledged')
   for (const item of row.formOptions.items) {
   item.cssClass = 'test-item';
   }
   }*/

  initializeColumnHovered(): void {
    /*
     creates a object with all columns (as int, e.g. 0)
     sets every int in object to a bool (default: false)
     when column 0 is hovered: sets 0: true in columnHovered object;
     */
    const tempDict = {};
    let index: number;
    for (index = 0; index < this.columns.length; index++) {
      tempDict[index] = false;
    }

    this.columnHovered = tempDict;
  }

  forceRerender(): void {
    /*
     forces the rerendering of the datagrid
     => hovering does not seem to activate Angular's rerendering
     */
    this.zone.run(() => {});
  }

  setColumnHovered(col: number, bool: boolean): void {
    /*
     when onCellHoverChanged function is called
     */
    for (const entry in this.columnHovered) {
      if (Object.prototype.hasOwnProperty.call(this.columnHovered, entry)) {
        this.columnHovered[entry] = false;
      }
    }
    this.columnHovered[col] = bool;
    this.forceRerender();
  }

  onCellHoverChanged(e: any): void {
    /*
     When cursor is hovering a cell in the grid, function is called;
     setColumnHovered is called to set whole column of hovered cell on hovered=true
     */
    if (!this.filter) {
      this.setColumnHovered(e.columnIndex, true);
    }
  }

  onRowPrepared(e: any): void {
    /*
     Adds specific adjustments to any row;
     Function is called when the datagrid is built, for all rows
     */
    if (e.rowType === 'data') {
      e.rowElement.style.cursor = 'Pointer';
      if (e.data.seen === false) {
        e.rowElement.style.fontWeight = 'bold';
      } else {
        e.rowElement.style.fontWeight = 'normal';
      }
      if (!e.data.settings.visible) {
        e.rowElement.style.color = 'lightgrey';
      } else {
        e.rowElement.style.color = 'black';
      }
    }
  }

  onRowClick(e: any): void {
    if (!this.contentClicked) {
      this.triggerNavigation(e.data.nidaId);
    } else {
      this.contentClicked = false;
    }

    this.onRowPrepared(e);
  }

  triggerNavigation(input: any): void {
    this.router.navigate(['/current-patients/details/' + input]).then();
  }

  onToolbarPreparing(e: any): void {
    /*
     Function creates a toolbar with specific adjustments;
     Function is called one, when datagrid is build;
     */

    this.sessionManager.hasPermission(['klinik.export.excel', 'klinik.admin']).subscribe((returnValue) => {
      if (returnValue) {
        e.toolbarOptions.items.splice(0, 0, {
          widget: 'dxButton',
          locateInMenu: 'auto',
          location: 'after',
          cssClass: 'basic-button-design',
          options: {
            text: this.translocoService.translate('Export to excel'),
            icon: 'exportxlsx',
            onClick: () => {
              this.exportChart();
            },
          },
        });
      }
    });

    e.toolbarOptions.items.splice(0, 0, {
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'after',
      cssClass: 'basic-button-design',
      options: {
        text: this.translocoService.translate('Filter'),
        icon: 'icon icon-bold-filter',
        onClick: () => {
          this.changeFilterVisibility();
        },
      },
    });
    e.toolbarOptions.items.splice(0, 0, {
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'after',
      cssClass: 'basic-button-design',
      options: {
        text: this.translocoService.translate('Choose columns'),
        icon: 'icon icon-bold-layout-column',
        onClick: () => {
          this.changeColumnVisibility();
        },
      },
    });
    e.toolbarOptions.items.splice(0, 0, {
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'after',
      cssClass: 'basic-button-design',
      options: {
        text: this.translocoService.translate('Reset'),
        icon: 'icon icon-bold-rotate-back',
        onClick: () => {
          this.resetListState(this.dataGrid);
          this.searchValue = '';
        },
      },
    });
  }

  onRowButtonClick(e: any): void {
    this.contentClicked = true;
    e.data.settings.clicked = !e.data.settings.clicked;
  }

  formatMoment(value: any, type: any) {
    return this.dateService.formatMoment(value, type);
  }

  displayDateIfToday = (date: Date) => {
    if (!date) {
      return false;
    } else {
      /*
       Returns true if: today is the same day as given parameter 'date'
       */
      // TODO: Function has been updated on 2021-05-04; Works without momentJS; Original: capacity-assignment-table;

      if (date.getDay() === this.now.getDay() && date.getMonth() === this.now.getMonth() && date.getFullYear() === this.now.getFullYear()) {
        return true;
      } else {
        return false;
      }
    }
  };

  triggerClosing(input: any): void {
    input.data.settings.clicked = false;
  }

  preventDetailOpening(): void {
    /*
     clicking on settings button (last column) opens settings tool tip;
     clicking on settings tooltip area opens the patient's detail;
     This function:
     if settings button is clicked, contentClicked = true;
     if contentClicked = true: onRowClick does not lead to patient's details;
     */
    this.contentClicked = true;
  }

  changeFilterVisibility(): void {
    /*
     Opens / closes filter row above datagrid
     */
    this.filter = !this.filter;
    this.initializeColumnHovered();
  }

  changeColumnVisibility(): void {
    /*
     Opens / closes column chooser above datagrid
     */
    this.tooltip = !this.tooltip;
    this.setChooserVisibility();
  }

  resetListState(patientGrid: DxDataGridComponent): void {
    patientGrid.instance.refresh();
    patientGrid.instance.state({});
  }

  toggleResetTooltip() {
    this.resetTooltipVisible = !this.resetTooltipVisible;
  }

  setChooserVisibility(): void {
    /*
     manages the columnChooserVisibility:
     true => false
     false => true
     */
    const instance = this.dataGrid.instance;
    if (this.tooltip) {
      instance.showColumnChooser();
    } else {
      instance.hideColumnChooser();
    }
  }

  onExporting(e) {
    // component = dxChart instance;
    const currentComponent = e;

    // set pageSize and index to pass skip/take to the load function
    this.pageIndex = e.getDataSource().pageIndex();
    this.pageSize = e.getDataSource().pageSize();

    const workbook = new ExcelJS.Workbook();
    const currentWorksheet = workbook.addWorksheet(this.translocoService.translate('Patients archive'));

    const priority = this.translocoService.translate('Priority');
    const prioImmediate = this.translocoService.translate('Immediate');
    const prioUrgent = this.translocoService.translate('Urgent');
    const prioNormal = this.translocoService.translate('Normal');

    const trueToYes = this.translocoService.translate('Yes');
    const falseToNo = this.translocoService.translate('No');

    const intToFemale = this.translocoService.translate('Female_lowercase');
    const intToMale = this.translocoService.translate('Male_lowercase');
    const intToDiverse = this.translocoService.translate('Diverse_lowercase');

    const intToStable = this.translocoService.translate('stable');
    const intToUnstable = this.translocoService.translate('clinically unstable');

    const KISConnected = this.translocoService.translate('Connected_lowercase');

    const pdfLabel = this.translocoService.translate('PDF');
    const pictureLabel = this.translocoService.translate('Image');
    const ecgLabel = this.translocoService.translate('ECG');
    const videoLabel = this.translocoService.translate('Video');

    //   var columns = e.component.option("columns");
    // var newColumns = flatten(columns);

    //e.component.beginUpdate();
    //e.component.option("columns", newColumns);

    exportDataGrid({
      component: currentComponent,
      worksheet: currentWorksheet,
      customizeCell(options) {
        const { gridCell, excelCell } = options;

        /*
        COLUMN STATS
         */
        const colStatus = currentWorksheet.getColumn(1);
        colStatus.width = 10;
        colStatus.header = priority;

        /*
        CELL STATS
         */
        if (gridCell !== undefined && gridCell.rowType === 'header') {
          if (gridCell.column?.dataField === 'status') {
            gridCell.column.caption = String(priority);
          }
        }

        if (gridCell !== undefined && gridCell.rowType === 'data') {
          if (gridCell.column !== undefined) {
            const cellValue = gridCell.data;

            if (gridCell.column.dataField === 'status') {
              if (cellValue.status === 2) {
                // excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0000' } };
                excelCell.size = excelCell.font = { color: { argb: 'FF0000' } };
                excelCell.value = prioImmediate;
              } else if (cellValue.status === 1) {
                // excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC241' } };
                excelCell.font = { color: { argb: 'FFC241' } };
                excelCell.value = prioUrgent;
              } else if (cellValue.status === 0) {
                // excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '008000' } };
                excelCell.font = { color: { argb: '008000' } };
                excelCell.value = prioNormal;
              } else {
                excelCell.value = null;
              }
            }

            if (gridCell.column.dataField === 'arrivalTime') {
              if (cellValue.arrivalTime) {
                excelCell.value = formatDate(cellValue.arrivalTime, 'dd.MM.yyyy HH:mm', 'de-DE');
              } else if (cellValue.date) {
                excelCell.value = formatDate(cellValue.date, 'dd.MM.yyyy HH:mm', 'de-DE');
              } else {
                excelCell.value = null;
              }
            }

            if (gridCell.column.dataField === 'gender') {
              if (cellValue.gender === 0) {
                excelCell.value = intToFemale;
              } else if (cellValue.gender === 1) {
                excelCell.value = intToMale;
              } else if (cellValue.gender === 2) {
                excelCell.value = intToDiverse;
              }
            }

            if (gridCell.column.dataField === 'circulation') {
              if (cellValue.circulation === 0) {
                excelCell.value = intToUnstable;
                excelCell.font = { color: { argb: 'FF0000' } };
              } else if (cellValue.circulation === 1) {
                excelCell.value = intToStable;
              }
            }

            if (gridCell.column.dataField === 'intubated') {
              if (cellValue.intubated) {
                excelCell.value = trueToYes;
              } else if (cellValue.intubated === false) {
                excelCell.value = falseToNo;
              } else if (cellValue.intubated === null) {
                // pass
              }
            }

            if (gridCell.column.dataField === 'iStatus') {
              if (cellValue.iStatus) {
                excelCell.value = trueToYes;
              } else if (cellValue.iStatus === false) {
                excelCell.value = falseToNo;
              } else if (cellValue.iStatus === null) {
                // pass
              }
            }

            if (gridCell.column.dataField === 'hl7ExportAt') {
              /*
               value hl7ExportAt := KIS;
               */
              if (cellValue.hl7ExportAt !== null) {
                excelCell.value = KISConnected;
              }
            }

            if (gridCell.column.dataField === 'attachments') {
              const list: string[] = [];
              if (cellValue.attachments.pdf) {
                list.push(pdfLabel);
              }
              if (cellValue.attachments.ekg) {
                list.push(ecgLabel);
              }
              if (cellValue.attachments.picture) {
                list.push(pictureLabel);
              }
              if (cellValue.attachments.video) {
                list.push(videoLabel);
              }

              excelCell.value = list.join(', ');
            }
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.generateFileName());
      });
    });
    e.cancel = true;
  }

  generateFileName() {
    return 'patient-table_' + new Date().toISOString() + '.xlsx';
  }

  exportChart() {
    this.onExporting(this.dataGrid.instance);
  }
}
