import { Injectable } from '@angular/core';
import { Group, GroupList, GroupServable } from '@nida-web/api/generic-interfaces/user-management';
import { Observable, ReplaySubject } from 'rxjs';
import { PermissionsGroupRESTService } from '../actions/permissions-group.rest.service';
import { PermissionsMemberRESTService } from '../actions/permissions-member.rest.service';

@Injectable({
  providedIn: 'root',
})
export class GroupRESTAdapterService implements GroupServable {
  constructor(
    private permissionsGroupRESTService: PermissionsGroupRESTService,
    private permissionsMemberRESTService: PermissionsMemberRESTService
  ) {}

  getAllGroups(): Observable<GroupList> {
    const resultSubject = new ReplaySubject<GroupList>(1);

    this.permissionsGroupRESTService.getAllGroups().subscribe((apiGroupList) => {
      const groupList: GroupList = [];

      if (apiGroupList === undefined || apiGroupList.data === undefined) {
        resultSubject.next(groupList);
        return;
      }

      for (const apigroup of apiGroupList.data) {
        const group: Group = new Group();
        group.id = apigroup.id !== null ? apigroup.id : undefined;
        group.idMandanten = apigroup.idMandanten;
        group.groupName = apigroup.name !== null ? apigroup.name : undefined;
        group.groupDescription = apigroup.beschreibung !== null ? apigroup.beschreibung : undefined;
        group.groupMapping = apigroup.zuordnung !== null ? apigroup.zuordnung : undefined;
        group.station = apigroup.wache !== null ? apigroup.wache : undefined;
        group.idPosition = apigroup.idStandorte !== null ? apigroup.idStandorte : undefined;

        groupList.push(group);
      }

      resultSubject.next(groupList);
    });

    return resultSubject.asObservable();
  }

  getGroup(groupID: number): Observable<Group> {
    // TODO Implementierung abschließen
    const resultSubject = new ReplaySubject<Group>(1);

    if (isNaN(groupID) || groupID < 0) {
      resultSubject.next({});
    }

    return resultSubject.asObservable();
  }

  getGroupsByUserId(userID: number): Observable<GroupList> {
    const resultSubject = new ReplaySubject<GroupList>(1);

    if (isNaN(userID) || userID < 0) {
      resultSubject.next([]);
      return resultSubject.asObservable();
    }

    this.permissionsGroupRESTService.getAllGroupsByPersonalId(String(userID)).subscribe((apiGroupList) => {
      const groupList: GroupList = [];

      if (apiGroupList === null || apiGroupList === undefined || apiGroupList.data === null || apiGroupList.data === undefined) {
        resultSubject.next(groupList);
        return;
      }

      for (const apigroup of apiGroupList.data) {
        const group: Group = new Group();

        group.id = apigroup.id !== null ? apigroup.id : undefined;
        group.idMandanten = apigroup.idMandanten;
        group.groupName = apigroup.name !== null ? apigroup.name : undefined;
        group.groupDescription = apigroup.beschreibung !== null ? apigroup.beschreibung : undefined;

        groupList.push(group);
      }

      resultSubject.next(groupList);
    });

    return resultSubject.asObservable();
  }

  getRescueServiceGroupsByUserId(userID: number): Observable<GroupList> {
    const resultSubject = new ReplaySubject<GroupList>(1);

    if (isNaN(userID) || userID < 0) {
      resultSubject.next([]);
      return resultSubject.asObservable();
    }

    this.permissionsGroupRESTService.getAllRescueServiceGroupsByPersonalId(String(userID)).subscribe((apiGroupList) => {
      const groupList: GroupList = [];

      if (apiGroupList === null || apiGroupList === undefined || apiGroupList.data === null || apiGroupList.data === undefined) {
        resultSubject.next(groupList);
        return;
      }

      for (const apigroup of apiGroupList.data) {
        const group: Group = new Group();

        group.id = apigroup.id !== null ? apigroup.id : undefined;
        group.groupDescription = apigroup.beschreibung !== null ? apigroup.beschreibung : undefined;
        group.groupMapping = apigroup.zuordnung;
        group.groupName = apigroup.name !== null ? apigroup.name : undefined;
        group.idMandanten = apigroup.idMandanten;
        group.idPosition = apigroup.idStandorte;
        group.station = apigroup.wache;

        groupList.push(group);
      }

      resultSubject.next(groupList);
    });

    return resultSubject.asObservable();
  }

  removeUser(userID: number, groupID: number): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    if (isNaN(userID) || userID < 0 || isNaN(groupID) || groupID < 0) {
      resultSubject.next(false);
      return resultSubject.asObservable();
    }

    this.permissionsMemberRESTService.deleteMembership(userID, groupID, -1).subscribe(() => {
      resultSubject.next(true);
    });

    return resultSubject.asObservable();
  }

  addUser(userID: number, groupID: number, idMandanten: number): Observable<boolean> {
    const resultSubject = new ReplaySubject<boolean>(1);

    if (isNaN(userID) || userID < 0 || isNaN(groupID) || groupID < 0) {
      resultSubject.next(false);
      return resultSubject.asObservable();
    }

    this.permissionsMemberRESTService.addmembership({ gid: groupID, uid: userID, idMandanten: idMandanten }).subscribe((answer) => {
      if (answer === null || answer === undefined || answer.uid < 0) {
        resultSubject.next(false);
      } else {
        resultSubject.next(true);
      }
    });

    return resultSubject.asObservable();
  }
}
