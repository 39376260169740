/**
 * mission data type
 * general data about a mission with its ID, the time and vehicle number etc.
 */
export class MissionData {
  id?: number;
  nida_id?: string;
  mission_nr?: string;
  anamnesis?: string;
  physician?: string;
  date_time?: string;
  deleted?: boolean;
  diagnosefuehrend?: string;
  reading_status?: number;
  post_box_id?: number;
  vehicle_name?: string;
  vehicle_tel?: string;
  dispatch_tel?: string;
  c3_mission_id?: string;
  c3_mission_uid?: string;
  c3_mission_serial_pam?: string;
  c3_mission_serial_dem?: string;
  c3_mission_serial_dim?: string;
  transferredToFachadmin?: boolean;
}
