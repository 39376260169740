import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@nida-web/shared/data-access';
import { UserManagementComponent } from './user-management.component';
import { NewUserEntryComponent } from './new-user-entry/new-user-entry.component';
import { SetInitialNidamobilePasswordComponent } from './set-initial-nidamobile-password/set-initial-nidamobile-password.component';

const routes: Routes = [
  {
    path: '',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create',
    component: NewUserEntryComponent,
  },
  {
    path: 'set-initial-nidamobile-password',
    component: SetInitialNidamobilePasswordComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserManagementRoutingModule {}
