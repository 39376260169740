import { Pipe, PipeTransform } from '@angular/core';
import { vitalParametersSpecialValues } from '@nida-web/api/generic-interfaces/patient-management';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'replaceVitalParameter',
})
export class ReplaceVitalParameterPipe implements PipeTransform {
  constructor() {}

  transform(value: string | number | null | undefined): number | string | null | undefined {
    let returnValue: number | string | null | undefined;

    if (value == null) {
      return value;
    }

    const numValue = Number(value);

    switch (numValue) {
      case vitalParametersSpecialValues.notMeasurable:
        returnValue = 'nm';
        break;
      case vitalParametersSpecialValues.high:
        returnValue = 'high';
        break;
      case vitalParametersSpecialValues.low:
        returnValue = 'low';
        break;
      case vitalParametersSpecialValues.notMeasured:
        returnValue = 'ng';
        break;
      default:
        if (isNaN(numValue)) {
          returnValue = numValue;
        } else {
          returnValue = formatNumber(numValue, 'de', '0.0-2');
        }
    }

    return returnValue;
  }
}
