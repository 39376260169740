import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-ui-support-tile',
  templateUrl: './ui-support-tile.component.html',
  styleUrls: ['./ui-support-tile.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UiSupportTileComponent {
  @Input()
  public pictureLinks: string[];
  @Input()
  public translatedHeadtext: string;
  @Input()
  public translatedText: string;
  @Input()
  public buttonText: string;
  @Input()
  public buttonLink: string;

  constructor() {
    if (this.pictureLinks === undefined) {
      this.pictureLinks = [];
    }
  }

  navigateToPage(buttonLink: string) {
    window.open(buttonLink);
  }
}
