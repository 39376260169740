import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  /**
   * Gets the year component of the given date.
   * @param date The date to extract the year from.
   * @returns The year component.
   */
  static getYear(date: Date): number {
    return date.getFullYear();
  }

  /**
   * Gets the month component of the given date.
   * @param date The date to extract the month from.
   * @returns The month component (0-indexed, 0 = January).
   */
  static getMonth(date: Date): number {
    return date.getMonth();
  }

  /**
   * Gets the date of the month component of the given date.
   * @param date The date to extract the date of the month from.
   * @returns The month component (1-indexed, 1 = first of month).
   */
  static getDate(date: Date): number {
    return date.getDate();
  }

  /**
   * Gets the hours of the given date.
   * @param date The hours of date
   * @returns The hours
   */
  static getHours(date: Date): number {
    return date.getHours();
  }

  /**
   * Gets the minutes of the given date.
   * @param date The minutes of date
   * @returns The minutes
   */
  static getMinutes(date: Date): number {
    return date.getMinutes();
  }

  /**
   * Gets the seconds of the given date.
   * @param date The seconds of date
   * @returns The seconds
   */
  static getSeconds(date: Date): number {
    return date.getSeconds();
  }

  /**
   * Add or subtract days from date
   * @param date The date you want to add to or subtract from
   * @param days The amount of days you to add or subtract
   * @returns The new calculated date
   */
  static changeDay(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  /**
   * Checks if provided year is a leap year
   * @param year Year to check
   * @returns True (leap year) / False (not leap year)
   */
  static isLeapYear(year: number): boolean {
    return new Date(year, 1, 29).getDate() === 29;
  }

  /**
   * Parste german Datetime String to Date
   * @param dateString in german date format DD.MM.YYYY hh:mm
   * also accepts whitespaces, slashes, minus and comma as date separator
   * and  colon, dot and comma as time separator
   * @returns parsed Date or NaN
   */
  static parseDateStringDE(dateString: string): Date | null {
    const m = dateString.match(/^(\d{1,2})[/\s.\-,](\d{1,2})[/\s.\-,](\d{4})\s(\d{1,2})[:.,](\d{1,2})$/);
    return m ? new Date(parseInt(m[3]), parseInt(m[2]) - 1, parseInt(m[1]), parseInt(m[4]), parseInt(m[5])) : null;
  }

  static getTimeDiffStringDE(d1: Date, d2: Date): string | undefined {
    let diffString: string | undefined;
    const diffMinutes: number = Math.abs(dayjs(d1).diff(d2, 'minutes'));

    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes % 60;

    const hoursString = hours > 0 ? (hours === 1 ? hours + ' Stunde' : hours + ' Stunden') : undefined;
    const minutesString = minutes === 1 ? minutes + ' Minute' : minutes + ' Minuten';

    if (minutes > 0) {
      diffString = hours > 0 ? hoursString + ' ' + minutesString : minutesString;
    } else {
      diffString = hours > 0 ? hoursString : undefined;
    }
    return diffString;
  }
}
