import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    // const token = this.auth.getAuthorizationToken();
    if (req.headers.has('x-meddv-gateway')) {
      const gwJWT = req.headers.get('x-meddv-gateway');
      let newRequest: HttpRequest<any>;
      if (gwJWT !== null && gwJWT !== '') {
        let headers = req.headers.delete('x-meddv-gateway');
        headers = headers.set('Authorization', `Bearer ${gwJWT}`);
        newRequest = req.clone({
          headers: headers,
        });
      } else {
        newRequest = req.clone({
          headers: req.headers.delete('x-meddv-gateway'),
        });
      }
      return next.handle(newRequest);
    }

    const token = localStorage.getItem('access_token');

    if (token) {
      // Clone the request to add the new header.
      const newRequest = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });

      // Pass on the cloned request instead of the original request.
      return next.handle(newRequest);
    } else {
      return next.handle(req);
    }
  }
}
