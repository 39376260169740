<div *ngIf="showBreadcrumbs">
  <div>
    <div class="breadcrumb-container">
      <ng-container *ngFor="let item of menuItems; index as index">
        <div
          (click)="index !== menuItems.length - 1 ? navigateTo(item.path) : ''"
          class="breadcrumb-fragment"
          [class.breadcrumb-fragment-active]="index !== menuItems.length - 1"
        >
          <div class="breadcrumb-caption">
            {{ item.caption }}
          </div>
        </div>
      </ng-container>
    </div>
    <div>
      <!--      <button [disabled]="!upperLevelExist" (click)="navigateBack()">ZURÜCK</button>-->
    </div>
  </div>
</div>
