import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavAppInfoService } from '@nida-web/navigation';
import { CountryCode } from '../models/country-code';

@Injectable({
  providedIn: 'root',
})
export class AppInfoService {
  constructor(private titleService: Title, private navAppInfoService: NavAppInfoService) {
    this._title = '';
    this._countryCode = CountryCode.DE;
  }

  private _title: string;
  private _countryCode: CountryCode;
  public navigation: any;

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
    this.titleService.setTitle(value);
    this.navAppInfoService.title = value;
  }

  get countryCode(): CountryCode {
    return this._countryCode;
  }

  set countryCode(value) {
    this._countryCode = value;
    this.navAppInfoService.countryCode = value;
  }

  setNavigation(navigation: any) {
    this.navigation = navigation;
  }
}
