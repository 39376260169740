import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationMessageService } from '../../services/notification-message.service';
import { NotificationSoundService } from '../../services/notification-sound.service';
import { NotificationBrokerService } from '../../services/notification-broker.service';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'nida-web-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  public filterListAvailable = false;

  constructor(
    private notificationBrokerService: NotificationBrokerService,
    private notificationMessageService: NotificationMessageService,
    private notificationSoundService: NotificationSoundService
  ) {
    console.log(this.getFilterFieldList());
    console.log(this.getFilterValue());
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * only display filter lists, if data is loaded
   */
  public loadData() {
    this.notificationBrokerService
      .getFilterInitializedStatus()
      .pipe(
        // skip isLoaded === false
        skipWhile((isLoaded) => !isLoaded),
        // if the data is loaded initially complete the subscription
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => (this.filterListAvailable = true));
  }

  public onNotificationValueChange(event: any) {
    if (event.value !== event.previousValue) {
      if (event.value) {
        this.notificationMessageService.enable();
      } else {
        this.notificationMessageService.disable();
      }
      if (!this.notificationMessageService.getActive()) {
        event.component.reset();
      }
    }
  }

  public onSoundValueChange(event: any) {
    if (event.value !== event.previousValue) {
      if (event.value) {
        this.notificationSoundService.enable();
      } else {
        this.notificationSoundService.disable();
      }

      if (!this.notificationSoundService.getActive()) {
        event.component.reset();
      }
    }
  }

  public isNotificationStatusActive() {
    return this.notificationMessageService.getActive();
  }

  public isSoundStatusActive() {
    return this.notificationSoundService.getActive();
  }

  onSoundFileChange($event: any) {
    switch ($event.value) {
      case 'Gong':
        this.notificationSoundService.changeAudioFile('gong');
        break;
      case 'Martinshorn':
        this.notificationSoundService.changeAudioFile('mh');
        break;
    }
    this.notificationSoundService.playAudio();
  }

  onFilterOnChange($event: any) {
    if ($event.value !== $event.previousValue) {
      this.notificationBrokerService.setFilterStatus($event.value);
    }
  }

  public isFilterActive() {
    return this.notificationBrokerService.getFilterStatus();
  }

  getFilterFieldList() {
    return this.notificationBrokerService.getFilterFieldList();
  }

  getFilterValue() {
    return this.notificationBrokerService.getFilterValue();
  }
  getAudioFileName() {
    let result = 'Gong';

    switch (this.notificationSoundService.getAudioFileName()) {
      case 'gong':
        result = 'Gong';
        break;
      case 'mh':
        result = 'Martinshorn';
        break;
    }

    return result;
  }

  onFilterValueChange($event: any) {
    this.notificationBrokerService.setFilterValue($event.value);
    console.log('Filter change detected', $event.value);
  }
}
