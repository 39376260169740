export class Group {
  id?: number;
  groupName?: string;
  groupDescription?: string;
  idMandanten?: number;
  groupMapping?: string;
  station?: string;
  idPosition?: number;

  constructor(
    id?: number,
    idMandanten?: number,
    groupName?: string,
    groupDescription?: string,
    groupMapping?: string,
    station?: string,
    idPosition?: number
  ) {
    this.id = id || 0;
    this.idMandanten = idMandanten || 0;
    this.groupName = groupName || '';
    this.groupDescription = groupDescription || '';
    this.groupMapping = groupMapping || '';
    this.station = station || '';
    this.idPosition = idPosition || 0;
  }
}
