<nida-web-card [height100]="true" headline="Patient" style="height: 100%">
  <ng-container *ngIf="detailData">
    <div class="row">
      <div class="col-sm-6 mb-2">
        <div class="basic-padding">
          <div class="basic-subject-title">
            {{ 'Name' | transloco }}
          </div>
          <div class="basic-font-medium" style="word-break: break-word">
            {{ patientData.name | displayNan }}
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-2">
        <div class="basic-padding">
          <div class="basic-subject-title">
            {{ 'Birthday' | transloco }}
          </div>
          <div class="basic-font-medium">
            {{ formatMoment(detailData.birthdate, 'day') | displayNan }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 mb-2">
        <div class="basic-padding">
          <div class="basic-subject-title">
            {{ 'Address' | transloco }}
          </div>
          <div *ngIf="detailData" class="basic-font-medium">
            <div class="basic-font-medium">
              {{ concatAddress() | displayNan }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-2">
        <div class="basic-padding">
          <div class="basic-subject-title">
            {{ 'Age' | transloco }}
          </div>
          <div class="basic-font-medium">
            {{ formatAgeInformation() | displayNan }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 mb-2 mb-sm-0">
        <div class="basic-padding">
          <div class="basic-subject-title">
            {{ 'Relatives telephone' | transloco }}
          </div>
          <div class="basic-font-medium">
            <div class="word-break-force">
              <a
                *ngIf="detailData.relativesPhone !== undefined && detailData.relativesPhone !== '' && detailData.relativesPhone !== null"
                [href]="'tel:' + detailData.relativesPhone"
              >
                <i class="dx-icon-tel"></i>{{ detailData.relativesPhone }}
              </a>
              <span
                *ngIf="detailData.relativesPhone === undefined || detailData.relativesPhone === '' || detailData.relativesPhone === null"
                >{{ detailData.relativesPhone | displayNan }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="basic-padding">
          <div class="basic-subject-title">
            {{ 'Gender' | transloco }}
          </div>
          <div class="basic-font-medium">
            <ng-container [ngSwitch]="patientData.gender">
              <i *ngSwitchCase="0" class="icon icon-bold-gender-female"></i>
              <i *ngSwitchCase="1" class="icon icon-bold-gender-male"></i>
              <i *ngSwitchCase="2" class="icon icon-bold-gender-diverse"></i>
              <div *ngSwitchDefault>{{ patientData.gender | displayNan }}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nida-web-card>
