export class EcgLeadDataByType {
  value: Array<number>;
  valid: Array<boolean>;
  synd: Array<boolean>;
  pacer: Array<boolean>;
  gainControl: Array<number>;
  highpass: Array<string>;
  lowpass: Array<string>;
  gap: Array<number>;

  constructor(
    value?: Array<number>,
    valid?: Array<boolean>,
    synd?: Array<boolean>,
    pacer?: Array<boolean>,
    gainControl?: Array<number>,
    highpass?: Array<string>,
    lowpass?: Array<string>,
    gap?: Array<number>
  ) {
    this.value = value || [];
    this.valid = valid || [];
    this.synd = synd || [];
    this.pacer = pacer || [];
    this.gainControl = gainControl || [];
    this.highpass = highpass || [];
    this.lowpass = lowpass || [];
    this.gap = gap || [];
  }
}
