import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from '@nida-web/core';

@Component({
  selector: 'nida-web-er-tile',
  templateUrl: './er-tile.component.html',
  styleUrls: ['./er-tile.component.scss'],
})
export class ErTileComponent implements OnInit {
  @Input()
  public nidaId: string;
  @Input()
  public protocolId: number;
  public patientRoot: string;

  constructor(private router: Router, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    const subscription = this.breadcrumbService.isCreated.subscribe((response) => {
      if (response) {
        if (this.breadcrumbService.isFragmentInPath('current-patients')) {
          this.patientRoot = 'current-patients';
        } else {
          this.patientRoot = 'patients';
        }

        // console.log(this.breadcrumbService.getMenuItems());
      }
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    const root = this.breadcrumbService.getRootLevel();
    if (root) {
    }
  }

  openNav() {
    // TODO: emergency-navigation
    this.router.navigate([this.patientRoot + `/details/${this.nidaId}/emergency-room/${this.protocolId}`]).then();
  }
}
