import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nida-web-feature-support-page',
  templateUrl: './feature-support-page.component.html',
  styleUrls: ['./feature-support-page.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FeatureSupportPageComponent {
  navigateToPage(page: string) {
    window.open(page);
  }
}
