<div
  [ngClass]="{
    'height-100-percent': height100 === true,
    'bg-white': !enableGrayStyling,
    'bg-gray': enableGrayStyling,
    shadow: !enableGrayStyling,
    outline: enableGrayStyling
  }"
  class="card-wrapper"
  style="border-radius: 0.35rem"
>
  <div
    *ngIf="!disableHeadline"
    class="card-headline m-0 d-flex justify-content-between align-items-center"
    style="padding: 0.5rem 1.5rem; border-bottom: 1px solid #cdcdcd"
  >
    <h2 *ngIf="headline" class="m-0 heading">{{ headline | transloco }}</h2>
    <h2 *ngIf="translatedHeadline" class="m-0 heading">{{ translatedHeadline }}</h2>

    <div class="d-flex align-items-center">
      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
    </div>
  </div>
  <div class="card-content" [ngClass]="{ 'p-1 p-sm-3 p-md-4': this.disableContentPadding === false }">
    <ng-content></ng-content>
  </div>
</div>
