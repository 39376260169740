import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigRESTServable, Configuration, CustomHttpUrlEncodingCodec } from '@nida-web/api/generic-interfaces/config';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class PatientViewRESTService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable, private translocoService: TranslocoService) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'nidatracker/';
    });
    this.subscription.add(configSub);
  }

  // TODO: getList / getListAfterDate / getSinglePatient / protocolRead are nearly identical please outsource source code
  public getList(notArchived?: 0 | 1, revision?: number): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (notArchived !== undefined) {
      queryParameters = queryParameters.set('notArchived', notArchived);
    }
    if (revision !== undefined) {
      queryParameters = queryParameters.set('revision', revision);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .get<any>(`${this.apiPrefix}clinic/alarm/list`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 403) {
            notify({
              message: this.translocoService.translate('Operation not permitted'),
              type: 'Warning',
              displayTime: 10000,
            });
          }
          return throwError(err.message);
        })
      );
  }

  public getListAfterDate(dateAfter: string): Observable<any> {
    if (!dateAfter) {
      throw new Error('Required parameter nidaId was null or undefined when calling getSinglePatient.');
    }
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (dateAfter) {
      queryParameters = queryParameters.set('dateAfter', dateAfter as any);
    }
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .get<any>(`${this.apiPrefix}clinic/alarm/list`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 403) {
            notify({
              message: this.translocoService.translate('Operation not permitted'),
              type: 'Warning',
              displayTime: 10000,
            });
          }
          return throwError(err.message);
        })
      );
  }

  public getSinglePatient(nidaId: string): Observable<any> {
    if (!nidaId) {
      throw new Error('Required parameter nidaId was null or undefined when calling getSinglePatient.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (nidaId) {
      queryParameters = queryParameters.set('fromId', nidaId as any);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .get<any>(`${this.apiPrefix}clinic/alarm/list`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            notify({
              message: this.translocoService.translate('Not authorized'),
              type: 'Error',
              displayTime: 5000,
            });
          } else if (err.status === 404) {
            notify({
              message: this.translocoService.translate('Not Found'),
              type: 'Error',
              displayTime: 5000,
            });
          }

          return throwError(err.message);
        })
      );
  }

  public protocolRead(nidaId: string, id: number): Observable<any> {
    if (!nidaId) {
      throw new Error('Required parameter nidaId was null or undefined when calling protocolRead.');
    }

    const body = { nidaId, revision: id, status: 1 };

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .put<any>(`${this.apiPrefix}clinic/protocol/announcement/status`, body, {
        withCredentials: this.configuration.withCredentials,
        headers,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            notify({
              message: this.translocoService.translate('Not authorized'),
              type: 'Error',
              displayTime: 5000,
            });
          } else if (err.status === 404) {
            notify({
              message: this.translocoService.translate('Not Found'),
              type: 'Error',
              displayTime: 5000,
            });
          }
          return throwError(err.message);
        })
      );
  }
}
