import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { Method } from '../models/method';
import { CustomHttpParameterCodec } from '../helper/custom-http-parameter-codec';

@Injectable({
  providedIn: 'root',
})
export class UserinfoRESTService {
  public defaultHeaders: HttpHeaders;
  public configuration: Configuration;
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;
  public encoder: HttpParameterCodec;

  constructor(private httpClient: HttpClient, private configRestSubjectService: ConfigRESTServable) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configRestSubjectService.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl + 'auth';
    });
    this.subscription.add(configSub);
    // this.subscription.unsubscribe();

    this.defaultHeaders = new HttpHeaders();
    this.configuration = new Configuration();
    this.encoder = new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Returns the appropriate authentication method for a given user.
   * Returns the appropriate authentication method for a given user. The result will have defaults so cannot be used to infer existence of the user. This resource only represents an appropriate login method, it does not initiate any actual login step. In cases where prior knowledge exists, this can be skipped.
   * @param login The user name for login. On mandanten login systems, the form \&quot;mandantId:user\&quot; is required.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAuthenticationMethod(
    login: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<Method>;
  public getAuthenticationMethod(
    login: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<HttpResponse<Method>>;
  public getAuthenticationMethod(
    login: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<HttpEvent<Method>>;
  public getAuthenticationMethod(
    login: string,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'text/plain' }
  ): Observable<any> {
    if (login === null || login === undefined) {
      throw new Error('Required parameter login was null or undefined when calling getAuthenticationMethod.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (login !== undefined && login !== null) {
      queryParameters = this.addToHttpParams(queryParameters, <any>login, 'login');
    }

    let headers = this.defaultHeaders;

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }

    return this.httpClient.get<Method>(`${this.apiPrefix}/user-method`, {
      params: queryParameters,
      responseType: <any>responseType,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
