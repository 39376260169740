import { ClientInfo } from './client-info.model';

export class LoginInformation {
  /**
   * Identifier of the User
   */
  id?: number;
  /**
   * Actual Username correspondending to the id. (Not necessarily last- or firstname)
   */
  userName?: string;

  /**
   * Tenant id of the user
   */
  idMandanten?: number;

  /**
   * Permissions of the user for clientside checking (e.g. Disabling components if there is no permission). The server shall
   * have an own protecting mechanism.
   */
  permissions?: string[];
  /**
   * True if the User is logged in. False if the User is not logged in or logging in has failed.
   */
  loggedIn: boolean;
  /**
   * Optional Authentication token which shall be used for further communication.
   */
  loginToken?: string;
  /**
   * Indicates whether the second factor is required to be completed.
   */
  twoFA?: boolean;
  /**
   * Indicates whether the second factor login is enforced.
   */
  twoFaEnforcement?: boolean;
  /**
   * The uuId of the session
   */
  sessionUuid?: string;

  clinics?: string[];

  /**
   * Client information about the Argus role and service areas
   */
  clientInfo?: ClientInfo;

  idStandorte?: number[];
}
