import { Injectable } from '@angular/core';
import { NotificationBrokerService, NotificationServable } from '@nida-web/shared/notifications';
import { Observable } from 'rxjs';
import { SessionManagerService } from '@nida-web/api/rest/authentication';
import { MQTTSubscribeListenerService } from '@nida-web/api-mqtt-wrapper';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from '@nida-web/api/rest/config';
import { PatientListServable, PatientView, PatientViewList } from '@nida-web/api/generic-interfaces/patient-management';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PatientNotificationService extends NotificationServable {
  constructor(
    protected notificationService: NotificationBrokerService,
    protected sessionManagerService: SessionManagerService,
    protected appConfigservice: ConfigService,
    protected mqttService: MQTTSubscribeListenerService,
    protected transloco: TranslocoService,
    private patientListServable: PatientListServable
  ) {
    super(sessionManagerService, appConfigservice, mqttService, notificationService, 'topicAlarm');
  }

  protected handleMQTTMessage(mqttMessage: string): Observable<any> {
    const msgJson = JSON.parse(mqttMessage);
    const protocolId = msgJson['id']['data'];
    return this.patientListServable.getPatientList(undefined, protocolId).pipe(
      tap((patientViewList: PatientViewList) => {
        if (patientViewList && patientViewList.length > 0) this.castNotification(patientViewList[0]);
      })
    );
  }

  protected loadFilterSetting() {
    console.log('PNS - Loading filter...');
    this.notificationService.addFilterField('Priorität', 'status', 'number', {
      valueField: 'id',
      displayField: 'name',
      data: [
        { id: 2, name: 'Sofort' },
        { id: 1, name: 'Dringend' },
        { id: 0, name: 'Normal' },
      ],
    });
    this.notificationService.addFilterField('Kreislauf', 'circulation', 'string', {
      valueField: 'circField',
      displayField: 'name',
      data: [
        { circField: 'stabil', name: 'Stabil' },
        { circField: 'instabil', name: 'Instabil' },
      ],
    });
    this.notificationService.addFilterField('GCS', 'gcs', 'number');
    this.notificationService.addFilterField('Intubiert', 'intubated', 'boolean');
    this.notificationService.addFilterField('I-Status', 'iStatus', 'boolean');

    this.notificationService.setFilterInitializedStatus(true); // Kreislauf
  }

  private castNotification(patient: PatientView) {
    if (patient.seen) return;

    let title: string;

    if (patient.new) {
      title = 'New';
    } else {
      title = 'Update';
    }

    title = this.transloco.translate(title);

    let date: Date;

    if (patient.date !== undefined) {
      date = patient.date;
    } else {
      date = new Date();
    }

    const text =
      this.transloco.translate('Time') +
      ': ' +
      date.toLocaleTimeString() +
      '\n' +
      this.transloco.translate('Vehicle') +
      ': ' +
      patient.vehicle +
      '\n' +
      this.transloco.translate('Diagnosis') +
      ': ' +
      patient.diagnosis;

    this.notificationService.notifyUser({ title, text, iconPath: 'assets/resources/ambulance-car.png' }, patient);
  }
}
