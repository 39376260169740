import { ErrorHandler, Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular-ivy';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private sentryErrorHandler: SentryErrorHandler) {}

  handleError(error: any): void {
    if (error.message && error.message.includes('ChunkLoadError')) {
      if (localStorage.getItem('OIDCLogin') !== null) {
        return;
      }

      console.log('ChunkLoadError detected, reloading page...');
      window.location.reload();
    } else {
      this.sentryErrorHandler.handleError(error);
    }
  }
}
