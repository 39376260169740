import { Injectable } from '@angular/core';
import { NavItem } from '@nida-web/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubNavigationHandlerService {
  private subNavItemSubject: Subject<NavItem[]>;

  constructor() {
    this.subNavItemSubject = new BehaviorSubject<NavItem[]>([]);
  }

  public showSubNavigation(items: NavItem[]): void {
    this.subNavItemSubject.next(items);
  }

  public closeSubNavigation(): void {
    this.subNavItemSubject.next([]);
  }

  public getSubNavigationItems(): Observable<NavItem[]> {
    return this.subNavItemSubject.asObservable();
  }
}
