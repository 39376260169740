import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, ReplaySubject, skipWhile, takeWhile } from 'rxjs';
import { SessionInformation } from '../models/session-information.model';
import { first, map, take } from 'rxjs/operators';
import { PermissionsHelper } from '../helper/permissions-helper';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root',
})
export class SessionManagerService {
  private sessionInformation: ReplaySubject<SessionInformation>;
  private sessionInformationValue: SessionInformation;

  constructor() {
    this.sessionInformation = new ReplaySubject<SessionInformation>(1);

    this.resetSession();
  }

  // TODO: Check all calls of getSessionInformation(), if they need updates.
  // TODO: If yes, replace with onSessionInformationChanged()
  /**
   * Should only return this.sessionInformationValue.
   * But does the same as onSessionInformationChanged()
   */
  public getSessionInformation(): Observable<SessionInformation> {
    return this.sessionInformation.asObservable();
  }

  /**
   * Emits only once, when the userId is valid and then completes.
   */
  public getCurrentUserId(): Observable<number> {
    return this.getSessionInformation().pipe(
      skipWhile((sessionInformation) => !sessionInformation.userId),
      take(1),
      map((sessionInformation) => sessionInformation.userId) as OperatorFunction<SessionInformation, number>
    );
  }

  /**
   * Emits only once, when the user is logged in and then completes.
   */
  onLoggedIn(): Observable<void> {
    return this.sessionInformation.pipe(
      first((sessionInformation) => sessionInformation.loggedIn),
      map(() => {
        return;
      })
    );
  }

  /**
   * Emits only once, when the user is logged out and then completes.
   */
  onLoggedOut(): Observable<boolean> {
    return this.sessionInformation.pipe(
      first((sessionInformation) => !sessionInformation.loggedIn),
      map(() => {
        return true;
      })
    );
  }

  /**
   * Returns a ReplaySubject that receives an never completes.
   */
  public onSessionInformationChanged(): Observable<SessionInformation> {
    return this.sessionInformation.asObservable();
  }

  public setSessionInformation(sessionInfo: SessionInformation): void {
    // do not publish logged out info again (just once) - this prevents infinity loops
    if (!this.sessionInformationValue.loggedIn && !sessionInfo.loggedIn) {
      return;
    } else {
      this.sessionInformationValue = sessionInfo;
      this.sessionInformation.next(this.sessionInformationValue);
      Sentry.configureScope((scope) => {
        scope.setUser({
          userId: sessionInfo.userId,
          username: sessionInfo.userName,
          loggedIn: sessionInfo.loggedIn,
          tenantId: sessionInfo.tenantId,
        });
      });
    }
  }

  /**
   * Does NOT check :rw and :ro
   * @param neededPermission
   */
  public hasPermission(neededPermission: string[]): Observable<boolean> {
    return this.getSessionInformation().pipe(
      take(1),
      map((sessionInformation) => {
        if (sessionInformation && sessionInformation.permissions) {
          return PermissionsHelper.isAllowedAccess(neededPermission, sessionInformation.permissions);
        } else {
          return false;
        }
      })
    );
  }

  /**
   * Also checks :rw and :ro permissions
   * @param neededPermission
   */
  public hasPermissionWithRwRo(neededPermission: string[]): Observable<boolean> {
    return this.getSessionInformation().pipe(
      take(1),
      map((sessionInformation) => {
        if (sessionInformation && sessionInformation.permissions) {
          return PermissionsHelper.isAllowedAccessWithRwRo(neededPermission, sessionInformation.permissions);
        } else {
          return false;
        }
      })
    );
  }

  // private isAllowedAccess(rolesAllowed: string[], currentRoles: string[]): boolean {
  //   console.log(rolesAllowed, currentRoles);
  //
  //   const intersectedRoles = currentRoles.reduce((acc, curr) => {
  //     return [...acc, ...rolesAllowed.filter((role) => this.getComparablePermission(role) === this.getComparablePermission(curr))];
  //   }, []);
  //   return intersectedRoles.length > 0;
  // }
  //
  // private getComparablePermission(perm: string): string {
  //   return perm.trim().split(':')[0];
  // }

  public getUserPermissionsIfLoggedIn(): Observable<string[]> {
    return this.sessionInformation.pipe(
      takeWhile((sessionInformation) => sessionInformation.loggedIn && sessionInformation.permissions !== undefined),
      // Sadly typescript doesn't realise that with the above code, permissions can no longer be undefined
      // So we need to help: https://stackoverflow.com/a/62971842/8408576
      map((sessionInformation) => sessionInformation.permissions) as OperatorFunction<SessionInformation, string[]>,
      take(1)
    );
  }

  public resetSession(): void {
    this.sessionInformationValue = {
      permissions: [],
      clinics: [],
      userName: '',
      loggedIn: false,
      loginToken: '',
      userId: -1,
      tenantId: -1,
    };
    this.sessionInformation.next(this.sessionInformationValue);

    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });
  }

  public getLocalStroageName(): string {
    if (this.sessionInformationValue.userName) {
      return 'missionKVBStorage' + this.sessionInformationValue.userName;
    } else {
      console.log('ERROR: could not set local storage for User!');
      return 'default';
    }
  }
}
