<div class="row mb-1">
  <div class="col-12 d-flex justify-content-between bold">
    <div>{{ data.name | transloco }}</div>
    <div>
      [ <span>{{ data.unit | displayNan }}</span> ]
    </div>
  </div>
</div>
<div class="values d-flex border-grey">
  <div class="left-values">
    <div>
      <p class="m-0 text-right cell-title">
        {{ data.initialFinding?.upperRightValue }}
      </p>
    </div>
    <div class="left-values text-center py-2 d-flex align-items-center justify-content-center">
      <p
        class="m-0 measured-value"
        *ngIf="
          (data.initialFinding?.firstValue && !data.initialFinding?.secondValue) ||
            (!data.initialFinding?.secondValue && !displayLeftTileTwoEmptyValues);
          else leftValuesElseBlock
        "
      >
        {{ data.initialFinding?.firstValue | replaceVitalParameter | displayNan }}
      </p>
      <ng-template #leftValuesElseBlock>
        <p class="m-0 measured-value">
          {{ data.initialFinding?.firstValue | replaceVitalParameter | displayNan }} /
          {{ data.initialFinding?.secondValue | replaceVitalParameter | displayNan }}
        </p>
      </ng-template>
    </div>

    <div>
      <p class="m-0 text-right cell-title">
        {{ data.initialFinding?.lowerRightValue }}
      </p>
    </div>
  </div>

  <div class="right-values text-center">
    <div class="right-values flex-direction-column">
      <div>
        <p class="m-0 text-right cell-title">
          {{ data.lastFinding?.upperRightValue }}
        </p>
      </div>
      <p
        class="m-0 measured-value"
        *ngIf="
          (data.lastFinding?.firstValue && !data.lastFinding?.secondValue) ||
            (!data.lastFinding?.secondValue && !displayRightTileTwoEmptyValues);
          else rightValuesElseBlock
        "
      >
        {{ data.lastFinding?.firstValue | replaceVitalParameter | displayNan }}
      </p>
      <ng-template #rightValuesElseBlock>
        <p class="m-0 measured-value">
          {{ data.lastFinding?.firstValue | replaceVitalParameter | displayNan }} /
          {{ data.lastFinding?.secondValue | replaceVitalParameter | displayNan }}
        </p>
      </ng-template>
      <p class="m-0">
        {{ data.lastFinding?.timestamp | displayNan }}
      </p>
      <div>
        <p class="m-0 text-right cell-title">
          {{ data.lastFinding?.lowerRightValue }}
        </p>
      </div>
    </div>
  </div>
</div>
