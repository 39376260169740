import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BaseRootComponent } from '@nida-web/navigation';
import { ChangePasswordGuard, StartPageGuard, WebclientPreviewGuard } from '@nida-web/shared/data-access';

const routes: Routes = [
  {
    path: '',
    component: BaseRootComponent,
    canActivate: [WebclientPreviewGuard, ChangePasswordGuard],
    children: [
      { path: '', children: [], canActivate: [StartPageGuard] },
      {
        path: 'settings',
        loadChildren: () => import(`@nida-web/shared/settings`).then((m) => m.SharedSettingsModule),
      },
      {
        path: 'users',
        loadChildren: () => import(`@nida-web/user-management`).then((m) => m.UserManagementModule),
      },
      {
        path: 'support/legal-notice',
        loadComponent: () => import(`@nida-web/shared/legal-notice`).then((m) => m.LegalNoticeComponent),
      },
    ],
  },
  {
    path: 'legal-notice',
    loadComponent: () => import(`@nida-web/shared/legal-notice`).then((m) => m.LegalNoticeComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
