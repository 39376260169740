import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import * as events from 'devextreme/events';
import { NavItem } from '@nida-web/core';
import { PermissionsHelper, SessionManagerService } from '@nida-web/api/rest/authentication';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'nida-web-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss'],
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu: DxTreeViewComponent;

  @Output() selectedItemChanged = new EventEmitter<string>();
  @Output() openMenu = new EventEmitter<any>();

  @Input() items: NavItem[];
  @Input() replaceIconsWithNumbers: boolean;

  private compact = false;

  public userPermissions: string[];

  constructor(
    private elementRef: ElementRef,
    private sessionManagerService: SessionManagerService,
    private navigationService: NavigationService,
    private ref: ChangeDetectorRef
  ) {
    this.sessionManagerService.getSessionInformation().subscribe((sessionInfo) => {
      this.userPermissions = sessionInfo.permissions ? sessionInfo.permissions : [];
    });
  }

  @Input()
  set selectedItem(value: string) {
    if (this.menu && this.menu.instance) {
      this.menu.instance.selectItem(value);
    }
  }

  @Input()
  get compactMode(): boolean {
    return this.compact;
  }

  set compactMode(val: boolean) {
    this.compact = val;
    if (val && this.menu && this.menu.instance) {
      this.menu.instance.collapseAll();
    }
  }

  updateSelection(event) {
    const nodeClass = 'dx-treeview-node';
    const selectedClass = 'dx-state-selected';
    const leafNodeClass = 'dx-treeview-node-is-leaf';
    const element: HTMLElement = event.element;

    const rootNodes = element.querySelectorAll(`.${nodeClass}:not(.${leafNodeClass})`);
    Array.from(rootNodes).forEach((node) => {
      node.classList.remove(selectedClass);
    });

    let selectedNode = element.querySelector(`.${nodeClass}.${selectedClass}`);
    while (selectedNode && selectedNode.parentElement) {
      if (selectedNode.classList.contains(nodeClass)) {
        selectedNode.classList.add(selectedClass);
      }

      selectedNode = selectedNode.parentElement;
    }
  }

  onItemClick(event) {
    this.selectedItemChanged.emit(event);
  }

  onMenuInitialized(event) {
    event.component.option('deferRendering', false);
  }

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e) => {
      if (this.navigationService.getMenuBarSizeByScreenSize() !== 'wide') this.openMenu.emit(e);
    });

    this.navigationService.currentBadgeCount$.subscribe((badgeCountItems) => {
      this.items.forEach((navItem) => {
        badgeCountItems.forEach((badgeItem) => {
          if (navItem.enableNotify && navItem.path === badgeItem.path) {
            if (navItem.badge !== badgeItem.count) {
              navItem.badge = badgeItem.count;
              if (badgeItem.count > 0) {
                navItem.notify = true;
                setTimeout(() => {
                  navItem.notify = false;
                  this.ref.markForCheck();
                }, 2400);
              }
            }
          }
        });
      });
      this.ref.markForCheck();
    });
  }

  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
  }

  checkAccess(permission: string[]): boolean {
    if (permission) {
      return PermissionsHelper.isAllowedAccess(this.userPermissions, permission);
    }
    return true;
  }
}
