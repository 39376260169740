import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PatientView } from '@nida-web/api/generic-interfaces/patient-management';

@Component({
  selector: 'nida-web-patient-detail-rescue-unit',
  templateUrl: './patient-detail-rescue-unit.component.html',
  styleUrls: ['./patient-detail-rescue-unit.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PatientDetailRescueUnitComponent {
  @Input() loaded: BehaviorSubject<boolean>;
  @Input() patientData: PatientView;

  constructor() {}
}
