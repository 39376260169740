import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { custom } from 'devextreme/ui/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { AppInfoService } from './app-info.service';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(private readonly swUpdate: SwUpdate, private translocoService: TranslocoService, private appInfoService: AppInfoService) {
    this.updateClient();
  }

  updateClient() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((evt) => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            this.showAppUpdateAlert();
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
    }
  }

  showAppUpdateAlert(): void {
    const header = this.translocoService.translate('New App Update available');
    const updateText = 'Please press the "Update and Reload" Button to get the newest version of ';
    const message = '<b>' + this.translocoService.translate(updateText, { title: this.appInfoService.title }) + '<b>';
    const buttonText = this.translocoService.translate('Update and Reload');
    const action = this.doAppUpdate;
    const updateDialog = custom({
      title: header,
      messageHtml: message,
      buttons: [
        {
          text: buttonText,
          onClick: action,
        },
      ],
    });
    updateDialog.show();
  }

  doAppUpdate(): void {
    localStorage.clear();
    document.location.reload();
  }
}
