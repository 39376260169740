<form [formGroup]="formGroup">
  <div class="row mx-0 p-0 percent-100-height">
    <div class="col-xl-2 col-sm-6 col-md-4 basic-card-wrapper">
      <div class="basic-card-style diagnosis-tile">
        <div class="percent-100-height">
          <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
            <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">A</h2>
            {{ 'Airway' | transloco }}
          </div>
          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-12 mb-1">{{ 'Respiratory rate' | transloco }}</div>
              <div class="col-12 text-lg-right d-flex align-items-center">
                <dx-number-box formControlName="respiratoryFrequency"></dx-number-box>
                <p class="my-0 ml-2" style="white-space: nowrap">/ min</p>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-12 mb-1">{{ 'Airway clear' | transloco }}</div>
              <div class="col-12">
                <dx-switch
                  formControlName="respiratoryTractClear"
                  [switchedOnText]="'Yes' | transloco"
                  [switchedOffText]="'No' | transloco"
                >
                </dx-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-sm-6 col-md-4 basic-card-wrapper">
      <div class="basic-card-style diagnosis-tile">
        <div class="percent-100-height">
          <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
            <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">B</h2>
            {{ 'Breathing' | transloco }}
          </div>
          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-12 mb-1">{{ 'SpO2' }}</div>
              <div class="col-12 text-lg-right d-flex align-items-center">
                <dx-number-box formControlName="spo2"></dx-number-box>
                <p class="my-0 ml-2" style="white-space: nowrap">%</p>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-12 mb-1">{{ 'etCO2' }}</div>
              <div class="col-12 text-lg-right d-flex align-items-center">
                <dx-number-box formControlName="etco2"></dx-number-box>
                <p class="my-0 ml-2" style="white-space: nowrap">mmHg</p>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-12 mb-1">{{ 'FiO2' }}</div>
              <div class="col-12 text-lg-right d-flex align-items-center">
                <dx-number-box formControlName="fio2"></dx-number-box>
                <p class="my-0 ml-2" style="white-space: nowrap">%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-sm-6 col-md-4 basic-card-wrapper">
      <div class="basic-card-style diagnosis-tile">
        <div class="percent-100-height">
          <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
            <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">C</h2>
            {{ 'Circulation' | transloco }}
          </div>
          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-12 mb-1">{{ 'RR sys' }}</div>
              <div class="col-12 text-lg-right d-flex align-items-center">
                <dx-number-box formControlName="rrSys"></dx-number-box>
                <p class="my-0 ml-2" style="white-space: nowrap">mmHg</p>
              </div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-12 mb-1">{{ 'Heart rate' | transloco }}</div>
              <div class="col-12 text-lg-right d-flex align-items-center">
                <dx-number-box formControlName="heartRate"></dx-number-box>
                <p class="my-0 ml-2" style="white-space: nowrap">/ min</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-sm-6 col-md-6 basic-card-wrapper">
      <div class="basic-card-style diagnosis-tile">
        <div class="percent-100-height">
          <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
            <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">D</h2>
            {{ 'Disability_b' | transloco }}
          </div>
          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-xl-6 justify-content-center align-self-center">{{ 'GCS' | transloco }}</div>
              <div class="col-xl-6 text-lg-right">
                <dx-number-box formControlName="gcs"></dx-number-box>
              </div>
            </div>
            <div class="row table-padding basic-padding-bottom">
              <table class="table table-bordered m-0">
                <thead>
                  <tr>
                    <th scope="col" style="width: 70px">
                      <strong>{{ 'Pupils' | transloco }}</strong>
                    </th>
                    <th scope="col">{{ 'Left_lowercase' | transloco }}</th>
                    <th scope="col">{{ 'Right_lowercase' | transloco }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{{ 'expanse' | transloco }}</th>
                    <td>
                      <dx-select-box
                        formControlName="pupilsWidthLeft"
                        [items]="width"
                        [placeholder]="'Select...' | transloco"
                        displayExpr="name"
                        valueExpr="name"
                      ></dx-select-box>
                    </td>
                    <td>
                      <dx-select-box
                        [items]="width"
                        [placeholder]="'Select...' | transloco"
                        displayExpr="name"
                        valueExpr="name"
                        formControlName="pupilsWidthRight"
                      ></dx-select-box>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ 'Light reaction' | transloco }}</th>
                    <td>
                      <dx-select-box
                        [items]="reactions"
                        [placeholder]="'Select...' | transloco"
                        displayExpr="name"
                        valueExpr="name"
                        formControlName="pupilsLightLeft"
                      ></dx-select-box>
                    </td>
                    <td>
                      <dx-select-box
                        [items]="reactions"
                        [placeholder]="'Select...' | transloco"
                        displayExpr="name"
                        valueExpr="name"
                        formControlName="pupilsLightRight"
                      ></dx-select-box>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mb-1">
              <div class="col-xl-12">{{ 'Intoxication' | transloco }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-xl-4 mb-1 mb-md-0">
                <dx-check-box [text]="'Drugs' | transloco" formControlName="drugs"></dx-check-box>
              </div>
              <div class="col-xl-4 mb-1 mb-md-0">
                <dx-check-box [text]="'Alcohol' | transloco" formControlName="alcohol"></dx-check-box>
              </div>
              <div class="col-xl-4 mb-1 mb-md-0">
                <dx-check-box [text]="'Other' | transloco" formControlName="intoxication"></dx-check-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-md-6 basic-card-wrapper">
      <div class="basic-card-style diagnosis-tile">
        <div class="percent-100-height">
          <div class="basic-card-title py-1 basic-padding d-flex align-items-center">
            <h2 class="m-0 mr-2 d-inline font-weight-bold" style="color: #5e5e5e">E</h2>
            {{ 'Exposure' | transloco }}
          </div>
          <div class="basic-padding">
            <div class="row basic-padding-bottom">
              <div class="col-12 mb-1">{{ 'Body temperature' | transloco }}</div>
              <div class="col-12 text-lg-right d-flex align-items-center">
                <dx-number-box formControlName="bodyTemperature"></dx-number-box>
                <p class="my-0 ml-2" style="white-space: nowrap">°C</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">{{ 'Pain' | transloco }}</div>
            </div>
            <div class="row basic-padding-bottom">
              <div class="col-12">
                <dx-slider [max]="10" [min]="0" formControlName="pain">
                  <dxo-label [visible]="true" position="top"></dxo-label>
                  <dxo-tooltip [enabled]="true" showMode="always" position="bottom"></dxo-tooltip>
                </dx-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
