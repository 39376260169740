import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EmergencyRoomStoreService } from '../services/emergency-room.store.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ERCouncil, ERCouncilMeta } from '@nida-web/api/generic-interfaces/emergencyroom';
import DepartmentJson from './departments.json';
import notify from 'devextreme/ui/notify';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'nida-web-emergency-room-council',
  templateUrl: './council.component.html',
  styleUrls: ['./council.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CouncilComponent implements OnInit {
  public departments = DepartmentJson;
  public departmentsDataSource;
  public teamMember;
  public date;
  public councils: Array<ERCouncil>;
  public councilsMeta: Array<ERCouncilMeta>;
  public arrangements;
  public orderListArray; // { 234: [...], <councilId>: <orderArray>}
  public globalDisabled: boolean;
  public councilsFormGroup: UntypedFormArray;
  public councilsFormArray: Array<UntypedFormGroup>;

  public newEntryMode: boolean;

  constructor(private emergencyRoomStoreService: EmergencyRoomStoreService) {}

  ngOnInit(): void {
    this.councils = [];
    this.councilsMeta = [];
    this.orderListArray = [];
    this.councilsFormArray = [];
    this.teamMember = [
      { memberId: 123, name: 'Prof. Dr. med. Klaus Mustermann' },
      { memberId: 456, name: 'No. Dr. Julian Winter' },
    ];
    this.date = new Date();
    this.newEntryMode = false;

    this.generateDataSource();
    this.loadCouncilData();
  }

  generateDataSource() {
    this.departmentsDataSource = new DataSource({
      store: new ArrayStore({
        data: this.departments,
        key: 'name',
      }),
      group: 'category',
    });
  }

  loadCouncilData() {
    this.emergencyRoomStoreService.councilListData.subscribe((value) => {
      if (value && value.data) {
        this.councils = [];
        this.councilsMeta = [];
        this.orderListArray = [];
        this.councilsFormArray = [];
        this.councils = value.data;
        this.setCouncilMeta();
        this.initFormGroups();
        // TODO: loadOrderListData() probably here?
      }
    });
  }

  initFormGroups() {
    this.councils.forEach((council) => {
      this.councilsFormArray.push(this.addNewFormControl(council));
    });
  }

  addNewFormControl(council: ERCouncil) {
    return new UntypedFormGroup({
      id: new UntypedFormControl(council.id),
      requestTime: new UntypedFormControl(council.requestTime),
      arrivalTime: new UntypedFormControl(council.arrivalTime),
      discipline: new UntypedFormControl(council.discipline),
      name: new UntypedFormControl(council.name),
      description: new UntypedFormControl(council.description),
      patientId: new UntypedFormControl(council.patientId),
    });
  }

  createEmptyCouncil() {
    /*
    Adding a new council demands:
    - an empty council object to be send to database
    - corresponding meta data (opened)
    - an entry in the forms array (dynamic form controlling)
     */
    this.newEntryMode = true;

    const emptyCouncil = {
      id: undefined,
      patientId: this.emergencyRoomStoreService.erPatientId,
      discipline: '',
      requestTime: undefined,
      name: '',
      arrivalTime: undefined,
      description: '',
    };

    this.councilsFormArray.push(this.addNewFormControl(emptyCouncil));
    this.councilsMeta.push(this.getEmptyCouncilMeta());
    this.councils.push(emptyCouncil);
  }

  deleteCouncilEntries(index) {
    this.councilsFormArray.splice(index, 1);
    this.councilsMeta.splice(index, 1);
    this.councils.splice(index, 1);
  }

  getEmptyCouncilMeta(): ERCouncilMeta {
    return {
      id: null,
      name: null,
      opened: true,
      arrangements: [],
    };
  }

  setCouncilMeta() {
    /*
    Council meta: Additional data about every council;
    E.g. if tile is expanded (opened) etc.
     */
    this.councils.forEach((council: ERCouncil) => {
      const councilMeta = this.getEmptyCouncilMeta();
      if (council.id) {
        // if council.id is undefined, empty array stays empty
        councilMeta.id = council.id;
        this.emergencyRoomStoreService.getOrderList(council.id).subscribe((response) => {
          if (response && response.data) {
            councilMeta.arrangements = response.data;
          }
        });
      }
      this.councilsMeta.push(councilMeta);
    });
  }

  onInitNewRow(e: any) {
    e.data.excecuted = false;
  }

  performedCheck(e: any) {
    console.log(e);
    // if (e.data.performed === false) {
    //   if (
    //     (e.data.performedBy !== null && Object.prototype.hasOwnProperty.call(e.data, 'performedBy')) ||
    //     (e.data.performedTime !== null && Object.prototype.hasOwnProperty.call(e.data, 'performedTime'))
    //   ) {
    //     return false;
    //   }
    // }
    return true;
  }

  performedByCheck(e: any) {
    console.log(e);
    // if (e.data.performedBy === null || !Object.prototype.hasOwnProperty.call(e.data, 'performedBy')) {
    //   if (e.data.performed === true || (e.data.performedTime !== null && Object.prototype.hasOwnProperty.call(e.data, 'performedTime'))) {
    //     return false;
    //   }
    // }
    return true;
  }

  performedTimeCheck(e: any) {
    console.log(e);
    // if (e.data.performedTime === null || !Object.prototype.hasOwnProperty.call(e.data, 'performedTime')) {
    //   if (e.data.performed === true || (e.data.performedBy !== null && Object.prototype.hasOwnProperty.call(e.data, 'performedBy'))) {
    //     return false;
    //   }
    // }
    return true;
  }

  autoFillCurrentRequestedDate(element: any) {
    if (element.requestTime === null) {
      element.requestTime = new Date();
    }
  }

  autoFillCurrentArrivedDate(element: any) {
    if (element.arrivalTime === null) {
      element.arrivalTime = new Date();
    }
  }

  autoFillDate(event) {
    if (event.rowType === 'data') {
      if (!event.value) {
        if (event.columnIndex === 1 || event.columnIndex === 4) {
          event.component.cellValue(event.row.rowIndex, event.columnIndex, new Date());
        }
      }
    }
  }

  submitForm(index: number) {
    const currentCouncil = this.councilsFormArray[index].getRawValue();
    const currentCouncilId = this.councils[index].id;

    // TODO: Better code necessary;
    if (currentCouncilId) {
      currentCouncil.id = currentCouncilId;
      this.emergencyRoomStoreService.updateCouncil(currentCouncilId, currentCouncil).subscribe((response) => {
        if (response) {
          this.councils[index] = response;
          this.councilsFormArray[index].markAsPristine();
          // TODO: Normally, FormGroups are re-initialized - not working here. Until then: .markAsPristine();
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
      });
    } else {
      this.emergencyRoomStoreService.createCouncil(currentCouncil).subscribe((response) => {
        if (response) {
          this.councils[index] = response;
          this.councilsFormArray[index].markAsPristine();
          // TODO: Normally, FormGroups are re-initialized - not working here. Until then: .markAsPristine();
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        // this.emergencyRoomStoreService.getCouncils();
      });
    }

    this.newEntryMode = false;
  }

  deleteCouncil(index: number) {
    const councilId = this.councils[index].id;
    if (councilId) {
      const ordersArray = this.councilsMeta[index].arrangements;

      if (ordersArray) {
        ordersArray.forEach((order) => {
          this.deleteOrder(order);
        });
      }

      const subDelete = this.emergencyRoomStoreService.deleteCouncil(councilId).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        subDelete.unsubscribe();
      });
    }

    this.deleteCouncilEntries(index);
    this.newEntryMode = false;
  }

  saveOrder(e: any, councilId: number, councilIndex: number) {
    e.data['councilId'] = councilId;

    delete e.data['__KEY__'];

    // const currentOrders = this.councilsMeta[councilIndex].arrangements;
    // if (currentOrders) {
    //   currentOrders.push(e.data);
    // }

    this.emergencyRoomStoreService.createOrder(e.data).subscribe((response) => {
      if (response) {
        this.emergencyRoomStoreService.getOrderList(councilId).subscribe((responseOrders) => {
          if (responseOrders && responseOrders.data) {
            this.councilsMeta[councilIndex].arrangements = responseOrders.data;
            notify({
              message: 'Änderungen erfolgreich übernommen!',
              type: 'Success',
              displayTime: 5000,
            });
          } else {
            notify({
              message: 'Vorgang nicht erfolgreich beendet!',
              type: 'Error',
              displayTime: 5000,
            });
          }
        });
      }
    });
  }

  deleteOrder(e: any) {
    if (e.data && e.data.id) {
      const sub = this.emergencyRoomStoreService.deleteOrder(e.data.id).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        sub.unsubscribe();
      });
    }
  }

  updateOrder(e: any) {
    if (e.data && e.data.id) {
      const sub = this.emergencyRoomStoreService.updateOrder(e.data.id, e.data).subscribe((response) => {
        if (response) {
          notify({
            message: 'Änderungen erfolgreich übernommen!',
            type: 'Success',
            displayTime: 5000,
          });
        } else {
          notify({
            message: 'Vorgang nicht erfolgreich beendet!',
            type: 'Error',
            displayTime: 5000,
          });
        }
        sub.unsubscribe();
      });
    }
  }
}
