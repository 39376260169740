import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { GroupList } from '@nida-web/api/generic-interfaces/user-management';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DxDataGridComponent } from 'devextreme-angular';
import { TableManagementService } from '@nida-web/core';
import { SessionInformation } from '@nida-web/api/rest/authentication';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'nida-web-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UserTableComponent {
  @Input() tableData: DataSource;
  @Input() groups?: GroupList;
  @Input() currentUser?: SessionInformation;
  @ViewChild('userGrid', { static: false }) dataGrid!: DxDataGridComponent;

  public tooltip = false;

  constructor(private translocoService: TranslocoService, private router: Router, public tableManagementService: TableManagementService) {}

  onInitialized() {
    this.dataGrid.columnChooser.title = this.translocoService.translate('Column chooser');
    this.dataGrid.columnChooser.emptyPanelText = this.translocoService.translate('Drag a column here to hide it');
  }

  changeColumnVisibility() {
    this.tooltip = !this.tooltip;
    this.setChooserVisibility();
  }

  setChooserVisibility() {
    const instance = this.dataGrid.instance;

    if (this.tooltip) {
      instance.showColumnChooser();
    } else {
      instance.hideColumnChooser();
    }
  }

  onCollapse() {
    this.dataGrid.instance.collapseAll(-1); // Collapsing all Master-Rows
  }

  userUpdated() {
    this.tableData.reload();
  }

  allowDeleting(e) {
    return e.row.data.loginType === 0 || e.row.data.loginType === undefined;
  }

  onToolbarPreparing(e: any): void {
    e.toolbarOptions.items.splice(0, 0, {
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'after',
      cssClass: 'basic-button-design',
      options: {
        text: this.translocoService.translate('Reset'),
        icon: 'icon icon-bold-rotate-back',
        onClick: () => {
          this.resetListState(this.dataGrid);
        },
      },
    });

    e.toolbarOptions.items.splice(0, 0, {
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'after',
      cssClass: 'basic-button-design',
      options: {
        text: this.translocoService.translate('Column chooser'),
        icon: 'icon icon-bold-layout-column',
        onClick: () => {
          this.changeColumnVisibility();
        },
      },
    });

    e.toolbarOptions.items.splice(0, 0, {
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'after',
      cssClass: 'basic-button-design',
      options: {
        text: this.translocoService.translate('add'),
        icon: 'add',
        onClick: () => {
          this.router.navigate(['users/create']).then();
        },
      },
    });
  }

  resetListState(patientGrid: DxDataGridComponent): void {
    patientGrid.instance.refresh();
    patientGrid.instance.state({});
  }
}
