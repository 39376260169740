import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
  BloodPressureData,
  BloodSugarData,
  EtCo2Data,
  HeartRateData,
  HemoglobinData,
  PulseData,
  RespiratoryRateData,
  SpCoData,
  SpO2Data,
  TemperatureData,
  VitalSignsServable,
} from '@nida-web/api/generic-interfaces/patient-management';
import { VitalSignsRestService } from '../actions/vital-signs.rest.service';
import { PulseRegularList } from '../models/pulseRegularList';

@Injectable({
  providedIn: 'root',
})
export class VitalSignsRESTAdapterService implements VitalSignsServable {
  constructor(private vitalSignsRESTService: VitalSignsRestService) {}

  getAfByProtocolId(protocolId: number): ReplaySubject<RespiratoryRateData> {
    const resultSubject = new ReplaySubject<RespiratoryRateData>(1);

    this.vitalSignsRESTService.getAfByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getBdByProtocolId(protocolId: number): ReplaySubject<BloodPressureData> {
    const resultSubject = new ReplaySubject<BloodPressureData>(1);

    this.vitalSignsRESTService.getBdByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getBzByProtocolId(protocolId: number): ReplaySubject<BloodSugarData> {
    const resultSubject = new ReplaySubject<BloodSugarData>(1);

    this.vitalSignsRESTService.getBzByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getCo2ByProtocolId(protocolId: number): ReplaySubject<EtCo2Data> {
    const resultSubject = new ReplaySubject<EtCo2Data>(1);

    this.vitalSignsRESTService.getCo2ByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getCoByProtocolId(protocolId: number): ReplaySubject<SpCoData> {
    const resultSubject = new ReplaySubject<SpCoData>(1);

    this.vitalSignsRESTService.getCoByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getHbByProtocolId(protocolId: number): ReplaySubject<HemoglobinData> {
    const resultSubject = new ReplaySubject<HemoglobinData>(1);

    this.vitalSignsRESTService.getHbByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getHfByProtocolId(protocolId: number): ReplaySubject<HeartRateData> {
    const resultSubject = new ReplaySubject<HeartRateData>(1);

    this.vitalSignsRESTService.getHfByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getPulsByProtocolId(protocolId: number): ReplaySubject<PulseData> {
    const resultSubject = new ReplaySubject<PulseData>(1);

    this.vitalSignsRESTService.getPulsByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getSpo2ByProtocolId(protocolId: number): ReplaySubject<SpO2Data> {
    const resultSubject = new ReplaySubject<SpO2Data>(1);

    this.vitalSignsRESTService.getSpo2ByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getTempByProtocolId(protocolId: number): ReplaySubject<TemperatureData> {
    const resultSubject = new ReplaySubject<TemperatureData>(1);

    this.vitalSignsRESTService.getTempByProtocolId(protocolId).subscribe((content) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }

  getPulseRegularByProtocolId(protocolId: number): ReplaySubject<PulseData> {
    const resultSubject = new ReplaySubject<PulseData>(1);

    this.vitalSignsRESTService.getPulseRegularById(protocolId).subscribe((content: PulseRegularList) => {
      resultSubject.next(content);
    });

    return resultSubject;
  }
}
