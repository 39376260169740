import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Kis, KisList } from '../..';

@Injectable({
  providedIn: 'root',
})
export abstract class KisListServable {
  public abstract getKisList(): Observable<KisList>;

  public abstract getKis(id: number): Observable<Kis>;

  public abstract getKisByProtocolId(protocolId: string): Observable<Kis>;

  public abstract mapKis(id: number, nidaId: string, protocolId: number, exportAt?: Date): Observable<Kis>;

  public abstract unmapKis(id: number): Observable<Kis>;
}
