import { availableCountries, Country } from '../models/country';
export class Countries {
  private countries: Country[];

  constructor(flagImgPath: string) {
    this.countries = [];
    this.countries.push({
      Code: 'de',
      Language: 'German',
      Name: 'Germany',
      FlagImageSrc: flagImgPath + 'de.svg',
    });

    this.countries.push({
      Code: 'en',
      Language: 'English',
      Name: 'England',
      FlagImageSrc: flagImgPath + 'en.svg',
    });

    // this.countries.push({
    //   Code: 'zh',
    //   Language: 'Chinese',
    //   Name: 'China',
    //   FlagImageSrc: flagImgPath + 'zh.svg',
    // });
  }

  getCountries(): Country[] {
    return this.countries;
  }

  getCountryCodes(): availableCountries[] {
    return this.countries.map((a) => a.Code);
  }
}
