export class UuidCreator {
  // create a UUID from zero for login
  public static createUUID(): string {
    const hexDigitArray: string[] = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      hexDigitArray[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    // bits 12-15 of the time_hi_and_version field to 0010
    hexDigitArray[14] = '4';
    // bits 6-7 of the clock_seq_hi_and_reserved to 01
    // eslint-disable-next-line no-bitwise
    hexDigitArray[19] = hexDigits.substr((parseFloat(hexDigitArray[19]) & 0x3) | 0x8, 1);
    hexDigitArray[8] = hexDigitArray[13] = hexDigitArray[18] = hexDigitArray[23] = '-';

    return hexDigitArray.join('');
  }
}
