<!--suppress TypeScriptValidateTypes -->
<nida-web-card class="mb-4" headline="Notification settings">
  <div class="row">
    <div class="col-9">{{ 'Desktop notifications' | transloco }}</div>
    <div class="col-3 d-flex justify-content-end">
      <dx-switch
        (onValueChanged)="onNotificationValueChange($event)"
        [switchedOffText]="'Off' | transloco"
        [switchedOnText]="'On' | transloco"
        [value]="isNotificationStatusActive()"
      >
      </dx-switch>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-9">{{ 'Notification sound' | transloco }}</div>
    <div class="col-3 d-flex justify-content-end">
      <dx-switch
        (onValueChanged)="onSoundValueChange($event)"
        [switchedOffText]="'Off' | transloco"
        [switchedOnText]="'On' | transloco"
        [value]="isSoundStatusActive()"
      >
      </dx-switch>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-6">{{ 'Active file' | transloco }}</div>
    <div class="col-6 d-flex justify-content-end">
      <dx-select-box
        (onValueChanged)="onSoundFileChange($event)"
        [acceptCustomValue]="false"
        [items]="['Gong', 'Martinshorn']"
        [value]="getAudioFileName()"
      >
      </dx-select-box>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-9">{{ 'Filter' | transloco }}</div>
    <div class="col-3 d-flex justify-content-end">
      <dx-switch
        (onValueChanged)="onFilterOnChange($event)"
        [switchedOffText]="'Off' | transloco"
        [switchedOnText]="'On' | transloco"
        [value]="isFilterActive()"
      >
      </dx-switch>
    </div>
  </div>
  <!-- only display filter lists, if data is loaded -->
  <div class="row mt-2" *ngIf="isFilterActive() && filterListAvailable">
    <div class="col-12">
      <dx-filter-builder
        (onValueChanged)="onFilterValueChange($event)"
        [fields]="getFilterFieldList()"
        [value]="getFilterValue()"
      ></dx-filter-builder>
    </div>
  </div>
</nida-web-card>
