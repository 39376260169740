export type ERAISLocalisation = Array<ERAISLocalisationItem>;

export interface ERAISLocalisationItem {
  name?: string;
  severity?: ERSeverityEnum;
}

export enum ERSeverityEnum {
  NONE,
  LIGHT,
  MIDDLE,
  HEAVY,
}
