<dx-data-grid
  (onRowClick)="onRowClick($event)"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [dataSource]="this.pdfList"
  [rowAlternationEnabled]="true"
  [showBorders]="true"
  [showColumnLines]="true"
  [showRowLines]="true"
  [wordWrapEnabled]="true"
  keyExpr="nidaId"
  noDataText="{{ 'No data' | transloco }}"
>
  <dxo-paging *ngIf="pageSize > 0" [enabled]="true" [pageSize]="this.pageSize"></dxo-paging>
  <dxo-paging *ngIf="!pageSize" [enabled]="false"></dxo-paging>

  <dxi-column
    *ngIf="!disableDescriptionColumn"
    [caption]="'Description' | transloco"
    alignment="left"
    dataField="description"
    dataType="string"
    [cellTemplate]="'transloco'"
  ></dxi-column>
  <dxi-column
    [caption]="'Status' | transloco"
    alignment="left"
    dataField="status"
    dataType="string"
    [cellTemplate]="'transloco'"
  ></dxi-column>
  <dxi-column [caption]="'Date' | transloco" dataField="timeStamp" dataType="datetime" cellTemplate="dateTemplate" width="135"></dxi-column>
  <div *dxTemplate="let data of 'dateTemplate'">
    {{ data.value | date: 'dd.MM.yyyy, HH:mm' }}
  </div>
  <div *dxTemplate="let d of 'transloco'">
    {{ d.value | transloco }}
  </div>
</dx-data-grid>

<dx-popup
  #pdfPopUp
  [disabled]="false"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  [fullScreen]="true"
  [visible]="false"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  (onShown)="refreshPDF()"
  (onHiding)="clearPDF()"
>
  <ng-container *dxTemplate="let data of 'content'">
    <ng2-pdfjs-viewer
      #pdfViewer
      [openFile]="false"
      [download]="download"
      [downloadFileName]="downloadFileName"
      [print]="true"
    ></ng2-pdfjs-viewer>
  </ng-container>
</dx-popup>
