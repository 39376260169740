import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Patient } from '../models/patient.model';

@Injectable({
  providedIn: 'root',
})
export abstract class PatientServable {
  public abstract getPatient(patientId: number): Observable<Patient>;
  public abstract getPatientByProtocolId(protocolId: number): Observable<Patient>;
  public abstract putPatient(patient: Patient, id: number): Observable<Patient>;
}
