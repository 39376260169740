import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Kis } from '../../models/kis.model';
import { KisListServable } from '../../interfaces/kis-list-servable.interface';
import { SessionManagerService } from '@nida-web/api/rest/authentication';

@Injectable({
  providedIn: 'root',
})
export class KisStoreService {
  public kisDataLoaded: BehaviorSubject<boolean>;

  private kisData: Kis[];
  private skipMappedDataSets: boolean;

  constructor(private kisListServable: KisListServable, private currentUserServable: SessionManagerService) {
    this.kisData = [];
    this.skipMappedDataSets = false;
    this.kisDataLoaded = new BehaviorSubject(false);
    this.currentUserServable.getSessionInformation().subscribe((userObject) => {
      if (!(userObject !== null && userObject !== undefined && userObject.userId !== undefined && userObject.userId > -1)) {
        this.kisData = [];
      }
    });
  }

  public getKisList(skipMappedDataSets: boolean): Kis[] {
    this.skipMappedDataSets = skipMappedDataSets;
    this.buildList();
    return this.kisData;
  }

  public buildList(): void {
    this.kisData = [];
    this.kisListServable.getKisList().subscribe((obj) => {
      if (obj === undefined || obj === null) {
        return;
      }
      if (this.skipMappedDataSets) {
        for (const newKis of obj) {
          if (this.skipMappedDataSets && newKis.nidaId === null) {
            this.kisData.push(newKis);
          }
        }
      } else {
        for (const newKis of obj) {
          this.kisData.push(newKis);
        }
      }
      this.kisDataLoaded.next(true);
    });
  }

  public getKisLoaded(): boolean {
    return this.kisDataLoaded.value;
  }
}
