import { Component, Input, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Kis } from '../../models/kis.model';
import { KisListServable } from '../../interfaces/kis-list-servable.interface';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nida-web-kis-row-detail',
  templateUrl: './kis-row-detail.component.html',
  styleUrls: ['./kis-row-detail.component.scss'],
})
export class KisRowDetailComponent {
  @Input() currentKis: Kis | undefined;
  @Input() nidaId: string | undefined;
  @Input() protocolId: number | undefined;
  @ViewChild('kisGrid', { static: false }) dataGrid!: DxDataGridComponent;

  constructor(
    protected router: Router,
    private kisListServable: KisListServable,
    private translocoService: TranslocoService,
    private activeRoute: ActivatedRoute
  ) {}

  public sendKisMapping(): void {
    if (this.currentKis && this.nidaId && this.protocolId) {
      this.kisListServable.mapKis(this.currentKis.id, this.nidaId, this.protocolId, new Date()).subscribe(() => {
        notify({
          message: this.translocoService.translate('Patient successfully mapped to HIS data set'),
          type: 'Success',
          displayTime: 5000,
        });
        this.triggerNavigation();
      });
    }
  }

  triggerNavigation(): void {
    this.router.navigate(['../'], { relativeTo: this.activeRoute }).then();
  }
}
