import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationSettingsComponent } from './views/notification-settings/notification-settings.component';
import { TranslocoModule } from '@ngneat/transloco';
import { DxFilterBuilderModule, DxSelectBoxModule, DxSwitchModule } from 'devextreme-angular';
import { SharedUiModule } from '@nida-web/shared/ui';

@NgModule({
  imports: [CommonModule, TranslocoModule, DxSwitchModule, DxSelectBoxModule, SharedUiModule, DxFilterBuilderModule],
  declarations: [NotificationSettingsComponent],
  exports: [NotificationSettingsComponent],
})
export class SharedNotificationsModule {}
