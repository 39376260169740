import { Injectable } from '@angular/core';
import { Observable, of, Subscription, throwError, timer } from 'rxjs';
import { LoginRESTService } from '../actions/login.rest.service';
import { UuidCreator } from '../helper/UuidCreator';
import { HashCreator } from '../helper/HashCreator';
import { AuthenticationServable, LoginCredential, LoginInformation } from '@nida-web/api/generic-interfaces/authentication';
import { Router } from '@angular/router';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { JsonWebTokenMeta } from '../models/json-web-token-meta';
import { PlainLoginRESTService } from '../actions/plain-login.rest.service';
import { UserinfoRESTService } from '../actions/userinfo.rest.service';
import { Method } from '../models/method';
import { AuthenticationRestService } from '../actions/authentication.rest.service';
import { SecondFactorResponse } from '../models/second-factor-response';
import { TwoFactorAuthenticationRestService } from '../actions/two-factor-authentication-rest.service';
import { SessionManagerService } from '../services/session-manager.service';
import { PasswordAdapterService } from '@nida-web/api/rest/nidaserver/password3';

@Injectable({
  providedIn: 'root',
})
/**
 * This class is used in our "default" login
 * for oidc login see: oidc-adapter.service.ts
 */
export class AuthenticationAdapterService implements AuthenticationServable {
  private renewActive: boolean;
  private renewTimer: Observable<number>;
  protected timerSubscription: Subscription;
  private sessionUuid: string | undefined;
  private setNidaMobilePassword = false;
  /** The origin route to which the user is returned after the login */
  public returnUrl: string | undefined;
  public tenantId: string | undefined;

  constructor(
    private authenticationRestService: AuthenticationRestService,
    private twoFactorAuthenticationRestService: TwoFactorAuthenticationRestService,
    private loginRESTService: LoginRESTService,
    private router: Router,
    private userInfoService: UserinfoRESTService,
    private plainLoginService: PlainLoginRESTService,
    private sessionManagerService: SessionManagerService,
    private passwordMobileService: PasswordAdapterService
  ) {
    this.renewActive = false;
    this.renewTimer = timer(10000, 1000 * 60 * 30);
    this.timerSubscription = this.renewTimer.subscribe(() => {
      if (this.renewActive) {
        const actualToken = this.getToken();
        if (actualToken) {
          const parsedToken: JsonWebTokenMeta = JSON.parse(atob(actualToken.split('.')[1]));
          const endDate = new Date(parsedToken.exp * 1000);
          console.log('TokenChecker - Token is Valid until: ', endDate);
          const checkTime = new Date(Date.now() + 1000 * 60 * 60);

          if (checkTime > endDate) {
            console.log('TokenChecker - Token is about to expire, Renewing');
            this.loginRESTService
              .authRenew()
              .pipe(take(1))
              .subscribe((newToken) => {
                this.setToken(newToken);
                console.log('TokenChecker - Token renewed.');
              });
          } else {
            console.log('TokenChecker - Token is still valid.');
          }
        }
      }
    });
  }

  getAuthState(): Observable<LoginInformation> {
    let resultLoginInfo = new LoginInformation();
    return this.loginRESTService.getCurrentUser().pipe(
      map((response) => {
        resultLoginInfo = {
          userName: response.userName,
          permissions: response.permissions,
          loggedIn: true,
          id: response.id,
          clinics: response.postfach,
          idMandanten: response.mandant,
          idStandorte: response.id_standorte,
        };
        this.renewActive = true;
        return resultLoginInfo;
      }),
      catchError(() => {
        resultLoginInfo = {
          userName: '',
          permissions: [],
          loggedIn: false,
          id: -1,
          clinics: [],
          idMandanten: -1,
          idStandorte: [],
        };
        this.renewActive = false;

        return of(resultLoginInfo);
      })
    );
  }

  encryptAndAuthenticateLogin(credential: LoginCredential): Observable<string | SecondFactorResponse> {
    const uuId = UuidCreator.createUUID();
    this.sessionUuid = uuId;

    return this.authenticationRestService.authNew(uuId).pipe(
      switchMap((salt) => {
        if (salt) {
          const passwordMD5 = HashCreator.createHash(credential.password, '', false, 'MD5');
          const passwordEncrypted = HashCreator.createHash(passwordMD5, salt, true);
          return this.authenticationRestService.authValidate(uuId, credential.userName, passwordEncrypted, {
            uuid: uuId,
            login: credential.userName,
            pass: passwordEncrypted,
          });
        } else {
          return throwError(() => new Error('No salt'));
        }
      })
    );
  }

  realLogin(credential: LoginCredential, returnUrl?: string, tenantId?: string, setNidaMobilePassword?: boolean): void {
    this.returnUrl = returnUrl;
    this.tenantId = tenantId;
    this.setNidaMobilePassword = setNidaMobilePassword ? setNidaMobilePassword : false;

    this.login(credential).subscribe((loginInfo: LoginInformation) => {
      if (loginInfo.loggedIn && this.tenantId) {
        localStorage.setItem('lastKnownClient', this.tenantId);
      }
      this.handleLoginNavigation(loginInfo);
    });
  }

  login(credential: LoginCredential): Observable<LoginInformation> {
    return this.userInfoService.getAuthenticationMethod(credential.userName).pipe(
      switchMap((result) => {
        if (result?.method === Method.MethodEnum.Plain) {
          return this.plainLoginService.plainLogin({ login: credential.userName, pass: credential.password });
        } else {
          return this.encryptAndAuthenticateLogin(credential);
        }
      }),
      catchError(() => {
        return this.encryptAndAuthenticateLogin(credential);
      }),
      switchMap(this.handleAuthValidationResponse.bind(this)),
      map((loginInfo) => {
        this.saveLoginInfoToSession(loginInfo);
        return loginInfo;
      })
    );
  }

  login2FA(code: string): void {
    if (this.sessionUuid) {
      this.twoFactorAuthenticationRestService
        .authValidate2Factor(this.sessionUuid, code)
        .pipe(
          switchMap(this.handleAuthValidationResponse.bind(this)),
          map((loginInfo) => {
            this.saveLoginInfoToSession(loginInfo);
            return loginInfo;
          })
        )
        .subscribe((loginInfo) => {
          if (loginInfo.loggedIn && this.tenantId) {
            localStorage.setItem('lastKnownClient', this.tenantId);
          }
          this.handleLoginNavigation(loginInfo);
        });
    }
  }

  logout(): Observable<LoginInformation> {
    return this.getAuthState().pipe(
      switchMap((loginInfo) => {
        if (loginInfo.loggedIn) {
          return this.loginRESTService.logout().pipe(
            map(() => {
              this.removeToken();
              loginInfo.loggedIn = false;
              this.renewActive = false;

              return loginInfo;
            })
          );
        } else {
          this.removeToken();
          this.renewActive = false;
          return of(loginInfo);
        }
      })
    );
  }

  navigateTo2FAActivation(): void {
    this.router.navigate(['/activate-second-factor']).then();
  }

  get2FASecret(): Observable<string> {
    return this.twoFactorAuthenticationRestService.activate2fa().pipe(
      map((secret) => {
        return secret;
      })
    );
  }

  save2FASecret(code: string): Observable<boolean> {
    return this.twoFactorAuthenticationRestService.verifysecondfactor(code).pipe(
      map(() => {
        return true;
      })
    );
  }

  startLogin(): void {
    this.removeToken();
    this.router.navigate(['login']).then();
  }

  startApp(): void {
    console.log('startApp');
  }

  private handleAuthValidationResponse(validateResponse: string | SecondFactorResponse): Observable<LoginInformation> {
    let authToken: string | undefined;
    let twoFA = false;
    let twoFaEnforcement = false;

    if (typeof validateResponse !== 'string') {
      authToken = validateResponse.token;
      twoFA = validateResponse.twoFA; // cast to boolean
      twoFaEnforcement = !!validateResponse.twoFaEnforcement; // cast to boolean
    } else {
      authToken = validateResponse;
    }

    this.setToken(authToken);

    const responseUser: LoginInformation = {
      loggedIn: false,
      twoFA: twoFA,
      twoFaEnforcement: twoFaEnforcement,
      loginToken: authToken,
    };

    if (!twoFA && !twoFaEnforcement) {
      // get user info from server
      return this.getAuthState().pipe(
        map((userInfo) => {
          responseUser.id = userInfo.id;
          responseUser.userName = userInfo.userName;
          responseUser.idMandanten = userInfo.idMandanten;
          responseUser.permissions = userInfo.permissions;
          responseUser.loggedIn = userInfo.loggedIn;
          responseUser.clinics = userInfo.clinics;
          responseUser.idStandorte = userInfo.idStandorte;
          responseUser.clientInfo = userInfo.clientInfo;
          return responseUser;
        })
      );
    } else {
      // return of(responseUser) if twoFA or twoFaEnforcement is true
      return of(responseUser);
    }
  }

  private saveLoginInfoToSession(loginInfo: LoginInformation): void {
    this.sessionManagerService.setSessionInformation({
      loggedIn: loginInfo.loggedIn,
      loginToken: loginInfo.loginToken,
      userName: loginInfo.userName,
      permissions: loginInfo.permissions,
      userId: loginInfo.id,
      clinics: loginInfo.clinics,
      tenantId: loginInfo.idMandanten !== undefined ? loginInfo.idMandanten : 0,
      clientInfo: loginInfo.clientInfo,
      locationId: loginInfo.idStandorte,
    });
  }

  private handleLoginNavigation(loginInfo: LoginInformation): void {
    if (loginInfo.twoFA) {
      this.router.navigate(['/second-factor-code']).then();
    } else if (loginInfo.twoFaEnforcement) {
      this.router.navigate(['/activate-second-factor']).then();
    } else if (this.setNidaMobilePassword) {
      this.passwordMobileService.statusUserMobilePassword(loginInfo.id ? loginInfo.id : -1).subscribe((result) => {
        if (result.passwort_mobil && result.passwort_mobil > 0) {
          this.navigateBasedOnReturnUrl(this.returnUrl);
        } else {
          this.router.navigate(['users/set-initial-nidamobile-password']).then();
        }
      });
    } else {
      this.navigateBasedOnReturnUrl(this.returnUrl);
    }
  }

  private navigateBasedOnReturnUrl(returnUrl?: string) {
    if (returnUrl) {
      this.router.navigate([returnUrl]).then();
    } else {
      this.router.navigate(['/']).then();
    }
  }

  /**
   * Returns the authentication token from the local storage
   * @private
   */
  private getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  /**
   * Sets the token to the local storage if it is not undefined
   * @param authToken the authentication token to set
   * @private
   */
  private setToken(authToken: string | undefined): void {
    if (authToken) {
      localStorage.setItem('access_token', authToken);
    }
  }

  private removeToken(): void {
    localStorage.removeItem('access_token');
  }
}
