import { Component, Input } from '@angular/core';
import { SessionInformation, SessionManagerService } from '@nida-web/api/rest/authentication';
import { PositionConfig } from 'devextreme/animation/position';

@Component({
  selector: 'nida-web-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent {
  @Input()
  menuItems: any;

  @Input()
  menuMode: string;

  public menuPosition: PositionConfig = {
    my: { x: 'center', y: 'top' },
    at: { x: 'center', y: 'bottom' },
  };

  public sessionInfo: SessionInformation;

  constructor(private sessionManagerService: SessionManagerService) {
    this.sessionManagerService.getSessionInformation().subscribe((data) => {
      if (data) {
        this.sessionInfo = data;
      }
    });
  }
}
