import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'translocoWord',
})
export class TranslocoWordPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  /**
   * Try to translate chinese strings that did not match any of the given translations
   * This will be done by trying to separate strings with ":" and translate them individually by using the translation file
   *
   * This has to be done because in our client (where everything is documented) the strings are selected as single strings and concatenated
   * to one big string and saved into DB. So we only get this concatenated string.
   *
   * This Pipe may be deactivated, when we have the full chinese DB storage in our translation file...
   *
   * At the moment this Pipe ONLY works with strings that should be translated into chinese language
   *
   * @param value
   */
  transform(value: string): string {
    let translatedResult = value;
    const translated: string[] = [];
    // we can only work with strings and such should contain at least one latin letter
    // - if there is none the string may already be translated and we do nothing here
    if (value && this.translocoService.getActiveLang() === 'zh' && value.match(/[A-Za-z]+/)) {
      // split it by the ":", because in the most cases this separates
      // the first part from the second part and this will probably gives us the best translation results
      const words = value.split(':');
      for (const word of words) {
        const element = word.trim();
        translated.push(this.translocoService.translate(element));
      }
      translatedResult = translated.join(':');
    }
    return translatedResult;
  }
}
