import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigRESTServable, Configuration } from '@nida-web/api/generic-interfaces/config';
import { catchError } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';
import { TranslocoService } from '@ngneat/transloco';
import { Findings } from '../models/findings';
import { AudioRecordList } from '../models/audioRecordList';

@Injectable({
  providedIn: 'root',
})
export class PatientDetailRESTService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  /** API Endpoint */
  private apiPrefix: string;
  private subscription: Subscription;

  constructor(protected httpClient: HttpClient, private configSubject: ConfigRESTServable, private translocoService: TranslocoService) {
    this.subscription = new Subscription();
    this.apiPrefix = '';
    const configSub = this.configSubject.getRESTURL().subscribe((apiUrl) => {
      this.apiPrefix = apiUrl;
    });

    this.subscription.add(configSub);
    this.subscription.unsubscribe();
  }

  public getDetailPictures(nidaId: string) {
    if (!nidaId) {
      throw new Error('Required parameter id was null or undefined when calling getDetailPictures.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .get<any>(`${this.apiPrefix}attachments/pictures/${encodeURIComponent(nidaId)}`, {
        withCredentials: this.configuration.withCredentials,
        headers,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 403) {
            notify({
              message: this.translocoService.translate('Not authenticated'),
              type: 'Error',
              displayTime: 10000,
            });
            return throwError(err.message);
          }
          return throwError(err.message);
        })
      );
  }

  public getDetailBefund(revision: string): Observable<Findings> {
    if (!revision) {
      throw new Error('Required parameter id was null or undefined when calling getDetailBefund.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .get<Findings>(`${this.apiPrefix}nidatracker/clinic/protocol/${encodeURIComponent(revision)}/details/befund`, {
        withCredentials: this.configuration.withCredentials,
        headers,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 403) {
            notify({
              message: this.translocoService.translate('Not authenticated'),
              type: 'Error',
              displayTime: 10000,
            });
            return throwError(err.message);
          }
          return throwError(err.message);
        })
      );
  }

  public getDetailSinglePicture(nidaId: string, pictureId: string) {
    if (!nidaId) {
      throw new Error('Required parameter id was null or undefined when calling getDetailSinglePicture.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['image/jpeg'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .get<any>( // clinic/protocol/{nida_id}/picture/{id}/{resolution}
        `${this.apiPrefix}attachments/pictures/${encodeURIComponent(nidaId)}/picture/${encodeURIComponent(pictureId)}`,
        {
          withCredentials: this.configuration.withCredentials,
          headers,
          // @ts-ignore
          responseType: 'blob',
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 403) {
            notify({
              message: this.translocoService.translate('Not authenticated'),
              type: 'Error',
              displayTime: 10000,
            });
          }
          return throwError(err.message);
        })
      );
  }

  /**
   * Gets the audio record with the given id
   * Gets the audio record with the given id
   * @param protocolId The ID of the protocol data set we want to work with
   * @param id The ID of the data set we want to work with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAudioRecordById(
    protocolId: number,
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'audio/_*' | 'text/plain' }
  ): Observable<Blob>;
  public getAudioRecordById(
    protocolId: number,
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'audio/_*' | 'text/plain' }
  ): Observable<HttpResponse<Blob>>;
  public getAudioRecordById(
    protocolId: number,
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'audio/_*' | 'text/plain' }
  ): Observable<HttpEvent<Blob>>;
  public getAudioRecordById(
    protocolId: number,
    id: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'audio/_*' | 'text/plain' }
  ): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getAudioRecordById.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAudioRecordById.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['audio/_*', 'text/plain'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get(
      `${this.apiPrefix}dispatch/protocols/${encodeURIComponent(String(protocolId))}/audiorecords/${encodeURIComponent(String(id))}`,
      {
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Gets audio records corresponding to the protocol
   * Gets audio records corresponding to the protocol
   * @param protocolId The ID of the protocol data set we want to work with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAudioRecords(
    protocolId: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<AudioRecordList>;
  public getAudioRecords(
    protocolId: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpResponse<AudioRecordList>>;
  public getAudioRecords(
    protocolId: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' | 'text/plain' }
  ): Observable<HttpEvent<AudioRecordList>>;
  public getAudioRecords(protocolId: number, observe: any = 'body', reportProgress = false): Observable<any> {
    if (protocolId === null || protocolId === undefined) {
      throw new Error('Required parameter protocolId was null or undefined when calling getAudioRecords.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.get<AudioRecordList>(
      `${this.apiPrefix}dispatch/protocols/${encodeURIComponent(String(protocolId))}/audiorecords`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
